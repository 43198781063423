import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { employeeSidebar, hrSidebar } from "./SidebarData";
import SubMenu from "./SubMenu";
import useLoadingIndicator from "../Loader/Loader";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions/index";
import { showErrorToastMessage } from "../Toasts/Toasts";
import { Strings } from "../../constant/Strings";

const SidebarMenu = styled.div`
  width: 250px;
  height: 100vh;
  // background-color: #27427A;
  background-color: #1853A2;
  // background-image: linear-gradient(#FF7F06, #FF5717);
  position: fixed;
 margin-top: 0px;
 height: calc(90vh-15px)
 position: sticky;
 overflow: auto;
 z-index: 1;
 top: 70px;
 min-width: 250px;
 left: 0px;
 box-shadow: none;
 border: none;
 order: 1;
 font-family: 'Poppins';
  margin-left: 0px;
`;

const SidebarMobileMenu = styled.div`
  width: 56px;
  height: 100vh;
  // background-color: #27427A;
  background-color: #1853A2;
  // background-image: linear-gradient(#FF7F06, #FF5717);
  position: fixed;
 margin-top: 0px;
 height: calc(90vh-15px)
 position: sticky;
 overflow: hidden;
 z-index: 1;
 top: 70px;
 min-width: 56px;
 left: 0px;
 box-shadow: none;
 border: none;
 order: 1;
 font-family: 'Poppins';
  margin-left: 0px;
`;

const Sidebar = (props: any) => {
  const { hideSideBar } = props;

  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const dispatch = useDispatch();
  const [sidebarData, setsidebarData] = useState([]);
  const role = localStorage.getItem("role");
  const [isCoach, setIsCoach] = useState(false);
  const [isCoachChecker, setIsCoachChecker] = useState(false);
  const [hrCheckerList, setHrCheckerList] = useState(false);
  const [managerCheckerList, setManagerCheckerList] = useState(false);
  const [financeCheckerList, setFinanceCheckerList] = useState(false);
  const [coachingRequestsList, setCoachingRequestsList] = useState(false);
  const [skillLinksMakerList, setSkillLinkMakerList] = useState(false);
  const [ijpMakerList, setIjpMakerList] = useState(false);
  const [skillLinksReferralPayoutsList, setSkillLinksReferralPayoutsList] =
    useState(false);
  const [benchList, setBenchList] = useState(false);

  useEffect(() => {
    showLoadingIndicator();
    if (role && role.includes("hr")) {
      setsidebarData(hrSidebar);
    } else {
      setsidebarData(employeeSidebar);
    }

    if (role && role.includes("coaching-and-mentoring-coach")) {
      setIsCoach(true);
    } else {
      setIsCoach(false);
    }

    if (role && role.includes("coaching-and-mentoring-checker")) {
      setIsCoachChecker(true);
    } else {
      setIsCoachChecker(false);
    }

    if (role && role.includes("learninganddevelopment-checker")) {
      setHrCheckerList(false);
    } else {
      setHrCheckerList(true);
    }

    if (role && role.includes("learninganddevelopment-manager")) {
      setManagerCheckerList(false);
    } else {
      setManagerCheckerList(true);
    }

    if (role && role.includes("learninganddevelopment-finance")) {
      setFinanceCheckerList(false);
    } else {
      setFinanceCheckerList(true);
    }

    if (role && role.includes("coaching-and-mentoring-hrbp")) {
      setCoachingRequestsList(false);
    } else {
      setCoachingRequestsList(true);
    }

    if (role && role.includes("skill-link-maker")) {
      setSkillLinkMakerList(false);
    } else {
      setSkillLinkMakerList(true);
    }

    if (role && role.includes("internal-job-maker")) {
      setIjpMakerList(false);
    } else {
      setIjpMakerList(true);
    }

    if (
      role &&
      (role.includes("skill-link-payout-master") ||
        role.includes("skill-link-payout-checker"))
    ) {
      setSkillLinksReferralPayoutsList(false);
    } else {
      setSkillLinksReferralPayoutsList(true);
    }
    if (
      role &&
      (role.includes("rmg-maker") ||
        role.includes("rmg-checker") ||
        role.includes("resource-maker") ||
        role.includes("resource-checker") ||
        role.includes("rmg-bench") ||
        role.includes("rmg-pmo"))
    ) {
      setBenchList(false);
    } else {
      setBenchList(true);
    }
    hideLoadingIndicator();
  }, [role]);

  return (
    <>
      {loadingComponent()}
      {hideSideBar ? (
        <SidebarMenu>
          <div className="logo-wrapper"></div>
          {sidebarData?.map(
            (item: any, index: React.Key | null | undefined) => {
              return (
                <SubMenu
                  item={item}
                  key={index}
                  hideSideBar={hideSideBar}
                  isCoach={isCoach}
                  isCoachChecker={isCoachChecker}
                  hrCheckerList={hrCheckerList}
                  managerCheckerList={managerCheckerList}
                  financeCheckerList={financeCheckerList}
                  coachingRequestsList={coachingRequestsList}
                  skillLinksMakerList={skillLinksMakerList}
                  ijpMakerList={ijpMakerList}
                  skillLinksReferralPayoutsList={skillLinksReferralPayoutsList}
                  benchList={benchList}
                />
              );
            }
          )}
        </SidebarMenu>
      ) : (
        <SidebarMobileMenu>
          <div className="logo-wrapper"></div>
          {sidebarData?.map(
            (item: any, index: React.Key | null | undefined) => {
              return (
                <SubMenu
                  item={item}
                  key={index}
                  hideSideBar={hideSideBar}
                  isCoach={isCoach}
                  isCoachChecker={isCoachChecker}
                  hrCheckerList={hrCheckerList}
                  managerCheckerList={managerCheckerList}
                  financeCheckerList={financeCheckerList}
                  coachingRequestsList={coachingRequestsList}
                  skillLinksMakerList={skillLinksMakerList}
                  ijpMakerList={ijpMakerList}
                  skillLinksReferralPayoutsList={skillLinksReferralPayoutsList}
                  benchList={benchList}
                />
              );
            }
          )}
        </SidebarMobileMenu>
      )}
    </>
  );
};

export default Sidebar;
