import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import "./App.scss";
import Skills from "./pages/EmployeeDb/EmployeeDetails/DbSideBarPages/Skills/Skills";
import BenchMgmt from "./pages/Resource Management/Bench Management/BenchMgmt";
import Employeedb from "./pages/EmployeeDb/EmployeeDetails/EmployeeManagement/EmployeeDb";
import Preferences from "./pages/EmployeeDb/EmployeeDetails/DbSideBarPages/Preferences/Preferences";
import ResourceButtons from "./pages/Resource Management/resource-buttons";
import LearningDevelopment from "./pages/LearningDevelopment/LearningDevelopment";
import "./App.scss";
import SkillLinksData from "./pages/SkillLinks/SkillLinksData";
import PostaJob from "./pages/SkillLinks/PostaJob";
import ReferResource from "./pages/SkillLinks/ReferResource";
import Career from "./pages/EmployeeDb/EmployeeDetails/DbSideBarPages/CareerGoals/Career";
import Certifications from "./pages/EmployeeDb/EmployeeDetails/DbSideBarPages/Certifications/Certifications";
import Potential from "./pages/EmployeeDb/EmployeeDetails/DbSideBarPages/PotentialRediness/Potential";
import Qualifications from "./pages/EmployeeDb/EmployeeDetails/DbSideBarPages/Qualification/Qualifications";
import Work from "./pages/EmployeeDb/EmployeeDetails/DbSideBarPages/WorkAchievements/Work";
import LoginPage from "./component/Login/LoginPage";
// import CompetencyDev from "./pages/CompetencyPlanning/CompetencyDevelopment/CompetencyDev";
import ProjectRepo from "./pages/Resource Management/ProjectRepository/ProjectrepositoryData";
// import CompetencyManage from "./pages/CompetencyPlanning/CompetencyManagement/CompetencyManage";
// import SelfAssessmentAdd from "./pages/CompetencyPlanning/SelfAssessment/SelfAssessmentBranchStack";
// import SelfAssessment from "./pages/CompetencyPlanning/SelfAssessment/SelfAssessment";

import ExperiencesNewPage from "./pages/EmployeeDb/EmployeeDetails/DbSideBarPages/Experiences/ExperiencesNewPage";
import DbSidebar from "./pages/EmployeeDb/EmployeeDetails/DbSidebar/DbSidebar";
import ResourceAddingTable from "./pages/Resource Management/Resource Allocation/ResourceAdding/ResourceAddingTable";
import ResourceAllocation from "./pages/Resource Management/Resource Allocation/ResourceAllocation";
import HandleToken from "./Auth/handle-token";
import EmployeeProjectDetails from "./pages/Resource Management/Bench Management/EmployeeProjectDetails";
import ProtectedRoute from "./pages/ProtectedRoutes";
import SharedLayout from "./pages/SharedLayout.jsx";
// import CompetencyBranchStack from "./pages/CompetencyPlanning/CompetencyManagement/CompetencyBranchStack";
import JobPost from "./pages/InternalJobPosting/JobPost";
import ViewAppications from "./pages/InternalJobPosting/ViewApplication";
// import CompetencyReport from "./pages/CompetencyPlanning/CompetencyReport/CompetencyReport";
import JobApplicationsTable from "./pages/InternalJobPosting/JobApplicationsTable";

import ReferSpecificJob from "./pages/SkillLinks/ReferSpecificJob";
import ProgramMaster from "./pages/InseedProgram/ProgramMaster/ProgramMaster";
import ResourceMaster from "./pages/InseedProgram/ResourceMaster/ResourceMaster";
import Evaluation from "./pages/InseedProgram/Evaluation/Evaluation";
import InseedReports from "./pages/InseedProgram/InseedReports/InseedReports";
import EmployeeEnrollment from "./pages/LearningDevelopment/EmployeeEnrollment/EmployeeEnrollment";
import EmployeeDetails from "./pages/EmployeeDb/EmployeeDetails/EmployeeDetails";
import BeACoach from "./pages/Coaching&Mentoring/BeACoach/BeACoach";
import CoachingProgress from "./pages/Coaching&Mentoring/CoachingProgress/CoachingProgress";
import CoachingReports from "./pages/Coaching&Mentoring/CoachingReports/CoachingReports";
import CoachingRequest from "./pages/Coaching&Mentoring/CoachingRequest/CoachingRequest";
import AllCoachingRequests from "./pages/Coaching&Mentoring/CoachingRequest/AllCoachingRequests";
import SkillCoinsReport from "./pages/SkillCoins/SkillCoinsReport";
import SkillCoinsRedeemData from "./pages/SkillCoins/Redeem SkillCoins/SkillCoinsRedeemData";
import TrainingMasterTable from "./pages/InseedProgram/TrainingMaster/TrainingMasterTable";
import AssignSessionCard from "./pages/Coaching&Mentoring/CoachingProgress/AssignSessionCard";
import CoachListTable from "./pages/Coaching&Mentoring/CoachingProgress/CoachListTable";
import CandidateListTable from "./pages/Coaching&Mentoring/CoachingProgress/CandidateListTable";
import SessionCard from "./pages/Coaching&Mentoring/CoachingProgress/SessionCard";
import CreateTrainingData from "./pages/InseedProgram/TrainingMaster/CreateTrainingData";
// import CompetencyAnalysisData from "./pages/CompetencyPlanning/CompetencyAnalysis/CompetencyAnalysisData";
import ReportPdf from "./pages/Coaching&Mentoring/CoachingReports/ReportPdf";
import SkillCoin from "./pages/SkillCoins/SkillCoins Landing Page/SkillCoins";
import ModuleTableData from "./pages/InseedProgram/ModuleMaster/ModuleTableData";
import AssessmentTableData from "./pages/InseedProgram/AssessmentMaster/AssessmentTableData";
import SpecificModule from "./pages/InseedProgram/ModuleMaster/SpecificModule";
import AllTrainings from "./pages/InseedProgram/TrainingMaster/AllTrainings";
import ViewAllModule from "./pages/InseedProgram/TrainingMaster/ViewAllModules";
import TransactionHistory from "./pages/SkillCoins/Redeem SkillCoins/TransactionHistory";
import EmployeeReports from "./pages/EmployeeDb/EmployeeReport/EmployeeReports";
import IndividualCoacheeReport from "./pages/Coaching&Mentoring/CoachingReports/IndividualCoacheeReport";
import Profile from "./pages/Profile/Profile";
import InvoiceTable from "./pages/Resource Management/ProjectRepository/ProjectRepositoryFunctions/InvoiceTable";
import IndividualInvoiceDetails from "./pages/Resource Management/ProjectRepository/ProjectRepositoryFunctions/IndividualInvoiceDetails";
import AddSessionCard from "./pages/Coaching&Mentoring/CoachingProgress/AddSessionCard";
import IndividualTechSpecReport from "./pages/Coaching&Mentoring/CoachingReports/IndividualTechSpecReport";
import EmployeeSkillCoinData from "./pages/SkillCoins/SkillCoins Landing Page/EmployeeSkillCoinData";
import ApplicationDetails from "./pages/InternalJobPosting/ApplicationDetails";
import IjpPostaJob from "./pages/InternalJobPosting/IjpPostaJob";
import LearningDevelopmentDetails from "./pages/LearningDevelopment/LearningDevelopmentData/LearningDevelopmentDetails";
import EnrollmentCourses from "./pages/LearningDevelopment/EnrollmentCourses/EnrollmentCourses";
import ManagerLearningDevelopment from "./pages/LearningDevelopment/ManagerLearningDevelopment/ManagerLearningDevelopment";
import ManagerLearningDevelopmentDetails from "./pages/LearningDevelopment/ManagerLearningDevelopment/ManagerLearningDevelopmentDetails";
import FinanceLearningDevelopment from "./pages/LearningDevelopment/FinanceLearningDevelopment/FinanceLearningDevelopment";
import FinanceLearningDevelopmentDetails from "./pages/LearningDevelopment/FinanceLearningDevelopment/FinanceLearningDevelopmentDetails";
import EmployeeReferralHistory from "./pages/SkillLinks/EmployeeReferralHistory/EmployeeReferralHistory";
import MyReferredList from "./pages/SkillLinks/EmployeeReferralHistory/MyReferredList";
import SpecificEmployeesReferredDetails from "./pages/SkillLinks/EmployeeReferralHistory/SpecificEmployeesReferredDetails";
import EligibleReferredListTable from "./pages/SkillLinks/EligibleReferredPayoutList/EligibleReferredPayoutTable";
import Competency from "./pages/CompetencyPlanning/Competency/Competency";
import Questions from "./pages/CompetencyPlanning/SelfAssesments/Questions";
import MyAssessments from "./pages/CompetencyPlanning/MyAssessments/MyAssessments";
import CompetancyCertifications from "./pages/CompetencyPlanning/Certifications/CertificationsTabel";
import Streams from "./pages/CompetencyPlanning/Streams/Streams";
import SubStreams from "./pages/CompetencyPlanning/Streams/SubStreams/SubStreams";
import NewBenchManagement from "./pages/Resource Management/NewBenchManagement/NewBenchManagement";
import { ToastContainer } from "react-toastify";

const App: React.FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="/handle/token" element={<HandleToken />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/" element={<Employeedb />} />
          <Route path="/:id" element={<Employeedb />} />
          <Route path="/employeeInfo/:id" element={<Employeedb />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/employeedb-reports" element={<EmployeeReports />} />

          <Route path="/competency-management" element={<Competency />} />
          <Route path="/my-assessments" element={<MyAssessments />} />
          <Route
            path="/competency-centerfications"
            element={<CompetancyCertifications />}
          />
          <Route path="/competency-streams" element={<Streams />} />
          <Route
            path="/competency-substreams/:streamName/:id"
            element={<SubStreams />}
          />
          <Route
            path="/competency-management/questions/:id"
            element={<Questions />}
          />
          <Route
            path="/employee-session-data/:coachee_id/:coach_id"
            element={<IndividualCoacheeReport />}
          />
          <Route
            path="/technical-specification-data/:techSpec_id/:coach_id"
            element={<IndividualTechSpecReport />}
          />
          <Route path="/report-pdf/:id" element={<ReportPdf />} />
          {/* <Route path="/branch-stack/:id" element={<CompetencyBranchStack />} /> */}

          <Route path="inseed-master" element={<ProgramMaster />} />
          <Route path="trainee-master" element={<ResourceMaster />} />
          <Route path="training-schedule" element={<TrainingMasterTable />} />
          <Route path="assessment-master" element={<AssessmentTableData />} />
          <Route path="module-master" element={<ModuleTableData />} />
          <Route path="module/:id" element={<SpecificModule />} />
          <Route path="training-modules/:id" element={<ViewAllModule />} />

          <Route path="create-training/:id" element={<CreateTrainingData />} />
          <Route path="all-trainings/:id" element={<AllTrainings />} />
          <Route path="evaluation" element={<Evaluation />} />
          <Route path="inseed-reports" element={<InseedReports />} />

          <Route path="be-a-coach" element={<BeACoach />} />
          <Route path="coaching-progress" element={<CoachListTable />} />
          <Route path="coaching-reports" element={<CoachingReports />} />
          <Route path="coaching-request" element={<CoachingRequest />} />
          <Route
            path="/all-coaching-requests"
            element={<AllCoachingRequests />}
          />
          <Route path="/assign-session-card/:id" element={<SessionCard />} />
          <Route
            path="/assign-session-card/add-session/:loginId/:id"
            element={<AddSessionCard />}
          />
          <Route
            path="coaching-progress-card/:id"
            element={<CoachingProgress />}
          />
          <Route path="candidate-list" element={<CandidateListTable />} />

          {/* <Route path="competency-development" element={<CompetencyDev />} />
          <Route path="competency-reporting" element={<CompetencyReport />} /> */}
          {/* <Route
            path="competency-analysis"
            element={<CompetencyAnalysisData />}
          /> */}

          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/self-assessment" element={<SelfAssessment />} />
          <Route path="/self-assessment/:id" element={<SelfAssessmentAdd />} /> */}

          <Route path="/sills" element={<Skills />} />
          <Route path="/skill-coins" element={<SkillCoin />} />
          <Route path="/skill-coins-report" element={<SkillCoinsReport />} />
          <Route path="/skillcoins-redeem" element={<SkillCoinsRedeemData />} />
          <Route path="/transaction-history" element={<TransactionHistory />} />
          {/* <Route path="/resource-management" element={<ResourceButtons />}> */}
          {/* <Route index element={<ProjectRepo />} /> */}
          <Route path="project-repository" element={<ProjectRepo />} />
          <Route path="bench-management" element={<BenchMgmt />} />
          {/* <Route path="bench-management" element={<NewBenchManagement />} /> */}
          <Route path="resource-allocation" element={<ResourceAllocation />} />
          {/* </Route> */}
          <Route
            path="employee-projectdetails/:id"
            element={<EmployeeProjectDetails />}
          />
          <Route
            path="/resource-adding/:id"
            element={<ResourceAddingTable />}
          />

          <Route path="invoices/:project_id" element={<InvoiceTable />} />
          <Route
            path="invoices/:invoice_id/:project_id"
            element={<IndividualInvoiceDetails />}
          />
          <Route path="enrollment-request" element={<LearningDevelopment />} />
          <Route
            path="enrollment-manager-request"
            element={<ManagerLearningDevelopment />}
          />
          <Route
            path="/learning-details/:id"
            element={<ManagerLearningDevelopmentDetails />}
          />
          <Route
            path="enrollment-finance-request"
            element={<FinanceLearningDevelopment />}
          />
          <Route
            path="/finance-learning-details/:id"
            element={<FinanceLearningDevelopmentDetails />}
          />
          <Route
            path="/learning-development-details/:id"
            element={<LearningDevelopmentDetails />}
          />
          <Route path="courses" element={<EnrollmentCourses />} />
          <Route
            path="employee-referral-history"
            element={<EmployeeReferralHistory />}
          />
          <Route path="referred-list" element={<MyReferredList />} />
          <Route
            path="employee-referred-list/:id"
            element={<SpecificEmployeesReferredDetails />}
          />
          <Route path="skill-links/:id" element={<SkillLinksData />} />
          <Route
            path="/eligble-payout-list"
            element={<EligibleReferredListTable />}
          />
          <Route path="post-a-job" element={<PostaJob />} />
          <Route path="/ijp-post-a-job" element={<IjpPostaJob />} />
          <Route path="JobPost" element={<JobPost />} />
          <Route path="/view-applications/:id" element={<ViewAppications />} />
          <Route
            path="/jobapplicationstable/:id"
            element={<JobApplicationsTable />}
          />
          <Route
            path="/application-details/:id/:jobApplicationId"
            element={<ApplicationDetails />}
          />
          <Route path="refer-resource" element={<ReferResource />} />
          <Route
            path="/refer-specific-job/:id"
            element={<ReferSpecificJob />}
          />
          <Route
            path="/employee-skillcoin/:id"
            element={<EmployeeSkillCoinData />}
          />
          <Route path="/dbsidebar" element={<DbSidebar />}>
            <Route path="qualifications" element={<Qualifications />} />
            <Route path="certifications" element={<Certifications />} />
            <Route path="work" element={<Work />} />
            <Route path="career" element={<Career />} />
            <Route path="potential" element={<Potential />} />
            <Route path="preferences" element={<Preferences />} />
            <Route path="skills" element={<Skills />} />
            <Route path="experience" element={<ExperiencesNewPage />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
