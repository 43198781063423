import React, { useState, useEffect } from "react";
import MainCard from "../../component/MainCard/MainCard";
import ProfileTabs from "../../component/Tabs/Tabs";
import EducationalInfo from "./EducationalInfo/EducationalInfo";
import EmploymentHistory from "./EmploymentHistory/EmploymentHistory";
import FinancialInfo from "./FinancialInfo/FinancialInfo";
import "./Profile.scss";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import ContactInfo from "./ContactInfo/ContactInfo";
import EmployeeIdentification from "./EmployeeIdentification/EmployeeIdentification";
import * as actions from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../component/Toasts/Toasts";
import moment from "moment";
import { icons } from "../../assets";
import useLoadingIndicator from "../../component/Loader/Loader";
import Skills from "./Skills/Skills";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../component/Buttons/Button";
import { Strings } from "../../constant/Strings";
// import ProfileInfo from "../../../Profile/ProfileInfo/ProfileInfo";
import ProfileInfo from "./ProfileInfo/ProfileInfo";
import ModalComponents from "../../component/ModalComponent/ModalComponent";
import { useLocation } from "react-router-dom";

const Profile = (props: any) => {
  const { employeeID } = props;
  const [eId, setEmployeeID] = useState(employeeID);
  const [personalInfoTab, setPersonalInfoTab] = useState(false);
  const [contactInfoTab, setContactInfoTab] = useState(false);
  const [employeeIdentificationTab, setemployeeIdentificationTab] =
    useState(false);
  const [financialInfoTab, setFinancialInfoTab] = useState(false);
  const [educationalInfo, setEducationalInfo] = useState(false);
  const [employmentHistory, setEmploymentHistory] = useState(false);
  const [skills, setSkills] = useState(false);
  const [pdfData, setPdfData] = useState<any>();
  const [profileInfo, setProfileInfo] = useState(true);
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const [employeeDetails, setEmployeeDetails] = useState<any>({
    candidateName: "",
    lastname: "",
    designation: "",
    emailId: "",
    mobileNumber: "",
    dateOfBirth: "",
    alternateNumber: "",
    passportNumber: "",
    currentAddress1: "",
    currentAddress2: "",
    currentAddress3: "",
    permanentAddress1: "",
    permanentAddress2: "",
    permanentAddress3: "",
    currentPinCode: "",
    permanentPinCode: "",
    middleName: "",
    currentState: "",
    permanentState: "",
    currentCountry: "India",
    permanentCountry: "India",
    currentDistrict: "",
    permanentDistrict: "",
    employeeId: "",
    personalMail: "",
    candidatePhoto: "",
    resume: "",
    dateOfJoining: "",
    aadharNumber: "",
    pan: "",
    title: "",
    level: "",
    band: "",
    department: "",
    clientName: "",
    workLocation: "",
    reportingManagerName: "",
    pfNumber: "",
    insuranceNumber: "",
    uanNumber: "",
    experience: "",
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
    emergencyContactNumber: "",
    panCardImage: "",
    aadharCardImage: "",
    eId: "",
    recordStatus: "",
    permanentAddressProof: "",
    currentAddressProof: "",
    cancelledCheque: "",
    isFresher: "",
    isSameComunicationAdderess: "",
    bloodGroup: "",
    hrbpName: "",
    hrbpRef: "",
    totalExperience: "",
    previousExperience: "",
    organisationExperience: "",
    currentLocation: "",
    summary: [],
  });
  const [images, setImages] = React.useState([]);
  const [displayPicture, setdisplayPicture] = React.useState(null);

  localStorage.setItem("selectedId", loginId);
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();

  const dispatch = useDispatch();
  const { id } = useParams();

  const editEmployeeDetails = useSelector(
    (state: any) => state.employeeReducer.editEmployeeData
  );
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setEmployeeID(id);
  }, [id]);

  const handlePersonalInfo = () => {
    setPersonalInfoTab(true);
    setContactInfoTab(false);
    setemployeeIdentificationTab(false);
    setFinancialInfoTab(false);
    setEducationalInfo(false);
    setEmploymentHistory(false);
    setSkills(false);
    setProfileInfo(false);
  };

  const handleContactInfo = () => {
    setContactInfoTab(true);
    setPersonalInfoTab(false);
    setemployeeIdentificationTab(false);
    setFinancialInfoTab(false);
    setEducationalInfo(false);
    setEmploymentHistory(false);
    setSkills(false);
    setProfileInfo(false);
  };

  const handleEmployeeIdentificationInfo = () => {
    setemployeeIdentificationTab(true);
    setPersonalInfoTab(false);
    setContactInfoTab(false);
    setFinancialInfoTab(false);
    setEducationalInfo(false);
    setEmploymentHistory(false);
    setSkills(false);
    setProfileInfo(false);
  };

  const handleFinancialInfo = () => {
    setFinancialInfoTab(true);
    setContactInfoTab(false);
    setPersonalInfoTab(false);
    setemployeeIdentificationTab(false);
    setEducationalInfo(false);
    setEmploymentHistory(false);
    setSkills(false);
    setProfileInfo(false);
  };

  const handleEducationalInfo = () => {
    setEducationalInfo(true);
    setFinancialInfoTab(false);
    setContactInfoTab(false);
    setPersonalInfoTab(false);
    setemployeeIdentificationTab(false);
    setEmploymentHistory(false);
    setSkills(false);
    setProfileInfo(false);
  };

  const handleEmploymentHistory = () => {
    setEmploymentHistory(true);
    setEducationalInfo(false);
    setFinancialInfoTab(false);
    setContactInfoTab(false);
    setPersonalInfoTab(false);
    setemployeeIdentificationTab(false);
    setSkills(false);
    setProfileInfo(false);
  };

  const handleSkills = () => {
    setSkills(true);
    setEmploymentHistory(false);
    setEducationalInfo(false);
    setFinancialInfoTab(false);
    setContactInfoTab(false);
    setPersonalInfoTab(false);
    setemployeeIdentificationTab(false);
    setProfileInfo(false);
  };

  const handleProfileInfo = () => {
    setSkills(false);
    setEmploymentHistory(false);
    setEducationalInfo(false);
    setFinancialInfoTab(false);
    setContactInfoTab(false);
    setPersonalInfoTab(false);
    setemployeeIdentificationTab(false);
    setProfileInfo(true);
  };

  const setProfile = (employeeData: any) => {
    setEmployeeDetails({
      recordStatus:
        employeeData && employeeData.recordStatus
          ? employeeData.recordStatus
          : "",
      status: employeeData && employeeData.status ? employeeData.status : "",
      candidateName:
        employeeData && employeeData.candidateName
          ? employeeData.candidateName
          : "",
      lastname:
        employeeData && employeeData.lastname ? employeeData.lastname : "",
      designation:
        employeeData && employeeData.designation
          ? employeeData.designation
          : "",
      emailId: employeeData && employeeData.emailId ? employeeData.emailId : "",
      mobileNumber:
        employeeData && employeeData.mobileNumber
          ? employeeData.mobileNumber
          : "",
      dateOfBirth:
        employeeData && employeeData.dateOfBirth
          ? moment(employeeData.dateOfBirth).format("DD/MMM/YYYY")
          : "",
      alternateNumber:
        employeeData && employeeData.alternateNumber
          ? employeeData.alternateNumber
          : "",
      passportNumber:
        employeeData && employeeData.passportNumber
          ? employeeData.passportNumber
          : "",
      currentAddress1:
        employeeData && employeeData.currentAddress1
          ? employeeData.currentAddress1
          : "",
      currentAddress2:
        employeeData && employeeData.currentAddress2
          ? employeeData.currentAddress2
          : "",
      currentAddress3:
        employeeData && employeeData.currentAddress3
          ? employeeData.currentAddress3
          : "",
      permanentAddress1:
        employeeData && employeeData.permanentAddress1
          ? employeeData.permanentAddress1
          : "",
      permanentAddress2:
        employeeData && employeeData.permanentAddress2
          ? employeeData.permanentAddress2
          : "",
      permanentAddress3:
        employeeData && employeeData.permanentAddress3
          ? employeeData.permanentAddress3
          : "",
      currentPinCode:
        employeeData && employeeData.currentPinCode
          ? employeeData.currentPinCode
          : "",
      permanentPinCode:
        employeeData && employeeData.permanentPinCode
          ? employeeData.permanentPinCode
          : "",
      middleName:
        employeeData && employeeData.middleName ? employeeData.middleName : "",
      currentState:
        employeeData && employeeData.currentState
          ? employeeData.currentState
          : "",
      permanentState:
        employeeData && employeeData.permanentState
          ? employeeData.permanentState
          : "",
      currentCountry:
        employeeData && employeeData.currentCountry
          ? employeeData.currentCountry
          : "",
      permanentCountry:
        employeeData && employeeData.permanentCountry
          ? employeeData.permanentCountry
          : "",
      employeeId:
        employeeData && employeeData.employeeId ? employeeData.employeeId : "",
      personalMail:
        employeeData && employeeData.personalMail
          ? employeeData.personalMail
          : "",
      currentDistrict:
        employeeData && employeeData.currentDistrict
          ? employeeData.currentDistrict
          : "",
      permanentDistrict:
        employeeData && employeeData.permanentDistrict
          ? employeeData.permanentDistrict
          : "",
      candidatePhoto:
        employeeData && employeeData.candidatePhoto
          ? employeeData.candidatePhoto
          : "",
      resume: employeeData && employeeData.resume ? employeeData.resume : "",
      dateOfJoining:
        employeeData && employeeData.dateOfJoining
          ? moment(employeeData.dateOfJoining).format("DD/MMM/YYYY")
          : "",
      level: employeeData && employeeData.level ? employeeData.level : "",
      band: employeeData && employeeData.band ? employeeData.band : "",
      department:
        employeeData && employeeData.department ? employeeData.department : "",
      clientName:
        employeeData && employeeData.clientName ? employeeData.clientName : "",
      workLocation:
        employeeData && employeeData.workLocation
          ? employeeData.workLocation
          : "",
      reportingManagerName:
        employeeData && employeeData?.Manager[0]
          ? employeeData?.Manager[0]?.candidateName +
            " " +
            employeeData?.Manager[0]?.middleName +
            " " +
            employeeData?.Manager[0]?.lastname +
            "-" +
            employeeData?.Manager[0]?.employeeId
          : "",
      pfNumber:
        employeeData && employeeData.pfNumber ? employeeData.pfNumber : "",
      insuranceNumber:
        employeeData && employeeData.insuranceNumber
          ? employeeData.insuranceNumber
          : "",
      uanNumber:
        employeeData && employeeData.uanNumber ? employeeData.uanNumber : "",
      experience:
        employeeData && employeeData.experience ? employeeData.experience : "",
      bankName:
        employeeData && employeeData.bankName ? employeeData.bankName : "",
      accountHolderName:
        employeeData && employeeData.accountHolderName
          ? employeeData.accountHolderName
          : "",
      accountNumber:
        employeeData && employeeData.accountNumber
          ? employeeData.accountNumber
          : "",
      ifscCode:
        employeeData && employeeData.ifscCode ? employeeData.ifscCode : "",
      emergencyContactNumber:
        employeeData && employeeData.emergencyContactNumber
          ? employeeData.emergencyContactNumber
          : "",
      aadharNumber:
        employeeData && employeeData.aadharNumber
          ? employeeData.aadharNumber
          : "",
      pan: employeeData && employeeData.pan ? employeeData.pan : "",
      aadharCardImage:
        employeeData && employeeData.aadharCardImage
          ? employeeData.aadharCardImage
          : "",
      panCardImage:
        employeeData && employeeData.panCardImage
          ? employeeData.panCardImage
          : "",
      title: employeeData && employeeData.title ? employeeData.title : "",
      eId: employeeData && employeeData._id ? employeeData._id : "",
      permanentAddressProof:
        employeeData && employeeData.permanentAddressProof
          ? employeeData.permanentAddressProof
          : "",
      currentAddressProof:
        employeeData && employeeData.currentAddressProof
          ? employeeData.currentAddressProof
          : "",
      cancelledCheque:
        employeeData && employeeData.cancelledCheque
          ? employeeData.cancelledCheque
          : "",
      isFresher:
        employeeData && employeeData.isFresher ? employeeData.isFresher : "",
      isSameComunicationAdderess:
        employeeData && employeeData.isSameComunicationAdderess
          ? employeeData.isSameComunicationAdderess
          : "",
      bloodGroup:
        employeeData && employeeData.bloodGroup ? employeeData.bloodGroup : "",
      hrbpName:
        employeeData && employeeData?.Hrbp[0]
          ? employeeData?.Hrbp[0]?.candidateName +
            " " +
            employeeData?.Hrbp[0]?.middleName +
            " " +
            employeeData?.Hrbp[0]?.lastname +
            "-" +
            employeeData?.Hrbp[0]?.employeeId
          : "",
      hrbpRef: employeeData && employeeData.hrbpRef ? employeeData.hrbpRef : "",
      totalExperience: employeeData && employeeData.totalExperience,
      previousExperience: employeeData && employeeData.previousExperience,
      organisationExperience:
        employeeData && employeeData.organisationExperience,
      currentLocation: employeeData && employeeData?.currentLocation,
      summary: employeeData && employeeData?.summary,
      draftSummary:employeeData && employeeData?.draftSummary
    });

    setdisplayPicture(
      employeeData?.candidatePhoto?.document_path_url
        ? employeeData.candidatePhoto.document_path_url
        : employeeData?.candidatePhoto?.candidatePhoto?.document_path_url
        ? employeeData.candidatePhoto.candidatePhoto.document_path_url
        : icons.default_avatar
    );
    setImages([]);
  };
  const getApiDispatch = () => {
    dispatch(
      actions.getemployeeDetailsAction(loginId, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              setProfile(res.data[0]);
              if (res?.data?.length && res?.data[0]?.summary?.length <= 0) {
                setShowAlert(true);
              } else {
                setShowAlert(false);
              }
              hideLoadingIndicator();
            }
          }
        }
      })
    );
  };
  useEffect(() => {
    showLoadingIndicator();
    getApiDispatch();
  }, [loginId, eId]);
  useEffect(() => {
    getApiDispatch();
  }, [editEmployeeDetails]);

  let navigate = useNavigate();
  const handleStatus = (statusData: any) => {
    const confirmed = window.confirm(
      statusData == "Approved"
        ? "Are you sure you want to approve? Employee will be no longer able to update their details once approved."
        : statusData == "Published"
        ? "Are you sure You want to Publish?"
        : statusData == "Re-Published"
        ? "Are you sure You want to Re-Publish?"
        : ""
    );
    if (confirmed) {
      showLoadingIndicator();
      dispatch(
        actions.editEmployeeDataAction(
          {
            eId: loginId,
            updatedBy: loginId,
            recordStatus: statusData,
          },
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                hideLoadingIndicator();
                if (res.data.recordStatus == "Approved") {
                  showSuccessToastMessage("Approved Successfully!");
                } else if (res.data.recordStatus == "Published") {
                  showSuccessToastMessage("Published Successfully!");
                } else if (res.data.recordStatus == "Re-Published") {
                  showSuccessToastMessage("Re-Published Successfully!");
                }
                {
                  () => navigate("/");
                }
              }
            }
          }
        )
      );
    } else {
    }
  };
  const {
    recordStatus,
    aadharNumber,
    candidateName,
    middleName,
    lastname,
    emailId,
    designation,
    title,
    dateOfBirth,
    pan,
    passportNumber,
    status,
    aadharCardImage,
    panCardImage,
    personalMail,
    mobileNumber,
    alternateNumber,
    emergencyContactNumber,
    emergencyContactName,
    currentAddress1,
    currentAddress2,
    currentAddress3,
    permanentAddress1,
    permanentAddress2,
    permanentAddress3,
    currentCountry,
    permanentCountry,
    currentState,
    currentPinCode,
    currentDistrict,
    permanentDistrict,
    permanentState,
    permanentPinCode,
    permanentAddressProof,
    currentAddressProof,
    employeeId,
    dateOfJoining,
    department,
    level,
    band,
    experience,
    clientName,
    workLocation,
    reportingManagerName,
    pfNumber,
    uanNumber,
    bankName,
    accountNumber,
    accountHolderName,
    ifscCode,
    insuranceNumber,
    cancelledCheque,
    isFresher,
    resume,
    isSameComunicationAdderess,
    bloodGroup,
    hrbpName,
    hrbpRef,
    organisationExperience,
    previousExperience,
    totalExperience,
    currentLocation,
    summary,
    draftSummary
  } = employeeDetails;
  const { profile } = Strings;
  //   useEffect(() => {
  //     if(summary?.length <= 0){
  //      setShowAlert(true)
  //     }else{
  //      setShowAlert(false)
  //     }
  //  },[summary])
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper mb-3 mt-3 ms-4">
          <h2>{profile}</h2>
        </div>
        <div className="profile-tabs-container">
        <ProfileTabs
            title="Profile Info"
            onClick={handleProfileInfo}
            active={profileInfo}
          />
          <ProfileTabs
            title="Personal Info"
            onClick={handlePersonalInfo}
            active={personalInfoTab}
          />

          <ProfileTabs
            title="Contact Info"
            onClick={handleContactInfo}
            active={contactInfoTab}
          />

          <ProfileTabs
            title="Employee Identification"
            onClick={handleEmployeeIdentificationInfo}
            active={employeeIdentificationTab}
          />

          <ProfileTabs
            title="Financial Info"
            onClick={handleFinancialInfo}
            active={financialInfoTab}
          />

          <ProfileTabs
            title="Educational Info"
            onClick={handleEducationalInfo}
            active={educationalInfo}
          />

          <ProfileTabs
            title="Employment History"
            onClick={handleEmploymentHistory}
            active={employmentHistory}
          />

          <ProfileTabs title="Skills" onClick={handleSkills} active={skills} />

         
        </div>

        <div className="profile-containers">
          {personalInfoTab && (
            <PersonalInfo
              profileImage={displayPicture}
              candidateName={candidateName}
              middleName={middleName}
              lastName={lastname}
              emailId={emailId}
              designation={designation}
              title={title}
              dateOfBirth={dateOfBirth}
              pan={pan}
              aadharNumber={aadharNumber}
              passportNumber={passportNumber}
              recordStatus={recordStatus}
              eId={loginId}
              status={status}
              aadharCardImage={aadharCardImage}
              panCardImage={panCardImage}
              resume={resume}
              bloodGroup={bloodGroup}
              profileInfoDisplayCondition={true}
              employeeId={employeeId}
            />
          )}

          {contactInfoTab && (
            <ContactInfo
              personalMail={personalMail}
              mobileNumber={mobileNumber}
              alternateNumber={alternateNumber}
              emergencyContactNumber={emergencyContactNumber}
              emergencyContactName={emergencyContactName}
              currentAddress1={currentAddress1}
              currentAddress2={currentAddress2}
              currentAddress3={currentAddress3}
              permanentAddress1={permanentAddress1}
              permanentAddress2={permanentAddress2}
              permanentAddress3={permanentAddress3}
              currentCountry={currentCountry}
              permanentCountry={permanentCountry}
              currentState={currentState}
              currentPinCode={currentPinCode}
              currentDistrict={currentDistrict}
              permanentDistrict={permanentDistrict}
              permanentState={permanentState}
              permanentPinCode={permanentPinCode}
              eId={loginId}
              status={status}
              recordStatus={recordStatus}
              permanentAddressProof={permanentAddressProof}
              currentAddressProof={currentAddressProof}
              isSameComunicationAdderess={isSameComunicationAdderess}
            />
          )}

          {employeeIdentificationTab && (
            <EmployeeIdentification
              currentLocation={currentLocation}
              eCode={employeeId}
              dateOfJoining={dateOfJoining}
              department={department}
              designation={designation}
              level={level}
              band={band}
              experience={experience}
              clientName={clientName}
              workLocation={workLocation}
              reportingManagerName={reportingManagerName}
              eId={loginId}
              status={status}
              recordStatus={recordStatus}
              hrbpName={hrbpName}
              hrbpRef={hrbpRef}
              totalExperience={totalExperience}
              previousExperience={previousExperience}
              organisationExperience={organisationExperience}
            />
          )}

          {financialInfoTab ? (
            <FinancialInfo
              pfNumber={pfNumber}
              uanNumber={uanNumber}
              bankName={bankName}
              accountNumber={accountNumber}
              accountHolderName={accountHolderName}
              ifscCode={ifscCode}
              insuranceNumber={insuranceNumber}
              eId={loginId}
              status={status}
              recordStatus={recordStatus}
              cancelledCheque={cancelledCheque}
            />
          ) : educationalInfo ? (
            <EducationalInfo status={status} />
          ) : skills ? (
            <Skills status={status} recordStatus={recordStatus} />
          ) : employmentHistory ? (
            <EmploymentHistory status={status} isFresher={isFresher} getApiDispatch={getApiDispatch}/>
          ) : profileInfo ? (
            <ProfileInfo summary={summary} getApiDispatch={getApiDispatch} resume={resume}   employeeId={employeeId}  candidateName={candidateName}  middleName={middleName}
            lastName={lastname} emailId={emailId}   profileImage={displayPicture}   designation={designation} draftSummary={draftSummary}/>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex justify-content-center mt-3 mb-3">
          {status !== "Active" && (
            <Button
              className="maker-checker-btn"
              title={(recordStatus == "Draft" || recordStatus =="New" )? "Publish" : "Re-Publish"}
              onClick={() => {
                (recordStatus === "Draft" || recordStatus === "New")
                  ? handleStatus("Published")
                  : handleStatus("Re-Published");
              }}
            />
          )}
        </div>
      </MainCard>
      <ModalComponents
        show={showAlert}
        onHide={false}
        title="Reminder"
        modalBody={
          <div className="alertSection">
            <div>Please Fill Your Profile Summary Details</div>

            <div className="modal-btn-wrapper mt-5 mx-auto">
              <div>
                <button
                  className="btn reminder-add-submit-button buttonsize"
                  onClick={() => {
                    setShowAlert(false);
                    handleProfileInfo();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        }
        className="modal-lg"
      />
    </>
  );
};

export default Profile;
