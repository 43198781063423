import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { Strings, requiredFeild } from "../../../constant/Strings";
import UndoPopup from "./UndoPopup";

const AcceptedModal = (props: any) => {
  const { hideAcceptModal, requestId, setShowUndoPopup, apiCallCompleted } =
    props;
  const [btnDisable, setBtnDisable] = useState(false);
  const { cancel, submit, approveSuccess, remarks } = Strings;
  const { required } = requiredFeild;
  const [acceptData, setRequirementData] = useState<any>({
    remarks: requestId.remarks,
    status: "1",
  });

  const dispatch = useDispatch();
  const handleAcceptModalSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      actions.updateStatusRemarksAction(requestId, acceptData, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          apiCallCompleted(true);
        } else {
          if (res?.code === 200) {
            showSuccessToastMessage(approveSuccess);
            apiCallCompleted(true);
            setShowUndoPopup(true);
          }
        }
      })
    );
    hideAcceptModal();
  };

  useEffect(() => {
    if (acceptData.remarks) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 mt-2 mb-3">
          <label className="form-label">{remarks}</label>
          <span className="required">{required}</span>
          <textarea
            id="exampleFormControlTextarea1"
            className="form-control"
            style={{
              width: "24rem",
              height: "10rem",
            }}
            value={acceptData.remarks}
            onChange={(e) =>
              setRequirementData({
                ...acceptData,
                remarks: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideAcceptModal}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleAcceptModalSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AcceptedModal;
