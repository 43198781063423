import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { icons } from "../../../../../assets";
import Button from "../../../../../component/Buttons/Button";
import ModalComponents from "../../../../../component/ModalComponent/ModalComponent";
import AddCertifications from "./AddCertifications";
import DeleteCertifications from "./DeleteCertifications";
import EditCertifications from "./EditCertifications";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./../../../../../Redux/Actions";
import { useNavigate } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import next from "../../../../../assets/forward.png";
import back from "../../../../../assets/backward.png";
import useLoadingIndicator from "../../../../../component/Loader/Loader";
import { showErrorToastMessage } from "./../../../../../component/Toasts/Toasts";

function Certifications() {
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const selectedId = localStorage.getItem("selectedId");
  const status = localStorage.getItem("employeeStatus");
  const dispatch = useDispatch();
  const [employeeDetails, setEmployeeDetails] = useState<any>({
    status: "",
  });
  useEffect(() => {
    dispatch(
      actions.getemployeeDetailsAction(loginId, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              setProfile(res.data[0]);
            }
          }
        }
      })
    );
  }, [loginId]);

  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();

  const [info, setInfo] = useState([]);
  const [certifications, setCertifications] = useState({
    id: "",
    nameOfCertification: "",
    institute: "",
    board: "",
    year: "",
    marks: "",
  });
  const [deleteId, setDeleteId] = useState("");
  const isFresher: any = localStorage.getItem("fresherStatus");
  let navigate = useNavigate();

  const [showAddCertifications, setShowAddCertifications] = useState(false);
  const handleAddCertifications = () => {
    setShowAddCertifications(true);
  };
  const hideAddCertifictions = () => {
    setShowAddCertifications(false);
  };

  const [showEditCertifications, setShowEditCertifications] = useState(false);
  const handleEditCertifications = (
    id: string,
    nameOfCertification: any,
    institute: any,
    board: any,
    year: string,
    marks: string
  ) => {
    setCertifications({
      id: id,
      nameOfCertification: nameOfCertification,
      institute: institute,
      board: board,
      year: year,
      marks: marks,
    });
    setShowEditCertifications(true);
  };
  const hideEditCertifications = () => {
    setShowEditCertifications(false);
  };

  const [showDeleteCertifications, setShowDeleteCertifications] =
    useState(false);
  const handleDeleteCertifications = (id: any) => {
    setDeleteId(id);
    setShowDeleteCertifications(true);
  };
  const hideDeleteCertifications = () => {
    setShowDeleteCertifications(false);
  };
  const addEmployeeCertifications = useSelector(
    (state: any) => state.employeeReducer.addEmployeeCertifications
  );
  const editEmployeeCertifications = useSelector(
    (state: any) => state.employeeReducer.editEmployeeCertifications
  );
  const deleteEmployeeCertifications = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeeCertifications
  );

  const dispatchCertifications = useDispatch();
  useEffect(() => {
    showLoadingIndicator();
    dispatchCertifications(actions.getEmployeeCertificationsAction());
    setTimeout(() => {
      hideLoadingIndicator();
    }, 1000);
  }, [
    addEmployeeCertifications,
    editEmployeeCertifications,
    deleteEmployeeCertifications,
  ]);

  const employeeCertifications = useSelector(
    (state: any) => state.employeeReducer.getEmployeeCertifications
  );

  useEffect(() => {
    if (employeeCertifications) {
      if (employeeCertifications.data) {
        setInfo(employeeCertifications.data);
      }
    }
  }, [employeeCertifications, info]);

  const setProfile = (employeeData: any) => {
    setEmployeeDetails({
      status: employeeData && employeeData.status ? employeeData.status : "",
    });
  };

  return (
    <>
      {loadingComponent()}
      <div className="right__container">
        <div className="title">
          <h6>Certification & Credentials</h6>
          {(status !== "Active" && status !== "Inactive") ||
          (role === "hr" && status === "Active" && selectedId != loginId) ? (
            <>
              <div className="btn-wrapper">
                <Button
                  icon={icons.add_icon}
                  title="Add"
                  onClick={handleAddCertifications}
                />
              </div>
            </>
          ) : (
            <>{"  "}</>
          )}
        </div>
        <div className="col">
          <Table hover striped>
            <thead className="employeedb">
              <tr className="table-head table-head-row">
                <td>Certification Name</td>
                <td>Institute Name/Certifying Body</td>
                <td>Board/Univ/Affiliation</td>
                <td>Year Of Passing</td>
                <td>Percentage</td>
                {(status !== "Active" && status !== "Inactive") ||
                (role === "hr" &&
                  status === "Active" &&
                  selectedId != loginId) ? (
                  <>
                    <td>Action</td>
                  </>
                ) : (
                  <>{""}</>
                )}
              </tr>
            </thead>
            <tbody className="employeedata">
              {info.length > 0 &&
                info.map((ele: any) => (
                  <tr key={ele.id} className="buttonsize">
                    <td>{ele.nameOfCertification}</td>
                    <td>{ele.institute}</td>
                    <td>{ele.board}</td>
                    <td>{ele.year}</td>
                    <td>{ele.marks}</td>
                    {(status !== "Active" && status !== "Inactive") ||
                    (role === "hr" &&
                      status === "Active" &&
                      selectedId != loginId) ? (
                      <>
                        <td>
                          <div className="action-btn">
                            <img
                              src={icons.edit_icon}
                              alt="edit_icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              onClick={() =>
                                handleEditCertifications(
                                  ele.id,
                                  ele.nameOfCertification,
                                  ele.institute,
                                  ele.board,
                                  ele.year,
                                  ele.marks
                                )
                              }
                              className="edit-icon"
                            />
                            <img
                              src={icons.delete_icon}
                              alt="delete-icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Delete"
                              onClick={() => handleDeleteCertifications(ele.id)}
                              className="edit-icon"
                            />
                          </div>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
          <div>
            <ModalComponents
              show={showAddCertifications}
              onHide={hideAddCertifictions}
              title="Add Certification & Credentials"
              modalBody={
                <AddCertifications
                  showAddCertifications={handleAddCertifications}
                  hideAddCertifictions={hideAddCertifictions}
                />
              }
              className="modal-lg"
            />

            <ModalComponents
              show={showEditCertifications}
              onHide={hideEditCertifications}
              title="Edit Certification & Credentials"
              modalBody={
                <EditCertifications
                  id={certifications.id}
                  nameOfCertification={certifications.nameOfCertification}
                  institute={certifications.institute}
                  board={certifications.board}
                  year={certifications.year}
                  marks={certifications.marks}
                  hideEditCertifications={hideEditCertifications}
                />
              }
              className="modal-lg"
            />

            <ModalComponents
              show={showDeleteCertifications}
              onHide={hideDeleteCertifications}
              title="Delete Certification & Credentials"
              modalBody={
                <DeleteCertifications
                  id={deleteId}
                  hideDeleteCertifications={hideDeleteCertifications}
                />
              }
              className="modal-sm"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <button
              className="btn btn-primary btn-next-pre"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Previous"
              onClick={() => navigate("/dbsidebar/qualifications")}
            >
              <img src={back} alt="icon" />
              Back
            </button>
          </div>
          <div className="col-8">
            <button
              className="btn btn-primary btn-next-pre"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Save & Next"
              onClick={
                isFresher == "false"
                  ? () => navigate("/dbsidebar/work")
                  : () => navigate("/dbsidebar/experience")
              }
            >
              Next
              <img src={next} alt="icon" />
            </button>
          </div>
          <div className="col">
            <button
              className="btn btn-outline-primary btn-skip"
              onClick={() => navigate("/dbsidebar/experience")}
            >
              Skip
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Certifications;
