import Card from "../../../../component/Card/Card";
import { Strings } from "../../../../constant/Strings";
import { icons } from "../../../../assets";
import "./InvoiceDetails.scss";
import TextContainer from "../../../../component/ProfileTabs/TextContainer";

const RemarksTab = (props: any) => {
  const { documentHeadingColumn, columnValues, data } = props;
  const { remarks, document_category, documents, preview_document, viewFile } =
    Strings;
  const { description } = icons;

  return (
    <>
      <TextContainer heading={remarks} content={data?.remarks} />
      <h5 className="mt-4">{documents}</h5>
      <Card>
        <div className="row">
          <div className="col-md-6">
            <h5>{document_category}</h5>
            {documentHeadingColumn.map((element: any, index: any) => {
              return (
                <div key={index} className="category-text mt-3">
                  {element}
                </div>
              );
            })}
          </div>
          <div className="col-md-6">
            <h5>{preview_document}</h5>
            {columnValues.length > 0 ? (
              columnValues.map((element: any, index: any) => {
                return (
                  <div key={index} className="mt-3">
                    <a
                      href={element.value}
                      download={element.name}
                      target="_blank"
                      className="project-document mt-4"
                    >
                      {element.value ? (
                        <>
                          <img src={description} alt="view file icon" />
                          {viewFile}
                        </>
                      ) : (
                        "-"
                      )}
                    </a>
                  </div>
                );
              })
            ) : (
              <div className="mt-3">
                <p>-</p>
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default RemarksTab;
