import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";
import { store } from "../../../../../store";
import moment from "moment";
import {
  isCharactersValid,
  projectProjectNameValidation,
  whiteSpaceValid,
} from "../../../../../constant/Validations";
import { Strings } from "../../../../../constant/Strings";

const AddWorkAchievements = (props: any) => {
  const employeeId: any = localStorage.getItem("selectedId");

  const { hideAddWorkAchievements } = props;
  const [docFile, setDocFile] = useState<any>([]);

  const [btnDisable, setBtnDisable] = useState(false);
  const [workAchievements, setWorkAchievements] = useState<any>({
    employeeRef: employeeId,
    award: "Award",
    description: "",
    date: "",
    certificate: null,
  });

  const [errors, setErrors] = useState({
    award: "Award",
    description: "",
  });

  const {
    valid_description,
    addSuccess,
    file_size_max_msg,
    file_type_msg,
    description_label,
    date_label,
    recognition_type,
    upload_certificate,
    imageSize2mb,
    uploadFile,
    cancel,
    submit,
  } = Strings;

  const AddWorkAchievements = useSelector(
    (state: any) => state.employeeReducer.getWorkAchievements
  );

  useEffect(() => {
    if (
      workAchievements.award &&
      workAchievements.description &&
      workAchievements.date
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [workAchievements, btnDisable, AddWorkAchievements]);

  const [showawardErrorToggle, setShowawardErrorToggle] = useState(false);
  const [showdescriptionErrorToggle, setShowdescriptionErrorToggle] =
    useState(false);

  const dispatch = useDispatch();
  const handleWorkAchievementsSubmit = () => {
    if (
      whiteSpaceValid(workAchievements.description) &&
      projectProjectNameValidation(workAchievements.description)
    ) {
      dispatch(actions.addEmployeeWorkAchievementsAction(workAchievements));
      showSuccessToastMessage(addSuccess);
      hideAddWorkAchievements();
    } else {
      if (
        whiteSpaceValid(workAchievements.description) &&
        projectProjectNameValidation(workAchievements.description)
      ) {
        setShowdescriptionErrorToggle(false);
      } else {
        setShowdescriptionErrorToggle(true);
      }
    }
  };

  const handleOnChange = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage(file_size_max_msg);
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(file_type_msg);
      return;
    } else {
      setDocFile(e.target.files[0]);
      dispatch(
        actions.workAchievementsDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setWorkAchievements({
                ...workAchievements,
                certificate: res.data,
              });
            }
          }
        )
      );
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            {recognition_type}
          </label>
          <span className="required">*</span>
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
            className="qualification-dropdown"
            onSelect={(eventKey: any) =>
              setWorkAchievements({
                ...workAchievements,
                award: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {workAchievements.award}
            </Dropdown.Toggle>
            <Dropdown.Menu show={showDropdown}>
              <Dropdown.Item eventKey="Award">Award</Dropdown.Item>
              <Dropdown.Item eventKey="Reward">Reward</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label className="form-label">{description_label}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setWorkAchievements({
                ...workAchievements,
                description: e.target.value,
              })
            }
          />
          <p className="error">
            {workAchievements.description.length !== 0 &&
              (showdescriptionErrorToggle ? valid_description : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{date_label}</label>
          <span className="required">*</span>
          <input
            type="date"
            max={moment().format("YYYY-MM-DD")}
            className="form-control"
            onChange={(e) =>
              setWorkAchievements({
                ...workAchievements,
                date: e.target.value,
              })
            }
          />
        </div>

        <div className="col-md-4">
          <label className="form-label">{upload_certificate}</label>

          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleOnChange}
              hidden
              id="actual-btn"
            />
            <label
              htmlFor="actual-btn"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <p className="image-size-condition">
            {imageSize2mb}
            <span className="required">*</span>
          </p>
        </div>
        <div className="col-md-4 mt-3">
          <a
            href={workAchievements.certificate?.document_path_url}
            target="_blank"
            className="buttonsize"
          >
            {workAchievements.certificate?.document_path_url ? "View File" : ""}
          </a>
          <p className="image-size-condition">{docFile?.name}</p>
        </div>
      </div>

      <div className="modal-btn-wrapper mt-3">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideAddWorkAchievements}
          >
            {cancel}
          </button>
        </div>

        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleWorkAchievementsSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddWorkAchievements;
