import React, { useState } from "react";
import { Strings } from "../../../../constant/Strings";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import * as actions from "../../../../Redux/Actions/index";
import { useDispatch } from "react-redux";

const EditProfileSummary = (props: any) => {
  const { handleProfileSummary, apiCallCompleted, summary, getApiDispatch,employee,draftSummary } =
    props;
  const { cancel, submit } = Strings;
  const dispatch = useDispatch();
  const selectedId: any = localStorage.getItem("selectedId");
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const [errorMessage, setErrorMessage] = useState("");
  const summaryArr =
    summary?.length > 0
      ? summary?.map((ele: any) => ({
          key: ele?.key,
          summary: ele?.summary,
        }))
      : [
          { key: 1, summary: "" },
          { key: 2, summary: "" },
          { key: 3, summary: "" },
          { key: 4, summary: "" },
          { key: 5, summary: "" },
        ];

const DraftArr =
draftSummary?.length > 0
          ? draftSummary?.map((ele: any) => ({
              key: ele?.key,
              summary: ele?.summary,
            }))
          : [
              { key: 1, summary: "" },
              { key: 2, summary: "" },
              { key: 3, summary: "" },
              { key: 4, summary: "" },
              { key: 5, summary: "" },
            ];
  const [inputs, setInputs] = useState<any>(employee ?DraftArr : summaryArr);

  const handleInputChange = (index: any, event: any) => {
    const newInputs = [...inputs];
    newInputs[index].summary = event.target.value.slice(0, 150);
    setInputs(newInputs);
  };

  const addInput = () => {
    if (inputs.length < 10) {
      setInputs([...inputs, { key: inputs.length + 1, summary: "" }]);
    }
  };

  const removeInput = (index: any) => {
    if (inputs.length > 5) {
      const newInputs = inputs.filter((_: any, i: any) => i !== index);
      setInputs(
        newInputs.map((input: any, i: any) => ({ ...input, key: i + 1 }))
      );
    }
  };

  const validateInputs = () => {
    return inputs.every((input: any) => input.summary.trim() !== "");
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (validateInputs()) {
      setErrorMessage("");
      apiCallCompleted(false);
      const payload :any= { 
        summary: inputs, 
        updatedBy: loginId 
      }
      if(employee){
        payload.draftSummary = inputs
      }
      dispatch(
        actions.postProfileSummaryAction(
          payload,
          selectedId,
          (res: any) => {
            if (res) {
              if (res.error) {
                if (res.error.error_description) {
                  showErrorToastMessage(res.error.error_description);
                  apiCallCompleted(true);
                  getApiDispatch();
                }
              } else {
                if (res.code === 200) {
                  showSuccessToastMessage("Submitted Successfully");
                  apiCallCompleted(true);
                  getApiDispatch();
                }
              }
            }
          }
        )
      );
      handleProfileSummary();
    } else {
      setErrorMessage("Please fill out all Summary text fields.");
    }
  };
  const handleSaveDraft = (event: any) => {
    event.preventDefault();
      setErrorMessage("");
      apiCallCompleted(false);
      dispatch(
        actions.postProfileSummaryAction(
          { draftSummary: inputs, updatedBy: loginId },
          selectedId,
          (res: any) => {
                if (res?.error?.error_description) {
                  showErrorToastMessage(res.error.error_description);
                  apiCallCompleted(true);
                  getApiDispatch();
                } else if (res?.code === 200) {
                  showSuccessToastMessage("Saved as draft");
                  apiCallCompleted(true);
                  getApiDispatch();
                }
          }
        )
      );
      handleProfileSummary();
  };
  return (
    <>
      {" "}
      <div>
        <p>
          <b className="text-danger">
            * Please enter your profile summary. A minimum of 5 summaries is
            mandatory, with a maximum limit of 10{" "}
          </b>
        </p>
        {inputs.map((input: any, index: any) => (
          <ul>
            <li>
              <div key={input.key}>
                <div className="row mt-2">
                  <div className="col-md-10">
                    <textarea
                      className="form-control"
                      value={input.summary}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="Enter Details"
                      maxLength={150}
                    />
                  </div>
                  <div className="col-md-2">
                    <div>
                      {inputs.length > 5 && (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => removeInput(index)}
                        >
                          -
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        ))}
        {inputs.length < 10 && (
          <button
            type="button"
            className="btn btn-success mt-2"
            onClick={addInput}
          >
            +
          </button>
        )}

        {errorMessage != "" && (
          <p className="text-danger text-center mt-2">{errorMessage}</p>
        )}

        <div className="modal-btn-wrapper mt-4 mb-4">
          <div>
            {employee ? 
            <button
              className="btn cancel-btn buttonsize"
              onClick={handleSaveDraft}
            >
              Save as draft
            </button> :
              <button
              className="btn cancel-btn buttonsize"
              onClick={handleProfileSummary}
            >
              Cancel
            </button> 
           }
          </div>
          <div>
            <button
              className={"btn add-submit-button buttonsize"}
              onClick={handleSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfileSummary;
