import React from "react";
import { Card } from "react-bootstrap";
import "./TextContainer.scss";

const TextContainer = (props: any) => {
  const { heading, content } = props;
  return (
    <>
      <h5>{heading ? heading : ""}</h5>
      <Card className="card">
        <div>
          <p className="content p-3">{content ? content : ""}</p>
        </div>
      </Card>
    </>
  );
};

export default TextContainer;
