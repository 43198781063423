import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as actions from "../../../Redux/Actions/Action";
import MainCard from "../../../component/MainCard/MainCard";

import CoachingProgressCard from "./CoachingProgressCard";
import moment from "moment";

import useLoadingIndicator from "../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { Strings } from "../../../constant/Strings";
import SearchBar from "../../../component/SearchBar/SearchBar";
import "../CoachingProgress/Coaching.scss";
import TablePagination from "../../../component/TablePagination/TablePagination";

const CoachingProgress = (props: any) => {
  const { loadingComponent } = useLoadingIndicator();

  const userLogin: any = localStorage.getItem("login");
  const { coachingPro, noData } = Strings;
  const loginId = JSON.parse(userLogin).id;
  let { id } = useParams();
  const [info, setInfo] = useState([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState<Number>(1);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean = onclickSearch;
  useEffect(() => {
    if (condition) {
      dispatch(
        actions.getAllSessionByEmpIdAction(
          page,
          pageSize,
          searchValue,
          loginId,
          id,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setOnclickSearch(false);
              }
            }
          }
        )
      );
    }
  }, [searchValue, page, pageSize, condition]);

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const getAllSessionByEmpId = useSelector(
    (state: any) => state.employeeReducer.getAllSessionByEmpIdDetails
  );

  const addNewSession = useSelector(
    (state: any) => state.employeeReducer.addNewSessionDetails
  );

  useEffect(() => {
    if (getAllSessionByEmpId) {
      if (getAllSessionByEmpId.data) {
        setInfo(getAllSessionByEmpId.data);
      }
    }
  }, [getAllSessionByEmpId, addNewSession, info]);

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="coaching-progress-heading-button-wrapper">
          <div>
            <h2>{coachingPro}</h2>
          </div>
        </div>
        <div className="mt-2">
          <div
            className="mt-3 buttonsize filter-assessment-wrapper"
            style={{ marginLeft: "0px" }}
          >
            <div className="coaching-progress-searchbar-wrapper mx-2">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
              />
            </div>
          </div>
          {info.length > 0 ? (
            info.map((jobsData: any) => (
              <CoachingProgressCard
                key={jobsData._id}
                coachUploadFile={jobsData.coachUploadFile}
                employeeUploadFile={jobsData.employeeUploadFile}
                sessionNumber={jobsData.sessionNumber}
                topicName={jobsData.topicName}
                sessionDate={moment(jobsData.sessionDate).format("DD-MMM-YYYY")}
                dueDate={moment(jobsData.dueDate).format("DD-MMM-YYYY")}
                sessionId={jobsData._id}
                homeWork={jobsData.homeWork}
                homeWorkStatus={jobsData?.homeWorkStatus}
                status={jobsData?.status}
                employeeFeedback={jobsData?.employeeFeedBack}
                employeeRating={jobsData?.employeeRating}
                remarks={jobsData?.reasonForRework}
              ></CoachingProgressCard>
            ))
          ) : (
            <p className="employeedata">{noData}</p>
          )}
          {info.length > 0 ? (
            <TablePagination
              totalPages={totalPages}
              currentPage={page}
              onClick={handlePageChange}
            />
          ) : (
            <></>
          )}
        </div>
      </MainCard>
    </>
  );
};

export default CoachingProgress;
