import PotentialCard from "../../../../../component/PotentialCard/PotentialCard";

function Potential() {
  return (
    <>
      <div className="right__container">
        <h4>Potential Readiness</h4>
        <div className="row">
          <div className="col-4">
            <PotentialCard
              software={"Software technical lead"}
              score={"56%"}
            ></PotentialCard>
          </div>
          <div className="col-4">
            <PotentialCard
              software={"Software technical lead"}
              score={"56%"}
            ></PotentialCard>
          </div>
          <div className="col-4">
            <PotentialCard
              software={"Software technical lead"}
              score={"56%"}
            ></PotentialCard>
          </div>
        </div>
      </div>
    </>
  );
}

export default Potential;
