import React from "react";
import { icons } from "../../../../../assets";

interface CardProps {
  preferredLocation: any;
  preferredMode: any;
  preferredCompany: any;
  onClick_edit: any;
  onClick_delete: any;
}

const PreferenceCard: React.FC<CardProps> = ({
  preferredLocation,
  preferredMode,
  preferredCompany,
  onClick_edit,
  onClick_delete,
}: CardProps) => (
  <div className="d-inline-block">
    <div className="experience-card">
      <div className="row">
        <div className="col-10">
          <div>
            <strong>Preferred Location: </strong>
            {preferredLocation}{" "}
          </div>
        </div>
        <div className="col-2">
          <div className="action-btn">
            <img
              src={icons.edit_icon}
              alt="edit_icon"
              onClick={onClick_edit}
              className="edit-icon"
            />
            <img
              src={icons.delete_icon}
              alt="delete-icon"
              onClick={onClick_delete}
              className="edit-icon"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div>
          <strong>Preferred Mode: </strong>
          {preferredMode}
        </div>
        <br />
        <br />
        <div>
          <strong>Preferred Company: </strong>
          {preferredCompany}
        </div>
      </div>
    </div>
  </div>
);
export default PreferenceCard;
