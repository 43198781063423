import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import MainCard from "../../../component/MainCard/MainCard";
import "./Competency.scss";
import * as actions from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import {
  Strings,
  competencyFormLabels,
  competencyValidationStrings,
  levelDropdown,
} from "../../../constant/Strings";
import useLoadingIndicator from "../../../component/Loader/Loader";

const Competency = () => {
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const designation = JSON.parse(userLogin).designation;
  const [btnDisable, setBtnDisable] = useState(false);
  const [streamValues, setStreamValues] = useState([]);
  const [subStreamValues, setSubStreamValues] = useState([]);
  const [competencyForm, setCompetencyForm] = useState<any>({
    stream: "",
    streamName: "",
    subStream: "",
    subStreamName: "",
    leader: "",
    leaderName: "",
    level: "",
    designation: designation,
  });
  const [errors, setErrors] = useState({
    stream: "",
    subStream: "",
    leader: "",
    level: "",
    designation: "",
  });
  const { stream_error, subStream_error, level_error } =
    competencyValidationStrings;

  const { submitSuccess, cancel, submit, competencyHeading } = Strings;
  const {
    streamLabel,
    subStreamLabel,
    leaderLabel,
    levelLabel,
    designationLabel,
  } = competencyFormLabels;

  useEffect(() => {
    showLoadingIndicator();
    dispatch(
      actions.getStreamsListAction((res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          hideLoadingIndicator();
        } else {
          if (res?.code === 200) {
            setStreamValues(res?.data);
            hideLoadingIndicator();
          }
        }
      })
    );
  }, []);

  useEffect(() => {
    if (
      competencyForm.stream &&
      competencyForm.subStream &&
      competencyForm.level
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [competencyForm]);

  const dispatch = useDispatch();
  const handleClearForm = () => {
    setCompetencyForm({
      stream: "",
      streamName: "",
      subStream: "",
      subStreamName: "",
      leader: "",
      leaderName: "",
      level: "",
    });
  };

  let valid: boolean = true;
  const validate = () => {
    if (competencyForm.stream == "") {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        stream: stream_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        stream: "",
      }));
    }
    if (competencyForm.subStream == "") {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        subStream: subStream_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        subStream: "",
      }));
    }

    if (competencyForm.level == "") {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        level: level_error,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        level: "",
      }));
    }
  };
  const handleCompetencySubmit = (e: any) => {
    e.preventDefault();
    validate();
    if (valid) {
      handleDispatch();
      setBtnDisable(true);
    }
    setBtnDisable(false);
  };
  const handleDispatch = () => {
    showLoadingIndicator();
    const payloadObj = {
      streamRef: competencyForm.stream,
      subStreamRef: competencyForm.subStream,
      leaderRef: competencyForm.leader,
      employeeRef: loginId,
      level: competencyForm.level,
      designation: competencyForm.designation,
    };

    dispatch(
      actions.addCompetencyAction(payloadObj, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          hideLoadingIndicator();
        } else {
          if (res.code === 200) {
            showSuccessToastMessage(submitSuccess);
            setCompetencyForm({
              stream: "",
              streamName: "",
              subStream: "",
              subStreamName: "",
              leader: "",
              leaderName: "",
              level: "",
            });
            hideLoadingIndicator();
          }
        }
      })
    );
  };

  const handleCompetencyCancel = () => {
    handleClearForm();
  };

  const fetchSubStream = (streamId: any) => {
    showLoadingIndicator();
    dispatch(
      actions.getSubStreamsListAction(streamId, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          hideLoadingIndicator();
        } else {
          if (res?.code === 200) {
            setSubStreamValues(res.data);
            hideLoadingIndicator();
          }
        }
      })
    );
  };

  const fetchLeaderName = (streamId: any, streamName: any) => {
    showLoadingIndicator();
    const matchedRecord: any = streamValues?.find(
      (item: any) => item._id === streamId
    );
    const concatedLeaderName =
      matchedRecord?.leadersData[0]?.candidateName +
      " " +
      matchedRecord?.leadersData[0]?.middleName +
      " " +
      matchedRecord?.leadersData[0]?.lastname;

    setCompetencyForm({
      ...competencyForm,
      streamName: streamName,
      stream: streamId,
      leaderName: concatedLeaderName,
      leader: matchedRecord?.leaderRef,
      subStream: "",
      subStreamName: "",
    });
    hideLoadingIndicator();
  };
  const fetchOtherdetails = (id: any, name: any) => {
    fetchSubStream(id);
    fetchLeaderName(id, name);
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{competencyHeading}</h2>
          </div>
        </div>

        <div className="card mt-2">
          <form className="row g-3 m-2">
            <div className="col-md-4">
              <label className="form-label">{streamLabel}</label>
              <span className="required">*</span>
              <Dropdown
                onSelect={(eventKey: any) => {
                  const id = eventKey.split("/")[0];
                  const name = eventKey.split("/")[1];
                  fetchOtherdetails(id, name);
                }}
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {competencyForm.streamName || "Select a Stream"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {streamValues?.length > 0 &&
                    streamValues?.map((data: any) => (
                      <Dropdown.Item
                        key={data._id}
                        eventKey={data._id + "/" + data.streamName}
                      >
                        {data.streamName}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-4">
              <label className="form-label">{subStreamLabel}</label>
              <span className="required">*</span>
              <Dropdown
                onSelect={(eventKey: any) =>
                  setCompetencyForm({
                    ...competencyForm,
                    subStream: eventKey.split("/")[0],
                    subStreamName: eventKey.split("/")[1],
                  })
                }
              >
                <Dropdown.Toggle
                  variant="light"
                  className="form-control"
                  disabled={subStreamValues?.length == 0}
                >
                  {subStreamValues?.length > 0 && competencyForm.subStreamName
                    ? competencyForm.subStreamName
                    : "Select Sub Stream"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {subStreamValues?.length > 0 &&
                    subStreamValues.map((data: any) => (
                      <Dropdown.Item
                        key={data._id}
                        eventKey={data._id + "/" + data.subStreamName}
                      >
                        {data?.subStreamName}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="col-md-4">
              <label className="form-label">{leaderLabel}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Leader Name"
                value={competencyForm.leaderName}
                onChange={(e) =>
                  setCompetencyForm({
                    ...competencyForm,
                    leaderName: e.target.value,
                  })
                }
                disabled
              />
              {errors.leader != "" && <p className="error">{errors.leader}</p>}
            </div>

            <div className="col-md-4">
              <label className="form-label">{levelLabel}</label>
              <span className="required">*</span>
              <Dropdown
                onSelect={(eventKey: any) =>
                  setCompetencyForm({
                    ...competencyForm,
                    level: eventKey,
                  })
                }
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {competencyForm.level || "Select Level "}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {levelDropdown?.map((value: any) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {value}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="col-md-4">
              <label className="form-label">{designationLabel}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Designation"
                value={competencyForm.designation}
                onChange={(e) =>
                  setCompetencyForm({
                    ...competencyForm,
                    designation: e.target.value,
                  })
                }
                disabled
              />
              {errors.designation != "" && (
                <p className="error">{errors.designation}</p>
              )}
            </div>

            <div className="modal-btn-wrapper mt-5 mx-auto">
              <div>
                <button
                  className="btn cancel-btn buttonsize"
                  onClick={handleCompetencyCancel}
                >
                  {cancel}
                </button>
              </div>
              <div>
                <button
                  className={
                    btnDisable
                      ? "btn add-submit-button buttonsize"
                      : "btn add-submit-button btn-disabled buttonsize"
                  }
                  onClick={handleCompetencySubmit}
                >
                  {submit}
                </button>
              </div>
            </div>
          </form>
        </div>
      </MainCard>
    </>
  );
};

export default Competency;
