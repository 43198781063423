import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import { Strings } from "../../../../constant/Strings";

const AddInvoiceUpload = (props: any) => {
  const { hideAddProjectInvoice, setnextScreen } = props;
  const { cancel, submit, imageSize, uploadFile, uploadInvoice } = Strings;
  const dispatch = useDispatch();
  const [btnDisable, setBtnDisable] = useState(false);
  const [projectInvoiceData, setProjectInvoiceData] = useState<any>({
    invoicePdf: "",
  });
  const [docFile, setDocFile] = useState<any>({
    invoicePdf: null,
  });
  const invoiceId: any = localStorage.getItem("invoiceId");

  const handleSubmit = () => {
    dispatch(
      actions.editProjectRepositoryInvoiceAction(invoiceId, projectInvoiceData)
    );
    setnextScreen(true);
    hideAddProjectInvoice();
    localStorage.removeItem("invoiceId");
    showSuccessToastMessage("Added Successfully!");
  };

  useEffect(() => {
    if (projectInvoiceData.invoicePdf) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  const handleSubmitInvoicePdf = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 5 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 5 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, invoicePdf: e.target.files[0] });
      dispatch(
        actions.projectInvoiceDetailsDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setProjectInvoiceData({
                ...projectInvoiceData,
                invoicePdf: res.data,
              });
            }
          }
        )
      );
    }
  };

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-12">
          <label className="form-label">{uploadInvoice}</label>
          <span className="required">*</span>
          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleSubmitInvoicePdf}
              hidden
              id="actual-btn-invoice"
            />
            <label
              htmlFor="actual-btn-invoice"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <p className="form-label">
            {imageSize}
            <span className="required">*</span>
          </p>
          <a
            className="buttonsize"
            href={projectInvoiceData.invoicePdf?.document_path_url}
            target="_blank"
          >
            {projectInvoiceData.invoicePdf?.document_path_url
              ? "View File"
              : ""}
          </a>
          <p className="image-size-condition">{docFile.sow?.name}</p>
        </div>
      </div>

      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideAddProjectInvoice}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddInvoiceUpload;
