import { useEffect, useState } from "react";
import * as actions from "../../../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import { showSuccessToastMessage } from "../../../../../component/Toasts/Toasts";
import { Dropdown } from "react-bootstrap";
import {
  whiteSpaceValid,
  projectProjectNameValidation,
} from "../../../../../constant/Validations";
import { Strings } from "../../../../../constant/Strings";

const EditSkills = (props: any) => {
  const {
    id,
    skillName,
    descrption,
    proficiencyLevel,
    setModalFinished,
    hideEditSkill,
  } = props;

  const [skillsData, setSkillsData] = useState({
    skillName: skillName,
    description: descrption,
    proficiencylevel: proficiencyLevel,
  });

  const [errors, setErrors] = useState({
    skillName: "",
    description: "",
    proficiencylevel: "",
  });

  const [btnDisable, setBtnDisable] = useState(false);
  const [showSkillErrorToggle, setShowSkillErrorToggle] = useState(false);
  const [showDescriptionErrorToggle, setShowDescriptionErrorToggle] =
    useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      skillsData.skillName &&
      skillsData.description &&
      skillsData.proficiencylevel
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [skillsData, btnDisable]);
  const handleEdit = () => {
    if (
      whiteSpaceValid(skillsData.skillName) &&
      projectProjectNameValidation(skillsData.skillName)
    ) {
      dispatch(actions.editEmployeeSkillsAction(id, skillsData));
      setModalFinished();
      hideEditSkill();
      showSuccessToastMessage("Edited Successfully!");
    } else {
      if (
        whiteSpaceValid(skillsData.skillName) &&
        projectProjectNameValidation(skillsData.skillName)
      ) {
        setShowSkillErrorToggle(false);
      } else {
        setShowSkillErrorToggle(true);
      }
    }
  };

  const {
    submit,
    cancel,
    addSuccess,
    valid_skill,
    proficiency_level,
    skill_name,
    less_than_a_year,
    years_of_experience,
  } = Strings;

  const [setDropdown1, setShowDropdown1] = useState(false);
  const [setDropdown, setShowDropdown] = useState(false);

  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            {skill_name}
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={skillsData.skillName}
            onChange={(e) =>
              setSkillsData({
                ...skillsData,
                skillName: e.target.value,
              })
            }
          />
          <p className="error">
            {skillsData.skillName.length !== 0 &&
              (showSkillErrorToggle ? valid_skill : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{years_of_experience}</label>
          <span className="required">*</span>

          <Dropdown
            onMouseLeave={() => setShowDropdown1(false)}
            onMouseOver={() => setShowDropdown1(true)}
            className="qualification-dropdown"
            onSelect={(eventKey: any) =>
              setSkillsData({
                ...skillsData,
                description: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {skillsData.description}
            </Dropdown.Toggle>
            <Dropdown.Menu show={setDropdown1}>
              <Dropdown.Item eventKey="Less than a year">
                {less_than_a_year}
              </Dropdown.Item>
              <Dropdown.Item eventKey="1">1</Dropdown.Item>
              <Dropdown.Item eventKey="2">2</Dropdown.Item>
              <Dropdown.Item eventKey="3">3</Dropdown.Item>
              <Dropdown.Item eventKey="4">4</Dropdown.Item>
              <Dropdown.Item eventKey="5">5</Dropdown.Item>
              <Dropdown.Item eventKey="6">6</Dropdown.Item>
              <Dropdown.Item eventKey="7">7</Dropdown.Item>
              <Dropdown.Item eventKey="8">8</Dropdown.Item>
              <Dropdown.Item eventKey="9">9</Dropdown.Item>
              <Dropdown.Item eventKey="10">10</Dropdown.Item>
              <Dropdown.Item eventKey="11">11</Dropdown.Item>
              <Dropdown.Item eventKey="12">12</Dropdown.Item>
              <Dropdown.Item eventKey="13">13</Dropdown.Item>
              <Dropdown.Item eventKey="14">14</Dropdown.Item>
              <Dropdown.Item eventKey="15">15</Dropdown.Item>
              <Dropdown.Item eventKey="16">16</Dropdown.Item>
              <Dropdown.Item eventKey="17">17</Dropdown.Item>
              <Dropdown.Item eventKey="18">18</Dropdown.Item>
              <Dropdown.Item eventKey="19">19</Dropdown.Item>
              <Dropdown.Item eventKey="20">20</Dropdown.Item>
              <Dropdown.Item eventKey="21">21</Dropdown.Item>
              <Dropdown.Item eventKey="22">22</Dropdown.Item>
              <Dropdown.Item eventKey="23">23</Dropdown.Item>
              <Dropdown.Item eventKey="24">24</Dropdown.Item>
              <Dropdown.Item eventKey="25">25</Dropdown.Item>
              <Dropdown.Item eventKey="26">26</Dropdown.Item>
              <Dropdown.Item eventKey="27">27</Dropdown.Item>
              <Dropdown.Item eventKey="28">28</Dropdown.Item>
              <Dropdown.Item eventKey="29">29</Dropdown.Item>
              <Dropdown.Item eventKey="30">30</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <p className="error">{errors.description}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{proficiency_level}</label>
          <span className="required">*</span>
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
            onSelect={(eventKey: any) =>
              setSkillsData({
                ...skillsData,
                proficiencylevel: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {skillsData.proficiencylevel}
            </Dropdown.Toggle>
            <Dropdown.Menu show={setDropdown}>
              <Dropdown.Item eventKey="Beginner">Beginner</Dropdown.Item>
              <Dropdown.Item eventKey="Intermediate">
                Intermediate
              </Dropdown.Item>
              <Dropdown.Item eventKey="Expert">Expert</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <p className="error">{errors.proficiencylevel}</p>
        </div>
      </div>
      <div className="modal-btn-wrapper mt-5">
        <div>
          <button className="btn cancel-btn buttonsize" onClick={hideEditSkill}>
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSkills;
