import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";
import { Strings } from "../../../../../constant/Strings";
import {
  whiteSpaceValid,
  projectProjectNameValidation,
} from "../../../../../constant/Validations";
import AddWorkAchievements from "./AddWorkAchievements";

const EditWorkAchievements = (props: any) => {
  const {
    id,
    award,
    description,
    date,
    certificate,
    hideEditWorkAchievements,
  } = props;
  const [workAchievementsData, setWorkAchievementsData] = useState({
    id: id,
    award: award,
    description: description,
    date: date,
    certificate: certificate,
  });
  const {
    valid_description,
    editSuccess,
    file_size_max_msg,
    file_type_msg,
    description_label,
    date_label,
    recognition_type,
    upload_certificate,
    imageSize2mb,
    uploadFile,
    cancel,
    submit,
  } = Strings;
  const [docFile, setDocFile] = useState<any>([]);

  const [errors, setErrors] = useState({
    award: "Award",
    description: "",
  });
  const [btnDisable, setBtnDisable] = useState(false);

  const [showawardErrorToggle, setShowawardErrorToggle] = useState(false);
  const [showdescriptionErrorToggle, setShowdescriptionErrorToggle] =
    useState(false);

  const dispatch = useDispatch();
  const handleEdit = () => {
    if (
      whiteSpaceValid(workAchievementsData.description) &&
      projectProjectNameValidation(workAchievementsData.description)
    ) {
      dispatch(
        actions.editEmployeeWorkAchievementsAction(id, workAchievementsData)
      );
      showSuccessToastMessage(editSuccess);
      hideEditWorkAchievements();
    } else {
      if (
        whiteSpaceValid(workAchievementsData.description) &&
        projectProjectNameValidation(workAchievementsData.description)
      ) {
        setShowdescriptionErrorToggle(false);
      } else {
        setShowdescriptionErrorToggle(true);
      }
    }
  };

  const handleOnChange = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage(file_size_max_msg);
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(file_type_msg);
      return;
    } else {
      setDocFile(e.target.files[0]);
      dispatch(
        actions.workAchievementsDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setWorkAchievementsData({
                ...workAchievementsData,
                certificate: res.data,
              });
            }
          }
        )
      );
    }
  };
  useEffect(() => {
    if (
      workAchievementsData.award &&
      workAchievementsData.description &&
      workAchievementsData.date
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [workAchievementsData, btnDisable]);

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            {recognition_type}
          </label>
          <span className="required">*</span>

          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
            className="qualification-dropdown"
            onSelect={(eventKey: any) =>
              setWorkAchievementsData({
                ...workAchievementsData,
                award: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {workAchievementsData.award}
            </Dropdown.Toggle>
            <Dropdown.Menu show={showDropdown}>
              <Dropdown.Item eventKey="Award">Award</Dropdown.Item>
              <Dropdown.Item eventKey="Reward">Reward</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label className="form-label">{description_label}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={workAchievementsData.description}
            onChange={(e) =>
              setWorkAchievementsData({
                ...workAchievementsData,
                description: e.target.value,
              })
            }
          />
          <p className="error">
            {workAchievementsData.description.length !== 0 &&
              (showdescriptionErrorToggle ? valid_description : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{date_label}</label>
          <span className="required">*</span>
          <input
            type="date"
            max={moment().format("YYYY-MM-DD")}
            className="form-control"
            value={moment(workAchievementsData.date).format("YYYY-MM-DD")}
            onChange={(e) =>
              setWorkAchievementsData({
                ...workAchievementsData,
                date: e.target.value,
              })
            }
          />
        </div>

        <div className="col-md-4">
          <label className="form-label">{upload_certificate}</label>

          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleOnChange}
              hidden
              id="actual-btn"
            />
            <label
              htmlFor="actual-btn"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <p className="image-size-condition">
            {imageSize2mb}
            <span className="required">*</span>
          </p>
        </div>
        <div className="col-md-4 mt-3">
          <a
            href={workAchievementsData.certificate?.document_path_url}
            target="_blank"
            className="buttonsize"
          >
            {workAchievementsData.certificate?.document_path_url
              ? "View File"
              : ""}
          </a>
          <p className="image-size-condition">{docFile?.name}</p>
        </div>
      </div>
      <div className="modal-btn-wrapper mt-3">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideEditWorkAchievements}
          >
            {cancel}
          </button>
        </div>

        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditWorkAchievements;
