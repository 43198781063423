import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../../Redux/Actions/index";
import { useNavigate } from "react-router-dom";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";

const ReasonForReSend = (props: any) => {
  const { hideModal, apiCallCompleted } = props;
  const eId = localStorage.getItem("selectedId");
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [reason, setReason] = useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const handleStatus = (statusData: any) => {
    apiCallCompleted(false);
    dispatch(
      actions.editEmployeeDataAction(
        {
          eId: eId,
          updatedBy: loginId,
          recordStatus: "Re-Sent",
          reason: reason,
        },
        (res: any) => {
          if (res) {
            if (res.error) {
              if (res.error.error_description) {
                showErrorToastMessage(res.error.error_description);
                apiCallCompleted(true);
              }
            } else {
              if (res.code === 200) {
                apiCallCompleted(true);
                showSuccessToastMessage("Re-Sent Successfully!");
                {
                  () => navigate("/");
                }
              }
            }
          }
        }
      )
    );
    hideModal();
  };

  const handleChange = (e: any) => {
    setReason(e.target.value);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 mt-2 mb-3">
          <label className="form-label">Reason for Re-Send</label>
          <textarea
            className="form-control"
            style={{
              backgroundColor: "#FFF9F4",
              width: "24rem",
              height: "10rem",
            }}
            value={reason}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="modal-btn-wrapper mt-3 mx-auto">
        <div>
          <button
            className="btn btn-outline-danger buttonsize"
            onClick={hideModal}
          >
            Cancel
          </button>
        </div>
        <div>
          <button className="btn btn-primary buttonsize" onClick={handleStatus}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReasonForReSend;
