import React from "react";
import "./Pagination.scss";

const Pagination = (props: any) => {
  const {
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageOptions,
    pageCount,
    pageIndex,
  } = props;
  return (

    <div className="pagination-wrapper">

      <button
        className="pagination-right-arrow"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        {"<<"}
      </button>
      <button
        className="pagination-right-arrow"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        {"<"}
      </button>
      <button className="pagination-btn-active ">
        <strong>
          {pageIndex + 1}
        </strong>
      </button>
      <button className="pagination-btn">
        {`of ${pageOptions.length}`}
      </button>
      <button
        className="pagination-right-arrow"
        onClick={() => nextPage()}
        disabled={!canNextPage}
      >
        {">"}
      </button>
      <button
        className="pagination-right-arrow"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canPreviousPage}
      >
        {">>"}
      </button>
    </div>
  );
};

export default Pagination;
