import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { FaUserCircle, FaCaretDown } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import spotlightLogo from "../../assets/SpotlightLogo.svg";
import calendar_logo from "../../assets/calendar-logo.png";
import notification_icon from "../../assets/notification-icon.svg";
import default_avatar from "../../assets/default-avatar.jpg";
// import user_logo from "../../assets/calendar-logo.png";

import cross from "../../assets/cross.png";
import search_icon from "../../assets/search.svg";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions/index";
import { TbLogout } from "react-icons/tb";
import { employeeLogoutApi } from "../../Redux/Services";
import { showErrorToastMessage } from "../Toasts/Toasts";
import SearchResultsList from "../GlobalSearch/SearchResultsList";
import { useSelector, shallowEqual } from "react-redux";
import { RiNotification2Line } from "react-icons/ri";
import moment from "moment";
import { Collapse, Form, ToggleButtonGroup } from "react-bootstrap";
import { CgLogOff } from "react-icons/cg";
import { BiPowerOff } from "react-icons/bi";
import useLoadingIndicator from "../Loader/Loader";
import hamburger_icon from "../../assets/sidebaricons/hamburger-menu.svg";
import { baseUrl } from "../../config/constants";

const Header = (props: any) => {
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const { title, body, handleSideBar } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  // localStorage.setItem("selectedId", loginId);

  const logout = () => {
    if (baseUrl?.includes("spotlight-dev-api.skill-mine.com")) {
      localStorage.clear();
      (window.location.href = "https://spotlight-dev.skill-mine.com/"),
        "_blank";
    } else if (baseUrl?.includes("spotlight-api.skill-mine.com")) {
      localStorage.clear();
      (window.location.href = "https://spotlight.skill-mine.com/"), "_blank";
    } else if (
      baseUrl?.includes("https://spotlight-stage-api.skill-mine.com")
    ) {
      localStorage.clear();
      (window.location.href = "https://spotlight-stage.skill-mine.com/"),
        "_blank";
    } else {
      dispatch(actions.logOutAction("logout"));
    }
  };

  const [showLogout, setShowLogout] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [inputValue, setinputValue] = useState("");
  const [employeeSearchResult, setemployeeSearchResult] = useState([]);
  const [projectSearchResult, setprojectSearchResult] = useState([]);
  const [allNotificationArray, setAllNotificationArray] = useState<any>([]);
  const [readArray, setReadNotificationArray] = useState<any>([]);
  const [unReadArray, setUnreadNotificationArray] = useState<any>([]);
  const [hasNotification, setHasNotification] = useState(false);
  const [onSwitch, setOnSwitch] = useState(false);
  const [unReadCount, setUnreadNotificationCount] = useState<any>("");
  const [displayPicture, setdisplayPicture] = React.useState(null);

  const editEmployeeDetails = useSelector(
    (state: any) => state.employeeReducer.editEmployeeData
  );
  const getApiDispatch = () => {
    dispatch(
      actions.getemployeeDetailsAction(loginId, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
        } else {
          if (res?.code === 200) {
            setdisplayPicture(
              res.data[0].candidatePhoto?.document_path_url
                ? res.data[0].candidatePhoto?.document_path_url
                : res.data[0].candidatePhoto?.candidatePhoto?.document_path_url
                ? res.data[0].candidatePhoto?.candidatePhoto?.document_path_url
                : ""
            );
            hideLoadingIndicator();
          }
        }
      })
    );
  };
  useEffect(() => {
    showLoadingIndicator();
    getApiDispatch();
  }, [loginId]);

  useEffect(() => {
    getApiDispatch();
  }, [editEmployeeDetails]);

  const role = localStorage.getItem("role");
  const user: any = localStorage.getItem("user");
  const firstName = JSON.parse(user)?.given_name;

  const handleChange = (value: any) => {
    setinputValue(value);
    dispatch(
      actions.fetchSearchDataAction(value.toLowerCase(), (res: any) => {
        if (res) {
          setemployeeSearchResult(res.data.employeeData);
          setprojectSearchResult(res.data.projectData);
        }
      })
    );
  };
  useEffect(() => {
    dispatch(actions.getAllNotificationAction());
  }, [unReadCount]);

  const getAllNotification = useSelector(
    (state: any) => state.employeeReducer.getAllNotification
  );

  useEffect(() => {
    if (getAllNotification && getAllNotification.data) {
      setAllNotificationArray(getAllNotification.data.allNotificationList);
      setReadNotificationArray(getAllNotification.data.readNotificationList);
      setUnreadNotificationArray(getAllNotification.data.newNotificationList);
      setUnreadNotificationCount(getAllNotification.data.unreadMessagesCount);
    }
  }, [getAllNotification, allNotificationArray, readArray, unReadArray]);

  const handleClearInput = () => {
    setemployeeSearchResult([]);
    setprojectSearchResult([]);
    setinputValue("");
  };

  const handleClick = () => {
    dispatch(actions.getAllNotificationAction());
    setShowNotification(true);
    setHasNotification(false);
  };

  const myRef: any = useRef();

  const onClickUnreadSubject = (notificationId: any, employeeId: any) => {
    dispatch(
      actions.editNotificationDataAction(notificationId, { is_Read: 1 })
    );
    dispatch(actions.getAllNotificationAction());
    dispatch(actions.getemployeeDetailsAction(employeeId, (res: any) => {}));
    localStorage.setItem("selectedId", employeeId);
    localStorage.setItem("fromSearch", JSON.stringify(true));
    setShowNotification(false);
    navigate("/");
  };

  const onClickReadSubject = (employeeId: any) => {
    showLoadingIndicator();
    dispatch(actions.getemployeeDetailsAction(employeeId, (res: any) => {}));
    localStorage.setItem("selectedId", employeeId);
    localStorage.setItem("fromSearch", JSON.stringify(true));
    setShowNotification(false);
    navigate("/");
    hideLoadingIndicator();
  };

  return (
    <div className="header">
      <div onClick={handleSideBar}>
        <img src={hamburger_icon} alt="icon" />
      </div>
      {loadingComponent()}
      <div onClick={() => navigate("/profile")}>
        <img
          src={spotlightLogo}
          className="spotlight-logo"
          alt="spotlightLogo"
          data-toggle="tooltip"
          data-placement="top"
          title="Spotlight"
        />
      </div>

      <div className="header-logos">
        <div
          className="sorting-cursor pwd-container"
          onClick={handleClick}
          ref={myRef}
        >
          <div className="btn-container">
            <button className="btn">
              <img
                src={notification_icon}
                className="notification_icon"
                alt="icon"
              />
            </button>
          </div>
          {unReadArray.length > 0 && <span className="notification-dot"></span>}
        </div>

        {
          <div
            className={
              showNotification
                ? "notification-list show-notification"
                : "notification-list"
            }
          >
            <div className="notification-div">
              <span>
                <div className="row">
                  <div className="col-11">
                    {onSwitch ? (
                      <strong className="notification-title">
                        Old Notifications
                      </strong>
                    ) : (
                      <strong className="notification-title">
                        New Notifications
                        <span style={{ fontSize: "13px" }}>
                          ({unReadCount})
                        </span>
                      </strong>
                    )}
                  </div>
                  <div className="col-1">
                    <img
                      className="notification-close"
                      onClick={() => setShowNotification(false)}
                      src={cross}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Close"
                      alt="icon"
                    />
                  </div>
                </div>
              </span>
            </div>
            <div className="toggle-switch">
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={onSwitch}
                className="switch"
                onChange={() => setOnSwitch(!onSwitch)}
              />
            </div>
            {onSwitch ? (
              readArray.length == 0 ? (
                <>
                  <div className="align-center-text">
                    No Notifications found
                  </div>
                </>
              ) : (
                readArray.map((ele: any, index: any) => (
                  <React.Fragment key={index}>
                    <div className="notification-subject">
                      <ul key={ele?.subject}>
                        <li>
                          <div
                            onClick={() =>
                              onClickReadSubject(ele.redirect_to_user_id)
                            }
                          >
                            {ele?.subject}
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="notification">
                      <p>{ele?.message}</p>
                      <div className="d-flex">
                        {moment(ele?.created_at).format("DD-MMM-YYYY")}
                        <div className="notification-time">
                          {moment(ele?.created_at).format("h:mm a")}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))
              )
            ) : unReadArray.length == 0 ? (
              <>
                <div className="align-center-text">No Notifications found</div>
              </>
            ) : (
              unReadArray.map((ele: any, index: any) => (
                <React.Fragment key={index}>
                  <div className="notification-subject">
                    <ul>
                      <li>
                        <div
                          onClick={() =>
                            onClickUnreadSubject(
                              ele?._id,
                              ele.redirect_to_user_id
                            )
                          }
                        >
                          {ele?.subject}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="notification">
                    <p>{ele?.message}</p>
                    <div className="d-flex">
                      {moment(ele?.created_at).format("DD-MMM-YYYY")}
                      <div className="notification-time">
                        {moment(ele?.created_at).format("h:mm a")}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))
            )}
          </div>
        }

        <div className="btn-container">
          <button
            className="btn"
            onMouseOver={() => setShowLogout(true)}
            onMouseLeave={() => setShowLogout(false)}
          >
            <img
              src={displayPicture ? displayPicture : default_avatar}
              className="image-profile"
              height="100%"
              width="100%"
              alt="icon"
            />
          </button>
          <div
            className={showLogout ? "dropdown show-dropdown" : "dropdown"}
            onMouseOver={() => setShowLogout(true)}
            onMouseLeave={() => setShowLogout(false)}
            style={{ fontSize: "13px" }}
          >
            <button className="dropdown-btn" onClick={logout}>
              <BiPowerOff className="icon-user logout-icon"></BiPowerOff> Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
