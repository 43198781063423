import React from "react";
import "./Button.scss";

const ButtonRow = (props: any) => {
  const { icon, title, onClick, className } = props;
  return (
    <>
      <button className={`button ${className}`} onClick={onClick}>
        {icon && (
          <>
            <img src={icon} className="button-icon" alt="icon" />
          </>
        )}

        {title}
      </button>
    </>
  );
};

export default ButtonRow;
