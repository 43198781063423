import { useEffect, useMemo, useRef, useState } from "react";
import { TiArrowUnsorted } from "react-icons/ti";
import Button from "../../../component/Buttons/Button";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import SearchBar from "../../../component/SearchBar/SearchBar";
import { useNavigate } from "react-router-dom";
import {
  Strings,
  moduleMasterFilter,
  moduleMasterExportReport,
} from "../../../constant/Strings/index";
import { icons } from "../../../assets/index";
import * as actions from "../../../Redux/Actions";
import "../InseedProgram.scss";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { useSelector, useDispatch } from "react-redux";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import AddModule from "./AddModule";
import * as XLSX from "xlsx";
import TablePagination from "../../../component/TablePagination/TablePagination";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";

const ModuleTable = (props: any) => {
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  const {
    totalPages,
    onPage,
    handlePageChange,
    searchValue,
    handleSearch,
    filterValue,
    filterChange,
    totalEntries,
    pagePerSize,
    setPagePerSize,
    onSearchClicked,
    filterKey
  } = props;
  const [showExportReport, setShowExportReport] = useState(false);
  const { hamburger_icon, export_report, download_icon } = icons;
  const [totalRecords, setTotalRecords] = useState(0);
  const [info, setInfo] = useState([]);
  const authRole = localStorage.getItem("role");
  const isInseedMaker = authRole?.includes("inseed-program-maker");
  const { module_master, exportReport, noData } = Strings;

  const moduleData = useSelector(
    (state: any) => state.employeeReducer.getInseedAllModulesDetails
  );

  const [showAddModule, setShowAddModule] = useState(false);
  const handleAddModule = () => {
    setShowAddModule(true);
  };
  const hideAddModule = () => {
    setShowAddModule(false);
  };

  const navigate = useNavigate();
  const handleProjectDetails = (moduleId: any) => {
    localStorage.setItem("routeTypeInseed", "ModuleMaster");
    navigate(`/module/${moduleId}`);
  };

  const ResourceAddingColumns: any = [
    {
      Header: "Module Name",
      accessor: "moduleName",
      Cell: (Action: any) => {
        const moduleName = Action.row.original.moduleName;
        const moduleId = Action.row.original._id;
        return (
          <a
            className="module-name-hyperlink"
            onClick={() => handleProjectDetails(moduleId)}
          >
            {moduleName}
          </a>
        );
      },
    },
    {
      Header: "Content",
      accessor: "content",
      Cell: (Action: any) => {
        const remarks = Action.row.original.content?.document_path_url;
        return (
          <div>
            {remarks ? (
              <div
                className="action-btn"
                data-toggle="tooltip"
                data-placement="top"
                title="Assignment Letter"
              >
                <a href={remarks} target="_blank">
                  <div className="center">
                    <img src={download_icon} alt="downloadIcon" />
                  </div>
                </a>
              </div>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      Header: "Duration (Days)",
      accessor: "duration",
    },
    {
      Header: "Trainer Name",
      accessor: "trainerName",
    },
    {
      Header: "Contact Number",
      accessor: "trainerContactNumber",
    },
    {
      Header: "Trainer Institute",
      accessor: "instituteName",
    },
    {
      Header: "Success Criteria",
      accessor: "successCriteria",
      Cell: (Action: any) => {
        const remarks = Action.row.original.successCriteria;
        return <div>  {remarks ? remarks.length > 10 ? `${remarks.slice(0, 10)}...` : remarks:"-"}</div>;
      },
    },
    {
      Header: "Technology Stream",
      accessor: "technologyStream",
      Cell: (Action: any) => {
        const remarks = Action.row.original.technologyStream;
        return <div>{remarks ? remarks.length > 10 ? `${remarks.slice(0, 10)}...` : remarks:"-"}</div>;
      },
    },
  ];
  const data = info;
  const columns = useMemo(() => ResourceAddingColumns, [pagePerSize]);
  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { pageSize },
  } = useTable(
    { columns, data, initialState: { pageSize: pagePerSize, pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  useEffect(() => {
    if (moduleData && moduleData.data) {
      setInfo(moduleData.data);
      setTotalRecords(moduleData.data.length);
    }
  }, [moduleData, info, pagePerSize, pageSize]);
  const dispatch = useDispatch();
  const generateXLFile = () => {
    showLoadingIndicator();
    dispatch(
      actions.getInseedAllModulesAction(totalEntries, 1, filterKey, searchValue, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res.error.error_description);
        }else if (res.code === 200) {
            var moduleMasterData = [moduleMasterExportReport];
            res?.data?.forEach((ele: any) => {
              let emptyArray: string[] = [];
              emptyArray.push(
                ele.moduleName,
                ele.syllabus,
                ele.duration,
                ele.trainerName,
                ele.trainerContactNumber,
                ele.instituteName,
                ele.successCriteria,
                ele.technologyStream
              );
              moduleMasterData.push(emptyArray);
            });
            var moduleMaster = XLSX.utils.aoa_to_sheet(moduleMasterData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, moduleMaster, module_master);
            hideLoadingIndicator();
            XLSX.writeFile(wb, "Module_Master.xlsx");
          }
      })
    );
  };
  const exportReportRef = useRef<HTMLInputElement | null>(null);
  const imgIcon = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (exportReportRef.current && !exportReportRef.current.contains(event.target as Node) ) {
        if(imgIcon.current && !imgIcon.current.contains(event.target as Node)){
        setShowExportReport(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showExportReport]);
  return (
    <>
      {loadingComponent()}
      <div className="module-heading-button-wrapper">
        <div>
          <h2>{module_master}</h2>
        </div>
        {isInseedMaker && (
          <div className="btn-wrapper">
            <Button
              title="+ Add Module"
              onClick={handleAddModule}
              className="add-module-btn"
            />
          </div>
        )}
      </div>
      <div className="mt-2">
        <div
          className="mt-3 buttonsize filter-module-wrapper"
          style={{ marginLeft: "0px" }}
        >
          <div className="module-searchbar-wrapper">
            <SearchBar
              filter={searchValue}
              setFilter={handleSearch}
              onSearchClicked={onSearchClicked}
            />
          </div>
          <div className="module-filter-container">
            <select
              id="select-filters"
              value={filterValue}
              onChange={(e: any) => {
                const selectedValue = e.target.value;
                const selectedOption = moduleMasterFilter.find(
                  (item: any) => item.value === selectedValue
                );
                if (selectedOption) {
                  filterChange(selectedOption);
                }
              }}
            >
              <option hidden>Filters</option>
              {moduleMasterFilter.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
          <div className="export-report-module-wrapper">
            <img
              src={hamburger_icon}
              onClick={() => setShowExportReport(!showExportReport)}
              alt="icon"
              height={30}
              width={30}
              ref={imgIcon} 
            />
            {showExportReport && (
              <div className="report-module-btn-wrapper" ref={exportReportRef}>
                <div className="export-module-btn">
                  <button onClick={generateXLFile}>
                    <img src={export_report} alt="icon" /> {exportReport}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
        />
        {data.length > 0 ? (
          <>
            <div className="table table-responsive">
              <table
                className="table-main table table-sm mt-3"
                {...getTableProps()}
              >
                <thead className="table-head">
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className="table-head-row"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column: any) => (
                        <th
                          className="table-head-row"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          scope="col"
                        >
                          {column.render("Header")}

                          <span>
                            {column.id !== "Action" && column.id !== "id" ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TiArrowUnsorted />
                                ) : (
                                  <TiArrowUnsorted />
                                )
                              ) : (
                                <TiArrowUnsorted />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody className="table-body" {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr className="table-body-row" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pagePerSize}
                setPageSize={setPagePerSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />

              <TablePagination
                totalPages={totalPages}
                currentPage={onPage}
                onClick={handlePageChange}
              />
            </div>
          </>
        ) : (
          <p className="employeedata">{noData}</p>
        )}
      </div>
      <ModalComponents
        show={showAddModule}
        onHide={hideAddModule}
        title="Add Module Details"
        modalBody={
          <AddModule
            hideAddModule={hideAddModule}
            apiCallCompleted={apiCallCompleted}
          />
        }
        className="modal-lg"
      />
    </>
  );
};
export default ModuleTable;
