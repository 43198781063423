import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions";

const AddpreferenceDetails = (props: any) => {
  const employeeId: any = localStorage.getItem("selectedId");

  const { hideAddPreferences } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  const [preferenceDetails, setPreferenceDetails] = useState({
    employeeRef: employeeId,
    preferredLocation: "",
    preferredMode: "",
    preferredCompany: "",
  });
  const addPreference = useSelector(
    (state: any) => state.employeeReducer.addEmployeePreference
  );
  useEffect(() => {
    if (
      preferenceDetails.preferredLocation &&
      preferenceDetails.preferredMode &&
      preferenceDetails.preferredCompany
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [preferenceDetails, btnDisable, addPreference]);

  const dispatch = useDispatch();
  const handlePreferenceSubmit = () => {
    if (
      preferenceDetails.preferredLocation &&
      preferenceDetails.preferredMode &&
      preferenceDetails.preferredCompany
    ) {
      dispatch(actions.addEmployeePreferenceAction(preferenceDetails));
      hideAddPreferences();
      showSuccessToastMessage("Added Successfully!");
    } else {
      showErrorToastMessage("Please fill all the details");
    }
    setPreferenceDetails({
      employeeRef: employeeId,
      preferredLocation: "",
      preferredMode: "",
      preferredCompany: "",
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            Preferred Location
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setPreferenceDetails({
                ...preferenceDetails,
                preferredLocation: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">Preferred Mode</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setPreferenceDetails({
                ...preferenceDetails,
                preferredMode: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">Preferred Company</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setPreferenceDetails({
                ...preferenceDetails,
                preferredCompany: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-2">
          <button
            className="btn btn-outline-danger"
            onClick={hideAddPreferences}
          >
            Cancel
          </button>
        </div>
        <div className="col-md-2">
          <button
            className={btnDisable ? "btn btn-primary" : "btn btn-disabled"}
            onClick={handlePreferenceSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddpreferenceDetails;
