import MainCard from "../../../component/MainCard/MainCard";
import { ToastContainer } from "react-toastify";
import MyAssessmentsTable from "./MyAssessmentsTable";
import { Strings } from "../../../constant/Strings";

const MyAssessments = () => {
  const { my_assessments } = Strings;

  return (
    <>
      <MainCard>
        <MyAssessmentsTable />
      </MainCard>
      <ToastContainer />
    </>
  );
};

export default MyAssessments;
