import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../Redux/Actions/Action";
import MainCard from "../../component/MainCard/MainCard";
import SearchBar from "../../component/SearchBar/SearchBar";
import { showErrorToastMessage } from "../../component/Toasts/Toasts";
import useLoadingIndicator from "../../component/Loader/Loader";
import "../InternalJobPosting/InternalJob.scss";
import FilterValues from "../../component/FieldFilter/FilterValues";
import TablePagination from "../../component/TablePagination/TablePagination";
import { ijpFilters, ijpHRFilters, Strings } from "../../constant/Strings";
import ReferResourceCard from "../SkillLinks/ReferResourceCard";
import ShowEntries from "../../component/ShowEntries/ShowEntries";
import ModalComponents from "../../component/ModalComponent/ModalComponent";
import EditPostAJobIJP from "./EditPostAJobIJP";

const JobPost = (props: any) => {
  const [page, setPage] = useState<Number>(1);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState<any>("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<any>(0);
  const [totalPages, setTotalPages] = useState(0);
  const {
    noData,
    internalJobPosting,
    filters,
    viewApplicationList,
    jdNotUploaded,
  } = Strings;
  const [info, setInfo] = useState([]);
  const [jobs, setJobs] = useState<any>();
  const [showEditReferResource, setShowEditReferResource] = useState(false);
  const dispatch = useDispatch();
  const editJobDetails = useSelector(
    (state: any) => state.employeeReducer.editIJPJobStatusDetails
  );
  const { apiCallCompleted, loadingComponent } = useLoadingIndicator();
  const role = localStorage.getItem("role");
  const filterKey = filterValue.map((ele: any) => ele.value);
  const employeeRole =
    role &&
    (!(
      role?.includes("hr") ||
      role?.includes("internal-job-maker") ||
      role?.includes("internal-job-checker")
    ) ||
      role === "undefined");
  const hrRole =
    role &&
    (role?.includes("hr") ||
      role?.includes("internal-job-maker") ||
      role?.includes("internal-job-checker"));
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  useEffect(() => {
    if (hrRole) {
      dispatch(
        actions.getAllInternalJobsAction(
          page,
          pageSize,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res?.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
              }
            }
          }
        )
      );
    }
    if (employeeRole) {
      dispatch(
        actions.getAllInternalJobsEmployeeViewAction(
          page,
          pageSize,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res?.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setInfo(res.data);
                setTotalRecords(res.data.length);
              }
            }
          }
        )
      );
    }
  }, [page, pageSize, filterValue, searchValue, editJobDetails]);

  const allInternalJobDetails = useSelector(
    (state: any) => state.employeeReducer.getAllInternalJobsDetails
  );

  useEffect(() => {
    if (allInternalJobDetails) {
      if (allInternalJobDetails.data) {
        setInfo(allInternalJobDetails.data);
        setTotalRecords(allInternalJobDetails.data.length);
      }
    }
  }, [allInternalJobDetails, info]);

  const handleEditReferResource = (row: any) => {
    setJobs(row);
    setShowEditReferResource(true);
  };
  const hideEditReferResource = () => {
    setShowEditReferResource(false);
  };
  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };
  const filterChange = (selectedOption: any) => {
    setPage(1);
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
  };
  const openDescURL = (e: any, jd: any) => {
    if (jd?.document_name) {
      e.preventDefault();
      dispatch(
        actions.viewJobDescriptionDocAction(
          { filename: jd?.document_name },
          (res: any) => {
            if (res.code == 200 && res.data) {
              window.open(res.data, "_blank");
            }
          }
        )
      );
    } else {
      showErrorToastMessage(jdNotUploaded);
    }
  };
  return (
    <>
      {loadingComponent()}

      <MainCard>
        <div className="ijp-heading-button-wrapper">
          <div>
            <h2>{internalJobPosting}</h2>
          </div>
        </div>
        <div className="mt-2">
          <div className="mt-3 buttonsize filter-module-wrapper">
            <div className="module-searchbar-wrapper">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
                disabled={!filterValue?.find((obj: any) => obj.value == "All")}
              />
            </div>
            <div className="module-filter-container">
              {employeeRole && (
                <>
                  <select
                    id="select-filters"
                    value={filterValue}
                    onChange={(e: any) => {
                      const selectedValue = e.target.value;
                      const selectedOption = ijpFilters.find(
                        (item: any) => item.value === selectedValue
                      );
                      if (selectedOption) {
                        filterChange(selectedOption);
                      }
                    }}
                  >
                    <option hidden>{filters}</option>
                    {ijpFilters.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </>
              )}
              {hrRole && (
                <>
                  <select
                    id="select-filters"
                    value={filterValue}
                    onChange={(e: any) => {
                      const selectedValue = e.target.value;
                      const selectedOption = ijpHRFilters.find(
                        (item: any) => item.value === selectedValue
                      );
                      if (selectedOption) {
                        filterChange(selectedOption);
                      }
                    }}
                  >
                    <option hidden>{filters}</option>
                    {ijpHRFilters.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </>
              )}
            </div>
          </div>
          <FilterValues
            filterValueArray={filterValue}
            onFilterChange={filterChange}
          />
          {info.length > 0 ? (
            info.map((jobsData: any) =>
              jobsData.recordStatus == "Approved" && employeeRole ? (
                <ReferResourceCard
                  key={jobsData._id}
                  id={jobsData._id}
                  jobStatus={jobsData?.jobStatus}
                  jobId={jobsData.jobId}
                  jobDataId={jobsData._id}
                  jobTitle={jobsData.jobTitle}
                  buttonTitle={jobsData.jobStatus === 0 ? "Closed" : "Open"}
                  modeOfJob={jobsData.modeOfWork}
                  jobType={jobsData.jobCategory}
                  postedOn={jobsData.timeSpan}
                  jobDescription={`${(jobsData?.description).slice(
                    0,
                    200
                  )}....`}
                  uploadDescription={jobsData?.uploadJobDescription}
                  location={jobsData?.location}
                  workLevel={jobsData?.workLevel}
                  majorSkills={jobsData?.majorSkills}
                  navigationLink={`/view-applications/${jobsData._id}`}
                  onClickEdit={() => handleEditReferResource(jobsData)}
                  onClickJD={(e: any) =>
                    openDescURL(e, jobsData?.uploadJobDescription)
                  }
                  pageIn={"IJP"}
                ></ReferResourceCard>
              ) : hrRole ? (
                <ReferResourceCard
                  key={jobsData._id}
                  id={jobsData._id}
                  jobStatus={jobsData?.jobStatus}
                  jobId={jobsData.jobId}
                  jobDataId={jobsData._id}
                  jobTitle={jobsData.jobTitle}
                  buttonTitle={jobsData.jobStatus === 0 ? "Closed" : "Open"}
                  modeOfJob={jobsData.modeOfWork}
                  jobType={jobsData.jobCategory}
                  postedOn={jobsData.timeSpan}
                  jobDescription={jobsData?.description}
                  uploadDescription={jobsData?.uploadJobDescription}
                  recordStatus={jobsData?.recordStatus}
                  location={jobsData?.location}
                  workLevel={jobsData?.workLevel}
                  majorSkills={jobsData?.majorSkills}
                  view_referral_list={viewApplicationList}
                  route_link={`/jobapplicationstable/${jobsData._id}`}
                  navigationLink={`/view-applications/${jobsData._id}`}
                  onClickEdit={() => handleEditReferResource(jobsData)}
                  editJobStatusApi={actions.editIJPJobStatusAction}
                  onClickJD={(e: any) =>
                    openDescURL(e, jobsData?.uploadJobDescription)
                  }
                  pageIn={"IJP"}
                ></ReferResourceCard>
              ) : (
                ""
              )
            )
          ) : (
            <p className="no-data">{noData}</p>
          )}
          {info.length > 0 ? (
            <div className="show-entries-wrapper my-3">
              <div className="ml-3">
                <ShowEntries
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalRecords={totalRecords}
                  totalEntries={totalEntries}
                />
              </div>
              <TablePagination
                totalPages={totalPages}
                currentPage={page}
                onClick={handlePageChange}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </MainCard>

      <ModalComponents
        show={showEditReferResource}
        onHide={hideEditReferResource}
        title="Edit Job IJP"
        modalBody={
          <EditPostAJobIJP
            jobsData={jobs}
            hideEditReferResource={hideEditReferResource}
            apiCallCompleted={apiCallCompleted}
          />
        }
        className="modal-lg"
      />
    </>
  );
};

export default JobPost;
