import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import FinanceFinalApprove from "../FinanceLearningDevelopment/FinanceFinalApprove";

const ApprovalRemark = (props: any) => {
  const { hideApproval, requestId, role, apiCallCompleted } = props;
  const [btnDisable, setBtnDisable] = useState(true);
  const [hrRemarks, setHrRemarks] = useState("");
  const [managerRemarks, setManagerRemarks] = useState("");
  const [financeRemarks, setFinanceRemarks] = useState("");
  const [amount, setAmount] = useState("");
  const [showFinance, setShowFinance] = useState(false);
  const handleFinanceProceed = () => {
    setShowFinance(true);
  };

  const handleClose = () => {
    setShowFinance(false);
    hideApproval();
  };

  let approvalObj: any;
  if (role === "Manager") {
    approvalObj = {
      managerRemarks: managerRemarks,
      managerApprovalStatus: "Approved",
    };
  } else if (role === "Finance") {
    approvalObj = {
      financeRemarks: financeRemarks,
      amount: amount,
      financeApprovalStatus: "Approved",
    };
  } else {
    approvalObj = {
      hrRemarks: hrRemarks,
      hrApprovalStatus: "Approved",
    };
  }

  const [approvalData, setapprovalData] = useState(approvalObj);

  const dispatch = useDispatch();
  const handleSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      actions.updateStatusRequestAction(requestId, approvalData, (res: any) => {
        if (res) {
          if (res.error) {
            showErrorToastMessage(res.error.error_description);
            apiCallCompleted(true);
          } else {
            showSuccessToastMessage("Approved Successfully!");
            apiCallCompleted(true);
          }
        }
      })
    );
    hideApproval();
  };

  const handleChangeHr = (e: any) => {
    setapprovalData({
      ...approvalData,
      hrRemarks: e.target.value.replace(/^\s+/, ""),
    });
  };

  const handleChangeManager = (e: any) => {
    setapprovalData({
      ...approvalData,
      managerRemarks: e.target.value.replace(/^\s+/, ""),
    });
  };

  const handleChangeFinance = (e: any) => {
    setapprovalData({
      ...approvalData,
      financeRemarks: e.target.value.replace(/^\s+/, ""),
      amount: amount,
    });
  };

  useEffect(() => {
    if (amount) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }, [amount]);

  return (
    <>
      {!showFinance ? (
        <div className="container">
          {role === "Finance" ? (
            <div className="row">
              <div className="col-md-12 mt-2 mb-3">
                <label className="form-label">
                  Skillmine Contribution Amount
                  <span className="required">
                    * (Amount & Remarks will be <br /> sent directly to employee
                    over mail)
                  </span>
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  maxLength={5}
                  placeholder="Enter Amount"
                  onChange={(e: any) =>
                    setAmount(e.target.value.replace(/\D/g, ""))
                  }
                  value={amount}
                />
              </div>
            </div>
          ) : null}

          <div className="row">
            <div className="col-md-12 mt-2 mb-3">
              <label className="form-label">
                {role === "Manager"
                  ? "Manager Remarks"
                  : role === "Finance"
                  ? "Finance Remarks"
                  : "Hr Remarks"}
              </label>
              <textarea
                id="exampleFormControlTextarea1"
                className="form-control"
                style={{
                  backgroundColor: "#FFF9F4",
                  width: "24rem",
                  height: "10rem",
                }}
                value={approvalData.reason}
                onChange={
                  role === "Manager"
                    ? handleChangeManager
                    : role === "Finance"
                    ? handleChangeFinance
                    : handleChangeHr
                }
                placeholder="Enter Remarks"
              />
            </div>
          </div>
          {(role === "Hr" || role === "Manager") && (
            <div className="modal-btn-wrapper mt-5 mx-auto">
              <div>
                <button
                  className="btn cancel-btn buttonsize"
                  onClick={hideApproval}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="btn add-submit-button buttonsize"
                  onClick={handleSubmit}
                >
                  Accept
                </button>
              </div>
            </div>
          )}

          {role === "Finance" && (
            <div className="modal-btn-wrapper mt-5 mx-auto">
              <div>
                <button
                  className="btn cancel-btn buttonsize"
                  onClick={hideApproval}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  onClick={handleFinanceProceed}
                  className={
                    !btnDisable
                      ? "btn add-submit-button buttonsize"
                      : "btn add-submit-button btn-disabled buttonsize"
                  }
                >
                  Proceed
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <ModalComponents
          show={showFinance}
          onHide={handleClose}
          modalBody={
            <>
              <FinanceFinalApprove
                requestId={requestId}
                hideApproval={handleClose}
                financeApprovalObj={approvalData}
                amount={amount}
                apiCallCompleted={apiCallCompleted}
              />
            </>
          }
          className="modal-lg"
        />
      )}
    </>
  );
};

export default ApprovalRemark;
