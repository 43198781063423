import React, { useState, useMemo, useEffect } from "react";
import MainCard from "../../../component/MainCard/MainCard";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import CoachingReportsCount from "./CoachingCounts";
import CoachingReportTable from "./CoachingReportsTable";
import { useNavigate } from "react-router";
import { Strings } from "../../../constant/Strings";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";


const CoachingReport = () => {
  const {unAuthorizeUser} = hanldeUnauthorizedUser();
  const { coaching_report } = Strings;
  const dispatch = useDispatch();
  unAuthorizeUser();
  useEffect(() => {
    dispatch(actions.getAllCoachReportCountAction());
  }, []);

  const getCoachReportCount = useSelector(
    (state: any) => state.employeeReducer.getAllCoachReportCountDetails
  );

  return (
    <>
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{coaching_report}</h2>
          </div>
        </div>
        <CoachingReportsCount countofstatus={getCoachReportCount?.data} />
        <CoachingReportTable />
      </MainCard>
      <ToastContainer />
    </>
  );
};

export default CoachingReport;
