import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import * as actions from "./../../../Redux/Actions";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "./Skills.scss";
import { Strings, skillTableHeader } from "../../../constant/Strings";
import Button from "../../../component/Buttons/Button";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import { icons } from "../../../assets";
import AddSkills from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/Skills/Addskills";
import EditSkills from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/Skills/EditSkills";
import DeleteSkills from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/Skills/DeleteSkills";

const Skills = (props: any) => {
  const { status } = props;
  const [info, setInfo] = useState([]);

  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const selectedId = localStorage.getItem("selectedId");

  const dispatch = useDispatch();
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();

  const [showAddSkill, setShowAddSkill] = useState(false);
  const handleAddSkill = () => setShowAddSkill(true);
  const hideAddSkill = () => setShowAddSkill(false);

  const [showEditSkill, setShowEditSkill] = useState(false);
  const handleEditSkill = (
    id: string,
    skillName: any,
    description: any,
    proficiencylevel: any
  ) => {
    setSkills({
      id: id,
      skillName: skillName,
      description: description,
      proficiencylevel: proficiencylevel,
    });
    setShowEditSkill(true);
  };
  const [skills, setSkills] = useState({
    id: "",
    skillName: "",
    description: "",
    proficiencylevel: "",
  });
  const [deleteId, setDeleteId] = useState("");
  const hideEditSkill = () => setShowEditSkill(false);
  const [isModalDone, setIsModalDone] = useState(false);
  const setModalFinished = () => {
    setIsModalDone(true);
  };

  const [showDeleteSkill, setShowDeleteSkill] = useState(false);

  const handleDeleteSkill = (id: any) => {
    setDeleteId(id);
    setShowDeleteSkill(true);
  };
  const hideDeleteSkill = () => {
    setShowDeleteSkill(false);
  };

  const addEmployeeSkills = useSelector(
    (state: any) => state.employeeReducer.addEmployeeSkills
  );

  const deleteEmployeeSkills = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeeSkills
  );

  const editEmployeeSkills = useSelector(
    (state: any) => state.employeeReducer.editEmployeeSkills
  );

  useEffect(() => {
    showLoadingIndicator();
    dispatch(actions.getEmployeeSkillsAction());
    setTimeout(() => {
      hideLoadingIndicator();
    }, 1000);
  }, [addEmployeeSkills, deleteEmployeeSkills, editEmployeeSkills, selectedId]);

  const employeeSkills = useSelector(
    (state: any) => state.employeeReducer.getEmployeeSkills
  );

  useEffect(() => {
    if (employeeSkills) {
      if (employeeSkills.data) {
        setInfo(employeeSkills.data);
      }
    }
  }, [employeeSkills, info]);

  const { skills_heading } = Strings;
  const { edit_blue_icon, delete_icon } = icons;

  return (
    <>
      {loadingComponent()}
      <div className="skills-container">
        <div className="heading-button-wrapper">
          <div>
            <h5>{skills_heading}</h5>
          </div>
          {(status !== "Active" && status !== "Inactive") ||
          (role &&
            role.includes("hr") &&
            status === "Active" &&
            selectedId != loginId) ? (
            <div className="btn-wrapper">
              <Button
                title="+ Add"
                onClick={handleAddSkill}
                className="add-employee-btn add-employee-details-skills"
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="card-div-container">
          <Table className="table-conatiner" cellSpacing={2} cellPadding={20}>
            <thead className="table-head">
              {skillTableHeader.map((item, key) => {
                return (
                  <>
                    <td className="table-td" key={key}>
                      {item}
                    </td>
                  </>
                );
              })}
              {(status !== "Active" && status !== "Inactive") ||
              (role &&
                role.includes("hr") &&
                status === "Active" &&
                selectedId != loginId) ? (
                <td className="table-td">Action</td>
              ) : (
                <></>
              )}
            </thead>
            <tbody className="table-body">
              {info?.map((item: any, key) => {
                return (
                  <tr key={key}>
                    <td className="table-td">{item.skillName}</td>
                    <td className="table-td">{item.description}</td>
                    <td className="table-td">{item.proficiencylevel}</td>
                    {(status !== "Active" && status !== "Inactive") ||
                    (role &&
                      role.includes("hr") &&
                      status === "Active" &&
                      selectedId != loginId) ? (
                      <>
                        <td className="table-td">
                          <div className="text-center">
                            <img
                              src={edit_blue_icon}
                              alt="edit_icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              onClick={() =>
                                handleEditSkill(
                                  item.id,
                                  item.skillName,
                                  item.description,
                                  item.proficiencylevel
                                )
                              }
                              className="edit-icon"
                            />
                            <img
                              src={delete_icon}
                              alt="delete-icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Delete"
                              onClick={() => {
                                handleDeleteSkill(item.id);
                              }}
                              className="edit-icon"
                            />
                          </div>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>

      <ModalComponents
        show={showAddSkill}
        onHide={hideAddSkill}
        title="Add Skill"
        modalBody={
          <AddSkills
            showAddSkill={handleAddSkill}
            hideAddSkill={hideAddSkill}
            setModalFinished={setModalFinished}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showEditSkill}
        onHide={hideEditSkill}
        title="Edit Skill"
        modalBody={
          <EditSkills
            id={skills.id}
            skillName={skills.skillName}
            descrption={skills.description}
            proficiencyLevel={skills.proficiencylevel}
            showEditSkill={handleEditSkill}
            hideEditSkill={hideEditSkill}
            setModalFinished={setModalFinished}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showDeleteSkill}
        onHide={hideDeleteSkill}
        title="Delete Skill"
        modalBody={
          <DeleteSkills
            id={deleteId}
            showDeleteSkill={handleDeleteSkill}
            hideDeleteSkill={hideDeleteSkill}
            setModalFinished={setModalFinished}
          />
        }
        className="modal-md"
      />
    </>
  );
};

export default Skills;
