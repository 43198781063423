import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions";
import useLoadingIndicator from "../../../component/Loader/Loader";
import MainCard from "../../../component/MainCard/MainCard";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import TransactionHistoryTable from "./TransactionHistoryTable";

export default function TransactionHistory(props: any) {
  const { loadingComponent } = useLoadingIndicator();

  const [info, setInfo] = useState<any>([]);
  const [page, setPage] = useState<Number>(1);
  const [searchKey, setSearchKey] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();

  const dispatch = useDispatch();

  useEffect(() => {
    const login: any = localStorage.getItem("login");
    const employeeId = JSON.parse(login).id;
    dispatch(
      actions.getTransactionHistory(page, pageSize, employeeId, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              setTotalPages(res.meta_data.totalPageCount);
              setTotalEntries(res.meta_data.totalEntries);
              setInfo(res.data);
            }
          }
        }
      })
    );
  }, [page, pageSize]);

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (value: any) => {
    if (value) {
      if (filterValue.includes(value)) {
        setFilterValue(filterValue.filter((obj: any) => obj != value));
      } else {
        setFilterValue((prevValue: any) => [...prevValue, value]);
      }
    }
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <TransactionHistoryTable
          totalPages={totalPages}
          onPage={page}
          handlePageChange={handlePageChange}
          searchKey={searchKey}
          searchValue={searchValue}
          handleSearch={handleSearch}
          setSearchKey={setSearchKey}
          filterValue={filterValue}
          filterChange={filterChange}
          pagePerSize={pageSize}
          setPagePerSize={setPageSize}
          totalEntries={totalEntries}
        />
      </MainCard>
    </>
  );
}
