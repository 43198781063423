import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "./TablePagination.scss";

const TablePagination = (props: any) => {
  const { totalPages, currentPage, onClick } = props;

  const maxButtonsToShow = 5;
  const halfMaxButtons = Math.floor(maxButtonsToShow / 2);

  // Calculate the range of page numbers to display dynamically
  let startPage = Math.max(currentPage - halfMaxButtons, 1);
  const maxStartPage = Math.max(totalPages - maxButtonsToShow + 1, 1);
  startPage = Math.min(startPage, maxStartPage);

  const endPage = Math.min(startPage + maxButtonsToShow - 1, totalPages);

  // Generate an array of page numbers to display
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  return (
    <div className="pagination-wrapper">
      <Pagination>
        <PaginationItem>
          <PaginationLink
            onClick={() => onClick(1)}
            first
            disabled={currentPage === 1}
            className="pagination-right-arrow"
          />
        </PaginationItem>

        {currentPage > 1 && (
          <PaginationItem>
            <PaginationLink
              onClick={() => onClick(currentPage - 1)}
              previous
              className="pagination-right-arrow"
            />
          </PaginationItem>
        )}

        {pageNumbers.map((page) => (
          <PaginationItem key={page}>
            <PaginationLink
              active={page === currentPage ? "true" : "false"}
              onClick={() => onClick(page)}
              className={
                page === currentPage
                  ? "pagination-btn-active"
                  : "pagination-btn"
              }
            >
              {page}
            </PaginationLink>
          </PaginationItem>
        ))}

        {currentPage < totalPages && (
          <PaginationItem>
            <PaginationLink
              onClick={() => onClick(currentPage + 1)}
              next
              className="pagination-right-arrow"
            />
          </PaginationItem>
        )}

        <PaginationItem>
          <PaginationLink
            onClick={() => onClick(totalPages)}
            last
            disabled={currentPage === totalPages}
            className="pagination-right-arrow"
          />
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default TablePagination;
