import { useEffect, useState } from "react";
import MainCard from "../../../component/MainCard/MainCard";
import "./Question.scss";
import * as actions from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { Strings } from "../../../constant/Strings";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { useParams } from "react-router-dom";

const Questions = () => {
  const { id } = useParams();
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [btnDisable, setBtnDisable] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [inputValues, setInputValues] = useState<any>({});
  const [alreadyExist, setAlreadyExist] = useState(false);
  const { submitSuccess, cancel, submit, questionsHeading } = Strings;
  const dispatch = useDispatch();

  useEffect(() => {
    showLoadingIndicator();
    dispatch(
      actions.getQuestionsListAction((res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
        } else {
          if (res?.code === 200) {
            setQuestions(res.data);
          }
        }
      })
    );
    const payloadObj = { employeeRef: loginId, competencyRef: id };
    dispatch(
      actions.checkCompenetencyAction(payloadObj, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
        } else {
          if (res?.code === 200) {
            setAlreadyExist(res.data);
          }
        }
      })
    );
    hideLoadingIndicator();
  }, []);

  useEffect(() => {
    const initialFormData: any = {};
    questions?.forEach((data: any) => {
      data?.questionData?.forEach((ele: any) => {
        initialFormData[`${ele._id}_${ele?.questionTypeRef}`] = "";
      });
    });
    setInputValues(initialFormData);
  }, [questions, alreadyExist]);

  useEffect(() => {
    const isValid = Object.values(inputValues).every(
      (value: any) => value.trim() !== ""
    );
    if (isValid) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [inputValues]);

  const handleCompetencySubmit = (e: any) => {
    e.preventDefault();
    handleDispatch();
  };

  const handleDispatch = () => {
    showLoadingIndicator();
    const payloadObj: any = {
      assessment: [],
      employeeRef: loginId,
      competencyRef: id,
    };
    for (const [key, value] of Object.entries(inputValues)) {
      const [questionRef, questionTypeRef] = key.split("_");
      const transformedObj: any = {
        questionRef,
        answer: value,
        questionTypeRef,
      };
      payloadObj.assessment.push(transformedObj);
    }

    dispatch(
      actions.addSelfAssessmentAction(payloadObj, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          hideLoadingIndicator();
        } else {
          if (res?.code === 200) {
            showSuccessToastMessage(submitSuccess);
            hideLoadingIndicator();
          }
        }
      })
    );
    setAlreadyExist(true);
  };

  const handleCompetencyCancel = () => {
    const initialFormData: any = {};
    questions?.forEach((data: any) => {
      data?.questionData?.forEach((ele: any) => {
        initialFormData[`${ele._id}_${ele?.questionTypeRef}`] = "";
      });
    });
    setInputValues(initialFormData);
  };

  const handleChange = (e: any, id: any, ref: any) => {
    const { value } = e.target;
    setInputValues((prevData: any) => ({
      ...prevData,
      [`${id}_${ref}`]: value,
    }));
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{questionsHeading}</h2>
          </div>
        </div>

        <div className="card mt-2">
          {alreadyExist ? (
            <h5 className="text-center">
              Response recorded for this competency !!
            </h5>
          ) : (
            <form className="row g-3 m-2">
              {questions?.length > 0 &&
                questions?.map((data: any, qIndex: any) => (
                  <div key={qIndex} className="row mt-2">
                    <p className="questions-heading">{data?.questionType}</p>
                    {data?.questionData?.length > 0 &&
                      data?.questionData?.map((ele: any, dIndex: any) => (
                        <div key={dIndex} className="col-md-4">
                          <label className="form-label text-wrap">
                            {ele?.question}
                            <span className="required">*</span>
                          </label>

                          {ele?.inputType == "text" ? (
                            <textarea
                              className="form-control text-height"
                              placeholder="Enter Answer."
                              defaultValue={
                                inputValues[`${ele._id}_${ele?.questionRef}`] ||
                                ""
                              }
                              onChange={(e) =>
                                handleChange(e, ele?._id, ele?.questionTypeRef)
                              }
                            />
                          ) : (
                            <input
                              type="number"
                              min={0}
                              max={5}
                              placeholder="Enter Rating"
                              defaultValue={
                                inputValues[`${ele._id}_${ele?.questionRef}`] ||
                                ""
                              }
                              className="form-control"
                              onChange={(e) =>
                                handleChange(e, ele._id, ele?.questionTypeRef)
                              }
                            />
                          )}
                        </div>
                      ))}
                  </div>
                ))}

              <div className="modal-btn-wrapper mt-5 mx-auto">
                <div>
                  <button
                    className="btn cancel-btn buttonsize"
                    onClick={handleCompetencyCancel}
                  >
                    {cancel}
                  </button>
                </div>
                <div>
                  <button
                    className={
                      btnDisable
                        ? "btn add-submit-button buttonsize"
                        : "btn add-submit-button btn-disabled buttonsize"
                    }
                    onClick={handleCompetencySubmit}
                  >
                    {submit}
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </MainCard>
    </>
  );
};

export default Questions;
