import React, { useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import * as actions from "../../../Redux/Actions/index";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainCard from "../../../component/MainCard/MainCard";
import SearchBar from "../../../component/SearchBar/SearchBar";
import useLoadingIndicator from "../../../component/Loader/Loader";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import TablePagination from "../../../component/TablePagination/TablePagination";
import { ToastContainer } from "react-toastify";
import {
  Strings,
  eligibleReferralPayoutFilter,
} from "../../../constant/Strings";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import StatusChangeModal from "./statusChangeModal";
import { timeDateFormat } from "../../../component/FormatDate";

const EligibleReferredListTable = () => {
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const [changeStatus, setChangeStatus] = useState({
    id: "",
    remarks: "",
    status: "",
  });

  const { eligibleReferralList, noData } = Strings;
  const [searchValue, setSearchValue] = useState<any>("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEntries, setTotalEntries] = useState<any>();
  const dispatch = useDispatch();
  const [data, setdata] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const handleChangeStatus = (id: any, status: any, remarks: any) => {
    setShowChangeStatus(true);
    setChangeStatus({ id: id, status: status, remarks: remarks });
  };
  const hideChangeStatus = () => {
    setShowChangeStatus(false);
  };
  const editStatusChange = useSelector(
    (state: any) => state.employeeReducer.putEligibleReferralPayouts
  );
  const role = localStorage.getItem("role");
  const unAuthorizeUser = () => {
    if (
      !(
        role &&
        (role.includes("skill-link-payout-master") ||
          role.includes("skill-link-payout-checker"))
      )
    ) {
      navigate("/profile");
    }
  };
  const skillLinkChecker =
    role &&
    (role.includes("skill-link-payout-master") ||
      role.includes("skill-link-payout-checker"));
  const navigate = useNavigate();
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    unAuthorizeUser();
    if (condition) {
      dispatch(
        actions.getEligibleReferralPayoutsAction(
          page,
          pageSize,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else {
              if (res.code === 200) {
                setdata(res.data.result);
                setTotalRecords(res.data.result.length);
                setTotalPages(res.data.totalPageCount);
                setTotalEntries(res.data.totalCount);
                setOnclickSearch(false);
              }
            }
          }
        )
      );
    }
  }, [editStatusChange, page, pageSize, filterValue, searchValue, condition]);

  const COLUMNS: any = [
    {
      Header: "Employee Name",
      accessor: "candidateName",
    },
    {
      Header: "E-Code",
      accessor: "employeeId",
    },
    {
      Header: "Referred Job ID",
      accessor: "jobId",
    },
    {
      Header: "Referred Job Title",
      accessor: "jobTitle",
    },
    {
      Header: "Candidate Name",
      accessor: "referredName",
    },
    {
      Header: "Candidate Email",
      accessor: "emailId",
    },
    {
      Header: "Accepted Date",
      accessor: "mobileNumber",
    },
    {
      Header: "Referral amount",
      accessor: "referralAmount",
    },
    {
      Header: "Remarks",
      accessor: "remarks",
    },
  ];

  const checkerCOLUMNS: any = [
    {
      Header: "Employee Name",
      accessor: "candidateName",
    },
    {
      Header: "E-Code",
      accessor: "employeeId",
    },
    {
      Header: "Referred Job ID",
      accessor: "jobId",
    },
    {
      Header: "Referred Job Title",
      accessor: "jobTitle",
    },
    {
      Header: "Candidate Name",
      accessor: "referredName",
    },
    {
      Header: "Candidate Email",
      accessor: "emailId",
    },
    {
      Header: "Accepted Date",
      accessor: "mobileNumber",
    },
    {
      Header: "Referral amount",
      accessor: "referralAmount",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Remarks",
      accessor: "remarks",
    },
  ];

  const columns = useMemo(
    () => (skillLinkChecker ? checkerCOLUMNS : COLUMNS),
    []
  );
  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };
  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="d-flex flex-column justify-content-center align-items-start progress-header">
          <div>
            <h2>{eligibleReferralList}</h2>
          </div>
        </div>
        <div className="mt-2">
          <div className="mt-3 buttonsize filter-ijp-wrapper">
            <div className="ijp-searchbar-wrapper">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
              />
            </div>
            <div className="ijp-filter-container">
              <select
                id="select-filters"
                value={filterValue}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  const selectedOption = eligibleReferralPayoutFilter.find(
                    (item: any) => item.value === selectedValue
                  );
                  if (selectedOption) {
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>Filters</option>
                {eligibleReferralPayoutFilter.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <FilterValues
            filterValueArray={filterValue}
            onFilterChange={filterChange}
          />
        </div>
        {data.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="table-margin">
                <thead className="employeedb">
                  <tr className="custom-table-head-row">
                    {columns.map((ele: any) => (
                      <React.Fragment key={ele.Header}>
                        <td>{ele.Header}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody className="employeedata">
                  {data.map((ele: any, index: any) => (
                    <>
                      <tr key={ele._id}>
                        <td>
                          {ele.Employee[0]?.candidateName +
                            " " +
                            ele.Employee[0]?.middleName +
                            " " +
                            ele.Employee[0]?.lastname}
                        </td>
                        <td>{ele.Employee[0]?.employeeId}</td>
                        <td>{ele.PostJob[0]?.jobId}</td>
                        <td>{ele?.PostJob[0]?.jobTitle}</td>
                        <td>{ele.ReferResource[0]?.fullName}</td>
                        <td>{ele.ReferResource[0]?.emailId}</td>
                        <td>
                          {ele.ReferResource[0]?.acceptedDate
                            ? timeDateFormat(ele.ReferResource[0]?.acceptedDate)
                            : ""}
                        </td>
                        <td>{ele.PostJob[0]?.referralAmount}</td>
                        {skillLinkChecker && (
                          <td className="skill-links-btns">
                            {
                              <button
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Change Status"
                                type="button"
                                disabled={ele?.status === 1}
                                className={`common-css ${
                                  ele.status === 1
                                    ? "approved-btn"
                                    : ele.status === 0
                                    ? "rejected-btn"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleChangeStatus(
                                    ele._id,
                                    ele.status,
                                    ele.remarks
                                  )
                                }
                              >
                                {ele?.status === 0
                                  ? "Not Paid"
                                  : ele?.status === 1
                                  ? "Paid"
                                  : ""}
                              </button>
                            }
                          </td>
                        )}
                        <td>{ele.remarks}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
              <div className="show-entries-wrapper">
                <ShowEntries
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalRecords={totalRecords}
                  totalEntries={totalEntries}
                />

                <TablePagination
                  totalPages={totalPages}
                  currentPage={page}
                  onClick={handlePageChange}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <p className="employeedata mt-2">{noData}</p>
          </>
        )}
        <ModalComponents
          show={showChangeStatus}
          onHide={hideChangeStatus}
          title={"Change Status"}
          modalBody={
            <StatusChangeModal
              apiCallCompleted={apiCallCompleted}
              hideChangeStatus={hideChangeStatus}
              status={changeStatus}
            />
          }
          className="modal-md"
        />
        <ToastContainer />
      </MainCard>
    </>
  );
};

export default EligibleReferredListTable;
