import { BASE_URL } from "../config/constants";
import { CLEAINT_ID } from "../config/constants";

const getBaseUrl = () => {
  return BASE_URL
};
const loginUrl = () => {
  const redirect_url = window.location.origin + "/handle/token";

  return (
    getBaseUrl() +
    `/authz-srv/authz?client_id=${CLEAINT_ID}&response_type=token&scope=openid%20profile%20user_info_all&redirect_uri=${redirect_url}&groups_info=0`
  );
};
const redirectLogin = () => {
  window.location.href = loginUrl();
  return getBaseUrl() + "/login-srv/login";
};

export { getBaseUrl, redirectLogin, loginUrl };
