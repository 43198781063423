import moment from "moment";

export const timeDateFormat = (date: any) =>{
    const actualDate = date && date?.split("T")[0]; 
    const formatedDate = actualDate? moment(actualDate)?.format("DD/MMM/YYYY"):"";
    return formatedDate
  }

export const timeDateFormatYMD = (date: any)=>{
    const actualDate = date && date?.split("T")[0]; 
    const formatedDate = actualDate? moment(actualDate)?.format("YYYY-MM-DD"):"";
    return formatedDate
}