import React from "react";

function ShowEntries(props: any) {
  const { pageSize, setPageSize, totalEntries } = props;

  const pageSizes = [10, 20, 30, 40, 50].filter((size) => size <= totalEntries);

  return (
    <>
      <div className="select-text">
        Showing{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {pageSizes.map((size, index) => (
            <option key={index} value={size}>
              {size}
            </option>
          ))}
        </select>
        <div className="d-flex">{`Entries of ${totalEntries} Entries`}</div>
      </div>
    </>
  );
}

export default ShowEntries;
