import { useEffect, useState } from "react";
import * as actions from "../../../../Redux/Actions";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import {
  Strings,
  competencyValidationStrings,
} from "../../../../constant/Strings";
import useLoadingIndicator from "../../../../component/Loader/Loader";
import Select from "react-select";

const AddorEditSubStreams = (props: any) => {
  const {
    editData,
    loginId,
    setShowAddOrEditSubStreams,
    setEditData,
    handleApiCall,
    streamName,
    id,
  } = props;
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const [btnDisable, setBtnDisable] = useState(false);

  const [SubStreamsForm, setSubStreamsForm] = useState<any>({
    streamName: streamName,
    subStreamName: editData.subStreamName ?? "",
  });

  const [errors, setErrors] = useState({
    subStreamName: "",
  });

  const { submitSuccess, cancel, submit, editSuccess } = Strings;
  const [leaderOptions, setLeaderOption] = useState([]);

  useEffect(() => {
    if (SubStreamsForm.streamName && SubStreamsForm.subStreamName) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [SubStreamsForm]);

  const dispatch = useDispatch();
  const handleClearForm = () => {
    setSubStreamsForm({
      leader: "",
    });
  };

  let valid: boolean = true;
  const validate = () => {
    if (SubStreamsForm.subStreamName == "") {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        subStreamName: "sub stream name cannot be empty",
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        subStreamName: "",
      }));
    }
  };
  const handleStreamSubmit = (e: any) => {
    e.preventDefault();
    validate();
    if (valid) {
      handleDispatch();
      setBtnDisable(true);
    }
    setBtnDisable(false);
  };
  const handleDispatch = () => {
    showLoadingIndicator();
    const payloadObj: any = {
      subStreamName: SubStreamsForm.subStreamName,
      streamRef: id,
    };
    if (editData) {
      payloadObj.updatedBy = loginId;
    } else {
      payloadObj.createdBy = loginId;
    }
    if (editData) {
      dispatch(
        actions.UpdateStreanAction(
          "sub-stream",
          editData._id,
          payloadObj,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
              hideLoadingIndicator();
            } else {
              if (res?.code === 200) {
                showSuccessToastMessage(editSuccess);
                handleClearForm();
                hideLoadingIndicator();
                setShowAddOrEditSubStreams(false);
                setEditData("");
                handleApiCall();
              }
            }
          }
        )
      );
    } else {
      dispatch(
        actions.AddStreamAction("sub-stream", payloadObj, (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
            hideLoadingIndicator();
          } else {
            if (res.code === 200) {
              showSuccessToastMessage(submitSuccess);
              handleClearForm();
              hideLoadingIndicator();
              setShowAddOrEditSubStreams(false);
              setEditData("");
              handleApiCall();
            }
          }
        })
      );
    }
  };

  useEffect(() => {
    if (loginId) {
      dispatch(
        actions.getLeaderListAction((res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
          } else {
            if (res.code === 200) {
              const options = res.data.map((item: any) => ({
                label: `${item.candidateName}  ${item.middleName}  ${item.lastname} `,
                value: item._id,
              }));
              setLeaderOption(options);
            }
          }
        })
      );
    }
  }, []);
  return (
    <>
      <div>
        <div>
          <label className="form-label">Stream</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            placeholder="Stream name"
            value={SubStreamsForm.streamName}
            onChange={(e) =>
              setSubStreamsForm({
                ...SubStreamsForm,
                streamName: e.target.value,
              })
            }
            disabled
          />
        </div>
        <div>
          <label className="form-label">Sub Stream</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            placeholder="Sub stream name"
            value={SubStreamsForm.subStreamName}
            onChange={(e) =>
              setSubStreamsForm({
                ...SubStreamsForm,
                subStreamName: e.target.value,
              })
            }
          />
          {errors.subStreamName != "" && (
            <p className="error">{errors.subStreamName}</p>
          )}
        </div>

        <div className="modal-btn-wrapper mt-4 mb-4">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={() => {
                setEditData("");
                setShowAddOrEditSubStreams(false);
              }}
            >
              {cancel}
            </button>
          </div>

          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleStreamSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddorEditSubStreams;
