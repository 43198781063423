import React from "react";
import { icons } from "../../../assets/index";
import "./CoachingReports.scss";

const CoachingReportsCount = (props: any) => {
  const { countofstatus } = props;
  const { total_no_sessions, total_no_trainee, no_of_coaches } = icons;

  const cardData = [
    {
      icon: no_of_coaches,
      title: "Number of Coaches",
      data: countofstatus?.totalCoach,
    },
    {
      icon: total_no_sessions,
      title: "Total Number of Trainees",
      data: countofstatus?.activeTrainees,
    },
    {
      icon: total_no_trainee,
      title: "Total Number of Active Sessions",
      data: countofstatus?.activeSessions,
    },
  ];

  return (
    <div className="total-coaching-reports-card-container">
      {cardData.map((item, index) => {
        return (
          <div key={index} className="card-wrapper">
            <div className="d-flex">
              <img src={item.icon} alt="icon" />
            </div>
            <div className="title-data-wrapper">
              <div className="data-title">{item.title} </div>
              <div className="data">{item.data}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CoachingReportsCount;
