import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { BsDownload, BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { icons } from "../../../../../assets";
import Button from "../../../../../component/Buttons/Button";
import ModalComponents from "../../../../../component/ModalComponent/ModalComponent";
import AddQualification from "./AddQualification";
import DeleteQualification from "./DeleteQualification";
import EditQualification from "./EditQualification";
import * as actions from "./../../../../../Redux/Actions";
import { TbBrandChrome } from "react-icons/tb";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { ToastContainer } from "react-toastify";
import next from "../../../../../assets/forward.png";
import back from "../../../../../assets/backward.png";
import useLoadingIndicator from "../../../../../component/Loader/Loader";
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "./../../../../../component/Toasts/Toasts";

function Qualifications() {
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const selectedId = localStorage.getItem("selectedId");
  const status = localStorage.getItem("employeeStatus");

  const dispatch = useDispatch();
  const [employeeDetails, setEmployeeDetails] = useState<any>({
    status: "",
  });
  useEffect(() => {
    dispatch(
      actions.getemployeeDetailsAction(loginId, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              setProfile(res.data[0]);
            }
          }
        }
      })
    );
  }, [loginId]);

  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();

  const [info, setInfo] = useState([]);
  const [qualifications, setQualifications] = useState({
    id: "",
    qualification: "",
    branch: "",
    institute: "",
    board: "",
    year: "",
    marks: "",
    qualificationImage: "",
  });
  let navigate = useNavigate();

  const [deleteId, setDeleteId] = useState("");

  const [showAddQualifications, setShowAddQualifications] = useState(false);

  const handleAddQualifications = () => {
    setShowAddQualifications(true);
  };
  const hideAddQualifications = () => {
    setShowAddQualifications(false);
  };

  const [showEditQualifications, setShowEditQualifications] = useState(false);
  const handleEditQualifications = (
    id: any,
    qualification: any,
    branch: string,
    institute: string,
    board: string,
    year: string,
    marks: any,
    qualificationImage: any
  ) => {
    setQualifications({
      id: id,
      qualification: qualification,
      branch: branch,
      institute: institute,
      board: board,
      year: year,
      marks: marks,
      qualificationImage: qualificationImage,
    });
    setShowEditQualifications(true);
  };
  const hideEditQualifications = () => {
    setShowEditQualifications(false);
  };

  const [showDeleteQualifications, setShowDeleteQualifications] =
    useState(false);
  const handleDeleteQualifications = (id: any) => {
    setDeleteId(id);
    setShowDeleteQualifications(true);
  };
  const hideDeleteQualifications = () => {
    setShowDeleteQualifications(false);
  };
  const addEmployeeQualification = useSelector(
    (state: any) => state.employeeReducer.addEmployeeQualification
  );

  const deleteEmployeeQualification = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeeQualification
  );

  const editEmployeeQualification = useSelector(
    (state: any) => state.employeeReducer.editEmployeeQualification
  );

  const dispatchQualifications = useDispatch();
  useEffect(() => {
    showLoadingIndicator();
    dispatchQualifications(actions.getEmployeeQualificationAction());
    setTimeout(() => {
      hideLoadingIndicator();
    }, 1000);
  }, [
    addEmployeeQualification,
    deleteEmployeeQualification,
    editEmployeeQualification,
  ]);

  const employeeQualification = useSelector(
    (state: any) => state.employeeReducer.getEmployeeQualification
  );

  useEffect(() => {
    if (employeeQualification) {
      if (employeeQualification.data) {
        setInfo(employeeQualification.data);
      }
    }
  }, [employeeQualification, info]);

  const setProfile = (employeeData: any) => {
    setEmployeeDetails({
      status: employeeData && employeeData.status ? employeeData.status : "",
    });
  };

  return (
    <>
      {loadingComponent()}

      <div className="right__container">
        <div className="title">
          <h6>Educational Qualifications</h6>
          {(status !== "Active" && status !== "Inactive") ||
          (role === "hr" && status === "Active" && selectedId != loginId) ? (
            <>
              <div className="btn-wrapper">
                <Button
                  icon={icons.add_icon}
                  title="Add"
                  onClick={handleAddQualifications}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="col">
          <Table hover striped>
            <thead className="employeedb">
              <tr className="sub-table-header table-head table-head-row">
                <td>Qualification</td>
                <td>Branch/Stream</td>
                <td
                  data-toggle="tooltip"
                  data-placement="top"
                  title="School/College"
                >
                  Institute
                </td>
                <td>Board/University</td>
                <td>Year Of Passing</td>
                <td>Percentage/CGPA</td>
                {(status !== "Active" && status !== "Inactive") ||
                (role === "hr" &&
                  status === "Active" &&
                  selectedId != loginId) ? (
                  <>
                    <td>Action</td>
                  </>
                ) : (
                  <>{""}</>
                )}
              </tr>
            </thead>
            <tbody className="employeedata">
              {info.length > 0 &&
                info.map((ele: any) => (
                  <tr key={ele.id} className="buttonsize">
                    <td>{ele.qualification} </td>

                    {ele.branch.length > 14 ? (
                      <td>{`${ele.branch?.slice(0, 14)}...`}</td>
                    ) : (
                      <td>{ele.branch}</td>
                    )}

                    {ele.institute.length > 14 ? (
                      <td>{`${ele.institute?.slice(0, 14)}...`}</td>
                    ) : (
                      <td>{ele.institute}</td>
                    )}
                    <td>{`${ele.board?.slice(0, 14)}...`}</td>
                    <td>{ele.year}</td>
                    <td>{ele.marks}</td>

                    {(status !== "Active" && status !== "Inactive") ||
                    (role === "hr" &&
                      status === "Active" &&
                      selectedId != loginId) ? (
                      <>
                        <td>
                          <div className="action-btn">
                            <img
                              src={icons.edit_icon}
                              alt="edit_icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              onClick={() =>
                                handleEditQualifications(
                                  ele.id,
                                  ele.qualification,
                                  ele.branch,
                                  ele.institute,
                                  ele.board,
                                  ele.year,
                                  ele.marks,
                                  ele.qualificationImage
                                )
                              }
                              className="edit-icon"
                            />
                            <img
                              src={icons.delete_icon}
                              alt="delete-icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Delete"
                              onClick={() => handleDeleteQualifications(ele.id)}
                              className="edit-icon"
                            />
                          </div>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
          <div>
            <ModalComponents
              show={showAddQualifications}
              onHide={hideAddQualifications}
              title="Add Qualification"
              modalBody={
                <AddQualification
                  showAddQualifications={handleAddQualifications}
                  hideAddQualifications={hideAddQualifications}
                />
              }
              className="modal-lg"
            />

            <ModalComponents
              show={showEditQualifications}
              onHide={hideEditQualifications}
              title="Edit Qualification"
              modalBody={
                <EditQualification
                  id={qualifications.id}
                  qualification={qualifications.qualification}
                  branch={qualifications.branch}
                  institute={qualifications.institute}
                  board={qualifications.board}
                  year={qualifications.year}
                  marks={qualifications.marks}
                  qualificationImage={qualifications?.qualificationImage}
                  hideEditQualifications={hideEditQualifications}
                />
              }
              className="modal-lg"
            />

            <ModalComponents
              show={showDeleteQualifications}
              onHide={hideDeleteQualifications}
              title="Delete Qualification"
              modalBody={
                <DeleteQualification
                  id={deleteId}
                  hideDeleteQualifications={hideDeleteQualifications}
                />
              }
              className="modal-sm"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <button
              className="btn btn-primary btn-next-pre"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Previous"
              onClick={() => navigate("/")}
            >
              <img src={back} alt="icon" />
              Back
            </button>
          </div>
          <div className="col-8">
            <button
              className="btn btn-primary btn-next-pre"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Save & Next"
              onClick={() => navigate("/dbsidebar/certifications")}
            >
              Next
              <img src={next} alt="icon" />
            </button>
          </div>
          <div className="col">
            <button
              className="btn btn-outline-primary btn-skip"
              onClick={() => navigate("/dbsidebar/certifications")}
            >
              Skip
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Qualifications;
