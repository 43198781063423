import React from "react";
import "./Tabs.scss";

const LoginTabs = (props: any) => {
  const { title, onClick, active } = props;

  return (
    <>
      <button
        onClick={onClick}
        className={
          active ? "tab-wrapper-active tab-wrapper mx-2" : "tab-wrapper mx-2"
        }
      >
        {title}
        {active ? <hr className="hr-active" /> : ""}
      </button>
    </>
  );
};

export default LoginTabs;
