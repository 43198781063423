import React, { useEffect, useMemo, useState } from "react";
import MainCard from "../../../component/MainCard/MainCard";
import SearchBar from "../../../component/SearchBar/SearchBar";
import {
  Strings,
  employeeReferredHistoryFilters,
} from "../../../constant/Strings";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import { TiArrowUnsorted } from "react-icons/ti";
import TablePagination from "../../../component/TablePagination/TablePagination";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import { useDispatch } from "react-redux";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";

function EmployeeReferralHistory() {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const [pageSize, setPageSize] = useState(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  const { no_data_found, filters, employee_referral_history } = Strings;
  const [totalRecords, setTotalRecords] = useState(0);
  const [info, setInfo] = useState([]);
  const dispatch = useDispatch();
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    unAuthorizeUser();
    if (condition) {
      dispatch(
        actions.allEmployeeReferralHistoryAction(
          page,
          pageSize,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res.error.error_description);
            } else {
              if (res.code === 200) {
                setTotalPages(res.data.totalPageCount);
                setTotalEntries(res.data.totalCount);
                setInfo(res.data.result);
                setTotalRecords(res.data.result?.length);
                setOnclickSearch(false);
              }
            }
          }
        )
      );
    }
  }, [page, searchValue, filterValue, pageSize, condition]);
  const navigate = useNavigate();
  const handleEmployeeDetails = (employeeRef: any, name: any) => {
    dispatch(
      actions.specificEmployeeReferredListAction(
        employeeRef,
        page,
        10,
        "",
        "",
        (res: any) => {}
      )
    );
    navigate(`/employee-referred-list/${employeeRef}`);
    localStorage.setItem("referree_name", name);
  };
  const Historycolumns: any = [
    {
      Header: "E-Code",
      accessor: "employeeId",
      Cell: (Action: any) => {
        const employeeId = Action.row.original.EmployeeData[0]?.employeeId;
        return employeeId;
      },
    },
    {
      Header: "Employee Name",
      accessor: "employeeName",
      Cell: (Action: any) => {
        const name = Action.row.original.EmployeeData[0]
          ? Action.row.original.EmployeeData[0]?.candidateName +
            " " +
            Action.row.original.EmployeeData[0]?.middleName +
            " " +
            Action.row.original.EmployeeData[0]?.lastname
          : "";
        const employeeRef = Action.row.original.EmployeeData[0]?._id;
        return (
          <div
            className="hyperlink"
            onClick={() => handleEmployeeDetails(employeeRef, name)}
          >
            {name}
          </div>
        );
      },
    },
    {
      Header: "Total Referrals",
      accessor: "totalReferrals",
    },
    {
      Header: "Accepted Candidates(%)",
      accessor: "PercentageAccepted",
    },
    {
      Header: "Rejected Candidates(%)",
      accessor: "PercentageRejected",
    },
    {
      Header: "Candidates in KIV(%)",
      accessor: "PercentageKIV",
    },
  ];
  const data = info;
  const columns = useMemo(() => Historycolumns, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy
    );

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  const handleSerach = (value: any) => {
    setPage(1);
    setSearchValue(value);
  };
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };
  return (
    <MainCard>
      <div className="heading-button-wrapper">
        <div>
          <h2>{employee_referral_history}</h2>
        </div>
      </div>
      <div className="mt-2">
        <div className="mt-3 buttonsize filter-wrapper">
          <div className="searchbar-wrapper">
            <SearchBar
              filter={searchValue}
              setFilter={handleSerach}
              onSearchClicked={onSearchClicked}
            />
          </div>
          <div className="filter-container">
            <select
              id="select-filters"
              value={filterValue}
              onChange={(event) => {
                const selectedValue = event.target.value;
                const selectedOption = employeeReferredHistoryFilters.find(
                  (item: any) => item.value === selectedValue
                );
                if (selectedOption) {
                  filterChange(selectedOption);
                }
              }}
            >
              <option hidden>{filters}</option>
              {employeeReferredHistoryFilters.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
        />
        {data.length > 0 ? (
          <>
            <div className="table-responsive">
              <div className="table">
                <table
                  className="table-main table table-sm mt-3"
                  {...getTableProps()}
                >
                  <thead className="table-head">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className="table-head-row"
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column: any) => (
                          <th
                            className="table-head-row"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            scope="col"
                          >
                            {column.render("Header")}

                            <span>
                              {column.id !== "action" &&
                              column.id !== "id" &&
                              column.id !== "remarks" ? (
                                column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TiArrowUnsorted />
                                  ) : (
                                    <TiArrowUnsorted />
                                  )
                                ) : (
                                  <TiArrowUnsorted />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="table-body" {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr className="table-body-row" {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />
              <TablePagination
                totalPages={totalPages}
                currentPage={page}
                onClick={handlePageChange}
              />
            </div>
          </>
        ) : (
          <p className="no-data">{no_data_found}</p>
        )}
      </div>
    </MainCard>
  );
}

export default EmployeeReferralHistory;
