import React, { useState } from "react";
import Card from "../../../component/Card/Card";
import "./FinancialInfo.scss";
import { Strings } from "../../../constant/Strings";
import EditButton from "../../../component/Buttons/EditButton/EditButton";
import { icons } from "../../../assets";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import EditInsuranceInfo from "./EditInsuranceDetails/EditInsuranceDetails";
import EditPFDetailsInfo from "./EditPFDetails/EditPFDetails";
import useLoadingIndicator from "../../../component/Loader/Loader";
import EditEmployeeBankDetails from "./EditBankDetails/EditBankDetails";

const FinancialInfo = (props: any) => {
  const {
    pfNumber,
    uanNumber,
    bankName,
    accountNumber,
    accountHolderName,
    ifscCode,
    insuranceNumber,
    eId,
    cancelledCheque,
    status,
  } = props;
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const [showEditFinancialInfo, setShowEditFinancialInfo] = useState(false);
  const [showEditInsuranceInfo, setShowEditInsuranceInfo] = useState(false);
  const [showEditPFDeatilsInfo, setShowEditPFDeatilsInfo] = useState(false);

  const employeeDetails: any = {
    bankName: bankName,
    ifscCode: ifscCode,
    accountNumber: accountNumber,
    accountHolderName: accountHolderName,
    updatedBy: eId,
    eId: eId,
    cancelledCheque: cancelledCheque,
  };

  const {
    financial_information_heading,
    provident_fund_details_heading,
    banking_information_heading,
    insurance_details_heading,
  } = Strings;

  const { edit_orange_icon } = icons;

  const handleEditFinancialInfo = () => {
    setShowEditFinancialInfo(!showEditFinancialInfo);
  };

  const handleEditInsuranceInfo = () => {
    setShowEditInsuranceInfo(!showEditInsuranceInfo);
  };

  const handleEditPfDetails = () => {
    setShowEditPFDeatilsInfo(!showEditPFDeatilsInfo);
  };

  const editShowCondition =
    (status !== "Active" && status !== "Inactive") ||
    (role && role.includes("hr") && status === "Active" && eId != loginId);

  return (
    <>
      {loadingComponent()}
      <div className="financial-container">
        <h5>{financial_information_heading}</h5>
        <div className="card-div-container">
          <Card>
            <div className="edit-icon-wrapper">
              <h5>{provident_fund_details_heading}</h5>
              {editShowCondition && (
                <EditButton
                  title="Edit"
                  icon={edit_orange_icon}
                  onClick={handleEditPfDetails}
                />
              )}
            </div>
            <div className="card-content-container">
              <div className="card-content">
                <div className="card-content-title">PF Number</div>
                <div className="card-content-data">{pfNumber}</div>
              </div>
              <div className="card-content">
                <div className="card-content-title">UAN Number</div>
                <div className="card-content-data">{uanNumber}</div>
              </div>
            </div>
          </Card>
        </div>
        <div className="bank-card-div-container">
          <Card>
            <div className="edit-icon-wrapper">
              <h5>{banking_information_heading}</h5>
              {editShowCondition && (
                <EditButton
                  title="Edit"
                  icon={edit_orange_icon}
                  onClick={handleEditFinancialInfo}
                />
              )}
            </div>
            <div className="bank-card-content-container">
              <div className="card-content">
                <div className="card-content-title">Bank Name</div>
                <div className="card-content-data">{bankName}</div>
              </div>
              <div className="card-content">
                <div className="card-content-title">Account Number</div>
                <div className="card-content-data">{accountNumber}</div>
              </div>

              <div className="card-content">
                <div className="card-content-title">Account Holder Name</div>
                <div className="card-content-data">{accountHolderName}</div>
              </div>
              <div className="card-content">
                <div className="card-content-title">IFSC Code</div>
                <div className="card-content-data">{ifscCode}</div>
              </div>
            </div>
          </Card>
        </div>
        <div className="card-div-container">
          <Card>
            <div className="edit-icon-wrapper">
              <h5>{insurance_details_heading}</h5>
              {editShowCondition && (
                <EditButton
                  title="Edit"
                  icon={edit_orange_icon}
                  onClick={handleEditInsuranceInfo}
                />
              )}
            </div>

            <div className="card-content-container">
              <div className="card-content">
                <div className="card-content-title">Insurance Number</div>
                <div className="card-content-data">{insuranceNumber}</div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <ModalComponents
        show={showEditFinancialInfo}
        onHide={handleEditFinancialInfo}
        title="Edit Financial Details"
        modalBody={
          <EditEmployeeBankDetails
            apiCallCompleted={apiCallCompleted}
            employeeBankDetails={employeeDetails}
            handleEmployeeBankDetails={handleEditFinancialInfo}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showEditInsuranceInfo}
        onHide={handleEditInsuranceInfo}
        title="Edit Insurance Details"
        modalBody={
          <EditInsuranceInfo
            apiCallCompleted={apiCallCompleted}
            insuranceNumber={insuranceNumber}
            handleEditInsuranceInfo={handleEditInsuranceInfo}
            eId={eId}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showEditPFDeatilsInfo}
        onHide={handleEditPfDetails}
        title="Edit PF Details"
        modalBody={
          <EditPFDetailsInfo
            apiCallCompleted={apiCallCompleted}
            pfNumber={pfNumber}
            uanNumber={uanNumber}
            handleEditPfDetails={handleEditPfDetails}
            eId={eId}
          />
        }
        className="modal-lg"
      />
    </>
  );
};

export default FinancialInfo;
