
import { useNavigate } from "react-router-dom";

const hanldeUnauthorizedUser = () => {
  const navigate = useNavigate();
const role = localStorage.getItem("role");
  const unAuthorizeUser = () => {
    if (role && role === "undefined") {
      navigate("/profile");
    } else if (role && !role.includes("hr")) {
      navigate("/profile");
    }
  };

  return {unAuthorizeUser};
};

export default hanldeUnauthorizedUser;

