import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";
import moment from "moment";
import {
  isCharactersValid,
  projectProjectNameValidation,
  whiteSpaceValid,
} from "../../../../../constant/Validations";
import { Strings } from "../../../../../constant/Strings";

const AddExperiences = (props: any) => {
  const employeeId: any = localStorage.getItem("selectedId");
  const { hideAddExperiences,data ,getApiDispatch} = props;
  const [docFile, setDocFile] = useState<any>({
    relievingLetter: null,
    paySlip: null,
    resignationAcceptanceLetter: null,
  });
  const [btnDisable, setBtnDisable] = useState(false);
  const [experienceDetails, setExperienceDetails] = useState<any>({
    employeeRef: employeeId,
    companyName: "",
    designation: "",
    location: "",
    rolesAndResponsibility: "",
    startDate: "",
    endDate: "",
    relievingLetter: null,
    paySlip: null,
    resignationAcceptanceLetter: null,
    lastEmployer: false,
  });

  const {
    valid_company_name,
    valid_designation,
    valid_location,
    valid_roles,
    addSuccess,
    file_size_max_msg,
    file_type_msg,
    company_name,
    location_label,
    designation,
    date_of_joining,
    date_of_relieving,
    roles_responsibility,
    imageSize2mb,
    uploadFile,
    upload_payslip,
    this_is_my_last_employer,
    upload_experience_relieving,
    upload_resignation_acceptance,
    cancel,
    submit,
  } = Strings;
  const [errors,setErrors] = useState<any>({})
  const addExperience = useSelector(
    (state: any) => state.employeeReducer.addExperienceData
  );

  useEffect(() => {
    if (
      experienceDetails.companyName &&
      experienceDetails.designation &&
      experienceDetails.location &&
      experienceDetails.rolesAndResponsibility &&
      experienceDetails.startDate &&
      experienceDetails.endDate &&
      experienceDetails.relievingLetter &&
      (experienceDetails.lastEmployer ? experienceDetails.paySlip : true) 
      &&
      Object.values(errors).every(val => val === null)
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [experienceDetails, btnDisable, addExperience,errors]);
  const [shownameErrorToggle, setShownameErrorToggle] = useState(false);
  const [showdesignationErrorToggle, setShowdesignationErrorToggle] =
    useState(false);
  const [showlocationErrorToggle, setShowlocationErrorToggle] = useState(false);
  const [showroleErrorToggle, setShowroleErrorToggle] = useState(false);

  const dispatch = useDispatch();
  const handleExperienceSubmit = () => {
    if (
      whiteSpaceValid(experienceDetails.companyName) &&
      isCharactersValid(experienceDetails.companyName) &&
      whiteSpaceValid(experienceDetails.designation) &&
      projectProjectNameValidation(experienceDetails.designation) &&
      whiteSpaceValid(experienceDetails.location) &&
      isCharactersValid(experienceDetails.location) &&
      whiteSpaceValid(experienceDetails.rolesAndResponsibility) &&
      projectProjectNameValidation(experienceDetails.rolesAndResponsibility)
    ) {

      dispatch(
        actions.addExperienceDataAction(experienceDetails, (res: any) => {
          if (res) {
            if (res.error) {
              if (res.error.error_description) {
                showErrorToastMessage(res.error.error_description);
                hideAddExperiences();
              }
            } else {
              if (res.code === 200) {
                hideAddExperiences();
                getApiDispatch();
                showSuccessToastMessage(addSuccess);
              }
            }
          }
        })
      );
     
    } else {
      if (
        whiteSpaceValid(experienceDetails.companyName) &&
        isCharactersValid(experienceDetails.companyName)
      ) {
        setShownameErrorToggle(false);
      } else {
        setShownameErrorToggle(true);
      }
      if (
        whiteSpaceValid(experienceDetails.designation) &&
        projectProjectNameValidation(experienceDetails.designation)
      ) {
        setShowdesignationErrorToggle(false);
      } else {
        setShowdesignationErrorToggle(true);
      }

      if (
        whiteSpaceValid(experienceDetails.location) &&
        isCharactersValid(experienceDetails.location)
      ) {
        setShowlocationErrorToggle(false);
      } else {
        setShowlocationErrorToggle(true);
      }

      if (
        whiteSpaceValid(experienceDetails.rolesAndResponsibility) &&
        projectProjectNameValidation(experienceDetails.rolesAndResponsibility)
      ) {
        setShowroleErrorToggle(false);
      } else {
        setShowroleErrorToggle(true);
      }
    }
  };

  const handleSubmitRelievingLetter = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage(file_size_max_msg);
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(file_type_msg);
      return;
    } else {
      setDocFile({ ...docFile, relievingLetter: e.target.files[0] });
      dispatch(
        actions.experienceDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setExperienceDetails({
                ...experienceDetails,
                relievingLetter: res.data,
              });
            }
          }
        )
      );
    }
  };

  const handleSubmitPaySlip = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage(file_size_max_msg);
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(file_type_msg);
      return;
    } else {
      setDocFile({ ...docFile, paySlip: e.target.files[0] });
      dispatch(
        actions.experienceDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setExperienceDetails({
                ...experienceDetails,
                paySlip: res.data,
              });
            }
          }
        )
      );
    }
  };

  const handleSubmitResignationAcceptanceLetter = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage(file_size_max_msg);
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(file_type_msg);
      return;
    } else {
      setDocFile({
        ...docFile,
        resignationAcceptanceLetter: e.target.files[0],
      });

      dispatch(
        actions.experienceDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setExperienceDetails({
                ...experienceDetails,
                resignationAcceptanceLetter: res.data,
              });
            }
          }
        )
      );
    }
  };
  const checkDateOverlap = (newStartDate:any, newEndDate:any) => {
    for (let exp of data) {
      const existingStart = moment(exp.startDate).startOf('day'); 
      const existingEnd = moment(exp.endDate).startOf('day');    
  
      const newStart = moment(newStartDate).startOf('day');
      const newEnd = moment(newEndDate).startOf('day');
      if (
        (newStart.isBetween(existingStart, existingEnd, null, '[]') || 
         newEnd.isBetween(existingStart, existingEnd, null, '[]')) ||
        (newStart.isSame(existingStart) || newEnd.isSame(existingEnd)) ||
        (newStart.isSameOrBefore(existingStart) && newEnd.isSameOrAfter(existingEnd))
      ) {
        return true; 
      }
    }
    return false; 
  };
  

  const handleStartDateChange = (feildName:any,e:any) => {
    const newStartDate = moment(e.target.value);
    
    if (checkDateOverlap(newStartDate, moment(experienceDetails.endDate))) {
      setErrors({
        ...errors,
        [feildName]: "Date overlaps with existing experience range."
    });
    } else {
      setExperienceDetails({
        ...experienceDetails,
        startDate: e.target.value,
      });
      setErrors({
        ...errors,
        [feildName]: null
    });
    }
  };
  
  const handleEndDateChange = (feildName:any,e:any) => {
    const newEndDate = moment(e.target.value);
    if (checkDateOverlap(moment(experienceDetails.startDate), newEndDate)) {
      setErrors({
        ...errors,
        [feildName]: "Date overlaps with existing experience range."
    });
    } else {
      setExperienceDetails({
        ...experienceDetails,
        endDate: e.target.value,
      });
      setErrors({
        ...errors,
        [feildName]: null
    });
    }
  };

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            {company_name}
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setExperienceDetails({
                ...experienceDetails,
                companyName: e.target.value,
              })
            }
          />
          <p className="error">
            {experienceDetails.companyName.length !== 0 &&
              (shownameErrorToggle ? valid_company_name : "")}
          </p>
        </div>

        <div className="col-md-4">
          <label className="form-label">{designation}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setExperienceDetails({
                ...experienceDetails,
                designation: e.target.value,
              })
            }
          />
          <p className="error">
            {experienceDetails.designation.length !== 0 &&
              (showdesignationErrorToggle ? valid_designation : "")}
          </p>
        </div>

        <div className="col-md-4">
          <label className="form-label">{location_label}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setExperienceDetails({
                ...experienceDetails,
                location: e.target.value,
              })
            }
          />
          <p className="error">
            {experienceDetails.location.length !== 0 &&
              (showlocationErrorToggle ? valid_location : "")}
          </p>
        </div>

        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            {date_of_joining}
          </label>
          <span className="required">*</span>
          <input
            type="date"
            max={moment().format("YYYY-MM-DD")}
            className="form-control"
            // onChange={(e) =>
            //   setExperienceDetails({
            //     ...experienceDetails,
            //     startDate: e.target.value,
            //   })
            // }
            onChange={(e) => handleStartDateChange("startDate",e)}
          />
          {errors["startDate"] &&  <p className="error">{errors["startDate"]}</p>}
        
        </div>

        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            {date_of_relieving}
          </label>
          <span className="required">*</span>
          <input
            type="date"
            min={moment(experienceDetails.startDate).format("YYYY-MM-DD")}
            className="form-control"
            onChange={(e) => handleEndDateChange("endDate",e)}
            // onChange={(e) =>
            //   setExperienceDetails({
            //     ...experienceDetails,
            //     endDate: e.target.value,
            //   })
            // }
          />
         {errors["endDate"] &&  <p className="error">{errors["endDate"]}</p>}
        </div>

        <div className="col-md-6">
          <label className="form-label">{roles_responsibility}</label>
          <span className="required">*</span>
          <textarea
            className="form-control address-text"
            onChange={(e) =>
              setExperienceDetails({
                ...experienceDetails,
                rolesAndResponsibility: e.target.value,
              })
            }
          />
          <p className="error">
            {experienceDetails.rolesAndResponsibility.length !== 0 &&
              (showroleErrorToggle ? valid_roles : "")}
          </p>
        </div>

        <div className="form-label mt-3">
          <input
            type="checkbox"
            id="checkBox"
            onClick={() =>
              setExperienceDetails({
                ...experienceDetails,
                lastEmployer: !experienceDetails.lastEmployer,
              })
            }
          />{" "}
          {this_is_my_last_employer}
        </div>

        <div className="col-md-6">
          <label className="form-label">{upload_experience_relieving}</label>
          <span className="required">*</span>
          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleSubmitRelievingLetter}
              hidden
              id="actual-btn-1"
            />
            <label
              htmlFor="actual-btn-1"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <p className="image-size-condition">
            {imageSize2mb}
            <span className="required">*</span>
          </p>
          <a
            href={experienceDetails.relievingLetter?.document_path_url}
            target="_blank"
            className="buttonsize"
          >
            {experienceDetails.relievingLetter?.document_path_url
              ? "View File"
              : ""}
          </a>
          <p className="image-size-condition">
            {docFile.relievingLetter?.name}
          </p>
        </div>

        <div className="col-md-6">
          <label className="form-label">{upload_resignation_acceptance}</label>
          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleSubmitResignationAcceptanceLetter}
              hidden
              id="actual-btn-3"
            />
            <label
              htmlFor="actual-btn-3"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <p className="image-size-condition">
            {imageSize2mb}
            <span className="required">*</span>
          </p>
          <a
            href={
              experienceDetails.resignationAcceptanceLetter?.document_path_url
            }
            target="_blank"
            className="buttonsize"
          >
            {experienceDetails.resignationAcceptanceLetter?.document_path_url
              ? "View File"
              : ""}
          </a>
          <p className="image-size-condition">
            {docFile.resignationAcceptanceLetter?.name}
          </p>
        </div>

        {experienceDetails.lastEmployer && (
          <>
            <div className="col-md-6 mt-0">
              <label className="form-label">{upload_payslip}</label>
              <span className="required">*</span>
              <div>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleSubmitPaySlip}
                  hidden
                  id="actual-btn-2"
                />
                <label
                  htmlFor="actual-btn-2"
                  className="btn btn-outline-secondary buttonsize"
                >
                  {uploadFile}
                </label>
              </div>
              <p className="image-size-condition">
                {imageSize2mb}
                <span className="required">*</span>
              </p>
              <a
                href={experienceDetails.paySlip?.document_path_url}
                target="_blank"
                className="buttonsize"
              >
                {experienceDetails.paySlip?.document_path_url
                  ? "View File"
                  : ""}
              </a>
              <p className="image-size-condition">{docFile.paySlip?.name}</p>
            </div>
          </>
        )}
      </div>
      <div className="modal-btn-wrapper mt-3">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideAddExperiences}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleExperienceSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddExperiences;
