import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import * as actions from "../../../../Redux/Actions/index";
import AddInvoiceUpload from "./AddInvoiceUpload";
import { Dropdown } from "react-bootstrap";
import {
  Strings,
  addInvoiceErrorsValidations,
  invoiceModalLabels,
  requiredFeild,
  invoiceStatusDropdown,
} from "../../../../constant/Strings";
import {
  invoiceInvoiceNumberValidation,
  invoiceRevenueMonthValidation,
  invoiceBaseAmountValidation,
  invoiceCgstValidation,
  invoiceSgstValidation,
  invoiceIgstValidation,
  invoiceVatValidation,
  invoiceTdsValidation,
  invoiceTotalAmountValidation,
  invoicePaidAmountValidation,
  invoiceDifferenceAmountValidation,
} from "../../../../constant/Validations";

const AddInvoice = (props: any) => {
  const { cancel, submit } = Strings;
  const { projectId, hideAddProjectInvoice } = props;
  const {
    difference_amount,
    paid_amount,
    total_amount,
    tds,
    vat,
    igst,
    sgst,
    cgst,
    baseAmount,
    revenueMonth,
    invoiceNumber,
  } = addInvoiceErrorsValidations;
  const [btnDisable, setBtnDisable] = useState(false);
  const [projectInvoiceData, setProjectInvoiceData] = useState<any>({
    projectRef: projectId,
    revenueMonth: "",
    baseAmount: "",
    cgst: "",
    sgst: "",
    igst: "",
    vat: "",
    tds: "",
    totalAmount: "",
    paidAmount: "",
    differenceAmount: "",
    status: "",
    invoiceNumber: "",
    invoiceDate: "",
    paidDate: "",
  });
  const dispatch = useDispatch();

  const [nextScreen, setnextScreen] = useState(true);

  const [errors, setErrors] = useState({
    invoiceNumber: "",
    revenueMonth: "",
    baseAmount: "",
    cgst: "",
    sgst: "",
    igst: "",
    vat: "",
    tds: "",
    totalAmount: "",
    paidAmount: "",
    differenceAmount: "",
    status: "",
  });

  useEffect(() => {
    if (
      projectInvoiceData.revenueMonth &&
      projectInvoiceData.baseAmount &&
      projectInvoiceData.cgst &&
      projectInvoiceData.sgst &&
      projectInvoiceData.igst &&
      projectInvoiceData.vat &&
      projectInvoiceData.tds &&
      projectInvoiceData.totalAmount &&
      projectInvoiceData.paidAmount &&
      projectInvoiceData.differenceAmount &&
      projectInvoiceData.status &&
      projectInvoiceData.invoiceNumber &&
      projectInvoiceData.invoiceDate &&
      projectInvoiceData.paidDate
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });
  let valid: boolean = true;
  const validate = () => {
    if (
      invoiceInvoiceNumberValidation(projectInvoiceData.invoiceNumber) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        invoiceNumber: invoiceNumber,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        invoiceNumber: "",
      }));
    }
    if (
      invoiceRevenueMonthValidation(projectInvoiceData.revenueMonth) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        revenueMonth: revenueMonth,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        revenueMonth: "",
      }));
    }
    if (invoiceBaseAmountValidation(projectInvoiceData.baseAmount) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        baseAmount: baseAmount,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        baseAmount: "",
      }));
    }
    if (invoiceCgstValidation(projectInvoiceData.cgst) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        cgst: cgst,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        cgst: "",
      }));
    }
    if (invoiceSgstValidation(projectInvoiceData.sgst) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        sgst: sgst,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        sgst: "",
      }));
    }
    if (invoiceIgstValidation(projectInvoiceData.igst) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        igst: igst,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        igst: "",
      }));
    }
    if (invoiceVatValidation(projectInvoiceData.vat) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        vat: vat,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        vat: "",
      }));
    }
    if (invoiceTdsValidation(projectInvoiceData.tds) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        tds: tds,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        tds: "",
      }));
    }
    if (invoiceTotalAmountValidation(projectInvoiceData.totalAmount) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        totalAmount: total_amount,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        totalAmount: "",
      }));
    }
    if (invoicePaidAmountValidation(projectInvoiceData.paidAmount) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        paidAmount: paid_amount,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        paidAmount: "",
      }));
    }
    if (
      invoiceDifferenceAmountValidation(projectInvoiceData.differenceAmount) ==
      false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        differenceAmount: difference_amount,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        differenceAmount: "",
      }));
    }
  };
  const handleInvoiceSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  const handleDispatch = () => {
    dispatch(
      actions.addProjectRepositoryInvoiceAction(
        projectInvoiceData,
        (res: any) => {
          if (res) {
            if (res.error) {
              if (res.error.error_description) {
                showErrorToastMessage(res.error.error_description);
              }
            } else {
              if (res.code === 200) {
                setnextScreen(false);
              }
            }
          }
        }
      )
    );
  };

  return (
    <>
      {nextScreen ? (
        <div className="row g-3">
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.invoiceNo}
            </label>
            <span className="required">{requiredFeild.required}</span>
            <input
              type="text"
              className="form-control"
              placeholder="Invoice No"
              value={projectInvoiceData.invoiceNumber}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  invoiceNumber: e.target.value,
                })
              }
            />
            <p className="error">{errors.invoiceNumber}</p>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.invoiceData}
            </label>
            <span className="required">{requiredFeild.required}</span>
            <input
              type="date"
              className="form-control"
              placeholder="DD-MM-YYYY"
              value={projectInvoiceData.invoiceDate}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  invoiceDate: e.target.value,
                })
              }
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.revenueMonth}
            </label>
            <span className="required">{requiredFeild.required}</span>
            <input
              type="text"
              className="form-control"
              placeholder="Month"
              value={projectInvoiceData.revenueMonth}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  revenueMonth: e.target.value,
                })
              }
            />
            <p className="error">{errors.revenueMonth}</p>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.baseAmount}
            </label>
            <span className="required">{requiredFeild.required}</span>
            <input
              type="text"
              className="form-control"
              placeholder="Base Amount"
              value={projectInvoiceData.baseAmount}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  baseAmount: e.target.value.replace(/\D/g, ""),
                })
              }
            />
            <p className="error">{errors.baseAmount}</p>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.cgst}
            </label>
            <span className="required">{requiredFeild.required}</span>

            <input
              type="text"
              className="form-control"
              placeholder="CGST"
              value={projectInvoiceData.cgst}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  cgst: e.target.value,
                })
              }
            />
            <p className="error">{errors.cgst}</p>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.sgst}
            </label>
            <span className="required">{requiredFeild.required}</span>
            <input
              type="text"
              className="form-control"
              placeholder="SGST"
              value={projectInvoiceData.sgst}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  sgst: e.target.value,
                })
              }
            />
            <p className="error">{errors.sgst}</p>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.igst}
            </label>
            <span className="required">{requiredFeild.required}</span>
            <input
              type="text"
              className="form-control"
              placeholder="IGST"
              value={projectInvoiceData.igst}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  igst: e.target.value,
                })
              }
            />
            <p className="error">{errors.igst}</p>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.vat}
            </label>
            <span className="required">{requiredFeild.required}</span>
            <input
              type="text"
              className="form-control"
              placeholder="VAT"
              value={projectInvoiceData.vat}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  vat: e.target.value,
                })
              }
            />
            <p className="error">{errors.vat}</p>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.tds}
            </label>
            <span className="required">{requiredFeild.required}</span>
            <input
              type="text"
              className="form-control"
              placeholder="TDS"
              value={projectInvoiceData.tds}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  tds: e.target.value.replace(/\D/g, ""),
                })
              }
            />
            <p className="error">{errors.tds}</p>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.totalAmount}
            </label>
            <span className="required">{requiredFeild.required}</span>
            <input
              type="text"
              className="form-control"
              placeholder="Total Amount"
              value={projectInvoiceData.totalAmount}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  totalAmount: e.target.value.replace(/\D/g, ""),
                })
              }
            />
            <p className="error">{errors.totalAmount}</p>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.paidAmount}
            </label>
            <span className="required">{requiredFeild.required}</span>
            <input
              type="text"
              className="form-control"
              placeholder="Paid Amount"
              value={projectInvoiceData.paidAmount}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  paidAmount: e.target.value.replace(/\D/g, ""),
                })
              }
            />
            <p className="error">{errors.paidAmount}</p>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.diffAmount}
            </label>
            <span className="required">{requiredFeild.required}</span>
            <input
              type="text"
              className="form-control"
              placeholder="Difference Amount"
              value={projectInvoiceData.differenceAmount}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  differenceAmount: e.target.value.replace(/\D/g, ""),
                })
              }
            />
            <p className="error">{errors.differenceAmount}</p>
          </div>

          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.status}
            </label>
            <span className="required">{requiredFeild.required}</span>
            <Dropdown
              placeholder="Select"
              onSelect={(eventKey: any) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  status: eventKey,
                })
              }
            >
              <Dropdown.Toggle variant="light" className="form-control">
                {projectInvoiceData.status}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {invoiceStatusDropdown.map((element: any) => (
                  <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.paidDate}
            </label>
            <span className="required">{requiredFeild.required}</span>
            <input
              type="date"
              className="form-control"
              placeholder="DD-MM-YYYY"
              value={projectInvoiceData.paidDate}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  paidDate: e.target.value,
                })
              }
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {invoiceModalLabels.remark}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="remark"
              value={projectInvoiceData.remarks}
              onChange={(e) =>
                setProjectInvoiceData({
                  ...projectInvoiceData,
                  remarks: e.target.value,
                })
              }
            />
          </div>

          <div className="modal-btn-wrapper mt-5 mx-auto">
            <div>
              <button
                className="btn cancel-btn buttonsize"
                onClick={hideAddProjectInvoice}
              >
                {cancel}
              </button>
            </div>
            <div>
              <button
                className={
                  btnDisable
                    ? "btn add-submit-button buttonsize"
                    : "btn add-submit-button btn-disabled buttonsize"
                }
                onClick={handleInvoiceSubmit}
              >
                {submit}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <AddInvoiceUpload
          setnextScreen={setnextScreen}
          hideAddProjectInvoice={hideAddProjectInvoice}
        />
      )}
    </>
  );
};

export default AddInvoice;
