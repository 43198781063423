import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions";
import {
  Strings,
  addEmployeeSkillCoinsErrorsValidations,
} from "../../../constant/Strings";

const AssignBulkSkillCoins = (props: any) => {
  const { assigned_dateVal, skillcoinsVal, skillcoinsRange } =
    addEmployeeSkillCoinsErrorsValidations;
  const { cancel_label, submit_label, no_skill, assigned_Date, addSuccess } =
    Strings;
  const {
    hideAllocateSkillCoins,
    employeeId,
    hideChecked,

    setShowBulkAssignSkillCoins,
    showLoadingIndicator,
    hideLoadingIndicator,
    skillValueGetApi,
    setClickedBulkUpload,
    apiCallFunction,
  } = props;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [btnDisable, setBtnDisable] = useState(false);
  const [allocateSkillCoins, setAllocateSkillCoins] = useState<any>({
    skillcoins: "",
    assignment_letter: "",
    employeeRef: employeeId,
    created_by: loginId,
    assigned_date: "",
  });
  const [errors, setErrors] = useState({
    skillcoins: "",
    assignment_letter: "",
    assigned_date: "",
    employeeName: "",
  });

  useEffect(() => {
    if (allocateSkillCoins.skillcoins && allocateSkillCoins.assigned_date) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });
  let valid: boolean = true;
  const validate = () => {
    if (allocateSkillCoins.assigned_date.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        assigned_date: assigned_dateVal,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, assigned_date: "" }));
    }
    if (allocateSkillCoins.skillcoins.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        skillcoins: skillcoinsVal,
      }));
      setBtnDisable(false);
    }
    if (
      allocateSkillCoins.skillcoins < 100 ||
      allocateSkillCoins.skillcoins > 500
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        skillcoins: skillcoinsRange,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, skillcoins: "" }));
    }
  };
  const handleAllocateSkillCoins = (e: any) => {
    setShowBulkAssignSkillCoins(false);
    showLoadingIndicator();
    setBtnDisable(false);
    validate();
    if (valid) {
      setClickedBulkUpload(true);
      apiCallFunction();
      handleAllocate();
    }
  };
  const dispatch = useDispatch();
  const handleAllocate: any = () => {
    dispatch(
      actions.assignBulkSkillCoinsAction(allocateSkillCoins, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
            hideLoadingIndicator();
          } else {
            if (res.code === 200) {
              hideAllocateSkillCoins();
              showSuccessToastMessage(addSuccess);
              hideChecked();
              skillValueGetApi();
            }
            hideLoadingIndicator();
          }
        }
      })
    );
  };
  return (
    <>
      <div>
        <div className="row g-3">
          <div className="col-md-8">
            <label className="form-label">{no_skill}</label>
            <span className="required">*</span>
            <input
              type="number"
              min={100}
              max={500}
              className="form-control"
              onChange={(e: any) => {
                setAllocateSkillCoins({
                  ...allocateSkillCoins,
                  skillcoins: e.target.value,
                });
              }}
            />
            <p className="error">{errors.skillcoins}</p>
          </div>
          <div className="col-md-4">
            <label className="form-label">{assigned_Date}</label>
            <span className="required">*</span>
            <input
              type="Date"
              className="form-control"
              max={new Date().toISOString().split("T")[0]}
              onChange={(e: any) => {
                setAllocateSkillCoins({
                  ...allocateSkillCoins,
                  assigned_date: e.target.value,
                });
              }}
            />
            <p className="error">{errors.assigned_date}</p>
          </div>

          <div className="modal-btn-wrapper mt-5 mx-auto">
            <div>
              <button
                className="btn cancel-btn buttonsize"
                onClick={hideAllocateSkillCoins}
              >
                {cancel_label}
              </button>
            </div>
            <div>
              <button
                className={
                  btnDisable
                    ? "btn add-submit-button buttonsize"
                    : "btn add-submit-button btn-disabled buttonsize"
                }
                onClick={(e) => handleAllocateSkillCoins(e)}
              >
                {submit_label}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignBulkSkillCoins;
