import React, { useState } from "react";
import Card from "../../../component/Card/Card";
import { Strings } from "../../../constant/Strings";
import { icons } from "../../../assets";
import "./ContactInfo.scss";
import EditButton from "../../../component/Buttons/EditButton/EditButton";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import EditPersonalContactInfo from "./EditPersonalContactInfo/EditPersonalContactInfo";
import EditAddressDetails from "./EditAddressDetails/EditAddressDetails";
import useLoadingIndicator from "../../../component/Loader/Loader";

const ContactInfo = (props: any) => {
  const [editPersonalContactInfo, setEditPersonalContactInfo] = useState(false);
  const [editAddressInfo, setEditAddressInfo] = useState(false);
  const {loadingComponent, apiCallCompleted} = useLoadingIndicator();
  const {
    personalMail,
    mobileNumber,
    alternateNumber,
    emergencyContactNumber,
    emergencyContactName,
    currentAddress1,
    currentAddress,
    currentAddress2,
    permanentAddress2,
    currentAddress3,
    permanentAddress1,
    permanentAddress,
    permanentAddress3,
    currentDistrict,
    currentState,
    currentPinCode,
    permanentCountry,
    currentCountry,
    permanentDistrict,
    permanentState,
    permanentPinCode,
    eId,
    currentAddressProof,
    permanentAddressProof,
    isSameComunicationAdderess,
    status,
  } = props;

  const {
    contact_info_heading,
    personal_contact_details,
    address_information,
    address_for_communication,
    personal_email_iD,
    mobile_number,
    alternate_number,
    emergency_no,
    name_of_the_emergency_contact,
    permanent_address,
  } = Strings;
  const { edit_orange_icon } = icons;

  const handleEditPersonalContactInfo = () => {
    setEditPersonalContactInfo(!editPersonalContactInfo);
  };

  const handleEditAddress = () => {
    setEditAddressInfo(!editAddressInfo);
  };
  return (
    <>
    {loadingComponent()}
      <div>
        <h5>{contact_info_heading}</h5>
        <Card>
          <div className="edit-icon-wrapper">
            <h5>{personal_contact_details}</h5>
            <EditButton
              title="Edit"
              icon={edit_orange_icon}
              onClick={handleEditPersonalContactInfo}
            />
          </div>

          <div className="edit-icon-wrapper">
            <div className="profile-details">
              <span>{personal_email_iD}</span> <p>{personalMail}</p>
            </div>
            <div className="profile-details">
              <span>{mobile_number}</span> <p>{mobileNumber}</p>
            </div>
            <div className="profile-details">
              <span>{alternate_number}</span> <p>{alternateNumber}</p>
            </div>
          </div>

          <div className="edit-icon-wrapper">
            <div className="profile-details">
              <span>{emergency_no}</span> <p>{emergencyContactNumber}</p>
            </div>
          </div>
        </Card>

        <Card>
          <div className="edit-icon-wrapper">
            <h5>{address_information}</h5>
            <EditButton
              title="Edit"
              icon={edit_orange_icon}
              onClick={handleEditAddress}
            />
          </div>

          <div className="edit-icon-wrapper">
            <div className="profile-details">
              <span>{address_for_communication}</span>
              <p>
                {currentAddress1} {currentAddress2}
                <br />
                {currentDistrict}
                <br />
                {currentState} - {currentPinCode}
                <br />
                {currentCountry}
              </p>
            </div>
            <div className="profile-details">
              <span>{permanent_address}</span>
              <p>
                {permanentAddress1} {permanentAddress2}
                <br />
                {permanentDistrict}
                <br />
                {permanentState} - {permanentPinCode}
                <br />
                {permanentCountry}
              </p>
            </div>
          </div>
        </Card>
      </div>

      <ModalComponents
        show={editPersonalContactInfo}
        onHide={handleEditPersonalContactInfo}
        title="Edit Personal Contact Details"
        modalBody={
          <EditPersonalContactInfo
          apiCallCompleted = {apiCallCompleted}
            personalMail={personalMail}
            mobileNumber={mobileNumber}
            alternateNumber={alternateNumber}
            emergencyContactNumber={emergencyContactNumber}
            emergencyContactName={emergencyContactName}
            handleEditPersonalInfo={handleEditPersonalContactInfo}
            eId={eId}
            status={status}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={editAddressInfo}
        onHide={handleEditAddress}
        title="Edit Address Details"
        modalBody={
          <EditAddressDetails
          apiCallCompleted = {apiCallCompleted}
            currentAddress1={currentAddress1}
            currentAddress2={currentAddress2}
            currentState={currentState}
            currentDistrict={currentDistrict}
            currentCountry={currentCountry}
            currentPinCode={currentPinCode}
            permanentAddress1={permanentAddress1}
            permanentAddress2={permanentAddress2}
            permanentDistrict={permanentDistrict}
            permanentState={permanentState}
            permanentCountry={permanentCountry}
            permanentPinCode={permanentPinCode}
            permanentAddressProof={permanentAddressProof}
            currentAddressProof={currentAddressProof}
            handleEditAddress={handleEditAddress}
            isSameComunicationAdderess={isSameComunicationAdderess}
            eId={eId}
          />
        }
        className="modal-lg"
      />
    </>
  );
};

export default ContactInfo;
