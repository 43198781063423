import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions";
import { ToastContainer } from "react-toastify";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import ModuleTable from "./ModuleTable";
import MainCard from "../../../component/MainCard/MainCard";
import "../InseedProgram.scss";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";

const ModuleTableData = () => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const { loadingComponent } = useLoadingIndicator();
  const [searchKey, setSearchKey] = useState("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState<Number>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  const dispatch = useDispatch();
  const addModule = useSelector(
    (state: any) => state.employeeReducer.addInseedModuleDetails
  );
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    unAuthorizeUser();
    if (condition) {
      dispatch(
        actions.getInseedAllModulesAction(
          pageSize,
          page,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setOnclickSearch(false);
              }
            }
          }
        )
      );
    }
  }, [
    addModule,
    page,
    pageSize,
    searchKey,
    searchValue,
    filterValue,
    condition,
  ]);

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <ModuleTable
          showColumn={false}
          totalPages={totalPages}
          onPage={page}
          handlePageChange={handlePageChange}
          searchKey={searchKey}
          searchValue={searchValue}
          handleSearch={handleSearch}
          setSearchKey={setSearchKey}
          filterValue={filterValue}
          filterChange={filterChange}
          pagePerSize={pageSize}
          setPagePerSize={setPageSize}
          totalEntries={totalEntries}
          onSearchClicked={onSearchClicked}
          filterKey={filterKey}
        />
        <ToastContainer />
      </MainCard>
    </>
  );
};

export default ModuleTableData;
