import React, { useEffect, useState } from "react";
import * as actions from "../../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import { Strings, bankDetailsValidations } from "../../../../constant/Strings";
import {
  accountNumberValidation,
  ifscCodeValidation,
  projectClientNameValidation,
} from "../../../../constant/Validations";

const EditEmployeeBankDetails = (props: any) => {
  const { employeeBankDetails, handleEmployeeBankDetails, apiCallCompleted } =
    props;
  const {
    editSuccess,
    cancel,
    submit,
    bankNameLabel,
    accountNumberLabel,
    accountHolderNameLabel,
    ifscCodeLabel,
    checqueCancel,
    uploadFile,
    imageSize2mb,
  } = Strings;
  const {
    accountNumberValid,
    ifscCodeValid,
    accountHolderNameValid,
    bankNameValid,
  } = bankDetailsValidations;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [btnDisable, setBtnDisable] = useState(false);

  const [employeeData, setEmployeeData] = useState({
    bankName: employeeBankDetails.bankName,
    accountNumber: employeeBankDetails.accountNumber,
    ifscCode: employeeBankDetails.ifscCode,
    cancelledCheque: employeeBankDetails.cancelledCheque,
    accountHolderName: employeeBankDetails.accountHolderName,
    updatedBy: loginId,
    eId: employeeBankDetails.eId,
  });
  const [errors, setErrors] = useState({
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    accountHolderName: "",
  });
  const [docFile, setDocFile] = useState<any>({
    cancelledCheque: null,
  });

  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (
      employeeData?.accountNumber?.length >= 9 &&
      employeeData?.accountNumber?.length <= 18 &&
      accountNumberValidation(employeeData?.accountNumber) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        accountNumber: accountNumberValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, department: "" }));
    }
    if (
      employeeData.ifscCode?.length == 11 &&
      ifscCodeValidation(employeeData.ifscCode) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        ifscCode: ifscCodeValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, ifscCode: "" }));
    }
    if (projectClientNameValidation(employeeData.accountHolderName) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        accountHolderName: accountHolderNameValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, accountHolderName: "" }));
    }
    if (projectClientNameValidation(employeeData.bankName) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        bankName: bankNameValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, bankName: "" }));
    }
  };
  const handleEdit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editEmployeeDataAction(employeeData, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            }
          } else {
            if (res.code === 200) {
              showSuccessToastMessage(editSuccess);
              apiCallCompleted(true);
            }
          }
        }
      })
    );
    handleEmployeeBankDetails();
  };
  useEffect(() => {
    if (
      employeeData.accountNumber &&
      employeeData.ifscCode &&
      employeeData.accountHolderName &&
      employeeData.bankName &&
      employeeData.cancelledCheque
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [employeeData]);

  const handleSubmitCancelledCheque = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, cancelledCheque: e.target.files[0] });
      dispatch(
        actions.employeeDetailsDocUploadAction(
          { file: e.target.files[0], eId: employeeData.eId },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setEmployeeData({
                ...employeeData,
                cancelledCheque: res.data,
              });
            }
          }
        )
      );
    }
  };

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-6">
          <label className="form-label">{bankNameLabel}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={employeeData.bankName}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                bankName: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
              })
            }
          />
          <p className="error">{errors.bankName}</p>
        </div>
        <div className="col-md-6">
          <label className="form-label">{accountNumberLabel}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            maxLength={18}
            value={employeeData.accountNumber}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                accountNumber: e.target.value.replace(/\D/g, ""),
              })
            }
          />
          <p className="error">{errors.accountNumber}</p>
        </div>
        <div className="col-md-6">
          <label className="form-label">{accountHolderNameLabel}</label>
          <span className="required">*</span>
          <input
            type="text"
            maxLength={100}
            className="form-control"
            value={employeeData.accountHolderName}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                accountHolderName: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
              })
            }
          />
          <p className="error">{errors.accountHolderName}</p>
        </div>

        <div className="col-md-6">
          <label className="form-label">{ifscCodeLabel}</label>
          <span className="required">*</span>
          <input
            type="text"
            maxLength={11}
            className="form-control"
            value={employeeData?.ifscCode?.toUpperCase()}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                ifscCode: e.target.value.replace(/[^\w\s]/gi, ""),
              })
            }
          />
          <p className="error">{errors.ifscCode}</p>
        </div>
        <div className="col-md-6">
          <label className="form-label">{checqueCancel}</label>
          <span className="required">*</span>
          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleSubmitCancelledCheque}
              hidden
              id="actual-btn-3"
            />
            <label
              htmlFor="actual-btn-3"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <p className="image-size-condition">
            {imageSize2mb}
            <span className="required">*</span>
          </p>
        </div>
        <div className="col-md-4" style={{ marginTop: "45px" }}>
          <a
            href={employeeData.cancelledCheque?.document_path_url}
            target="_blank"
            className="buttonsize"
          >
            {employeeData.cancelledCheque?.document_path_url ? "View File" : ""}
          </a>
          <p className="image-size-condition">
            {docFile.cancelledCheque?.name}
          </p>
        </div>
      </div>

      <div className="modal-btn-wrapper mt-3">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={handleEmployeeBankDetails}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditEmployeeBankDetails;
