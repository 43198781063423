import React, { useEffect, useState } from "react";
import MainCard from "../../../component/MainCard/MainCard";
import { useDispatch, useSelector } from "react-redux";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import EditModule from "./EditModule";
import DeleteModule from "./DeleteModule";
import useLoadingIndicator from "../../../component/Loader/Loader";
import * as actions from "../../../Redux/Actions";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { icons } from "../../../assets";
import { Strings } from "../../../constant/Strings/index";
import "../ModuleMaster/moduleMaster.scss";

const SpecificModule = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const {
    back,
    module_name,
    editButton,
    deleteButton,
    contentSyllabus,
    successCriteria,
    viewFile,
    trainer_name,
    technology_stream,
    durationDays,
    trainer_contact,
    trainer_institute,
  } = Strings;
  const authRole = localStorage.getItem("role");
  const isInseedChecker = authRole?.includes("inseed-program-checker");
  const isInseedMaker = authRole?.includes("inseed-program-maker");
  const routeTypeInseed = localStorage.getItem("routeTypeInseed");
  const inseedScheduleId = localStorage.getItem("createTrainingId");
  const { edit_icon_blue, delete_icon, description } = icons;
  const [info, setInfo] = useState<any>([]);
  const dispatch = useDispatch();
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  const editModule = useSelector(
    (state: any) => state.employeeReducer.editInseedModuleDetails
  );
  const deleteModule = useSelector(
    (state: any) => state.employeeReducer.deleteInseedModuleDetails
  );

  useEffect(() => {
    showLoadingIndicator();
    dispatch(
      actions.getInseedModuleByIdAction((res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              hideLoadingIndicator();
            }
          }
        }
      }, id)
    );
  }, [editModule, deleteModule]);
  const moduleData = useSelector(
    (state: any) => state.employeeReducer.getModulesByIdDetails
  );
  useEffect(() => {
    if (moduleData && moduleData.data) {
      setInfo(moduleData.data);
    }
  }, [moduleData, info]);

  const [moduleDetails, setModuleDetails] = useState<any>({
    moduleName: "",
    content: "",
    duration: "",
    successCriteria: "",
    description: "",
    trainerName: "",
    trainerContactNumber: "",
    technologyStream: "",
    syllabus: "",
  });

  const [showEditModule, setShowEditModule] = useState(false);
  const handleEditModule = (
    moduleId: any,
    moduleName: any,
    duration: any,
    trainerName: any,
    trainerContactNumber: any,
    successCriteria: any,
    content: any,
    description: any,
    technologyStream: any,
    instituteName: any,
    syllabus: any
  ) => {
    setModuleDetails({
      moduleId: moduleId,
      moduleName: moduleName,
      content: content,
      duration: duration,
      successCriteria: successCriteria,
      description: description,
      trainerName: trainerName,
      trainerContactNumber: trainerContactNumber,
      technologyStream: technologyStream,
      instituteName: instituteName,
      syllabus: syllabus,
    });
    setShowEditModule(true);
  };
  const hideEditModule = () => {
    setShowEditModule(false);
  };

  const [showDeleteModule, setShowDeleteModule] = useState(false);
  const [deleteModuleId, setdeleteModuleId] = useState("");

  const handleDeleteModule = (id: any) => {
    setdeleteModuleId(id);
    setShowDeleteModule(true);
  };

  const hideDeleteModule = () => {
    setShowDeleteModule(false);
  };

  return (
    <>
      <div>
        {loadingComponent()}
        <MainCard>
          <Card className="module-details-card p-2">
            <div className="row">
              <div className="d-flex flex-row justify-content-between">
                <div className="col-md-6">
                  <div
                    className="d-flex flex-row jutify-content-between module-back-button"
                    onClick={() =>
                      navigate(
                        routeTypeInseed == "TrainingSchedule"
                          ? `/create-training/${inseedScheduleId}`
                          : "/module-master"
                      )
                    }
                  >
                    <img
                      src={icons.backIcon}
                      className="backIcon"
                      alt="back button"
                    />
                    <p className="back-text">{back}</p>
                  </div>
                  <div>
                    <div className="module-heading">{module_name}:</div>
                    <div className="module-name">{info[0]?.moduleName}</div>
                  </div>
                </div>
                <div className="col-md-4 mx-4">
                  <div className="row">
                    <div className="col-md-5 module-details-label">
                      {trainer_name}:
                    </div>
                    <div className="col-md-7 module-details-values">
                      {info[0]?.trainerName ? info[0]?.trainerName : "-"}
                    </div>
                    <div className="col-md-5 module-details-label">
                      {technology_stream}
                    </div>
                    <div className="col-md-7 module-details-values">
                      {info[0]?.technologyStream
                        ? info[0]?.technologyStream
                        : "-"}
                    </div>
                    <div className="col-md-5 module-details-label">
                      {durationDays}
                    </div>
                    <div className="col-md-7 module-details-values">
                      {info[0]?.duration ? info[0]?.duration : "-"}
                    </div>
                    <div className="col-md-5 module-details-label">
                      {trainer_contact}
                    </div>
                    <div className="col-md-7 module-details-values">
                      {info[0]?.trainerContactNumber
                        ? info[0]?.trainerContactNumber
                        : "-"}
                    </div>
                    <div className="col-md-5 module-details-label">
                      {trainer_institute}
                    </div>
                    <div className="col-md-7 module-details-values">
                      {info[0]?.instituteName ? info[0]?.instituteName : "-"}
                    </div>
                  </div>
                </div>
                {(isInseedChecker || isInseedMaker) && (
                  <div className="col-md-2 mx-4">
                    <div className="mt-2">
                      <button
                        className="btn module-edit-button"
                        onClick={() => {
                          handleEditModule(
                            info[0]?._id,
                            info[0]?.moduleName,
                            info[0]?.duration,
                            info[0]?.trainerName,
                            info[0]?.trainerContactNumber,
                            info[0]?.successCriteria,
                            info[0]?.content,
                            info[0]?.description,
                            info[0]?.technologyStream,
                            info[0]?.instituteName,
                            info[0]?.syllabus
                          );
                        }}
                      >
                        <img
                          className="editdelete-icon"
                          src={edit_icon_blue}
                          alt="edit"
                        />
                        &nbsp;
                        {editButton}
                      </button>
                    </div>
                    <div className="mt-2">
                      <button
                        className="btn module-delete-button"
                        onClick={() => handleDeleteModule(info[0]?._id)}
                      >
                        <img
                          className="editdelete-icon"
                          src={delete_icon}
                          alt="edit"
                        />
                        &nbsp;
                        {deleteButton}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Card>
          <div className="mt-4">
            <div className="d-flex flex-row justify-content-start">
              <h5 className="content-heading mx-2">{contentSyllabus}</h5>
              {info[0]?.content?.document_path_url && (
                <a
                  href={info[0]?.content?.document_path_url}
                  download={info[0]?.moduleName + "Details.pdf"}
                  target="_blank"
                  className="project-document mx-2"
                >
                  <img src={description} alt="view file icon" />
                  {viewFile}
                </a>
              )}
            </div>
            <Card className="card mt-3">
              <div>
                <p className="content p-3">{info[0]?.syllabus ? info[0]?.syllabus : "no Content/Syllabus available..."}</p>
              </div>
            </Card>

          </div>
          <div className="mt-4">
            <h5 className="content-heading">{successCriteria}</h5>
            <Card className="card mt-3">
              <div>
                <p className="content p-3">{info[0]?.successCriteria ? info[0]?.successCriteria: "no Success Criteria available..."}</p>
              </div>
            </Card>
          </div>
        </MainCard>
      </div>
      <div>
        <ModalComponents
          show={showEditModule}
          onHide={hideEditModule}
          title="Edit Module Details"
          modalBody={
            <EditModule
              hideEditModule={hideEditModule}
              moduleDetails={moduleDetails}
              apiCallCompleted={apiCallCompleted}
            />
          }
          className="modal-lg"
        />

        <ModalComponents
          show={showDeleteModule}
          onHide={hideDeleteModule}
          title="Delete Module Details"
          modalBody={
            <DeleteModule
              hideDeleteModule={hideDeleteModule}
              deleteModuleId={deleteModuleId}
              apiCallCompleted={apiCallCompleted}
            />
          }
          className="modal-lg"
        />
      </div>
    </>
  );
};

export default SpecificModule;
