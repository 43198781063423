import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../Redux/Actions";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import Select from "react-select";
import "../../../../App.scss";
import ModalComponents from "../../../../component/ModalComponent/ModalComponent";
import ReasonForRejection from "../../../../component/MakerChecker/ReasonForRejection";
import ModalWarning from "../../../../component/MakerChecker/ModalWarning";
import checkMakerCheckerData from "../../../../component/MakerChecker/MakerCheckerData";
import {
  roleDescriptionValidation,
  utilizationValidation,
  poAmountValidation,
  poNumberValidation,
} from "../../../../constant/Validations";
import {
  Strings,
  billingRateDropdown,
  poValueInDropdown,
  projectDetailsLabels,
} from "../../../../constant/Strings";
import { timeDateFormatYMD } from "../../../../component/FormatDate";

const EditResource = (props: any) => {
  const {
    resourceDetails,
    hideEditResource,
    projectData,
    apiCallCompleted,
    getApiCall,
  } = props;
  const role = localStorage.getItem("role");
  const isCheckedData = role?.includes("resource-checker");
  const [btnDisable, setBtnDisable] = useState(false);
  const id = resourceDetails?._id;
  const [resourceData, setResourceData] = useState({
    employeeRef: resourceDetails.employeeRef,
    managerRef: resourceDetails.managerRef,
    resourceName: resourceDetails.resourceName,
    projectName: resourceDetails.projectName,
    reportingManager:
    resourceDetails?.resource[0]?.resourceManagerData?.length ? resourceDetails?.resource[0]?.resourceManagerData[0]?.candidateName +
      " " +
      resourceDetails?.resource[0]?.resourceManagerData[0]?.middleName +
      " " +
      resourceDetails?.resource[0]?.resourceManagerData[0]?.lastname : "-",
    roleDescription: resourceDetails.roleDescription,
    utilization: resourceDetails.utilization,
    remarks: resourceDetails.remarks,
    noOfDays: resourceDetails.noOfDays,
    startDate: resourceDetails.startDate,
    endDate: resourceDetails.endDate,
    poStartDate: resourceDetails.poStartDate,
    poEndDate: resourceDetails.poEndDate,
    poNumber: resourceDetails.poNumber,
    currencyType: resourceDetails.currencyType,
    billingRate: resourceDetails.billingRate,
    poAmount: resourceDetails.poAmount,
    recordStatus: "Updated",
  });
  const [employeeInfo, setEmployeeInfo] = useState([]);
  const [errors, setErrors] = useState({
    roleDescription: "",
    utilization: "",
    poAmount: "",
    poNumber: "",
  });
  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (roleDescriptionValidation(resourceData.roleDescription) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        roleDescription: "Please enter valid role description",
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        roleDescription: "",
      }));
    }
    if (resourceData.utilization) {
      if (utilizationValidation(resourceData.utilization) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          utilization: "Please enter valid utilization",
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          utilization: "",
        }));
      }
    }
    // if (resourceData.poAmount) {
    //   if (poAmountValidation(resourceData.poAmount) == false) {
    //     valid = false;
    //     setErrors((errorObj) => ({
    //       ...errorObj,
    //       poAmount: "Please enter valid role poAmount",
    //     }));
    //     setBtnDisable(false);
    //   } else {
    //     setErrors((errorObj) => ({
    //       ...errorObj,
    //       poAmount: "",
    //     }));
    //   }
    // }
    if (resourceData.poNumber) {
      if (poNumberValidation(resourceData.poNumber) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          poNumber: "Please enter valid poNumber",
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          poNumber: "",
        }));
      }
    }
  };

  const employeeData = useSelector(
    (state: any) => state.employeeReducer.getEmployeeData
  );
  useEffect(() => {
    if (employeeData && employeeData.data) {
      setEmployeeInfo(employeeData.data);
    }
  }, [employeeData, employeeInfo]);
  useEffect(() => {

    if (
      (resourceData.resourceName ||
        resourceDetails?.resource[0]?.candidateName +
          " " +
          resourceDetails?.resource[0]?.middleName +
          " " +
          resourceDetails?.resource[0]?.lastname !=
          "") &&
      (resourceData.reportingManager ||
        resourceDetails?.manager[0]?.candidateName +
          " " +
          resourceDetails?.manager[0]?.middleName +
          " " +
          resourceDetails?.manager[0]?.lastname !=
          "") &&
      resourceData.roleDescription &&
      resourceData.startDate
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [btnDisable, resourceData]);
  const {
    start_date,
    estimated_end_date,
    resource_name,
    reporting_manager,
    role_desc,
    utilization,
    cancel,
    submit,
    approve,
    reject,
  } = Strings;
  const {
    purchaseOrderNoLabel,
    poStartDateLabel,
    poEndDateLabel,
    approvedHcLabel,
    currencyTypeLabel,
    billingRateLabel,
    billingStatusLabel,
    remarksLabel,
  } = projectDetailsLabels;
  const [showReason, setShowReason] = useState(false);
  const [showReasonForRejection, setShowReasonForRejection] = useState(false);
  const handleRejectModal = () => {
    setShowReasonForRejection(true);
  };
  const hideRejectModal = () => {
    setShowReasonForRejection(false);
  };
  const [modalWarning, setModalWarning] = useState("");
  const handleApproval = (text: any) => {
    setModalWarning(text);
    setShowReason(true);
  };
  const hideModal = () => {
    setShowReason(false);
  };

  const handleDispatch = () => {
    const userLogin: any = localStorage.getItem("login");
    const loginId = JSON.parse(userLogin)?.id;
    const payload ={
      ...resourceData,
      updatedBy:loginId
    }
    apiCallCompleted(false);
    dispatch(
      actions.editResourceAllocationAction(id, payload, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res.error.error_description);
          apiCallCompleted(true);
        } else {
          if (res?.code === 200) {
            showSuccessToastMessage("Edited successfully");
            getApiCall();
            apiCallCompleted(true);
          }
        }
      })
    );
    hideEditResource();
  };

  const handleResourceEditSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  const options: any = employeeInfo.map((ele: any) => ({
    value:
      ele.candidateName +
      " " +
      ele.middleName +
      " " +
      ele.lastname +
      "-" +
      ele.employeeId +
      "/" +
      ele._id,
    label:
      ele.candidateName +
      " " +
      ele.middleName +
      " " +
      ele.lastname +
      "-" +
      ele.employeeId,
      managerData:ele?.managerData?.[0]
  }));
  const handleChange = (selected: any) => {
    const manager = selected?.managerData ? `${selected?.managerData?.candidateName ?? ""} ${ selected?.managerData?.middleName ?? ""
            } ${selected?.managerData?.lastname ?? ""} - ${selected?.managerData?.employeeId ?? ""}`: "-";
    setResourceData({
      ...resourceData,
      employeeRef: selected.value.split("/")[1],
      resourceName: selected.value.split("/")[0],
      reportingManager:manager 
    });
  };


  return (
    <div className="row g-3">
      <div className="col-md-4">
        <label htmlFor="inputSkills" className="form-label">
          {resource_name}
        </label>
        <span className="required">*</span>
        <Select
          className="dropdown-select"
          placeholder={
            resourceDetails?.resource[0]?.candidateName +
            " " +
            resourceDetails?.resource[0]?.middleName +
            " " +
            resourceDetails?.resource[0]?.lastname
          }
          options={options}
          isSearchable={true}
          onChange={handleChange}
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputSkills" className="form-label">
          {start_date}
        </label>
        <span className="required">*</span>
        <input
          max={moment(resourceData.endDate)
            .subtract(1, "day")
            .format("YYYY-MM-DD")}
          type="date"
          className="form-control"
          value={timeDateFormatYMD(resourceData.startDate)}
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              startDate: e.target.value,
            })
          }
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputSkills" className="form-label">
          {estimated_end_date}
        </label>
        <input
          type="date"
          min={moment(resourceData.startDate)
            .add(1, "day")
            .format("YYYY-MM-DD")}
          className="form-control"
          value={timeDateFormatYMD(resourceData.endDate)}
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              endDate: e.target.value,
            })
          }
        />
      </div>
      <div className="col-md-4 mt-3">
        <label htmlFor="inputSkills" className="form-label">
          {reporting_manager}
        </label>
        <span className="required">*</span>
        {/* <Select
          className="dropdown-select"
          placeholder={
            resourceDetails?.manager[0]?.candidateName +
            " " +
            resourceDetails?.manager[0]?.middleName +
            " " +
            resourceDetails?.manager[0]?.lastname
          }
          options={managerOptions}
          isSearchable={true}
          onChange={handleReportingManager}
        /> */}
        <input
          type="text"
          className="form-control"
          disabled={true}
          value={resourceData?.reportingManager}
        />
      </div>
      <div className="col-md-4 mt-3">
        <label htmlFor="inputSkills" className="form-label">
          {role_desc}
        </label>
        <span className="required">*</span>
        <input
          type="text"
          className="form-control"
          value={resourceData.roleDescription}
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              roleDescription: e.target.value,
            })
          }
        />
        <p className="error">{errors.roleDescription}</p>
      </div>
      <div className="col-md-4 mt-3">
        <label htmlFor="inputSkills" className="form-label">
          {utilization}
        </label>
        {/* <span className="required">*</span> */}
        <input
          type="text"
          className="form-control"
          value={resourceData.utilization}
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              utilization: e.target.value.replace(/\D/g, ""),
            })
          }
        />
        <p className="error">{errors.utilization}</p>
      </div>
      <div className="col-md-4">
        <label htmlFor="inputSkills" className="form-label">
          {purchaseOrderNoLabel}
        </label>
        {/* <span className="required">*</span> */}
        <input
          type="text"
          className="form-control"
          value={resourceData.poNumber ? resourceData.poNumber : ""}
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              poNumber: e.target.value,
            })
          }
        />
        <p className="error">{errors.poNumber}</p>
      </div>
      <div className="col-md-4">
        <label htmlFor="inputSkills" className="form-label">
          {poStartDateLabel}
        </label>
        {/* <span className="required">*</span> */}
        <input
          type="date"
          max={moment(resourceData.poEndDate)
            .subtract(1, "day")
            .format("YYYY-MM-DD")}
          className="form-control"
          value={timeDateFormatYMD(resourceData.poStartDate)}
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              poStartDate: e.target.value,
            })
          }
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputSkills" className="form-label">
          {poEndDateLabel}
        </label>
        {/* <span className="required">*</span> */}
        <input
          type="date"
          min={moment(resourceData.poStartDate)
            .add(1, "day")
            .format("YYYY-MM-DD")}
          className="form-control"
          value={timeDateFormatYMD(resourceData.poEndDate)}
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              poEndDate: e.target.value,
            })
          }
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputSkills" className="form-label">
          {currencyTypeLabel}
        </label>
        <Dropdown
          onSelect={(eventKey: any) =>
            setResourceData({
              ...resourceData,
              currencyType: eventKey,
            })
          }
        >
          <Dropdown.Toggle variant="light" className="form-control">
            {resourceData.currencyType ? resourceData.currencyType : ""}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {poValueInDropdown.map((value: any) => (
              <Dropdown.Item eventKey={value}>{value}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="col-md-4">
        <label htmlFor="inputSkills" className="form-label">
          {billingStatusLabel}
        </label>
        {/* <span className="required">*</span> */}
        <Dropdown
          onSelect={(eventKey: any) =>
            setResourceData({
              ...resourceData,
              billingRate: eventKey,
            })
          }
        >
          <Dropdown.Toggle variant="light" className="form-control">
            {resourceData.billingRate}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {billingRateDropdown.map((value: any) => (
              <Dropdown.Item eventKey={value}>{value}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="col-md-4">
        <label htmlFor="inputSkills" className="form-label">
          {billingRateLabel}
        </label>
        <input
          type="text"
          className="form-control"
          value={resourceData.poAmount ? resourceData.poAmount : ""}
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              poAmount: e.target.value,
            })
          }
        />
        <p className="error">{errors.poAmount}</p>
      </div>
      <div className="col-md-4 mt-3">
        <label htmlFor="inputSkills" className="form-label">
          {remarksLabel}
        </label>
        <input
          type="text"
          className="form-control"
          value={resourceData.remarks}
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              remarks: e.target.value,
            })
          }
        />
      </div>
      <div className="row mt-5">
        {isCheckedData == false ? (
          <></>
        ) : resourceDetails.recordStatus == "Approved" ||
          resourceDetails.recordStatus == "Rejected" ? (
          <></>
        ) : (
          <>
            <div className="modal-btn-wrapper mt-2 mx-auto">
              <button
                type="button"
                className="btn btn-success buttonsize"
                onClick={() => {
                  handleApproval("Approve");
                }}
              >
                {approve}
              </button>
              <button
                type="button"
                className="btn btn-danger buttonsize"
                onClick={() => {
                  handleRejectModal();
                }}
              >
                {reject}
              </button>
            </div>
          </>
        )}
        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideEditResource}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleResourceEditSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>

      <ModalComponents
        show={showReason}
        onHide={hideModal}
        title=""
        modalBody={
          <ModalWarning
            apiCallCompleted={apiCallCompleted}
            hideModal={hideModal}
            header={modalWarning}
            id={id}
            editApi={actions.editResourceAllocationAction}
            data={{ recordStatus: modalWarning + "d" }}
            hideEditModal={hideEditResource}
            setShowUndoPopup={() => {}}
            getApiCall={getApiCall}
          />
        }
        className="modal-md"
      />
      <ModalComponents
        show={showReasonForRejection}
        onHide={hideRejectModal}
        title="Reason For Rejection"
        modalBody={
          <ReasonForRejection
            apiCallCompleted={apiCallCompleted}
            hideModal={hideRejectModal}
            id={id}
            editApi={actions.editResourceAllocationAction}
            statusKey="recordStatus"
            hideEditModal={hideEditResource}
            setShowUndoPopup={() => {}}
          />
        }
        className="modal-md"
      />
    </div>
  );
};

export default EditResource;
