import React, { useEffect, useState } from "react";
import * as actions from "../../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import {
  Strings,
  yearsOfExperienceDropdown,
} from "../../../../constant/Strings";
import { PreviousExpValidation } from "../../../../constant/Validations";

const EditOrgDetails = (props: any) => {
  const {
    level,
    band,
    yoe,
    handleEditOrgDetails,
    eId,
    status,
    apiCallCompleted,
    dateOfJoining,
    totalExperience,
    previousExperience,
    organisationExperience,
  } = props;
  const {
    cancel,
    submit,
    levelLabel,
    bandLabel,
    years_of_experience,
    editSuccess,
  } = Strings;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");

  const calculateExperience = (joiningDate: any) => {
    const currentDate = new Date();
    const joinDate = new Date(joiningDate);

    let years = currentDate.getFullYear() - joinDate.getFullYear();
    let months = currentDate.getMonth() - joinDate.getMonth();
    if (months < 0) {
      years -= 1;
      months += 12;
    } else if (months === 0 && currentDate.getDate() < joinDate.getDate()) {
      years -= 1;
      months = 11;
    }

    const experience = years + parseFloat((months / 12).toFixed(1));
    return experience;
  };
  const dispatch = useDispatch();
  const skillmineExp = calculateExperience(dateOfJoining);
  const [employeeData, setEmployeeData] = useState({
    level: level,
    band: band,
    eId: eId,
    updatedBy: eId,
    previousExperience: previousExperience,
    organisationExperience: organisationExperience ?? skillmineExp,
    totalExperience: totalExperience,
  });
  const [errors, setErrors] = useState({
    previousExp: "",
  });

  let valid: boolean = true;
  const validate = () => {
    if (employeeData.previousExperience) {
      if (employeeData.previousExperience == "") {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          previousExp: "Experience Cannot be Blank",
        }));
      } else {
        setErrors((errorObj) => ({ ...errorObj, previousExp: "" }));
      }
    }
  };
  const handleEdit = () => {
      handleDispatch();
  };
  
  const handleDispatch = () => {
    apiCallCompleted(false);
    const payload = {
      ...employeeData,
    };
    dispatch(
      actions.editEmployeeDataAction(payload, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            }
          } else {
            if (res.code === 200) {
              showSuccessToastMessage(editSuccess);
              apiCallCompleted(true);
            }
          }
        }
      })
    );
    handleEditOrgDetails();
  };

  const disableCondition: any =
    (!(role && role.includes("hr")) && status == "Active") ||
    status == "Inactive" ||
    (role && role.includes("hr") && status == "Active" && eId == loginId);

  return (
    <div>
      <div className="row g-4">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {levelLabel}
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.level}
            disabled={disableCondition}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                level: e.target.value,
              });
            }}
          />
        </div>

        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {bandLabel}
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.band}
            disabled={disableCondition}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                band: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            Previous Experience
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            defaultValue={
              employeeData.previousExperience == undefined ||
              employeeData.previousExperience == "" ||
              employeeData.previousExperience == null
                ? ""
                : String(employeeData.previousExperience)
            }
            disabled={true}
          />
          <p className="error">{errors.previousExp}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            Skillmine Experience
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.organisationExperience}
            disabled={true}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                organisationExperience: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            Total Experience
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.totalExperience}
            disabled={true}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                totalExperience: e.target.value,
              });
            }}
          />
        </div>
      </div>

      <div className="modal-btn-wrapper mt-4 mb-4">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={handleEditOrgDetails}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className="btn add-submit-button buttonsize"
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditOrgDetails;
