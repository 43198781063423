import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import LearningDevelopmentTable from "./LearningDevelopmentData/LearningDevelopmentTable";
import MainCard from "../../component/MainCard/MainCard";
import { showErrorToastMessage } from "../../component/Toasts/Toasts";
import { useNavigate } from "react-router-dom";
import { Strings } from "../../constant/Strings";

const LearningDevelopment = () => {
  const [hrCheckerList, setHrCheckerList] = useState(false);

  const navigate = useNavigate();

  const role = localStorage.getItem("role");
  const checkMasterList = role?.includes("learninganddevelopment-checker");
  const { inValidModuleAccess } = Strings;

  useEffect(() => {
    if (checkMasterList) {
      setHrCheckerList(true);
    } else {
      setHrCheckerList(false);
    }
    if (checkMasterList == undefined || !checkMasterList) {
      showErrorToastMessage(inValidModuleAccess);
      navigate("/profile");
    }
  }, [checkMasterList]);

  return (
    <>
      <MainCard>
        <>{hrCheckerList && <LearningDevelopmentTable />}</>
      </MainCard>
      <ToastContainer />
    </>
  );
};

export default LearningDevelopment;
