import React, { useState } from "react";
import Card from "../../../component/Card/Card";
import { Strings } from "../../../constant/Strings";
import { icons } from "../../../assets";
import "./EmployeeIdentification.scss";
import moment from "moment";
import EditButton from "../../../component/Buttons/EditButton/EditButton";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import EditOrgDetails from "./EditOrgDetails/EditOrgDetails";
import EditWorkDetails from "./EditWorkDetails/EditWorkDetails";
import EditBasicDetails from "./EditBasicDetails/EditBasicDetails";
import useLoadingIndicator from "../../../component/Loader/Loader";

const EmployeeIdentification = (props: any) => {
  const {
    currentLocation,
    eCode,
    dateOfJoining,
    designation,
    department,
    level,
    band,
    experience,
    clientName,
    workLocation,
    reportingManagerName,
    hrbpName,
    eId,
    status,
    hrbpRef,
    organisationExperience,
    previousExperience,
    totalExperience,
  } = props;

  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const [editBasicDetails, setEditBasicDetails] = useState(false);
  const [editOrgInfo, setEditOrgInfo] = useState(false);
  const [editWorkDetails, setEditWorkDetails] = useState(false);
  const { employee_identification_heading } = Strings;
  const { edit_orange_icon } = icons;
  const handleEditBasicDetails = () => {
    setEditBasicDetails(!editBasicDetails);
  };

  const handleEditOrgDetails = () => {
    setEditOrgInfo(!editOrgInfo);
  };

  const handleEditWorkDetails = () => {
    setEditWorkDetails(!editWorkDetails);
  };

  return (
    <>
      {loadingComponent()}
      <div>
        <h5>{employee_identification_heading}</h5>
        <Card>
          <div className="edit-icon-wrapper">
            <h5>Basic Details</h5>
            <EditButton
              title="Edit"
              icon={edit_orange_icon}
              onClick={handleEditBasicDetails}
            />
          </div>

          <div className="edit-icon-wrapper">
            <div className="profile-details">
              <span>E-code</span> <p>{eCode}</p>
            </div>
            <div className="profile-details">
              <span>Date Of Joining</span> <p>{dateOfJoining}</p>
            </div>
            <div className="profile-details">
              <span>Department</span> <p>{department}</p>
            </div>
            <div className="profile-details">
              <span>Designation/Role</span> <p>{designation}</p>
            </div>
          </div>
        </Card>

        <Card>
          <div className="edit-icon-wrapper">
            <h5>Organizational Information</h5>
            <EditButton
              title="Edit"
              icon={edit_orange_icon}
              onClick={handleEditOrgDetails}
            />
          </div>

          <div className="edit-icon-wrapper">
            <div className="profile-details">
              <span>Level</span> <p>{level}</p>
            </div>
            <div className="profile-details">
              <span>Band</span> <p>{band}</p>
            </div>
            <div className="profile-details">
              <span>Previous Experience(s)</span> <p>{previousExperience}</p>
            </div>
            <div className="profile-details">
              <span>Skillmine Experience(s)</span>{" "}
              <p>{organisationExperience}</p>
            </div>
            <div className="profile-details">
              <span>Total Experience(s)</span> <p>{totalExperience}</p>
            </div>
          </div>
        </Card>

        <Card>
          <div className="edit-icon-wrapper">
            <h5>Work Details</h5>
            <EditButton
              title="Edit"
              icon={edit_orange_icon}
              onClick={handleEditWorkDetails}
            />
          </div>

          <div className="edit-icon-wrapper">
            <div className="profile-details">
              <span>Client Name</span> <p>{clientName}</p>
            </div>
            <div className="profile-details">
              <span>Work Location</span> <p>{workLocation}</p>
            </div>
            <div className="profile-details">
              <span>Current Location</span> <p>{currentLocation}</p>
            </div>
            <div className="profile-details">
              <span>Reporting Manager Name</span> <p>{reportingManagerName}</p>
            </div>
            <div className="profile-details">
              <span>HRBP Name</span> <p>{hrbpName}</p>
            </div>
          </div>
        </Card>
      </div>

      <ModalComponents
        show={editOrgInfo}
        onHide={handleEditOrgDetails}
        title="Edit Organisation Details"
        modalBody={
          <EditOrgDetails
            apiCallCompleted={apiCallCompleted}
            level={level}
            band={band}
            yoe={experience}
            handleEditOrgDetails={handleEditOrgDetails}
            eId={eId}
            status={status}
            dateOfJoining={dateOfJoining}
            totalExperience={totalExperience}
            previousExperience={previousExperience}
            organisationExperience={organisationExperience}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={editWorkDetails}
        onHide={handleEditWorkDetails}
        title="Edit Work Details"
        modalBody={
          <EditWorkDetails
            apiCallCompleted={apiCallCompleted}
            clientName={clientName}
            workLocation={workLocation}
            reportingManagerName={reportingManagerName}
            handleEditWorkDetails={handleEditWorkDetails}
            eId={eId}
            hrbpName={hrbpName}
            hrbpRef={hrbpRef}
            status={status}
            currentLocation={currentLocation}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={editBasicDetails}
        onHide={handleEditBasicDetails}
        title="Edit Basic Details"
        modalBody={
          <EditBasicDetails
            apiCallCompleted={apiCallCompleted}
            eCode={eCode}
            dateOfJoining={moment(dateOfJoining).format("YYYY-MM-DD")}
            department={department}
            designation={designation}
            handleEditBasicDetails={handleEditBasicDetails}
            eId={eId}
          />
        }
        className="modal-lg"
      />
    </>
  );
};

export default EmployeeIdentification;
