import React from "react";
import { Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import "../DbSideBarPages.scss";
import { icons } from "../../../../../assets";
import Button from "../../../../../component/Buttons/Button";
import ModalComponents from "../../../../../component/ModalComponent/ModalComponent";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actions from "../../../../../Redux/Actions";
import back from "../../../../../assets/backward.png";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../../component/Toasts/Toasts";
import { IoIosArrowBack } from "react-icons/io";
import {} from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { MdOutlineNavigateNext } from "react-icons/md";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { ToastContainer } from "react-toastify";
import useLoadingIndicator from "../../../../../component/Loader/Loader";
import AddSkills from "./Addskills";
import EditSkills from "./EditSkills";
import DeleteSkills from "./DeleteSkills";

function Skills() {
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const selectedId = localStorage.getItem("selectedId");
  const status = localStorage.getItem("employeeStatus");

  const dispatch = useDispatch();
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const [info, setInfo] = useState([]);
  const [skills, setSkills] = useState({
    id: "",
    skillName: "",
    description: "",
    proficiencylevel: "",
  });
  const [deleteId, setDeleteId] = useState("");
  const [isModalDone, setIsModalDone] = useState(false);
  const setModalFinished = () => {
    setIsModalDone(true);
  };

  const [showAddSkill, setShowAddSkill] = useState(false);
  const handleAddSkill = () => setShowAddSkill(true);
  const hideAddSkill = () => setShowAddSkill(false);

  const [showEditSkill, setShowEditSkill] = useState(false);
  const handleEditSkill = (
    id: string,
    skillName: any,
    description: any,
    proficiencylevel: any
  ) => {
    setSkills({
      id: id,
      skillName: skillName,
      description: description,
      proficiencylevel: proficiencylevel,
    });
    setShowEditSkill(true);
  };
  const hideEditSkill = () => setShowEditSkill(false);

  const [showDeleteSkill, setShowDeleteSkill] = useState(false);

  const handleDeleteSkill = (id: any) => {
    setDeleteId(id);
    setShowDeleteSkill(true);
  };
  const hideDeleteSkill = () => {
    setShowDeleteSkill(false);
  };
  const addEmployeeSkills = useSelector(
    (state: any) => state.employeeReducer.addEmployeeSkills
  );

  const deleteEmployeeSkills = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeeSkills
  );

  const editEmployeeSkills = useSelector(
    (state: any) => state.employeeReducer.editEmployeeSkills
  );

  useEffect(() => {
    showLoadingIndicator();
    dispatch(actions.getEmployeeSkillsAction());
    setTimeout(() => {
      hideLoadingIndicator();
    }, 1000);
  }, [addEmployeeSkills, deleteEmployeeSkills, editEmployeeSkills]);

  const employeeSkills = useSelector(
    (state: any) => state.employeeReducer.getEmployeeSkills
  );

  useEffect(() => {
    if (employeeSkills) {
      if (employeeSkills.data) {
        setInfo(employeeSkills.data);
      }
    }
  }, [employeeSkills, info]);
  let navigate = useNavigate();

  return (
    <>
      {loadingComponent()}
      <div className="right__container">
        <div className="title">
          <h6>Skills</h6>
          {(status !== "Active" && status !== "Inactive") ||
          (role === "hr" && status === "Active" && selectedId !== loginId) ? (
            <>
              <div className="btn-wrapper">
                <Button
                  icon={icons.add_icon}
                  title="Add"
                  onClick={handleAddSkill}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <Table hover striped>
          <thead className="employeedb">
            <tr className="table-head table-head-row">
              <td>Skill Name</td>
              <td>Years Of Experience</td>
              <td>Proficiency Level</td>
              {(status !== "Active" && status !== "Inactive") ||
              (role === "hr" &&
                status === "Active" &&
                selectedId != loginId) ? (
                <>
                  <td>Action</td>
                </>
              ) : (
                <></>
              )}
            </tr>
          </thead>
          <tbody className="employeedata">
            {info.length > 0 &&
              info.map((ele: any) => (
                <tr key={ele.id} style={{ fontSize: "11px" }}>
                  <td>{ele.skillName}</td>
                  <td>{ele.description}</td>
                  <td>{ele.proficiencylevel}</td>
                  {(status !== "Active" && status !== "Inactive") ||
                  (role === "hr" &&
                    status === "Active" &&
                    selectedId != loginId) ? (
                    <>
                      <td>
                        <div className="action-btn">
                          <img
                            src={icons.edit_icon}
                            alt="edit_icon"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit"
                            onClick={() =>
                              handleEditSkill(
                                ele.id,
                                ele.skillName,
                                ele.description,
                                ele.proficiencylevel
                              )
                            }
                            className="edit-icon"
                          />
                          <img
                            src={icons.delete_icon}
                            alt="delete-icon"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                            onClick={() => {
                              handleDeleteSkill(ele.id);
                            }}
                            className="edit-icon"
                          />
                        </div>
                      </td>
                    </>
                  ) : (
                    <></>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
        <div>
          <ModalComponents
            show={showAddSkill}
            onHide={hideAddSkill}
            title="Add Skill"
            modalBody={
              <AddSkills
                showAddSkill={handleAddSkill}
                hideAddSkill={hideAddSkill}
                setModalFinished={setModalFinished}
              />
            }
            className="modal-lg"
          />

          <ModalComponents
            show={showEditSkill}
            onHide={hideEditSkill}
            title="Edit Skill"
            modalBody={
              <EditSkills
                id={skills.id}
                skillName={skills.skillName}
                descrption={skills.description}
                proficiencyLevel={skills.proficiencylevel}
                showEditSkill={handleEditSkill}
                hideEditSkill={hideEditSkill}
                setModalFinished={setModalFinished}
              />
            }
            className="modal-lg"
          />

          <ModalComponents
            show={showDeleteSkill}
            onHide={hideDeleteSkill}
            title="Delete Skill"
            modalBody={
              <DeleteSkills
                id={deleteId}
                showDeleteSkill={handleDeleteSkill}
                hideDeleteSkill={hideDeleteSkill}
                setModalFinished={setModalFinished}
              />
            }
            className="modal-sm"
          />
        </div>
        <div className="row">
          <div className="col-10">
            <button
              className="btn btn-primary btn-next-pre"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Previous"
              onClick={() => navigate("/dbsidebar/work")}
            >
              <img src={back} alt="icon" />
              Back
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Skills;
