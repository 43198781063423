import React from 'react'
import { icons } from '../../../../../assets'

interface CardProps {
    companyName: string,
    designation: string,
    location: string,
    role: string,
    startDate: any,
    endDate: any,
    onClick_edit: any,
    onClick_delete: any
}

const Card: React.FC<CardProps> = (({ companyName, designation, location, role, startDate, endDate,
    onClick_edit, onClick_delete }: CardProps) => (
    <div className='d-inline-block '>
        <div className='experience-card' key={companyName}>
            <div className='row'>
                <div className='col-8'>
                    <strong>Company Name:</strong> {`${companyName?.slice(0, 14)}...`}
                </div>
                <div className='col-4'>
                    <div className="action-btn">
                        <img
                            src={icons.edit_icon}
                            alt="edit_icon"
                            data-toggle="tooltip" data-placement="top" title="Edit"
                            onClick={onClick_edit}
                            className="edit-icon"
                        />
                        <img
                            src={icons.delete_icon}
                            alt="delete-icon"
                            data-toggle="tooltip" data-placement="top" title="Delete"
                            onClick={onClick_delete}
                            className="edit-icon"
                        />
                    </div>
                </div>
                <div><strong>Designation: </strong>{`${designation?.slice(0, 14)}...`}</div>
                <div><strong>Location: </strong>{`${location?.slice(0, 14)}...`}</div>
                <div><strong>Roles & Responsibility: </strong>{`${role?.slice(0, 14)}...`}</div>
                <div><strong>Start Date: </strong>{startDate}</div>
                <div><strong>End Date: </strong>{endDate}</div>
            </div>
        </div>
    </div>
))
export default Card