import { useEffect, useMemo, useRef, useState } from "react";
import { TiArrowUnsorted } from "react-icons/ti";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import SearchBar from "../../../component/SearchBar/SearchBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import useLoadingIndicator from "../../../component/Loader/Loader";
import {
  benchManagementFilters,
  benchManagementAllRecordsFilters,
  Strings,
} from "../../../constant/Strings";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import AssignProject from "./AssignProject";
import * as actions from "../../../Redux/Actions";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import * as XLSX from "xlsx";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { icons } from "../../../assets/index";
import "./BenchMgmt.scss";
import TablePagination from "../../../component/TablePagination/TablePagination";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import MainCard from "../../../component/MainCard/MainCard";
import "../ResourceManagement.scss";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";
import { timeDateFormat } from "../../../component/FormatDate";
interface RowData {
  endDate: string;
  endDateColor: "red" | "amber" | "green";
  poColorCode: "red" | "amber" | "green";
}

const BenchMgmtTable = () => {
  const [queryParameters] = useSearchParams();
  const querySearchKey = queryParameters.get("benchSearchKey");
  const querySearchValue = queryParameters.get("benchSearchValue");
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    apiCallCompleted,
  } = useLoadingIndicator();
  const [showExportReport, setShowExportReport] = useState(false);
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const [pageSize, setPageSize] = useState(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  const role = localStorage.getItem("role");
  const makerData = role?.includes("rmg-bench");
  const { hamburger_icon, export_report } = icons;
  const { exportReport, no_data_found, filters, bench_management } = Strings;
  const [totalRecords, setTotalRecords] = useState(0);
  const [statusObj, setStatusObj] = useState({ status: "" });
  const [info, setInfo] = useState([]);

  const addResource = useSelector(
    (state: any) => state.employeeReducer.addResourceAllocationDetails
  );
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const dispatch = useDispatch();
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    unAuthorizeUser();
    
    if (querySearchKey != null && querySearchValue != null) {
      const payload ={
        page,
        size:pageSize,
        searchValue:querySearchValue,
        searchKey:querySearchValue
      }
      dispatch(
        actions.getBenchResourceApi(
          payload,
          (res: any) => {
              if ( res?.error?.error_description) {
                  showErrorToastMessage(res.error.error_description);
              } else if (res?.code === 200) {
                  setTotalPages(res.meta_data.totalPageCount);
                  setTotalEntries(res.meta_data.totalEntries);
                  setInfo(res.data);
                  setTotalRecords(res.data.length);
                  setOnclickSearch(false);
                }
          }
        )
      );
    }else {
      let search =""
      if(filterKey?.includes("Inactive")){
        search = "Inactive"
        
      }else if(filterKey?.includes("within30Days")){
        search = "within30Days"
      } if(filterKey?.includes("within60Days")){
        search = "within60Days"
      } if(filterKey?.includes("within90Days")){
        search = "within90Days"
      }
      const payload ={
        page:  page,
        size:pageSize,
        searchValue:   searchValue == "" ? search : searchValue,
        searchKey:filterKey
      }
      dispatch(
        actions.getBenchResourceApi(
          payload,
          (res: any) => {
            if (res) {
              if (res?.error?.error_description) {
                  showErrorToastMessage(res.error.error_description);
              }else if ( res?.code === 200) {
                  setTotalPages(res.meta_data.totalPageCount);
                  setTotalEntries(res.meta_data.totalEntries);
                  setInfo(res.data);
                  setTotalRecords(res.data.length);
                  setOnclickSearch(false);
                }
              }
            }
        )
      );
    }
  }, [
    addResource,
    statusObj,
    page,
    searchValue,
    filterValue,
    pageSize,
    condition,
  ]);

  const [employeeProjectDetails, setEmployeeProjectDetails] = useState({
    employeeRef: "",
    client: "",
    projectName: "",
    resourceName: "",
    managerName:""
  });
  const [showAddResource, setShowAddResource] = useState(false);
  const handleAddResource = (
    employeeRef: any,
    client: any,
    projectName: any,
    resourceName: any,
    managerName:any
  ) => {
    setEmployeeProjectDetails({
      employeeRef: employeeRef,
      client: client,
      projectName: projectName,
      resourceName: resourceName,
      managerName:managerName
    });
    setShowAddResource(true);
  };
  const hideAddResource = () => {
    setShowAddResource(false);
  };
  const navigate = useNavigate();

  const handleProjectDetails = (employeeRef: any, resourceName: any) => {
    dispatch(
      actions.getProjectByEmployeeIdAction(
        employeeRef,
        page,
        10,
        (res: any) => { }
      )
    );
    navigate(`/employee-projectdetails/${employeeRef}`);
    localStorage.setItem("resourceName", resourceName);
  };

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const ResourceAddingColumns: any = [
    {
      Header: "Employee Name",
      accessor: "resourceName",
      Cell: (Action: any) => {
        const name =
          (Action.row.original?.candidateName
            ? Action.row.original?.candidateName
            : "") +
          " " +
          (Action.row.original?.middleName
            ? Action.row.original?.middleName
            : "") +
          " " +
          (Action.row.original?.lastname
            ? Action.row.original?.lastname
            : "");
        const employeeRef = Action.row.original?._id;

        return (
          <div
            className="hyperlink"
            onClick={() => handleProjectDetails(employeeRef, name)}
          >
            {name}
          </div>
        );
      },
    },
    {
      Header: "E-Code",
      accessor: "resource[0]?.employeeId",
      Cell: (Action: any) => {
        const name = Action.row.original?.employeeId;
        return name;
      },
    },
    {
      Header: "Date of Joining",
      accessor: "startDate",
      Cell: (Action: any) => {
        const startDate = Action.row.original?.dateOfJoining;
        return timeDateFormat(startDate);
      },
    },
    {
      Header: "Last Project",
      accessor: "resourceAllocation[0].Project[0].projectName",
    },
    {
      Header: "Estimated End Date",
      accessor: "endDate",
      Cell: (Action: any) => {
        const endDate = Action.row.original?.resourceAllocation?.[0]?.endDate;
        // const textColor =
        //   Action.row.original?.endDateColor == "red"
        //     ? "text-danger"
        //     : Action.row.original?.endDateColor == "amber"
        //     ? "text-warning"
        //     : Action.row.original?.endDateColor == "green"
        //     ? "projectRepo-approved-btn"
        //     : "";
        return (
          <div>
            {endDate ? timeDateFormat(endDate) : ""}
          </div>
        );
      },
    },
    {
      Header: "PO End Date",
      accessor: "poEndDate",
      Cell: (Action: any) => {
        const endDate = Action.row.original?.resourceAllocation?.[0]?.poEndDate;
        // const textColor =
        //   Action.row.original?.poColorCode == "red"
        //     ? "text-danger"
        //     : Action.row.original?.poColorCode == "amber"
        //     ? "text-warning"
        //     : Action.row.original?.poColorCode == "green"
        //     ? " projectRepo-approved-btn"
        //     : "";
        return (
          <div>
            {endDate ? timeDateFormat(endDate) : ""}
          </div>
        );
      },
    },
    {
      Header: "Skills",
      accessor: "resourceAllocation[0].roleDescription",
    },
    {
      Header: "Reporting Manager",
      accessor: "reportingManager",
      Cell: (Action: any) => {
        const managerName =Action.row.original?.manager?.length ?
        Action.row.original?.manager[0]?.candidateName +
        " " +
        Action.row.original?.manager[0]?.middleName +
        " " +
        Action.row.original?.manager[0]?.lastname : "";
        return managerName;
      },
    },
    {
      Header: "Manager E-Code",
      accessor: "manager[0]?.employeeId",
      Cell: (Action: any) => {
        const name = Action.row.original?.manager?.length ?
        Action.row.original?.manager[0]?.employeeId :"";
        return name;
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (Action: any) => {
        const employeeRef = Action.row.original?._id;
        const client ="";
        const projectName = Action.row.original.resourceAllocation?.[0]?.Project?.[0]?.projectName;
        const status = Action.row.original?.status;
        const resourceName =
          Action.row.original?.candidateName +
          " " +
          Action.row.original?.middleName +
          " " +
          Action.row.original?.lastname +
          "-" +
          Action.row.original?.employeeId;
        const managerName = Action.row.original?.manager?.length ? Action.row.original?.manager?.[0]?.candidateName +
        " " +
        Action.row.original?.manager?.[0]?.middleName +
        " " +
        Action.row.original?.manager?.[0]?.lastname +
        "-" +
        Action.row.original?.manager?.[0]?.employeeId :"-"
        return (
          <>
            {status !== "Inactive" ? (
              <div>
                <a
                  href=""
                  className="assign-link"
                  onClick={(e: any) => {
                    e.preventDefault();
                    handleAddResource(
                      employeeRef,
                      client,
                      projectName,
                      resourceName,
                      managerName
                    );
                  }}
                >
                  + Assign
                </a>
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      Cell: (Action: any) => {
        const remarks = Action.row.original?.resourceAllocation?.[0]?.remarks;
        return <div className="remarks-cell">{remarks ? remarks : "-"}</div>;
      },
    },
  ];
  const checkerColumns: any = [
    {
      Header: "Employee Name",
      accessor: "resourceName",
      Cell: (Action: any) => {
        const name =
        (Action.row.original?.candidateName
          ? Action.row.original?.candidateName
          : "") +
        " " +
        (Action.row.original?.middleName
          ? Action.row.original?.middleName
          : "") +
        " " +
        (Action.row.original?.lastname
          ? Action.row.original?.lastname
          : "");
      const employeeRef = Action.row.original?._id;
        return (
          <div
            className="hyperlink"
            onClick={() => handleProjectDetails(employeeRef, name)}
          >
            {name}
          </div>
        );
      },
    },
    {
      Header: "E-Code",
      accessor: "resource[0]?.employeeId",
      Cell: (Action: any) => {
        const name = Action.row.original?.employeeId;
        return name;
      },
    },
    {
      Header: "Date of Joining",
      accessor: "startDate",
      Cell: (Action: any) => {
        const startDate = Action.row.original?.dateOfJoining;
        return timeDateFormat(startDate);
      },
    },
    {
      Header: "Last Project",
      accessor: "resourceAllocation[0].Project[0].projectName",
    },
    {
      Header: "Estimated End Date",
      accessor: "endDate",
      Cell: (Action: any) => {
        const endDate = Action.row.original?.resourceAllocation?.[0]?.endDate;
        // const cellClass =
        //   Action.row.original?.endDateColor == "red"
        //     ? "danger-cell"
        //     : Action.row.original?.endDateColor == "amber"
        //     ? "warning-cell"
        //     : Action.row.original?.endDateColor == "green"
        //     ? "approved-cell"
        //     : "";
        return (
          <div>
            {endDate ? timeDateFormat(endDate) : ""}
          </div>
        );
      },
    },
    {
      Header: "PO End Date",
      accessor: "poEndDate",
      Cell: (Action: any) => {
        const endDate = Action.row.original?.resourceAllocation?.[0]?.poEndDate;
        // const textColor =
        //   Action.row.original?.poColorCode == "red"
        //     ? "text-danger"
        //     : Action.row.original?.poColorCode == "amber"
        //     ? "text-warning"
        //     : Action.row.original?.poColorCode == "green"
        //     ? " projectRepo-approved-btn"
        //     : "";
        return (
          <div>
            {endDate ? timeDateFormat(endDate) : ""}
          </div>
        );
      },
    },

    {
      Header: "Skills",
      accessor: "resourceAllocation[0].roleDescription",
    },
    {
      Header: "Reporting Manager",
      accessor: "reportingManager",
      Cell: (Action: any) => {
        const managerName =Action.row.original?.manager?.length ?
        Action.row.original?.manager[0]?.candidateName +
        " " +
        Action.row.original?.manager[0]?.middleName +
        " " +
        Action.row.original?.manager[0]?.lastname : "";
        return managerName;
      },
    },
    {
      Header: "Manager E-Code",
      accessor: "manager[0]?.employeeId",
      Cell: (Action: any) => {
        const name = Action.row.original?.manager?.length ?
        Action.row.original?.manager[0]?.employeeId :"";
        return name;
      },
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      Cell: (Action: any) => {
        const remarks = Action.row.original?.resourceAllocation?.[0]?.remarks;
        return <div className="remarks-cell">{remarks ? remarks : "-"}</div>;
      },
    },
  ];

  const data = info;
  const columns = useMemo(
    () => (makerData == true ? ResourceAddingColumns : checkerColumns),
    [makerData]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy
    );
  
 
  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      let filtered = filterValue.filter(
        (item: any) =>
          item.value !== "Inactive" &&
          item.value !== "within30Days" &&
          item.value !== "within60Days" &&
          item.value !== "within90Days" 
      );
  
      const existsInFilter = filterValue.some(
        (obj: any) => obj.value === selectedOption.value
      );
  
      if (existsInFilter) {
        setFilterValue((prevValue: any) =>
          filtered.filter((obj: any) => obj.value !== selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...filtered, selectedOption]);
      }
    }
  };
  
  const generateXLFile = () => {
    showLoadingIndicator();
    const filter =   querySearchKey ?querySearchKey: filterKey; 
    const search = querySearchValue? querySearchValue:searchValue;
    let searchName =""
    if(filter?.includes("Inactive")){
      searchName = "Inactive"
    }else if(filter?.includes("within30Days")){
      searchName = "within30Days"
    } if(filter?.includes("within60Days")){
      searchName = "within60Days"
    } if(filter?.includes("within90Days")){
      searchName = "within90Days"
    }
    const paylaod ={
      page:1,
      size:totalEntries,
      searchValue:search == "" ? searchName : searchValue,
      searchKey:filter
    }
    dispatch(
      actions.getBenchResourceApi(
        paylaod,
        (res: any) => {
          if (res.code === 200) {
            var BenchManagementData = [
              [
                "Employee Name",
                "E-Code",
                "Date of Joining",
                "Last Project",
                "Estimated End Date",
                "PO End Date",
                "Last Working Day of Resource",
                "Skills",
                "Reporting Manager Name",
                "Reporting Manager E-Code",
                "Employee Status",
                "Remarks",
                "Color Code"
              ],
            ];

            res?.data.length > 0 &&
              res?.data?.forEach((ele: any) => {
                let emptyArray: string[] = [];
                emptyArray.push( ele?.candidateName +
                    " " +
                    ele?.middleName +
                    " " +
                    ele?.lastname,
                  ele?.employeeId,
                  ele?.dateOfJoining
                    ? timeDateFormat(ele?.dateOfJoining)
                    : "",
                  ele?.resourceAllocation?.[0].Project?.[0].projectName,
                  ele?.resourceAllocation?.[0]?.endDate ? timeDateFormat(ele?.resourceAllocation?.[0]?.endDate) : "",
                  ele?.resourceAllocation?.[0]?.poEndDate ? timeDateFormat(ele?.resourceAllocation?.[0]?.poEndDate) : "",
                  ele?.lastWorkingDate
                    ? timeDateFormat(ele?.lastWorkingDate)
                    : "",
                  ele?.resourceAllocation?.[0]?.roleDescription,
                  ele?.manager?.length ?
                  ele?.manager[0]?.candidateName +
                  " " +
                  ele?.manager[0]?.middleName +
                  " " +
                  ele?.manager[0]?.lastname : "",
                  ele?.manager?.length ?
                 ele?.manager[0]?.employeeId :"",
                  ele?.status ?? "",
                  ele?.resourceAllocation?.[0]?.remarks,
                  (ele.endDateColor ? ele.endDateColor.charAt(0).toUpperCase() + ele.endDateColor.slice(1) : 
                  ele.poColorCode ? ele.poColorCode.charAt(0).toUpperCase() + ele.poColorCode.slice(1) : 
                  "")
                 
                );
                BenchManagementData.push(emptyArray);
              });

            var BenchManagement = XLSX.utils.aoa_to_sheet(BenchManagementData);

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              wb,
              BenchManagement,
              "Bench Management"
            );
            hideLoadingIndicator();
            XLSX.writeFile(wb, "Bench_management.xlsx");
          }
        }
      )
    );
  };

  const statusHandler = (sta: any) => {
    setStatusObj({ status: sta });
  };
  const handleSerach = (value: any) => {
    setPage(1);
    setSearchValue(value);
  };
  const exportReportRef = useRef<HTMLInputElement | null>(null);
  const imgIcon = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        exportReportRef.current &&
        !exportReportRef.current.contains(event.target as Node)
      ) {
        if (
          imgIcon.current &&
          !imgIcon.current.contains(event.target as Node)
        ) {
          setShowExportReport(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showExportReport]);
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{bench_management}</h2>
          </div>
        </div>
        {loadingComponent()}
        <div className="mt-2">
          <div className="mt-3 buttonsize filter-wrapper-bench-mgmt">
            <div className="searchbar-wrapper">
              <SearchBar
                filter={searchValue}
                setFilter={handleSerach}
                onSearchClicked={onSearchClicked}
              />
            </div>
            <div className="filter-container">
              <select
                id="select-filters"
                value={filterValue}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  const selectedOption = benchManagementFilters.find(
                    (item: any) => item.value === selectedValue
                  );
                  if (selectedOption) {
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>{filters}</option>
                {benchManagementFilters.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="filter-container">
              <select
                id="select-filters"
                onChange={(event) => {
                  // setOnclickSearch(true);
                  // setPage(1);
                  // statusHandler(event.target.value);
                  const selectedValue = event.target.value;
                  const selectedOption = benchManagementAllRecordsFilters.find(
                    (item: any) => item.value === selectedValue
                  );
                  if (selectedOption) {
                    setPage(1);
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>All Records</option>
                {benchManagementAllRecordsFilters.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item?.title === "Bench All Records"? "All Records" :item?.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="export-report-wrapper">
              <img
                src={hamburger_icon}
                onClick={() => setShowExportReport(!showExportReport)}
                alt="icon"
                height={30}
                width={30}
                ref={imgIcon}
              />
              {showExportReport && (
                <div className="report-btn-wrapper" ref={exportReportRef}>
                  <div className="export-btn">
                    <button onClick={generateXLFile}>
                      <img src={export_report} alt="icon" /> {exportReport}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <FilterValues
            filterValueArray={filterValue}
            onFilterChange={filterChange}
          />
          {data.length > 0 ? (
            <>
              <div className="table-responsive">
                <div className="table">
                  <table
                    className="table-main table table-sm mt-3"
                    {...getTableProps()}
                  >
                    <thead className="table-head">
                      {headerGroups.map((headerGroup) => (
                        <tr
                          className="table-head-row"
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map((column: any) => (
                            <th
                              className="table-head-row"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              scope="col"
                            >
                              {column.render("Header")}

                              <span>
                                {column.id !== "action" &&
                                  column.id !== "id" &&
                                  column.id !== "remarks" ? (
                                  column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <TiArrowUnsorted />
                                    ) : (
                                      <TiArrowUnsorted />
                                    )
                                  ) : (
                                    <TiArrowUnsorted />
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody className="table-body" {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        const originalRow = row.original as RowData;
                        return (
                          <tr className="table-body-row" {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              const cellColor =
                                (cell.column.id === "endDate" && originalRow.endDateColor === "red") ||
                                  (cell.column.id === "poEndDate" && originalRow.poColorCode === "red")
                                  ? { backgroundColor: "#f25050" }
                                  : (cell.column.id === "endDate" && originalRow.endDateColor === "amber") ||
                                    (cell.column.id === "poEndDate" && originalRow.poColorCode === "amber")
                                    ? { backgroundColor: "#FFBF00" }
                                    : (cell.column.id === "endDate" && originalRow.endDateColor === "green") ||
                                      (cell.column.id === "poEndDate" && originalRow.poColorCode === "green")
                                      ? { backgroundColor: "#2bd92b" }
                                      : {};
                              return (
                                <td style={cellColor} {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>

                  </table>
                </div>
              </div>
              <div className="show-entries-wrapper">
                <ShowEntries
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalRecords={totalRecords}
                  totalEntries={totalEntries}
                />
                <TablePagination
                  totalPages={totalPages}
                  currentPage={page}
                  onClick={handlePageChange}
                />
              </div>
            </>
          ) : (
            <p className="no-data">{no_data_found}</p>
          )}
          <div>
            <ModalComponents
              show={showAddResource}
              onHide={hideAddResource}
              title="Add Resource Details"
              modalBody={
                <AssignProject
                  apiCallCompleted={apiCallCompleted}
                  employeeRef={employeeProjectDetails.employeeRef}
                  client={employeeProjectDetails.client}
                  projectName={employeeProjectDetails.projectName}
                  resourceName={employeeProjectDetails.resourceName}
                  managerName={employeeProjectDetails.managerName}
                  hideAddResource={hideAddResource}
                />
              }
              className="modal-lg"
            />
          </div>
        </div>
      </MainCard>
    </>
  );
};
export default BenchMgmtTable;
