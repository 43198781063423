import React, { useState } from "react";
import { Strings } from "../../constant/Strings";
import { icons } from "../../assets/index";
import "./FilterValue.scss";

const FilterValues = (props: any) => {
  const {
    filterValueArray,
    onFilterChange,
    setShowMonthAndYear,
    setFilterYearValues,
    setFilterMonthValues,
  } = props;

  const filterChange = (value: any) => {
    if (value.value == "MonthAndYear") {
      setShowMonthAndYear(false);
      setFilterYearValues(null);
      setFilterMonthValues(null);
    }
    if (value) {
      onFilterChange(value);
    }
  };
const filterMappedData = filterValueArray.filter((item:any) => item.title != "Bench All Records")
  return (
    <div className="filter-wrap">
      {filterMappedData.length !== 0 && (
        <div className="filter-by">{Strings.filter_by}:</div>
      )}
      {filterMappedData &&
        filterMappedData.map((obj: any) => (
          <div className="filter-label" key={obj?.value}>
            {obj?.title}{" "}
            <img
              className="cursor"
              src={icons.close_icon}
              onClick={() => filterChange(obj)}
              alt="icon"
            />
          </div>
        ))}
    </div>
  );
};

export default FilterValues;
