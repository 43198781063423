import React, { useEffect, useMemo, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import Pagination from "../../../component/Pagination/Pagination";
import Button from "../../../component/Buttons/Button";
import add from "../../../assets/add.png";
import edit_icon from "../../../assets/edit.png";
import delete_icon from "../../../assets/delete.png";
import {
  FaLessThanEqual,
  FaSort,
  FaSortUp,
  FaFileAlt,
  FaPlus,
  FaFileExcel,
} from "react-icons/fa";
import { icons } from "../../../assets/index";
import { Table } from "react-bootstrap";
import { Card } from "react-bootstrap";
import ReactPaginate from "react-paginate";

import * as actions from "../../../Redux/Actions/index";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import report_icon from "../../../assets/report-icon.png";

import {
  useTable,
  TableOptions,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useFilters,
} from "react-table";

import { Dropdown } from "react-bootstrap";
import { TiArrowUnsorted } from "react-icons/ti";
import { CSVLink } from "react-csv";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import { timeDateFormat } from "../../../component/FormatDate";

const InseedReportTable = (props: any) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("Mapped");
  const getAllInseedReport = useSelector(
    (state: any) => state.employeeReducer.getAllInseedReport
  );

  const dispatch = useDispatch();
  const [data, setdata] = useState<any>([]);

  useEffect(() => {
    dispatch(actions.getAllInseedReportAction(selectedStatus));
  }, [selectedStatus]);

  useEffect(() => {
    if (getAllInseedReport && getAllInseedReport.data) {
      setdata(getAllInseedReport.data);
      setTotalRecords(getAllInseedReport.data.length);
    }
  }, [getAllInseedReport, data]);

  const COLUMNS: any = [
    {
      Header: "Inseed Batch",
      accessor: "inseedBatch",
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      Cell: (Action: any) => {
        const startDate = Action.row.original.startDate;
        return timeDateFormat(startDate);
      },
    },
    {
      Header: "End Date",
      accessor: "endDate",
      Cell: (Action: any) => {
        const endDate = Action.row.original.endDate;
        return timeDateFormat(endDate);
      },
    },
    {
      Header: "Client Name",
      accessor: "client",
    },
    {
      Header: "Technology Required",
      accessor: "technology",
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    { columns, data, initialState: { pageSize: 10, pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="row w-50 ">
        <label className="form-label ">Select Status</label>
        <div className="col-6 ">
          <Dropdown onSelect={(eventKey: any) => setSelectedStatus(eventKey)}>
            <Dropdown.Toggle variant="light" className="form-control col">
              {selectedStatus}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="Mapped">Mapped</Dropdown.Item>
              <Dropdown.Item eventKey="On Going">On Going</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {data.length > 0 ? (
        <Card style={{ marginTop: "48px" }}>
          <div className="row w-50 mt-3" style={{ marginLeft: "0px" }}>
            <div className="col">
              <SearchBar filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
            <div className="col show-entries-wrapper">
              <ShowEntries
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalRecords={totalRecords}
              />
            </div>
          </div>

          <div className="table">
            <table
              className="table-main table table-sm mt-3"
              {...getTableProps()}
            >
              <thead className="table-head">
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="table-head-row"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column: any) => (
                      <th
                        className="table-head-row"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        scope="col"
                      >
                        {column.render("Header")}

                        <span>
                          {column.id !== "action" &&
                          column.id !== "id" &&
                          column.id !== "remarks" ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <TiArrowUnsorted />
                              ) : (
                                <TiArrowUnsorted />
                              )
                            ) : (
                              <TiArrowUnsorted />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="table-body" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="table-body-row" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Pagination
            nextPage={nextPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            pageIndex={pageIndex}
          />
        </Card>
      ) : (
        <p className="no-data">No Data Found</p>
      )}
    </>
  );
};

export default InseedReportTable;
