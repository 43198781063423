import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { showSuccessToastMessage } from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";
import { Strings } from "../../../../../constant/Strings";
import {
  projectProjectNameValidation,
  whiteSpaceValid,
} from "../../../../../constant/Validations";

const AddSkills = (props: any) => {
  const employeeId: any = localStorage.getItem("selectedId");
  const { hideAddSkill } = props;

  const [btnDisable, setBtnDisable] = useState(false);
  const [skills, setSkills] = useState({
    employeeRef: employeeId,
    skillName: "",
    description: "Less than a year",
    proficiencylevel: "Beginner",
  });

  const {
    submit,
    cancel,
    addSuccess,
    valid_skill,
    proficiency_level,
    skill_name,
    less_than_a_year,
    years_of_experience,
  } = Strings;

  const addSkills = useSelector(
    (state: any) => state.employeeReducer.addEmployeeSkills
  );

  useEffect(() => {
    if (skills.skillName && skills.description && skills.proficiencylevel) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [skills, btnDisable, addSkills]);

  const dispatch = useDispatch();
  const [showSkillErrorToggle, setShowSkillErrorToggle] = useState(false);

  const handleSkillsSubmit = () => {
    if (
      whiteSpaceValid(skills.skillName) &&
      projectProjectNameValidation(skills.skillName)
    ) {
      dispatch(actions.addEmployeeSkillsAction(skills));
      hideAddSkill();
      showSuccessToastMessage(addSuccess);
    } else {
      if (
        whiteSpaceValid(skills.skillName) &&
        projectProjectNameValidation(skills.skillName)
      ) {
        setShowSkillErrorToggle(false);
      } else {
        setShowSkillErrorToggle(true);
      }
    }
  };

  const [setDropdown1, setShowDropdown1] = useState(false);
  const [setDropdown, setShowDropdown] = useState(false);

  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            {skill_name}
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setSkills({
                ...skills,
                skillName: e.target.value,
              })
            }
          />
          <p className="error">
            {skills.skillName.length !== 0 &&
              (showSkillErrorToggle ? valid_skill : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{years_of_experience}</label>
          <span className="required">*</span>

          <Dropdown
            onMouseLeave={() => setShowDropdown1(false)}
            onMouseOver={() => setShowDropdown1(true)}
            className="qualification-dropdown"
            onSelect={(eventKey: any) =>
              setSkills({
                ...skills,
                description: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {skills.description}
            </Dropdown.Toggle>
            <Dropdown.Menu show={setDropdown1}>
              <Dropdown.Item eventKey="Less than a year">
                {less_than_a_year}
              </Dropdown.Item>
              {Array.apply(null, Array(30)).map(function (y, i) {
                return <Dropdown.Item eventKey={i + 1}>{i + 1}</Dropdown.Item>;
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label className="form-label">{proficiency_level}</label>
          <span className="required">*</span>

          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
            onSelect={(eventKey: any) =>
              setSkills({
                ...skills,
                proficiencylevel: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {skills.proficiencylevel}
            </Dropdown.Toggle>
            <Dropdown.Menu show={setDropdown}>
              <Dropdown.Item eventKey="Beginner">Beginner</Dropdown.Item>
              <Dropdown.Item eventKey="Intermediate">
                Intermediate
              </Dropdown.Item>
              <Dropdown.Item eventKey="Expert">Expert</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="modal-btn-wrapper mt-5">
        <div>
          <button className="btn cancel-btn buttonsize" onClick={hideAddSkill}>
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleSkillsSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSkills;
