import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions";
import { Dropdown } from "react-bootstrap";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import ModalWarning from "../../../component/MakerChecker/ModalWarning";
import {
  Strings,
  addEmployeeSkillCoinsErrorsValidations,
} from "../../../constant/Strings";
import { timeDateFormatYMD } from "../../../component/FormatDate";

const DeallocateSkillCoins = (props: any) => {
  const role = localStorage.getItem("role");
  const isCheckedData = role?.includes("skillcoin-checker");
  const {
    employee_code,
    Employee_label,
    no_skill,
    submit_label,
    cancel_label,
    deallocate,
    allocate,
    Reason,
    approve_label,
    reject_label,
    assigned_Date,
    editSuccess,
  } = Strings;
  const { skillcoinsVal, skillcoinsRange } =
    addEmployeeSkillCoinsErrorsValidations;
  const [modalWarning, setModalWarning] = useState("");
  const [showReason, setShowReason] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { hideDeallocateSkillCoins, skillCoinData, apiCallCompleted } = props;
  const [deallocateSkillCoins, setDeallocateSkillCoins] = useState({
    skillcoins: skillCoinData?.skillcoins,
    remarks: skillCoinData?.remarks,
    assigned_date: skillCoinData?.assignedDate,
    status: skillCoinData?.status,
    assignment_letter: skillCoinData?.assignment_letter,
    recordStatus: "Updated",
  });
  const { skillcoins, remarks, assigned_date } = deallocateSkillCoins;
  const [errors, setErrors] = useState({
    skillcoins: "",
  });
  useEffect(() => {
    if (deallocateSkillCoins?.status?.length > 0) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [deallocateSkillCoins]);
  let valid: boolean = true;
  const validate = () => {
    if (deallocateSkillCoins.skillcoins.length == 0) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        skillcoins: skillcoinsVal,
      }));
      setBtnDisable(false);
    }
    if (
      (deallocateSkillCoins.status != "deallocated" &&
        deallocateSkillCoins.skillcoins < 100) ||
      deallocateSkillCoins.skillcoins > 500
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        skillcoins: skillcoinsRange,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, skillcoins: "" }));
    }
  };

  const dispatch = useDispatch();
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.deallocateSkillCoinsAction(
        skillCoinData.skillCoinId,
        deallocateSkillCoins,
        (res: any) => {
          if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
              apiCallCompleted(true);
            } else if (res.code === 200) {
                showSuccessToastMessage(editSuccess);
                apiCallCompleted(true);
              }
        }
      )
    );
  };
  const handleDeallocateSkillCoins = (e: any) => {
    setBtnDisable(false);
    validate();
    if (valid) {
      handleDispatch();
      hideDeallocateSkillCoins();
    }
  };

  const handleReject = (text: any) => {
    setModalWarning(text);
    setShowReason(true);
  };
  const hideModal = () => {
    setShowReason(false);
  };
  const [docFile, setDocFile] = useState<any>({
    assignment_letter: null,
  });

  return (
    <>
      <div className="row g-3">
        <div className="col-md-6">
          <label className="form-label">{employee_code}</label>
          <span className="required">*</span>
          <input
            type="text"
            disabled
            className="form-control"
            placeholder="ABCDE"
            value={skillCoinData?.employeeId}
          />
        </div>
        <div className="col-md-6">
          <label className="form-label">{Employee_label}</label>
          <span className="required">*</span>
          <input
            type="text"
            disabled
            className="form-control"
            placeholder="ABCDE"
            value={skillCoinData?.employeeName}
          />
        </div>

        <div className="col-md-12">
          <label className="form-label">{no_skill}</label>
          <span className="required">*</span>
          {deallocateSkillCoins.status == "deallocated" ? (
            <input
              type="number"
              disabled
              value={skillCoinData?.skillcoins}
              className="form-control"
            />
          ) : (
            <>
              <input
                type="number"
                min={100}
                max={500}
                value={skillcoins}
                className="form-control"
                onChange={(e: any) => {
                  setDeallocateSkillCoins({
                    ...deallocateSkillCoins,
                    skillcoins: e.target.value,
                  });
                }}
              />
              <p className="error">{errors.skillcoins}</p>
            </>
          )}
        </div>
        <div className="col-6">
          <label htmlFor="inputName4" className="form-label">
            Status
          </label>
          <span className="required">*</span>
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
            className="qualification-dropdown"
            onSelect={(e: any) =>
              setDeallocateSkillCoins({
                ...deallocateSkillCoins,
                status: e,
                skillcoins:
                  e == "deallocated" ? skillCoinData?.skillcoins : skillcoins,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {deallocateSkillCoins.status == "allocated"
                ? "Allocated"
                : deallocateSkillCoins.status == "deallocated"
                ? "Deallocated"
                : ""}
            </Dropdown.Toggle>
            <Dropdown.Menu show={showDropdown}>
              <Dropdown.Item eventKey="allocated">{allocate}</Dropdown.Item>
              <Dropdown.Item eventKey="deallocated">{deallocate}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-6">
          <label className="form-label">{assigned_Date}</label>
          <span className="required">*</span>
          <input
            type="date"
            className="form-control"
            max={new Date().toISOString().split("T")[0]}
            value={timeDateFormatYMD(assigned_date)}
            onChange={(e) =>
              setDeallocateSkillCoins({
                ...deallocateSkillCoins,
                assigned_date: e.target.value,
              })
            }
          />
        </div>

        <div className="col-md-6 mb-3">
          <label className="form-label">{Reason}</label>
          <textarea
            className="form-control"
            placeholder="Reason"
            value={remarks}
            onChange={(e) =>
              setDeallocateSkillCoins({
                ...deallocateSkillCoins,
                remarks: e.target.value,
              })
            }
          />
        </div>

        <div className="row">
          {isCheckedData == false ? (
            <></>
          ) : skillCoinData?.recordStatus == "Approved" ||
            skillCoinData?.recordStatus == "Accepted" ||
            skillCoinData?.recordStatus == "Rejected" ? (
            <></>
          ) : (
            <>
              <div className="modal-btn-wrapper mt-3 mb-3 mx-auto">
                <button
                  type="button"
                  className="btn btn-danger buttonsize"
                  onClick={() => {
                    handleReject("Reject");
                  }}
                >
                  {reject_label}
                </button>
                <button
                  type="button"
                  className="btn btn-success buttonsize"
                  onClick={() => {
                    handleReject("Approve");
                  }}
                >
                  {approve_label}
                </button>
              </div>
            </>
          )}
          <hr></hr>
          <div className="modal-btn-wrapper  mx-auto">
            <button
              type="button"
              className="btn cancel-btn buttonsize"
              onClick={hideDeallocateSkillCoins}
            >
              {cancel_label}
            </button>
            <button
              type="button"
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleDeallocateSkillCoins}
            >
              {submit_label}
            </button>
          </div>
        </div>
      </div>
      <ModalComponents
        show={showReason}
        onHide={hideModal}
        title=""
        modalBody={
          <ModalWarning
            apiCallCompleted={apiCallCompleted}
            hideModal={hideModal}
            header={modalWarning}
            id={skillCoinData.skillCoinId}
            editApi={actions.deallocateSkillCoinsAction}
            data={{
              recordStatus:
                modalWarning == "Approve"
                  ? modalWarning + "d"
                  : modalWarning == "Reject"
                  ? modalWarning + "ed"
                  : modalWarning,
            }}
            hideEditModal={hideDeallocateSkillCoins}
            setShowUndoPopup={() => {}}
          />
        }
        className="modal-md"
      />
    </>
  );
};

export default DeallocateSkillCoins;
