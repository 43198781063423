import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";
import {
  whiteSpaceValid,
  isCharactersValid,
  isYearValid,
  isMarks1Valid,
  isMarks2Valid,
  validationAlphaNumeric,
} from "../../../../../constant/Validations";
import { Strings } from "../../../../../constant/Strings";

const EditQualification = (props: any) => {
  const {
    id,
    qualification,
    branch,
    institute,
    board,
    year,
    marks,
    qualificationImage,
    hideEditQualifications,
  } = props;
  const [qualificationData, setQualificationData] = useState({
    id: id,
    qualification: qualification,
    branch: branch,
    institute: institute,
    board: board,
    year: year,
    marks: marks,
    qualificationImage: qualificationImage,
  });

  const [errors, setErrors] = useState({
    branch: "",
    institute: "",
    board: "",
    year: "",
    marks: "",
  });
  const {
    addSuccess,
    uploadFile,
    imageSize2mb,
    file_size_max_msg,
    file_type_msg,
    valid_branch,
    valid_board_name,
    valid_institute,
    valid_marks,
    valid_year,
    year_of_passing,
    branch_Stream,
    institue_school_college,
    board_university,
    percentage_of_marks,
    upload_certificate,
    submit,
    cancel,
  } = Strings;

  const [btnDisable, setBtnDisable] = useState(false);
  useEffect(() => {
    if (
      (qualificationData.qualification == "10th"
        ? true
        : qualificationData.branch) &&
      qualificationData.institute &&
      qualificationData.board &&
      qualificationData.year &&
      qualificationData.marks &&
      qualificationData.qualificationImage
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [qualificationData, btnDisable]);
  const dispatch = useDispatch();
  const handleEdit = () => {
    if (
      qualificationData.qualification !== "10th"
        ? whiteSpaceValid(qualificationData.branch) &&
          validationAlphaNumeric(qualificationData.branch)
        : true
    ) {
      setShowbranchErrorToggle(false);
    } else {
      setShowbranchErrorToggle(true);
    }

    if (
      whiteSpaceValid(qualificationData.institute) &&
      validationAlphaNumeric(qualificationData.institute)
    ) {
      setShowinstituteErrorToggle(false);
    } else {
      setShowinstituteErrorToggle(true);
    }

    if (
      whiteSpaceValid(qualificationData.board) &&
      validationAlphaNumeric(qualificationData.board)
    ) {
      setShowboardErrorToggle(false);
    } else {
      setShowboardErrorToggle(true);
    }
    if (
      qualificationData.year.toString().length == 4 &&
      isYearValid(qualificationData.year)
    ) {
      setShowyearErrorToggle(false);
    } else {
      setShowyearErrorToggle(true);
    }

    if (
      isMarks1Valid(qualificationData.marks) ||
      isMarks2Valid(qualificationData.marks)
    ) {
      setShowmarksErrorToggle(false);
    } else {
      setShowmarksErrorToggle(true);
    }

    if (
      whiteSpaceValid(qualificationData.institute) &&
      validationAlphaNumeric(qualificationData.institute) &&
      whiteSpaceValid(qualificationData.board) &&
      validationAlphaNumeric(qualificationData.board) &&
      isYearValid(qualificationData.year) &&
      (isMarks2Valid(qualificationData.marks) ||
        isMarks1Valid(qualificationData.marks))
    ) {
      dispatch(actions.editEmployeeQualificationAction(id, qualificationData));
      showSuccessToastMessage("Edited Successfully!");
      hideEditQualifications();
    }
  };

  const [showbranchErrorToggle, setShowbranchErrorToggle] = useState(false);
  const [showinstituteErrorToggle, setShowinstituteErrorToggle] =
    useState(false);
  const [showboardErrorToggle, setShowboardErrorToggle] = useState(false);
  const [showyearErrorToggle, setShowyearErrorToggle] = useState(false);
  const [showmarksErrorToggle, setShowmarksErrorToggle] = useState(false);

  const [docFile, setDocFile] = useState<any>([]);
  const handleOnChange = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile(e.target.files[0]);
      dispatch(
        actions.qualificationsDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setQualificationData({
                ...qualificationData,
                qualificationImage: res.data,
              });
            }
          }
        )
      );
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            Qualification
          </label>
          <span className="required">*</span>
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
            className="qualification-dropdown"
            onSelect={(eventKey: any) =>
              setQualificationData({
                ...qualificationData,
                qualification: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {qualificationData.qualification}
            </Dropdown.Toggle>
            <Dropdown.Menu show={showDropdown}>
              <Dropdown.Item eventKey="10th">10th</Dropdown.Item>
              <Dropdown.Item eventKey="12th/Diploma">
                12th/Diploma
              </Dropdown.Item>
              <Dropdown.Item eventKey="Graduation/Degree">
                Graduation/Degree
              </Dropdown.Item>
              <Dropdown.Item eventKey="Post Graduation">
                Post Graduation
              </Dropdown.Item>
              <Dropdown.Item eventKey="Any Other Degree">
                Any Other Degree
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label className="form-label">{branch_Stream}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={qualificationData.branch}
            disabled={qualificationData.qualification == "10th"}
            onChange={(e) =>
              setQualificationData({
                ...qualificationData,
                branch: e.target.value,
              })
            }
          />
          <p className="error">
            {qualificationData.branch.length !== 0 &&
              (showbranchErrorToggle ? "Valid branch" : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{institue_school_college}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={qualificationData.institute}
            onChange={(e) =>
              setQualificationData({
                ...qualificationData,
                institute: e.target.value,
              })
            }
          />
          <p className="error">
            {qualificationData.institute.length !== 0 &&
              (showinstituteErrorToggle ? valid_institute : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{board_university}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={qualificationData.board}
            onChange={(e) =>
              setQualificationData({
                ...qualificationData,
                board: e.target.value,
              })
            }
          />
          <p className="error">
            {qualificationData.board.length !== 0 &&
              (showboardErrorToggle ? valid_board_name : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{year_of_passing}</label>
          <span className="required">*</span>
          <input
            type="text"
            maxLength={4}
            className="form-control"
            value={qualificationData.year}
            onChange={(e) =>
              setQualificationData({
                ...qualificationData,
                year: e.target.value,
              })
            }
          />
          <p className="error">
            {qualificationData.year.length !== 0 &&
              (showyearErrorToggle ? valid_year : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{percentage_of_marks}</label>
          <span className="required">*</span>
          <input
            type="text"
            maxLength={5}
            className="form-control"
            value={qualificationData.marks}
            onChange={(e) =>
              setQualificationData({
                ...qualificationData,
                marks: e.target.value,
              })
            }
          />
          <p className="error">
            {qualificationData.marks.length !== 0 &&
              (showmarksErrorToggle ? valid_marks : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{upload_certificate}</label>
          <span className="required">*</span>
          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleOnChange}
              hidden
              id="actual-btn"
            />
            <label
              htmlFor="actual-btn"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <p className="image-size-condition">
            {imageSize2mb}
            <span className="required">*</span>
          </p>
        </div>
        <div className="col-md-4" style={{ marginTop: "45px" }}>
          <a
            href={qualificationData.qualificationImage?.document_path_url}
            target="_blank"
            className="buttonsize"
          >
            {qualificationData.qualificationImage?.document_path_url
              ? "View File"
              : ""}
          </a>
          <p className="image-size-condition">{docFile?.name}</p>
        </div>
      </div>
      <div className="modal-btn-wrapper mt-3">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideEditQualifications}
          >
            {cancel}
          </button>
        </div>

        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditQualification;
