import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";

const AddCareerGoals = (props: any) => {
  const employeeId: any = localStorage.getItem("selectedId");
  const { hideAddCareerGoals } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  const [careerGoals, setCareerGoals] = useState({
    employeeRef: employeeId,
    title: "",
    description: "",
  });

  const AddCareerGoals = useSelector(
    (state: any) => state.employeeReducer.addEmployeeCareerGoals
  );

  useEffect(() => {
    if (careerGoals.title && careerGoals.description) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [careerGoals, btnDisable, AddCareerGoals]);

  const dispatch = useDispatch();
  const handleCareerGoalsSubmit = () => {
    if (careerGoals.title && careerGoals.description) {
      dispatch(actions.addEmployeeCareerGoalsAction(careerGoals));
      hideAddCareerGoals();
      showSuccessToastMessage("Added Successfully!");
    } else {
      showErrorToastMessage("Please fill all the details");
    }
    setCareerGoals({
      employeeRef: employeeId,
      title: "",
      description: "",
    });
  };

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            Title
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setCareerGoals({
                ...careerGoals,
                title: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">Description</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setCareerGoals({
                ...careerGoals,
                description: e.target.value,
              })
            }
          />
        </div>

        <div className="col-md-4">
          <label className="form-label">Certificate</label>
          <span className="required">*</span>
          <input type="file" className="form-control" />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-2">
          <button
            className="btn btn-outline-danger"
            onClick={hideAddCareerGoals}
          >
            Cancel
          </button>
        </div>

        <div className="col-md-2">
          <button
            className={btnDisable ? "btn btn-primary" : "btn btn-disabled"}
            onClick={handleCareerGoalsSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCareerGoals;
