import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import * as actions from "../Redux/Actions";
import { useDispatch } from "react-redux";
import OneSignal from "react-onesignal";
import { postPlayerId } from "../Redux/Services";

const SharedLayout = () => {
  const logOut = useSelector((state) => state.employeeReducer.logOut);
  const token = localStorage.getItem("access_token");
  const dispatch = useDispatch();

  function runOneSignal() {
    OneSignal.on("subscriptionChange", function (isSubscribed) {
      if (isSubscribed) {
        OneSignal.getUserId().then((playerID) => {
          postPlayerId({ player_id: playerID });
        });
      }
    });
  }

  useEffect(() => {
    OneSignal.init({ appId: "ae026437-ca2e-45d3-9945-6ca387c83b22" });
    runOneSignal();
  }, []);

  useEffect(() => {
    dispatch(actions.userLoginAction(token));
  }, [logOut]);

  return (
    <>
      <div>
        <Outlet />
      </div>
      {/* <ToastContainer /> */}
    </>
  );
};

export default SharedLayout;
