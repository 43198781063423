import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { Strings, requiredFeild } from "../../../constant/Strings";

const RejectedModal = (props: any) => {
  const { hideRejection, requestId, setShowUndoPopup, apiCallCompleted } =
    props;
  const [btnDisable, setBtnDisable] = useState(false);
  const [rejectionData, setRejectionData] = useState<any>({
    remarks: "",
    status: "2",
  });
  const { rejectSuccess, remarks, cancel, submit } = Strings;
  const { required } = requiredFeild;
  const dispatch = useDispatch();
  const handleRejectionSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      actions.updateStatusRemarksAction(
        requestId,
        rejectionData,
        (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
            apiCallCompleted(true);
          } else {
            if (res?.code === 200) {
              showErrorToastMessage(rejectSuccess);
              apiCallCompleted(true);
              setShowUndoPopup(true);
            }
          }
        }
      )
    );
    hideRejection();
  };

  const handleChange = (e: any) => {
    setRejectionData({ ...rejectionData, remarks: e.target.value });
  };

  useEffect(() => {
    if (rejectionData.remarks) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 mt-2 mb-3">
          <label className="form-label">{remarks}</label>
          <span className="required">{required}</span>
          <textarea
            id="exampleFormControlTextarea1"
            className="form-control"
            style={{
              width: "24rem",
              height: "10rem",
            }}
            value={rejectionData.remarks}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button className="btn cancel-btn buttonsize" onClick={hideRejection}>
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleRejectionSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectedModal;
