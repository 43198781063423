import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showErrorToastMessage } from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";

const DeleteCareerGoals = (props: any) => {
  const { id, hideDeleteCareerGoals } = props;
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(actions.deleteEmployeeCareerGoalsAction(id));
    hideDeleteCareerGoals();
    showErrorToastMessage("Deleted Successfully!")
  };
  const deleteEmployeeCareerGoalsAction = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeeCareerGoals
  );
  return (
    <div className="container">
      <div className="row mx-auto">Are you sure you want to delete?</div>
      <div className="row mt-3 mx-auto">
        <div className="col">
          <button className="btn btn-outline-danger" onClick={hideDeleteCareerGoals}>Cancel</button>
        </div>
        <div className="col">
          <button className="btn btn-primary" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteCareerGoals;
