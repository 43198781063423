import React, { useEffect, useState } from "react";
import * as actions from "../../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import {
  Strings,
  requiredFeild,
  bloodGroupDropdown,
} from "../../../../constant/Strings";

const EditPersonalInfo = (props: any) => {
  const { handleEditPersonalInfo, eId, bloodGroup, apiCallCompleted } = props;
  const { cancel, submit, editSuccess, blood_group } = Strings;
  const { required } = requiredFeild;
  const [employeeData, setEmployeeData] = useState({
    bloodGroup: bloodGroup,
    eId: eId,
    updatedBy: eId,
  });

  useEffect(() => {
    if (employeeData.bloodGroup) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });
  const [btnDisable, setBtnDisable] = useState(false);

  const dispatch = useDispatch();

  const handleEdit = () => {
    handleDispatch();
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editEmployeeDataAction(employeeData, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            }
          } else {
            if (res.code === 200) {
              showSuccessToastMessage(editSuccess);
              apiCallCompleted(true);
            }
          }
        }
      })
    );
    handleEditPersonalInfo();
  };

  const [setDropdown, setShowDropdown] = useState(false);

  return (
    <div>
      <div className="row g-4">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {blood_group}
            <span className="required">{required}</span>
          </label>
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onSelect={(eventKey: any) =>
              setEmployeeData({
                ...employeeData,
                bloodGroup: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {employeeData.bloodGroup}
            </Dropdown.Toggle>
            <Dropdown.Menu show={setDropdown}>
              {bloodGroupDropdown.map((element: any) => (
                <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="modal-btn-wrapper mt-4 mb-4">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={handleEditPersonalInfo}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditPersonalInfo;
