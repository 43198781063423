import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";
import {
  whiteSpaceValid,
  isCharactersValid,
  projectProjectNameValidation,
} from "../../../../../constant/Validations";
import { Strings } from "../../../../../constant/Strings";

const EditExperiences = (props: any) => {
  const [btnDisable, setBtnDisable] = useState(false);
  const [errors, setErrors] = useState<any>({})
  const {
    id,
    companyName,
    designation,
    location,
    startDate,
    endDate,
    rolesAndResponsibility,
    relievingLetter,
    paySlip,
    resignationAcceptanceLetter,
    hideEditExperiences,
    lastEmployer,
    data,
    getApiDispatch
  } = props;
  const [experienceData, setExperienceData] = useState({
    id: id,
    companyName: companyName,
    designation: designation,
    location: location,
    rolesAndResponsibility: rolesAndResponsibility,
    startDate: startDate,
    endDate: endDate,
    relievingLetter: relievingLetter,
    paySlip: paySlip,
    resignationAcceptanceLetter: resignationAcceptanceLetter,
    lastEmployer: lastEmployer,
  });

  const {
    valid_company_name,
    valid_designation,
    valid_location,
    valid_roles,
    editSuccess,
    file_size_max_msg,
    file_type_msg,
    company_name,
    location_label,
    date_of_joining,
    date_of_relieving,
    roles_responsibility,
    imageSize2mb,
    uploadFile,
    upload_payslip,
    upload_experience_relieving,
    upload_resignation_acceptance,
    cancel,
    submit,
  } = Strings;
  useEffect(() => {
    if (
      experienceData.companyName &&
      experienceData.designation &&
      experienceData.location &&
      experienceData.rolesAndResponsibility &&
      experienceData.startDate &&
      experienceData.endDate &&
      experienceData.relievingLetter &&
      (experienceData.lastEmployer ? experienceData.paySlip : true)
      &&
      Object.values(errors).every(val => val === null)
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [experienceData, btnDisable, errors]);
  const dispatch = useDispatch();
  const handleEdit = () => {
    if (
      whiteSpaceValid(experienceData.companyName) &&
      isCharactersValid(experienceData.companyName) &&
      whiteSpaceValid(experienceData.designation) &&
      projectProjectNameValidation(experienceData.designation) &&
      whiteSpaceValid(experienceData.location) &&
      isCharactersValid(experienceData.location) &&
      whiteSpaceValid(experienceData.rolesAndResponsibility) &&
      projectProjectNameValidation(experienceData.rolesAndResponsibility)
    ) {

      dispatch(
        actions.editExperienceDataAction(id, experienceData, (res: any) => {
          if (res) {
            console.log("res",res)
              if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
                hideEditExperiences();
              } else if (res?.code === 200) {
                hideEditExperiences();
                showSuccessToastMessage(editSuccess);
                getApiDispatch();
              }
          }
        })
      );

      // dispatch(actions.editExperienceDataAction(id, experienceData))
      //   .then((response: any) => {
      //     if (response.success) {
      //       getApiDispatch();
      //       hideEditExperiences();
      //       showSuccessToastMessage(editSuccess);
      //     } else {
      //       hideEditExperiences();
      //       showErrorToastMessage("Failed to edit experience");
      //     }
      //   })
      //   .catch((error: any) => {
      //     hideEditExperiences();
      //     showErrorToastMessage("An error occurred while editing experience");
      //   })

    } else {
      if (
        whiteSpaceValid(experienceData.companyName) &&
        isCharactersValid(experienceData.companyName)
      ) {
        setShownameErrorToggle(false);
      } else {
        setShownameErrorToggle(true);
      }
      if (
        whiteSpaceValid(experienceData.designation) &&
        projectProjectNameValidation(experienceData.designation)
      ) {
        setShowdesignationErrorToggle(false);
      } else {
        setShowdesignationErrorToggle(true);
      }

      if (
        whiteSpaceValid(experienceData.location) &&
        isCharactersValid(experienceData.location)
      ) {
        setShowlocationErrorToggle(false);
      } else {
        setShowlocationErrorToggle(true);
      }

      if (
        whiteSpaceValid(experienceData.rolesAndResponsibility) &&
        projectProjectNameValidation(experienceData.rolesAndResponsibility)
      ) {
        setShowroleErrorToggle(false);
      } else {
        setShowroleErrorToggle(true);
      }
    }
  };

  const [shownameErrorToggle, setShownameErrorToggle] = useState(false);
  const [showdesignationErrorToggle, setShowdesignationErrorToggle] =
    useState(false);
  const [showlocationErrorToggle, setShowlocationErrorToggle] = useState(false);
  const [showroleErrorToggle, setShowroleErrorToggle] = useState(false);


  const [docFile, setDocFile] = useState<any>({
    relievingLetter: null,
    paySlip: null,
    resignationAcceptanceLetter: null,
  });

  const handleSubmitRelievingLetter = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage(file_size_max_msg);
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(file_type_msg);
      return;
    } else {
      setDocFile({ ...docFile, relievingLetter: e.target.files[0] });
      dispatch(
        actions.experienceDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setExperienceData({
                ...experienceData,
                relievingLetter: res.data,
              });
            }
          }
        )
      );
    }
  };

  const handleSubmitPaySlip = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage(file_size_max_msg);
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(file_type_msg);
      return;
    } else {
      setDocFile({ ...docFile, paySlip: e.target.files[0] });
      dispatch(
        actions.experienceDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setExperienceData({
                ...experienceData,
                paySlip: res.data,
              });
            }
          }
        )
      );
    }
  };

  const handleSubmitResignationAcceptanceLetter = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage(file_size_max_msg);
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(file_type_msg);
      return;
    } else {
      setDocFile({
        ...docFile,
        resignationAcceptanceLetter: e.target.files[0],
      });
      dispatch(
        actions.experienceDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setExperienceData({
                ...experienceData,
                resignationAcceptanceLetter: res.data,
              });
            }
          }
        )
      );
    }
  };
  const newData = data.filter((item: any) => item.id != id);

  const checkDateOverlap = (newStartDate: any, newEndDate: any) => {

    for (let exp of newData) {
      const existingStart = moment(exp.startDate).startOf('day');
      const existingEnd = moment(exp.endDate).startOf('day');

      const newStart = moment(newStartDate).startOf('day');
      const newEnd = moment(newEndDate).startOf('day');
      if (
        (newStart.isBetween(existingStart, existingEnd, null, '[]') ||
          newEnd.isBetween(existingStart, existingEnd, null, '[]')) ||
        (newStart.isSame(existingStart) || newEnd.isSame(existingEnd)) ||
        (newStart.isSameOrBefore(existingStart) && newEnd.isSameOrAfter(existingEnd))
      ) {
        return true;
      }
    }
    return false;
  };


  const handleStartDateChange = (feildName: any, e: any) => {
    const newStartDate = moment(e.target.value);

    if (checkDateOverlap(newStartDate, moment(experienceData.endDate))) {
      setErrors({
        ...errors,
        [feildName]: "Date overlaps with existing experience range."
      });
    } else {
      setExperienceData({
        ...experienceData,
        startDate: e.target.value,
      });
      setErrors({
        ...errors,
        [feildName]: null
      });
    }
  };

  const handleEndDateChange = (feildName: any, e: any) => {
    const newEndDate = moment(e.target.value);
    if (checkDateOverlap(moment(experienceData.startDate), newEndDate)) {
      setErrors({
        ...errors,
        [feildName]: "Date overlaps with existing experience range."
      });
    } else {
      setExperienceData({
        ...experienceData,
        endDate: e.target.value,
      });
      setErrors({
        ...errors,
        [feildName]: null
      });
    }
  };
  return (
    <div>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            {company_name}
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={experienceData.companyName}
            onChange={(e) =>
              setExperienceData({
                ...experienceData,
                companyName: e.target.value,
              })
            }
          />
          <p className="error">
            {experienceData.companyName.length !== 0 &&
              (shownameErrorToggle ? valid_company_name : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">Designation</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={experienceData.designation}
            onChange={(e) =>
              setExperienceData({
                ...experienceData,
                designation: e.target.value,
              })
            }
          />
          <p className="error">
            {experienceData.designation.length !== 0 &&
              (showdesignationErrorToggle ? valid_designation : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{location_label}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={experienceData.location}
            onChange={(e) =>
              setExperienceData({
                ...experienceData,
                location: e.target.value,
              })
            }
          />
          <p className="error">
            {experienceData.location.length !== 0 &&
              (showlocationErrorToggle ? valid_location : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            {date_of_joining}
          </label>
          <span className="required">*</span>
          <input
            type="date"
            max={moment().format("YYYY-MM-DD")}
            className="form-control"
            value={moment(experienceData.startDate).format("YYYY-MM-DD")}
            // onChange={(e) =>
            //   setExperienceData({
            //     ...experienceData,
            //     startDate: e.target.value,
            //   })
            // }
            onChange={(e) => handleStartDateChange("startDate", e)}
          />
          {errors["startDate"] && <p className="error">{errors["startDate"]}</p>}
        </div>
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            {date_of_relieving}
          </label>
          <span className="required">*</span>
          <input
            type="date"
            min={moment(experienceData.startDate).format("YYYY-MM-DD")}
            className="form-control"
            value={moment(experienceData.endDate).format("YYYY-MM-DD")}
            onChange={(e) => handleEndDateChange("endDate", e)}
          // onChange={(e) =>
          //   setExperienceDetails({
          //     ...experienceDetails,
          //     endDate: e.target.value,
          //   })
          // }
          />
          {errors["endDate"] && <p className="error">{errors["endDate"]}</p>}
        </div>
        <div className="col-md-6">
          <label className="form-label">{roles_responsibility}</label>
          <span className="required">*</span>
          <textarea
            className="form-control address-text"
            value={experienceData.rolesAndResponsibility}
            onChange={(e) =>
              setExperienceData({
                ...experienceData,
                rolesAndResponsibility: e.target.value,
              })
            }
          />
          <p className="error">
            {experienceData.rolesAndResponsibility.length !== 0 &&
              (showroleErrorToggle ? valid_roles : "")}
          </p>
        </div>

        <div className="col-md-6">
          <label className="form-label">{upload_experience_relieving}</label>
          <span className="required">*</span>
          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleSubmitRelievingLetter}
              hidden
              id="actual-btn-1"
            />
            <label
              htmlFor="actual-btn-1"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <p className="image-size-condition">
            {imageSize2mb}
            <span className="required">*</span>
          </p>
          <a
            href={experienceData.relievingLetter?.document_path_url}
            target="_blank"
            className="buttonsize"
          >
            {experienceData.relievingLetter?.document_path_url
              ? "View File"
              : ""}
          </a>
          <p className="image-size-condition">
            {docFile.relievingLetter?.name}
          </p>
        </div>

        <div className="col-md-6" style={{ marginTop: "-8px" }}>
          <label className="form-label">{upload_resignation_acceptance}</label>
          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleSubmitResignationAcceptanceLetter}
              hidden
              id="actual-btn-3"
            />
            <label
              htmlFor="actual-btn-3"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <p className="image-size-condition">
            {imageSize2mb}
            <span className="required">*</span>
          </p>
          <a
            href={experienceData.resignationAcceptanceLetter?.document_path_url}
            target="_blank"
            className="buttonsize"
          >
            {experienceData.resignationAcceptanceLetter?.document_path_url
              ? "View File"
              : ""}
          </a>
          <p className="image-size-condition">
            {docFile.resignationAcceptanceLetter?.name}
          </p>
        </div>

        {experienceData.lastEmployer && (
          <>
            <div className="col-md-6" style={{ marginTop: "-8px" }}>
              <label className="form-label">{upload_payslip}</label>
              <span className="required">*</span>
              <div>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleSubmitPaySlip}
                  hidden
                  id="actual-btn-2"
                />
                <label
                  htmlFor="actual-btn-2"
                  className="btn btn-outline-secondary buttonsize"
                >
                  {uploadFile}
                </label>
              </div>
              <p className="image-size-condition">
                {imageSize2mb}
                <span className="required">*</span>
              </p>
              <a
                href={experienceData.paySlip?.document_path_url}
                target="_blank"
                className="buttonsize"
              >
                {experienceData.paySlip?.document_path_url ? "View File" : ""}
              </a>
              <p className="image-size-condition">{docFile.paySlip?.name}</p>
            </div>
          </>
        )}
      </div>

      <div className="modal-btn-wrapper mt-3">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideEditExperiences}
          >
            {cancel}
          </button>
        </div>

        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditExperiences;
