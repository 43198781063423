import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "./ExperienceCard";
import * as actions from "../../../../../Redux/Actions";
import Button from "../../../../../component/Buttons/Button";
import { icons } from "../../../../../assets";
import ModalComponents from "../../../../../component/ModalComponent/ModalComponent";
import DeleteExperiences from "./DeleteExperiences";
import EditExperiences from "./EditExperiences";
import AddExperiences from "./AddExperiences";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import next from "../../../../../assets/forward.png";
import back from "../../../../../assets/backward.png";
import useLoadingIndicator from "../../../../../component/Loader/Loader";
import { showErrorToastMessage } from "./../../../../../component/Toasts/Toasts";

function Experiences() {
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const selectedId = localStorage.getItem("selectedId");
  const status = localStorage.getItem("employeeStatus");
  const dispatch = useDispatch();
  const [employeeDetails, setEmployeeDetails] = useState<any>({
    status: "",
  });
  useEffect(() => {
    dispatch(
      actions.getemployeeDetailsAction(loginId, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              setProfile(res.data[0]);
            }
          }
        }
      })
    );
  }, [loginId]);
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();

  const [info, setInfo] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [experiences, setExperiences] = useState({
    id: "",
    companyName: "",
    designation: "",
    location: "",
    rolesAndResponsibility: "",
    startDate: "",
    endDate: "",
    relievingLetter: "",
    paySlip: "",
    resignationAcceptanceLetter: "",
    lastEmployer: "",
  });
  let navigate = useNavigate();

  const [showAddExperiences, setShowAddExperiences] = useState(false);
  const handleAddExperiences = () => setShowAddExperiences(true);
  const hideAddExperiences = () => setShowAddExperiences(false);

  const [showEditExperiences, setShowEditExperiences] = useState(false);
  const handleEditExperiences = (
    id: any,
    companyName: any,
    designation: any,
    location: any,
    rolesAndResponsibility: any,
    startDate: any,
    endDate: any,
    relievingLetter: any,
    paySlip: any,
    resignationAcceptanceLetter: any,
    lastEmployer: any
  ) => {
    setExperiences({
      id: id,
      companyName: companyName,
      designation: designation,
      location: location,
      rolesAndResponsibility: rolesAndResponsibility,
      startDate: startDate,
      endDate: endDate,
      relievingLetter: relievingLetter,
      paySlip: paySlip,
      resignationAcceptanceLetter: resignationAcceptanceLetter,
      lastEmployer: lastEmployer,
    });
    setShowEditExperiences(true);
  };
  const hideEditExperiences = () => setShowEditExperiences(false);

  const [showDeleteExperiences, setShowDeleteExperiences] = useState(false);
  const handleDeleteExperiences = (id: any) => {
    setDeleteId(id);
    setShowDeleteExperiences(true);
  };
  const hideDeleteExperiences = () => setShowDeleteExperiences(false);

  const addExperience = useSelector(
    (state: any) => state.employeeReducer.addExperienceData
  );
  const editExperience = useSelector(
    (state: any) => state.employeeReducer.editExperienceData
  );
  const deleteExperience = useSelector(
    (state: any) => state.employeeReducer.deleteExperienceData
  );

  useEffect(() => {
    showLoadingIndicator();
    dispatch(actions.getEmployeeExperienceAction());
    setTimeout(() => {
      hideLoadingIndicator();
    }, 1000);
  }, [addExperience, editExperience, deleteExperience]);

  const experienceData = useSelector(
    (state: any) => state.employeeReducer.getExperienceData
  );
  useEffect(() => {
    if (experienceData) {
      if (experienceData.data) {
        setInfo(experienceData.data);
      }
    }
  }, [experienceData, info]);

  const setProfile = (employeeData: any) => {
    setEmployeeDetails({
      status: employeeData && employeeData.status ? employeeData.status : "",
    });
  };

  return (
    <>
      {loadingComponent()}

      <div className="right__container">
        <div className="title">
          <h6>Employment History</h6>
          {(status !== "Active" && status !== "Inactive") ||
          (role === "hr" && status === "Active" && selectedId != loginId) ? (
            <>
              <div className="btn-wrapper">
                <Button
                  icon={icons.add_icon}
                  title="Add"
                  onClick={handleAddExperiences}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {info.map((experience: any, index: any) => (
          <React.Fragment key={index}>
            {(status !== "Active" && status !== "Inactive") ||
            (role === "hr" && status === "Active" && selectedId != loginId) ? (
              <>
                <Card
                  companyName={experience.companyName}
                  startDate={moment(experience.startDate).format("DD-MMM-YYYY")}
                  endDate={moment(experience.endDate).format("DD-MMM-YYYY")}
                  onClick_edit={() =>
                    handleEditExperiences(
                      experience.id,
                      experience.companyName,
                      experience.designation,
                      experience.location,
                      experience.rolesAndResponsibility,
                      experience.startDate,
                      experience.endDate,
                      experience.relievingLetter,
                      experience.paySlip,
                      experience.resignationAcceptanceLetter,
                      experience.lastEmployer
                    )
                  }
                  onClick_delete={() => handleDeleteExperiences(experience.id)}
                  designation={experience.designation}
                  location={experience.location}
                  role={experience.rolesAndResponsibility}
                ></Card>
              </>
            ) : (
              <>
                <Card
                  companyName={experience.companyName}
                  startDate={moment(experience.startDate).format("DD-MMM-YYYY")}
                  endDate={moment(experience.endDate).format("DD-MMM-YYYY")}
                  designation={experience.designation}
                  location={experience.location}
                  role={experience.rolesAndResponsibility}
                  onClick_edit={""}
                  onClick_delete={""}
                ></Card>
              </>
            )}
          </React.Fragment>
        ))}

        <ModalComponents
          show={showAddExperiences}
          onHide={hideAddExperiences}
          title="Add Emloyment History"
          modalBody={<AddExperiences hideAddExperiences={hideAddExperiences} />}
          className="modal-lg"
        />

        <ModalComponents
          show={showEditExperiences}
          onHide={hideEditExperiences}
          title="Edit Employment History"
          modalBody={
            <EditExperiences
              id={experiences.id}
              companyName={experiences.companyName}
              designation={experiences.designation}
              location={experiences.location}
              rolesAndResponsibility={experiences.rolesAndResponsibility}
              startDate={experiences.startDate}
              endDate={experiences.endDate}
              relievingLetter={experiences.relievingLetter}
              paySlip={experiences.paySlip}
              resignationAcceptanceLetter={
                experiences.resignationAcceptanceLetter
              }
              hideEditExperiences={hideEditExperiences}
              lastEmployer={experiences.lastEmployer}
            />
          }
          className="modal-lg"
        />

        <ModalComponents
          show={showDeleteExperiences}
          onHide={hideDeleteExperiences}
          title="Delete Employment History"
          modalBody={
            <DeleteExperiences
              id={deleteId}
              hideDeleteExperiences={hideDeleteExperiences}
            />
          }
          className="modal-sm"
        />
        <div className="row mt-4">
          <div className="col-2 ">
            <button
              className="btn btn-primary btn-next-pre"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Previous"
              onClick={() => navigate("/dbsidebar/certifications")}
            >
              <img src={back} alt="icon" />
              Back
            </button>
          </div>
          <div className="col-8">
            <button
              className="btn btn-primary btn-next-pre"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Save & Next"
              onClick={() => navigate("/dbsidebar/work")}
            >
              Next
              <img src={next} alt="icon" />
            </button>
          </div>
          <div className="col">
            <button
              className="btn btn-outline-primary btn-skip"
              onClick={() => navigate("/dbsidebar/work")}
            >
              Skip
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Experiences;
