import React, { useEffect, useState } from "react";
import MainCard from "../../../component/MainCard/MainCard";
import SkillCoinsTable from "./SkillCoinsTable";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";

const SkillCoin = () => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [totalEntriesLoaded, setTotalEntriesLoaded] = useState(false);
  const [info, setInfo] = useState<any>([]);
  const [clickedAdd, setClickedAdd] = useState(false);
  const [clickedDeallocate, setClickedDeallocate] = useState(false);
  const [clickedBulkUpload, setClickedBulkUpload] = useState(false);
  const role = localStorage.getItem("role");
  const [filterYearValues, setFilterYearValues] = useState<Number>();
  const [filterMonthValues, setFilterMonthValues] = useState<Number>();
  const dispatch = useDispatch();
  const filterKey = filterValue.map((ele: any) => ele.value);
  const allocateSkillCoinsData = useSelector(
    (state: any) => state.employeeReducer.assignSkillCoinsDetails
  );
  const deallocateSkillCoinsData = useSelector(
    (state: any) => state.employeeReducer.deallocateSkillCoinsDetails
  );
  const bulkAssainSkillCoins = useSelector(
    (state: any) => state.employeeReducer.assignBulkSkillCoinsDetails
  );
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: any =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch) ||
    filterYearValues ||
    filterMonthValues ||
    clickedAdd ||
    clickedDeallocate ||
    clickedBulkUpload;
  const getApi = () => {
    const payload: any = {
      page: page,
      size: pageSize,
      searchValue: searchValue,
    };
    if (filterYearValues) {
      payload.year = filterYearValues;
    }
    if (filterMonthValues) {
      payload.month = filterMonthValues;
    }
    if (!filterKey.includes("MonthAndYear")) {
      payload.searchKey = filterKey;
    } else {
      const filteredKeys = filterKey.filter(
        (key: any) => key !== "MonthAndYear"
      );
      if (filteredKeys.length > 0) {
        payload.searchKey = filteredKeys;
      }
    }
    unAuthorizeUser();
    showLoadingIndicator();
    dispatch(
      actions.getkillDataPaginationAction(payload, (res: any) => {
        if (res) {
          hideLoadingIndicator();
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
            }
          }
        }
      })
    );
  };
  useEffect(() => {
    apiCallFunction();
  }, [
    page,
    searchValue,
    searchKey,
    filterValue,
    pageSize,
    totalEntries,
    filterYearValues,
    filterMonthValues,
    bulkAssainSkillCoins,
    allocateSkillCoinsData,
    deallocateSkillCoinsData,
    condition,
  ]);

  const apiCallFunction = () => {
    const payload: any = {
      page: page,
      size: pageSize,
      searchValue: searchValue,
    };
    if (filterYearValues) {
      payload.year = filterYearValues;
    }
    if (filterMonthValues) {
      payload.month = filterMonthValues;
    }
    if (!filterKey.includes("MonthAndYear")) {
      payload.searchKey = filterKey;
    } else {
      const filteredKeys = filterKey.filter(
        (key: any) => key !== "MonthAndYear"
      );
      if (filteredKeys.length > 0) {
        payload.searchKey = filteredKeys;
      }
    }
    if (condition) {
      showLoadingIndicator();
      dispatch(
        actions.getkillDataPaginationAction(payload, (res: any) => {
          if (res) {
            hideLoadingIndicator();
            if (res.error) {
              if (res.error.error_description) {
                showErrorToastMessage(res.error.error_description);
              }
            } else {
              if (res.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setInfo(res.data);
                setTotalEntriesLoaded(true);
                setOnclickSearch(false);
              }
            }
          }
        })
      );
    }
  };

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const handleMonthChange = (filter: Number) => {
    setFilterMonthValues(filter);
    setPage(1);
  };

  const handleYearChange = (filter: Number) => {
    setFilterYearValues(filter);
    setPage(1);
  };
  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };

  return (
    <div>
      <MainCard>
        <SkillCoinsTable
          totalPages={totalPages}
          onPage={page}
          handlePageChange={handlePageChange}
          searchKey={searchKey}
          searchValue={searchValue}
          handleSearch={handleSearch}
          setSearchKey={setSearchKey}
          filterValue={filterValue}
          filterChange={filterChange}
          pagePerSize={pageSize}
          setPagePerSize={setPageSize}
          totalEntries={totalEntries}
          totalEntriesLoaded={totalEntriesLoaded}
          info={info}
          getApi={getApi}
          onSearchClicked={onSearchClicked}
          showLoadingIndicator={showLoadingIndicator}
          hideLoadingIndicator={hideLoadingIndicator}
          loadingComponent={loadingComponent}
          apiCallCompleted={apiCallCompleted}
          handleMonthChange={handleMonthChange}
          handleYearChange={handleYearChange}
          setFilterYearValues={setFilterYearValues}
          setFilterMonthValues={setFilterMonthValues}
          apiCallFunction={apiCallFunction}
          setClickedAdd={setClickedAdd}
          setClickedDeallocate={setClickedDeallocate}
          setClickedBulkUpload={setClickedBulkUpload}
          filterYearValues={filterYearValues}
          filterMonthValues={filterMonthValues}
          filterKey={filterKey}
        />
      </MainCard>
    </div>
  );
};
export default SkillCoin;
