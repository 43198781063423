import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "./DbSidebar.scss";
import MainCard from "../../../../component/MainCard/MainCard";
import ButtonRow from "../../../../component/Buttons/Button";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import * as actions from "../../../../Redux/Actions/index";
import { icons } from "../../../../assets";
import ModalComponents from "../../../../component/ModalComponent/ModalComponent";
import ReasonForReSend from "./ReasonForResend";
import Button from "../../../../component/Buttons/Button";
import download from "../../../../assets/download.png";
import upload from "../../../../assets/upload.png";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function DbSidebar() {
  const [isFresher, setIsFresher] = useState<any>(true);
  const role = localStorage.getItem("role");
  const status = localStorage.getItem("employeeStatus");
  const recordStatus = localStorage.getItem("employeeRecordStatus");
  localStorage.setItem("fresherStatus", isFresher);
  const dispatch = useDispatch();
  const eId = localStorage.getItem("selectedId");
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const loginEmployeeId = JSON.parse(userLogin).employeeId;

  let navigate = useNavigate();
  const [showReason, setShowReason] = useState(false);
  const handleReason = () => {
    setShowReason(true);
  };
  const hideModal = () => {
    setShowReason(false);
  };

  const handleBulkUpload = (e: any) => {
    dispatch(
      actions.EmployeeSkillsBulkUploadAction(
        { file: e.target.files[0] },
        (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
          } else {
            if (res.code === 200) {
              if (
                res.data.QualificationData.length > 0 ||
                res.data.CertificationData.length > 0 ||
                res.data.ExperienceData.length > 0 ||
                res.data.AchievementsData.length > 0 ||
                res.data.SkillsData.length > 0 ||
                res.data.allData.length > 0
              ) {
                var educationalQualificationsData = [
                  [
                    "Employee ID",
                    "Qualification",
                    "Branch/Stream",
                    "Institute",
                    "Board/University",
                    "Year Of Passing",
                    "Percentage/CGPA",
                  ],
                ];

                res.data.QualificationData?.forEach((ele: any) => {
                  let emptyArray: string[] = [];
                  let candidateID = ele.employeeId;
                  emptyArray.push(
                    candidateID,
                    ele.qualification,
                    ele.branch,
                    ele.institute,
                    ele.board,
                    ele.year,
                    ele.marks
                  );
                  educationalQualificationsData.push(emptyArray);
                });

                var educationalQualifications = XLSX.utils.aoa_to_sheet(
                  educationalQualificationsData
                );

                var workAchData = [
                  ["Employee ID", "Recognition Type", "Description", "Date"],
                ];
                res.data.AchievementsData?.forEach((ele: any) => {
                  let emptyArray: string[] = [];
                  let candidateID = ele.employeeId;
                  emptyArray.push(
                    candidateID,
                    ele.award,
                    ele.description,
                    ele.date
                  );
                  workAchData.push(emptyArray);
                });
                var workAch = XLSX.utils.aoa_to_sheet(workAchData);

                var skillsData = [
                  [
                    "Employee ID",
                    "Skill Name",
                    "Years of Experience",
                    "Proficiency Level",
                  ],
                ];
                res.data.SkillsData?.forEach((ele: any) => {
                  let candidateID = ele.employeeId;
                  let emptyArray: string[] = [];
                  emptyArray.push(
                    candidateID,
                    ele.skillName,
                    ele.description,
                    ele.proficiencylevel
                  );
                  skillsData.push(emptyArray);
                });
                var skills = XLSX.utils.aoa_to_sheet(skillsData);

                var employementHistoryData = [
                  [
                    "Employee ID",
                    "Company Name",
                    "Designation",
                    "Location",
                    "Roles & Responsibility",
                    "Start Date",
                    "End Date",
                  ],
                ];

                res.data.ExperienceData?.forEach((ele: any) => {
                  let candidateID = ele.employeeId;
                  let emptyArray: string[] = [];
                  emptyArray.push(
                    candidateID,
                    ele.companyName,
                    ele.designation,
                    ele.location,
                    ele.rolesAndResponsibility,
                    ele.startDate,
                    ele.endDate
                  );
                  employementHistoryData.push(emptyArray);
                });

                var employementHistory = XLSX.utils.aoa_to_sheet(
                  employementHistoryData
                );

                var certificationsData = [
                  [
                    "Employee ID",
                    "Certificate Name",
                    "Institute Name/Certifying Body",
                    "Board/Univ/Affiliation",
                    "Year Of Passing",
                    "Percentage",
                  ],
                ];

                res.data.CertificationData?.forEach((ele: any) => {
                  let candidateID = ele.employeeId;
                  let emptyArray: string[] = [];
                  emptyArray.push(
                    candidateID,
                    ele.nameOfCertification,
                    ele.institute,
                    ele.board,
                    ele.year,
                    ele.marks
                  );
                  certificationsData.push(emptyArray);
                });

                var certifications =
                  XLSX.utils.aoa_to_sheet(certificationsData);

                var empIdMismatchData = [
                  [
                    "Employee ID",
                    "Qualification",
                    "Branch/Stream",
                    "Institute",
                    "Board/University",
                    "Year Of Passing",
                    "Percentage/CGPA",
                    "Certificate Name",
                    "Institute Name/Certifying Body",
                    "Board/Univ/Affiliation",
                    "Year Of Passing",
                    "Percentage",
                    "Company Name",
                    "Designation",
                    "Location",
                    "Roles & Responsibility",
                    "Start Date",
                    "End Date",
                    "Skill Name",
                    "Years of Experience",
                    "Proficiency Level",
                    "Recognition Type",
                    "Description",
                    "Date",
                  ],
                ];

                res.data.allData?.forEach((ele: any) => {
                  let emptyArray: string[] = [];
                  emptyArray.push(
                    ele.employeeId,
                    ele.qualification,
                    ele.branch,
                    ele.instituteQualification,
                    ele.boardQualification,
                    ele.yearQualification,
                    ele.marksQualification,
                    ele.nameOfCertification,
                    ele.institute,
                    ele.board,
                    ele.year,
                    ele.marks,
                    ele.companyName,
                    ele.designation,
                    ele.location,
                    ele.rolesAndResponsibility,
                    ele.startDate,
                    ele.endDate,
                    ele.skillName,
                    ele.description,
                    ele.proficiencylevel,
                    ele.award,
                    ele.descriptionWork,
                    ele.date
                  );
                  empIdMismatchData.push(emptyArray);
                });

                var empIdMismatch = XLSX.utils.aoa_to_sheet(empIdMismatchData);

                const wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(
                  wb,
                  educationalQualifications,
                  "Educational Qualifications"
                );
                XLSX.utils.book_append_sheet(
                  wb,
                  certifications,
                  "Certifications"
                );
                XLSX.utils.book_append_sheet(
                  wb,
                  employementHistory,
                  "Employement History"
                );
                XLSX.utils.book_append_sheet(wb, workAch, "Work Achievements");
                XLSX.utils.book_append_sheet(wb, skills, "Skills");
                XLSX.utils.book_append_sheet(
                  wb,
                  empIdMismatch,
                  "Employee ID Mismatch"
                );

                XLSX.writeFile(wb, "EmployeeDetails_Fault_Records.xlsx");
              }
              showSuccessToastMessage("Uploaded Successfully");
            }
          }
        }
      )
    );
  };

  const handleStatus = (statusData: any) => {
    const confirmed = window.confirm(
      statusData == "Approved"
        ? "Are you sure you want to approve? Employee will be no longer able to update their details once approved."
        : statusData == "Published"
        ? "Are you sure You want to Publish?"
        : statusData == "Re-Published"
        ? "Are you sure You want to Re-Publish?"
        : ""
    );
    if (confirmed) {
      dispatch(
        actions.editEmployeeDataAction(
          {
            eId: eId,
            updatedBy: loginId,
            recordStatus: statusData,
          },
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                if (res.data.recordStatus == "Approved") {
                  showSuccessToastMessage("Approved Successfully!");
                } else if (res.data.recordStatus == "Published") {
                  showSuccessToastMessage("Published Successfully!");
                } else if (res.data.recordStatus == "Re-Published") {
                  showSuccessToastMessage("Re-Published Successfully!");
                }
                {
                  () => navigate("/");
                }
              }
            }
          }
        )
      );
    } else {
    }
  };
  return (
    <>
      <MainCard heading="Employee Details">
        <div className="row">
          <div className="col-6">
            <div className="form-label mt-2 p-2 fresherbox">
              <input
                type="checkbox"
                id="checkBox"
                onClick={() => setIsFresher(!isFresher)}
              />{" "}
              Click here if you are fresher
            </div>
          </div>
          {loginId == eId ? (
            <></>
          ) : (
            <div className="col-6">
              <div className="btn-wrapper">
                <a
                  href="https://management-spotlight.sgp1.digitaloceanspaces.com/employee/Employee-Database-Professional-Details-Template-File/bbb4376a-2a88-4776-84e4-5367720a1c81.csv"
                  download={"tempfile.csv"}
                  style={{ textDecoration: "none" }}
                >
                  <Button title="Template" icon={download} />
                </a>
                <input
                  type="file"
                  className="form-control"
                  accept={".csv"}
                  onChange={handleBulkUpload}
                  hidden
                  id="bulk_upload"
                />
                <label htmlFor="bulk_upload" className="button">
                  <img src={upload} className="button-icon" alt="icon" />
                  Bulk Upload
                </label>
              </div>
            </div>
          )}
          <div className="left__card">
            <div className="sidebar">
              <ul>
                <div className="sidebardata">
                  <NavLink to="qualifications" className="Link">
                    Educational Qualifications
                  </NavLink>
                </div>
                <div className="sidebardata">
                  <NavLink to="certifications" className="Link">
                    Certifications
                  </NavLink>
                </div>
                {isFresher && (
                  <div className="sidebardata">
                    <NavLink to="experience" className="Link">
                      Employment History
                    </NavLink>
                  </div>
                )}
                <div className="sidebardata">
                  <NavLink to="work" className="Link">
                    Work Achievements
                  </NavLink>
                </div>
                <div className="sidebardata">
                  <NavLink to="skills" className="Link">
                    Skills
                  </NavLink>
                </div>
              </ul>
            </div>

            <div className="right__card">
              <Outlet />
            </div>
          </div>
          <div className="row justify-content-center mt-3 ">
            {eId === loginId ? (
              status == "Active" ? (
                <></>
              ) : (
                <ButtonRow
                  title={(recordStatus == "Draft" || recordStatus =="New" )? "Publish" : "Re-Publish"}
                  icon={icons.select_icon}
                  onClick={() => {
                    (recordStatus === "Draft" || recordStatus === "New")
                  ? handleStatus("Published")
                  : handleStatus("Re-Published");
                  }}
                />
              )
            ) : (
              <>
                {status !== "Active" && status !== "Inactive" ? (
                  <>
                    {loginEmployeeId == 10228 ? (
                      <>
                        <div className="col-auto">
                          <button
                            className="btn btn-success wip-btn buttonsize"
                            onClick={() => {
                              handleStatus("Approved");
                            }}
                          >
                            Approve
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <>
                      <div className="col-auto">
                        <button
                          className="btn btn-warning delivery-btn buttonsize"
                          onClick={() => {
                            handleReason();
                          }}
                        >
                          Re-Send
                        </button>
                      </div>
                    </>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </MainCard>

      <ModalComponents
        show={showReason}
        onHide={hideModal}
        title="Reason for Re-send"
        modalBody={<ReasonForReSend hideModal={hideModal} />}
        className="modal-md"
      />
    </>
  );
}

export default DbSidebar;
