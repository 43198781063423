import { useEffect, useRef, useState } from "react";
import * as actions from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import {
  Strings,
  competencyValidationStrings,
} from "../../../constant/Strings";
import useLoadingIndicator from "../../../component/Loader/Loader";
import Select from "react-select";

const AddorEditCertifications = (props: any) => {
  const {
    editData,
    loginId,
    setShowAddOrEditCertifications,
    setEditData,
    handleDownload,
    handleApiCall,
  } = props;
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const [btnDisable, setBtnDisable] = useState(false);

  const formatDate = (isoString: any) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [certificateForm, setCertificateForm] = useState<any>({
    competancy: editData
      ? {
          label: editData.employeeCompetencyData[0].competencyNumber,
          value: editData.competencyRef,
        }
      : "",
    certificateName: editData.certificationName ?? "",
    instituteName: editData.instituteName ?? "",
    dateOfCompletion: formatDate(editData.completionDate) ?? "",
    uploadCertificate: editData.uploadCertificate ?? "",
  });

  const [errors, setErrors] = useState({
    certificateName: "",
    instituteName: "",
  });

  const { submitSuccess, cancel, submit, editSuccess } = Strings;
  const [competancyOptions, setCompentancyOption] = useState([]);

  useEffect(() => {
    if (
      certificateForm.competancy &&
      certificateForm.certificateName &&
      certificateForm.instituteName &&
      certificateForm.dateOfCompletion &&
      certificateForm.uploadCertificate
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [certificateForm]);

  const dispatch = useDispatch();
  const handleClearForm = () => {
    setCertificateForm({
      competancy: "",
      certificateName: "",
      instituteName: "",
      dateOfCompletion: "",
      uploadCertificate: "",
    });
  };

  let valid: boolean = true;
  const validate = () => {
    if (certificateForm.certificateName == "") {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        certificateName: "certificateName cannot be empty",
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        certificateName: "",
      }));
    }

    if (certificateForm.instituteName == "") {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        instituteName: "instituteName cannot be empty",
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        instituteName: "",
      }));
    }
  };
  const handleCompetencySubmit = (e: any) => {
    e.preventDefault();
    validate();
    if (valid) {
      handleDispatch();
      setBtnDisable(true);
    }
    setBtnDisable(false);
  };
  const handleDispatch = () => {
    showLoadingIndicator();
    const payloadObj = {
      competencyRef: certificateForm.competancy?.value,
      employeeRef: loginId,
      certificationName: certificateForm.certificateName,
      instituteName: certificateForm.instituteName,
      completionDate: certificateForm.dateOfCompletion,
      uploadCertificate: certificateForm.uploadCertificate,
    };
    if (editData) {
      dispatch(
        actions.UpdateCertificateAction(
          editData._id,
          payloadObj,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
              hideLoadingIndicator();
            } else {
              if (res?.code === 200) {
                showSuccessToastMessage(editSuccess);
                handleClearForm();
                hideLoadingIndicator();
                setShowAddOrEditCertifications(false);
                setEditData("");
                handleApiCall();
              }
            }
          }
        )
      );
    } else {
      dispatch(
        actions.AddCertificateAction(payloadObj, (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
            hideLoadingIndicator();
          } else {
            if (res?.code === 200) {
              showSuccessToastMessage(submitSuccess);
              handleClearForm();
              hideLoadingIndicator();
              setShowAddOrEditCertifications(false);
              setEditData("");
              handleApiCall();
            }
          }
        })
      );
    }
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleSubmituploadFile = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      // 2 MB limit
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      clearFileInput();
      return;
    } else if (
      ![
        "image/jpeg",
        "image/png",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      clearFileInput();
      return;
    } else {
      dispatch(
        actions.uploadCertificateAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setCertificateForm({
                ...certificateForm,
                uploadCertificate: res.data,
              });
            }
          }
        )
      );
    }
  };

  useEffect(() => {
    if (loginId) {
      dispatch(
        actions.getCompetancyListAction(loginId, (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
          } else {
            if (res.code === 200) {
              const options = res.data.map((item: any) => ({
                label: item.competencyNumber,
                value: item._id,
              }));
              setCompentancyOption(options);
            }
          }
        })
      );
    }
  }, []);
  return (
    <>
      <div className="row g-3">
        <div className="col-md-4">
          <label className="form-label">Competency</label>
          <span className="required">*</span>
          <Select
            className="dropdown-select"
            options={competancyOptions}
            onChange={(option: any) => {
              setCertificateForm({
                ...certificateForm,
                competancy: option,
              });
            }}
            closeMenuOnSelect={true}
            placeholder="Select Competancy"
            value={certificateForm.competancy}
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">Certification name</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            placeholder="Certification name"
            value={certificateForm.certificateName}
            onChange={(e) =>
              setCertificateForm({
                ...certificateForm,
                certificateName: e.target.value,
              })
            }
          />
          {errors.certificateName != "" && (
            <p className="error">{errors.certificateName}</p>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">Institute name/Certifying body</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            placeholder="Institute name"
            value={certificateForm.instituteName}
            onChange={(e) =>
              setCertificateForm({
                ...certificateForm,
                instituteName: e.target.value,
              })
            }
          />
          {errors.instituteName != "" && (
            <p className="error">{errors.certificateName}</p>
          )}
        </div>

        <div className="col-md-4">
          <label className="form-label">Date of Completion</label>
          <span className="required">*</span>
          <input
            type="date"
            className="form-control"
            placeholder="Date of Completion"
            value={certificateForm.dateOfCompletion}
            onChange={(e) =>
              setCertificateForm({
                ...certificateForm,
                dateOfCompletion: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">Upload Certificate</label>
          <span className="required">*</span>
          <div>
            <input
              type="file"
              className="form-control"
              onChange={handleSubmituploadFile}
              ref={fileInputRef}
              // value={certificateForm.uploadCertificate.document_name}
            />
          </div>
          <div
            className="certificate-view"
            onClick={() =>
              handleDownload(certificateForm.uploadCertificate.document_name)
            }
          >
            View File
          </div>
          {/* <p className="image-size-condition assessmet-veiw" onClick={() => handleDownload(certificateForm.uploadCertificate.document_name)}>

                        {docFile.uploadCertificate?.name || docFile.uploadCertificate?.document_name}
                    </p> */}
        </div>

        <div className="modal-btn-wrapper mt-4 mb-4">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={() => {
                setEditData("");
                setShowAddOrEditCertifications(false);
              }}
            >
              {cancel}
            </button>
          </div>

          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleCompetencySubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddorEditCertifications;
