import React, { useEffect, useState } from "react";
import * as actions from "../../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import { Strings, requiredFeild } from "../../../../constant/Strings";
import {
  employeeMobileNumberValidtion,
  isEmailValid,
  whiteSpaceValid,
} from "../../../../constant/Validations";

const EditPersonalContactInfo = (props: any) => {
  const {
    personalMail,
    mobileNumber,
    alternateNumber,
    emergencyContactNumber,
    handleEditPersonalInfo,
    eId,
    status,
    apiCallCompleted
  } = props;
  const { required } = requiredFeild;
  const {
    personal_email_iD,
    mobile_number,
    alternate_number,
    emergency_no,
    valid_mobile_no,
    valid_email_id,
    valid_alternate_no,
    valid_emergency_no,
    cancel,
    submit,
    editSuccess,
  } = Strings;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const [employeeData, setEmployeeData] = useState({
    personalMail: personalMail,
    mobileNumber: mobileNumber,
    alternateNumber: alternateNumber,
    emergencyContactNumber: emergencyContactNumber,
    eId: eId,
    updatedBy: eId,
  });
  useEffect(() => {
    if (
      employeeData.personalMail &&
      employeeData.mobileNumber &&
      employeeData.alternateNumber &&
      employeeData.emergencyContactNumber
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });
  const [errors, setErrors] = useState({
    personalMail: "",
    mobileNumber: "",
    alternateNumber: "",
    emergencyContactNumber: "",
    emergencyContactName: "",
  });

  const [btnDisable, setBtnDisable] = useState(false);

  const dispatch = useDispatch();

  let valid: boolean = true;
  const validate = () => {
    if (
      isEmailValid(employeeData.personalMail) == false ||
      whiteSpaceValid(employeeData.personalMail) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        personalMail: valid_email_id,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, personalMail: "" }));
    }
    if (
      employeeMobileNumberValidtion(employeeData.mobileNumber) == false ||
      whiteSpaceValid(employeeData.mobileNumber) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        mobileNumber: valid_mobile_no,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, mobileNumber: "" }));
    }
    if (
      employeeMobileNumberValidtion(employeeData.alternateNumber) == false ||
      whiteSpaceValid(employeeData.alternateNumber) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        alternateNumber: valid_alternate_no,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, alternateNumber: "" }));
    }
    if (
      employeeMobileNumberValidtion(employeeData.emergencyContactNumber) ==
        false ||
      whiteSpaceValid(employeeData.emergencyContactNumber) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        emergencyContactNumber: valid_emergency_no,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, emergencyContactNumber: "" }));
    }
  };
  const handleEdit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };

  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editEmployeeDataAction(employeeData, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            }
          } else {
            if (res.code === 200) {
              showSuccessToastMessage(editSuccess);
              apiCallCompleted(true);
            }
          }
        }
      })
    );
    handleEditPersonalInfo();
  };
  const disableCondition: any =
    (!(role && role.includes("hr")) && status == "Active") ||
    status == "Inactive" ||
    ((role && role.includes("hr")) && status == "Active" && eId == loginId);
  return (
    <div>
      <div className="row g-4">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {personal_email_iD} <span className="required">{required}</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.personalMail}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                personalMail: e.target.value,
              });
            }}
          />
          <p className="error">{errors.personalMail}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {mobile_number} <span className="required">{required}</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.mobileNumber}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                mobileNumber: e.target.value,
              });
            }}
            maxLength={15}
          />
          <p className="error">{errors.mobileNumber}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {alternate_number} <span className="required">{required}</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.alternateNumber}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                alternateNumber: e.target.value,
              });
            }}
            maxLength={15}
          />
          <p className="error">{errors.alternateNumber}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">
            {emergency_no} <span className="required">{required}</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.emergencyContactNumber}
            disabled={disableCondition}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                emergencyContactNumber: e.target.value,
              });
            }}
            maxLength={15}
          />
          <p className="error">{errors.emergencyContactNumber}</p>
        </div>
      </div>

      <div className="modal-btn-wrapper mt-4 mb-4">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={handleEditPersonalInfo}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditPersonalContactInfo;
