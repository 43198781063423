import React, { useEffect, useState } from "react";
import MainCard from "../../../component/MainCard/MainCard";
import { Strings } from "../../../constant/Strings";
import "../CoachingProgress/Coaching.scss";
import { CardBody } from "reactstrap";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions/Action";
import { useDispatch, useSelector } from "react-redux";
import useLoadingIndicator from "../../../component/Loader/Loader";
import {
  addSessionLabel,
  requiredFeild,
} from "../../../constant/Strings/index";

const AddSessionCard = () => {
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const { required } = requiredFeild;
  const dispatch = useDispatch();
  const {
    createNewSession,
    newSession,
    addedSessionSuccessfully,
    assignmentUpload,
    uploadFile,
    imageSize2mb,
  } = Strings;
  const { topicName, sessionDate, homeWork, dueDate } = addSessionLabel;
  const { loginId, id } = useParams();
  const [info, setInfo] = useState<any>([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [sessionCount, setSessionCount] = useState("");
  const [addSessionData, setAddSessionData] = useState<any>({
    topicName: "",
    sessionDate: "",
    dueDate: "",
    homeWork: "",
    coachRef: loginId,
    employeeRef: id,
    coachUploadFile: "",
    employeeUploadFile: "",
    sessionNumber: null,
  });
  const handleClearForm = () => {
    setAddSessionData({
      sessionNumber: null,
      topicName: "",
      sessionDate: "",
      dueDate: "",
      homeWork: "",
      coachRef: "",
      employeeRef: "",
      coachUploadFile: "",
      employeeUploadFile: "",
    });
  };
  const getAllSessionByEmpId = useSelector(
    (state: any) => state.employeeReducer.getAllSessionByEmpIdDetails
  );
  useEffect(() => {
    showLoadingIndicator();
    dispatch(
      actions.getAllSessionByEmpIdAction(id, loginId, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
        } else {
          if (res.code === 200) {
            setAddSessionData((obj: any) => ({
              ...obj,
              sessionNumber: res.data.length + 1,
            }));
            hideLoadingIndicator();
          }
        }
      })
    );
  }, []);

  useEffect(() => {
    if (getAllSessionByEmpId) {
      if (getAllSessionByEmpId.data) {
        setInfo(getAllSessionByEmpId.data);
      }
    }
  }, [getAllSessionByEmpId, info]);
  const [docFile, setDocFile] = useState<any>({
    coachUploadFile: null,
  });
  const handleSubmituploadFile = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      // 2 MB limit
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, coachUploadFile: e.target.files[0] });

      dispatch(
        actions.coachEmployeeDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setAddSessionData({
                ...addSessionData,
                coachUploadFile: res.data,
              });
            }
          }
        )
      );
    }
  };

  const handleSessionSubmit = () => {
    setAddSessionData((obj: any) => ({
      ...obj,
      sessionNumber: sessionCount + 1,
    }));
    handleDispatch();
  };
  const handleDispatch = () => {
    dispatch(
      actions.addNewSessionAction(addSessionData, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
        } else {
          if (res.code === 200) {
            showSuccessToastMessage(addedSessionSuccessfully);
            window.location.reload();
          }
        }
      })
    );
    handleClearForm();
  };

  useEffect(() => {
    if (
      addSessionData.topicName &&
      addSessionData.sessionDate &&
      addSessionData.homeWork &&
      addSessionData.dueDate &&
      addSessionData.coachUploadFile
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });
  return (
    <>
      <MainCard>
        <div className="coaching-progress-heading-button-wrapper">
          <div>
            <h2>{createNewSession}</h2>
          </div>
        </div>
        <div className="mt-4">
          <Card>
            <CardBody>
              <p className="new-session">{newSession}</p>
              <div className="row">
                <div className=" col-8 titlefont">
                  <label htmlFor="inputCity">{topicName}</label>
                  <span className="required">{required}</span>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCity"
                    value={addSessionData.topicName}
                    onChange={(e: any) =>
                      setAddSessionData({
                        ...addSessionData,
                        topicName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-2 titlefont">
                  <label htmlFor="inputState">{sessionDate}</label>
                  <span className="required">{required}</span>
                  <input
                    type="date"
                    className="form-control"
                    id="inputCity"
                    value={addSessionData.sessionDate}
                    onChange={(e: any) =>
                      setAddSessionData({
                        ...addSessionData,
                        sessionDate: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-8 titlefont">
                  <label htmlFor="inputState">{homeWork}</label>
                  <span className="required">{required}</span>
                  <textarea
                    style={{ height: "100px" }}
                    className="form-control"
                    value={addSessionData.homeWork}
                    onChange={(e: any) =>
                      setAddSessionData({
                        ...addSessionData,
                        homeWork: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-2 titlefont">
                  <label htmlFor="inputState">{dueDate}</label>
                  <span className="required">{required}</span>
                  <input
                    type="date"
                    className="form-control"
                    id="inputCity"
                    value={addSessionData.dueDate}
                    onChange={(e: any) =>
                      setAddSessionData({
                        ...addSessionData,
                        dueDate: e.target.value,
                      })
                    }
                  />

                  <div className="col-12 mt-2 titlefont">
                    <label className="form-label">{assignmentUpload}</label>
                    <span className="required">{required}</span>
                    <div>
                      <input
                        type="file"
                        className="form-control"
                        onChange={handleSubmituploadFile}
                        hidden
                        id={id}
                      />
                      <label htmlFor={id} className="btn upload-file-button">
                        {uploadFile}
                      </label>
                    </div>
                    <p className="form-label">
                      {imageSize2mb}
                      <span className="required">{required}</span>
                    </p>
                    <a
                      href={addSessionData.coachUploadFile?.document_path_url}
                      target="_blank"
                      className="buttonsize"
                    >
                      {addSessionData.coachUploadFile?.document_path_url
                        ? "View File"
                        : ""}
                    </a>
                    <p className="image-size-condition">
                      {docFile.coachUploadFile?.name}
                    </p>
                  </div>
                </div>
              </div>

              <div className="modal-btn-wrapper mx-auto">
                <div>
                  <button
                    className={
                      btnDisable
                        ? "btn add-session-button buttonsize"
                        : "btn add-session-button add-session-btn-disabled buttonsize"
                    }
                    onClick={handleSessionSubmit}
                  >
                    Add
                  </button>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </MainCard>
    </>
  );
};

export default AddSessionCard;
