import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { showSuccessToastMessage } from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";

const EditCareerGoals = (props: any) => {
  const { id, title, description, hideEditCareerGoals } = props;
  const [careerGoalsData, setCareerGoalsData] = useState({
    title: title,
    description: description,
  });

  const dispatch = useDispatch();
  const handleEdit = () => {
    dispatch(actions.editEmployeeCareerGoalsAction(id, careerGoalsData));
    hideEditCareerGoals();
    showSuccessToastMessage("Edited Successfully!");
  };

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            Title
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={careerGoalsData.title}
            onChange={(e) =>
              setCareerGoalsData({
                ...careerGoalsData,
                title: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">Description</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={careerGoalsData.description}
            onChange={(e) =>
              setCareerGoalsData({
                ...careerGoalsData,
                description: e.target.value,
              })
            }
          />
        </div>

        <div className="col-md-4">
          <label className="form-label">Certificate</label>
          <span className="required">*</span>
          <input type="file" className="form-control" />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-2">
          <button
            className="btn btn-outline-danger"
            onClick={hideEditCareerGoals}
          >
            Cancel
          </button>
        </div>

        <div className="col-md-2">
          <button className="btn btn-primary" onClick={handleEdit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCareerGoals;
