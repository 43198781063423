import React from 'react';
import "./Card.scss";

const Card = (props:any) => {
  const {children} = props;
  return (
    <div className='card-container'>{children}</div>
  )
}

export default Card