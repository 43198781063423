import { useState } from "react";
import { useDispatch } from "react-redux";
import { showSuccessToastMessage } from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";

const EditPreferences = (props: any) => {
  const{
    id,
    preferredLocation,
    preferredMode,
    preferredCompany,
    hideEditPreferences
  } = props;
  const[preferences, setPreferences] = useState({
    id: id,
    preferredLocation: preferredLocation,
    preferredMode: preferredMode,
    preferredCompany: preferredCompany

  });
  const dispatch = useDispatch();
  const handleEdit = () => {
    dispatch(actions.editEmployeePreferenceAction(id, preferences))
    hideEditPreferences();
    showSuccessToastMessage("Edited Successfully!");
  }
  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            Preferred Location
          </label>
          <span className="required">*</span>
          <input type="text"
            className="form-control"
            value={preferences.preferredLocation}
            onChange={(e) =>
              setPreferences({
                ...preferences,
                preferredLocation: e.target.value,
              })
            }/>
        </div>
        <div className="col-md-4">
          <label className="form-label">
            Preferred Mode
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={preferences.preferredMode}
            onChange={(e) =>
              setPreferences({
                ...preferences,
                preferredMode: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">
            Preferred Company
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={preferences.preferredCompany}
            onChange={(e) =>
              setPreferences({
                ...preferences,
                preferredCompany: e.target.value,
              })
            }
          />

        </div>

      </div>
      <div className="row mt-3">
        <div className="col-md-2">
          <button className="btn btn-outline-danger" onClick={hideEditPreferences}>Cancel</button>
        </div>

        <div className="col-md-2">
          <button className="btn btn-primary" onClick={handleEdit}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default EditPreferences;
