import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions/index";
import { Strings } from "../../../constant/Strings";

function RedeemSkillCoins(props: any) {
  const { redeem_label, cancel_label, submit_label } = Strings;
  const { hideRedeemSkillCoins, skillCoinsId, apiCallCompleted } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  const [redeemSkillCoins, setRedeemSkillCoins] = useState({
    skillcoins: "",
  });
  useEffect(() => {
    if (redeemSkillCoins.skillcoins) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [redeemSkillCoins]);

  const dispatch = useDispatch();
  const handleRedeemSkillCoins = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editRedeemSkillCoinsAction(
        skillCoinsId,
        redeemSkillCoins,
        (res: any) => {
          if (res) {
            if (res.data.error) {
              if (res.data.error.error_description) {
                showErrorToastMessage(res.data.error.error_description);
                apiCallCompleted(true);
              }
            } else if (res.code === 200) {
              showSuccessToastMessage("Redeeemed Successfully!");
              apiCallCompleted(true);
            }
          }
        }
      )
    );
    hideRedeemSkillCoins();
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">{redeem_label}</label>
            <input
              type="text"
              className="form-control"
              style={{ backgroundColor: "#FFF9F4" }}
              onChange={(e) =>
                setRedeemSkillCoins({
                  ...redeemSkillCoins,
                  skillcoins: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="modal-btn-wrapper mt-3 mx-auto">
          <div>
            <button
              className="btn btn-outline-danger buttonsize"
              onClick={hideRedeemSkillCoins}
            >
              {cancel_label}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn btn-primary buttonsize"
                  : "btn btn-disabled buttonsize"
              }
              onClick={handleRedeemSkillCoins}
            >
              {submit_label}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RedeemSkillCoins;
