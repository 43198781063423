import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as actions from "../../../Redux/Actions/Action";
import MainCard from "../../../component/MainCard/MainCard";
import SearchBar from "../../../component/SearchBar/SearchBar";
import { Button } from "react-bootstrap";
import AssignSessionCard from "./AssignSessionCard";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import SessionStatusModal from "./SessionStatusModal";
import moment from "moment";
import "./Coaching.scss";
import useLoadingIndicator from "../../../component/Loader/Loader";
import "../CoachingProgress/Coaching.scss";
import { Strings } from "../../../constant/Strings";
import TablePagination from "../../../component/TablePagination/TablePagination";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";

const SessionCard = (props: any) => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const { loadingComponent } = useLoadingIndicator();
  const userLogin: any = localStorage.getItem("login");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState<Number>(1);
  const [pageSize, setPageSize] = useState(10);
  const loginId = JSON.parse(userLogin).id;
  let { id } = useParams();
  const { coachingPro } = Strings;
  const [info, setInfo] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const [btnDisable, setBtnDisable] = useState(false);
  const sessionCount = info.length;
  const [addSessionData, setAddSessionData] = useState<any>({
    sessionNumber: null,
    topicName: "",
    sessionDate: "",
    dueDate: "",
    homeWork: "",
    coachRef: loginId,
    employeeRef: id,
    coachUploadFile: "",
    employeeUploadFile: "",
  });

  const [sessionId, setSessionId] = useState("");

  const addNewSession = useSelector(
    (state: any) => state.employeeReducer.addNewSessionDetails
  );

  const updateHwStatus = useSelector(
    (state: any) => state.employeeReducer.coachUpdateHwStatusDetails
  );
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const condition: boolean = searchValue == "" || searchValue != "";
  useEffect(() => {
    unAuthorizeUser();
    if (condition) {
      dispatch(
        actions.getAllSessionByEmpIdAction(
          page,
          pageSize,
          searchValue,
          id,
          loginId,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                setTotalPages(res.meta_data.totalPageCount);
              }
            }
          }
        )
      );
    }
  }, [addNewSession, updateHwStatus, page, pageSize, searchValue]);

  const getAllSessionByEmpId = useSelector(
    (state: any) => state.employeeReducer.getAllSessionByEmpIdDetails
  );
  useEffect(() => {
    if (getAllSessionByEmpId) {
      if (getAllSessionByEmpId.data) {
        setInfo(getAllSessionByEmpId.data);
      }
    }
  }, [getAllSessionByEmpId, info]);
  useEffect(() => {
    setAddSessionData({
      topicName: "",
      sessionDate: "",
      dueDate: "",
      homeWork: "",
      coachRef: loginId,
      employeeRef: id,
      coachUploadFile: "",
      employeeUploadFile: "",
      sessionNumber: sessionCount + 1,
    });
  }, [sessionCount]);

  const [showStatusModal, setShowStatusModal] = useState(false);
  const handleStatus = (id: any) => {
    setShowStatusModal(true);
    setSessionId(id);
  };
  const hideStatusModal = () => {
    setShowStatusModal(false);
  };

  useEffect(() => {
    if (
      addSessionData.topicName &&
      addSessionData.sessionDate &&
      addSessionData.homeWork &&
      addSessionData.dueDate &&
      addSessionData.coachUploadFile
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };
  const handlePageChange = (newPage: any) => {
    setPage(newPage);
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="coaching-progress-heading-button-wrapper">
          <div>
            <h2>{coachingPro}</h2>
          </div>
          <div className="btn-wrapper">
            <Button
              title="Coaching Requests"
              onClick={() => {
                navigate(`/assign-session-card/add-session/${loginId}/${id}`);
              }}
              className="coaching-progress-view-btn"
            >
              New Session
            </Button>
          </div>
        </div>
        <div className="mt-2">
          <div
            className="mt-3 buttonsize filter-assessment-wrapper"
            style={{ marginLeft: "0px" }}
          >
            <div className="coaching-progress-searchbar-wrapper mx-2">
              <SearchBar filter={searchValue} setFilter={handleSearch} />
            </div>
          </div>
          {info.length > 0 &&
            info.map((jobsData: any) => (
              <AssignSessionCard
                key={jobsData._id}
                employeeUploadFile={jobsData.employeeUploadFile}
                coachUploadFile={jobsData.coachUploadFile}
                sessionNumber={jobsData.sessionNumber}
                topicName={jobsData.topicName}
                sessionDate={moment(jobsData.sessionDate).format(
                  "DD-MMM-YYYY HH:MM"
                )}
                dueDate={moment(jobsData.dueDate).format("DD-MMM-YYYY HH:MM")}
                homeWork={jobsData.homeWork}
                onClick={() => handleStatus(jobsData._id)}
                feedback={jobsData.employeeFeedBack}
                rating={jobsData.employeeRating}
                status={`${
                  jobsData.homeWorkStatus == "0"
                    ? "WIP"
                    : jobsData.homeWorkStatus == "1"
                    ? "Completed"
                    : jobsData.homeWorkStatus == "2"
                    ? "ReWork"
                    : ""
                }`}
              ></AssignSessionCard>
            ))}
          {info.length > 0 ? (
            <TablePagination
              totalPages={totalPages}
              currentPage={page}
              onClick={handlePageChange}
            />
          ) : (
            <></>
          )}
        </div>
      </MainCard>
      <ModalComponents
        show={showStatusModal}
        onHide={hideStatusModal}
        title={"Update Status"}
        modalBody={
          <SessionStatusModal
            hideStatusModal={hideStatusModal}
            sessionId={sessionId}
          />
        }
        className="modal-md"
      />
    </>
  );
};

export default SessionCard;
