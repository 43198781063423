import { useEffect, useRef, useState, } from "react";
import Card from "../../../component/Card/Card";
import "./ProfileInfo.scss";
import { icons } from "../../../assets";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { useDispatch } from "react-redux";
import EditButton from "../../../component/Buttons/EditButton/EditButton";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import EditProfileSummary from "../PersonalInfo/EditProfileSummary/EditProfileSummary";
import { showErrorToastMessage, showSuccessToastMessage } from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions";
import moment from "moment";
import Header from "../../../assets/SkillmineLogoPng.png";
import PdfModal from "../PersonalInfo/PdfModal/PdfModal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { MdOutlineEdit } from "react-icons/md";
import { Strings } from "../../../constant/Strings";

const ProfileInfo = (props: any) => {
  const { summary, getApiDispatch, resume, employeeId, candidateName, middleName, lastName,
    emailId, profileImage,
    designation ,draftSummary} = props;
  const role = localStorage.getItem("role");
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const [docFile, setDocFile] = useState<any>({
    data: null,
  });
  const [pData, setPData] = useState(false);
  const [pdfData, setPdfData] = useState<any>();
  const [previewPdf, setPreviewPdf] = useState(false);
  const pdfContentRef = useRef(null);
  const [employeeResumeModal, setEmployeeResumeModal] = useState(false)
  const [editProfileSummary, setEditProfileSummary] = useState(false);
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  const { clickUpload,
    dragDrop,
    fileTypeSize } = Strings;

  const dispatch = useDispatch();
  const { default_avatar, edit_orange_icon, upload_icon } = icons;

  const handleProfileSummary = () => {
    setEditProfileSummary(!editProfileSummary);
  };

  const getResumeApiDispatch = (e: any) => {
    e.preventDefault();
    dispatch(
      actions.generateResumeDataAction((res: any) => {
        if (res?.error?.error_description) {
          hideLoadingIndicator();
          showErrorToastMessage(res.error.error_description);
        } else if (res?.code === 200) {
          setPdfData(res.data[0]);
          setPreviewPdf(true)
          hideLoadingIndicator();
        }
      })
    );
  };
  const printPdf = () => {
    setPData(!pData);
    const pdfContentDiv: any = document.getElementById("pdf-content-profile");
    if (pdfContentDiv) {
      showLoadingIndicator();
      pdfContentDiv.style.display = "block";
    }
  };
  useEffect(() => {
    const loadImage = (src: string) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    const loadImages = async () => {
      const images = Array.from(
        document.querySelectorAll<HTMLImageElement>("#pdf-content-profile img")
      );
      const promises = images.map((img) => loadImage(img.src));
      await Promise.all(promises);
    };
    const generatePdf = async () => {
      const pdfContentDiv = document.getElementById("pdf-content-profile");
      if (pdfContentDiv) {
        if (pdfContentDiv.style.display === "block") {
          await loadImages();
          const pdfDocument = new jsPDF();
          const canvas = await html2canvas(pdfContentDiv, {
            useCORS: true,
            allowTaint: true,
          });

          const imgData = canvas.toDataURL("image/png");
          const imgProps = pdfDocument.getImageProperties(imgData);
          const pdfWidth = pdfDocument.internal.pageSize.getWidth() - 20;
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdfDocument.addImage(imgData, "PNG", 10, 10, pdfWidth, pdfHeight);
          pdfDocument.save(
            `${employeeId + "_" + candidateName + "_"}Resume.pdf`
          );
          pdfContentDiv.style.display = "none";
          hideLoadingIndicator();
        }
      }
    };

    setTimeout(generatePdf, 2000);
  }, [pData])

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleSubmitResume = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 5 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 5 MB.");
      return;
    } else if (!["application/pdf"].includes(fileType)) {
      showErrorToastMessage("File type not allowed. Allowed type is PDF.");
      return;
    } else {
      setDocFile({ ...docFile, resume: e.target.files[0] });
      dispatch(
        actions.employeeDetailsDocUploadAction(
          { file: e.target.files[0], eId: loginId },
          (res: any) => {
            if (res.code == 200 && res.data) {
              showSuccessToastMessage("Resume Added Successfully!");

              dispatch(
                actions.editEmployeeDataAction(
                  { resume: res.data, updatedBy: loginId, eId: loginId },
                  (res: any) => { }
                )
              );
              setEmployeeResumeModal(false)
            }
          }
        )
      );
    }
  };
  const veiwResume = () => {
    if (resume?.document_path_url) {
      window.open(resume?.document_path_url, "_blank")
    } else {
      showErrorToastMessage("Resume does not exist!! Please upload the resume to view.")
    }
  }
  const handleSubmitImage = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 5 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 5 MB.");
      return;
    } else if (!["image/jpeg", "image/png", "image/gif"].includes(fileType)) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, PNG and GIF"
      );
      return;
    } else {
      showLoadingIndicator();
      dispatch(
        actions.employeeDetailsImageUploadAction(
          { file: e.target.files[0], eId: loginId },
          (res: any) => {
            if (res.code == 200 && res.data) {
              dispatch(
                actions.editEmployeeDataAction(
                  { candidatePhoto: res.data, updatedBy: loginId, eId: loginId },
                  (res: any) => {
                    if (res.code == 200 && res.data) {
                      hideLoadingIndicator();
                      showSuccessToastMessage("Picture Added Successfully!");
                    }
                  }
                )
              );
            }
          }
        )
      );
    }
  };

  const filterData = draftSummary?.length && draftSummary.some((ele:any)=> ele.summary != "")
  return (
    <>
      {loadingComponent()}
      <div>
        <Card>
          <div className="personal__info-container">
            <div className="personal__info-profile-details">
              <img
                src={profileImage ? profileImage : default_avatar}
                alt="icon"
              />
              <div>
                <p>{`${candidateName} ${middleName} ${lastName}`}</p>
                <div className="profile-position">{designation}</div>
                <div className="profile-position">{emailId}</div>
              </div>
            </div>

            <div className="upload-wrapper">
              <div>
                <img src={upload_icon} alt="icon" />
                <p>
                  <b>{clickUpload}</b> {dragDrop} <br />
                  {fileTypeSize}
                </p>
              </div>
              <input type="file" value={""} onChange={handleSubmitImage} />
            </div>
          </div>
        </Card>
        <Card>
          <div className="edit-icon-wrapper">
            <h5>
              Profile Summary<span className="required">*</span>
            </h5>
            <div className="mx-4">
              {filterData ? (
                draftSummary?.map((ele: any) => {
                  return (
                    ele?.summary && ( 
                <ul>
                  <li>{ele?.summary}</li>
                  </ul>
                    )
                  )
                })
                
              ) : (
                <>
                  no summary available
                </>
              )}
            </div>
            <div className="editButtonSection">
              <button
                title="Edit"
                className="profile-edit-btn"
                onClick={() => {
                  handleProfileSummary();
                }}
              >
                Edit <MdOutlineEdit style={{ color: "white" }} />
              </button>
            </div>
            {/* <EditButton
              title="Edit"
              icon={edit_orange_icon}
              onClick={handleProfileSummary}
            /> */}
          </div>
        </Card>
        <Card>
          <div className="personalinfo-resume-section">
            <button className="resume-button" onClick={() => setEmployeeResumeModal(true)}>
              Employee Resume
            </button>
            <button className="resume-button" onClick={(e) =>
              getResumeApiDispatch(e)
            }>
              Skillmine Resume
            </button>
          </div>
        </Card>
      </div>
      <div
        ref={pdfContentRef}
        id="pdf-content-profile"
        style={{ display: "none" }}
      // className="p-4"
      >
        <div className="skillminelogoSection">
          <img src={Header} alt="no_header_img" className="skillmine_Logo" />
        </div>
        <div className="imageDiv">
          <div>
            <b>Profile Name : </b>
            {pdfData?.candidateName +
              " " +
              pdfData?.middleName +
              " " +
              pdfData?.lastname}

            <div className="pt-2">
              <b>Designation : </b>
              {pdfData?.designation}
            </div>
            <div className="pt-2">
              <b>Experience : </b>
              {pdfData?.totalExperience} year(s)
            </div>
          </div>
          <img
            src={
              pdfData?.candidatePhoto?.document_path_url
                ? pdfData.candidatePhoto.document_path_url
                : pdfData?.candidatePhoto?.candidatePhoto?.document_path_url
                  ? pdfData.candidatePhoto.candidatePhoto.document_path_url
                  : icons.default_avatar
            }
            alt="icon"
            className="profilePic"
          />
        </div>
        {pdfData?.summary?.length > 0 && (
          <div className="pt-2">
            <h4>
              <b>Profile Summary : </b>
            </h4>
            <ul>
              {pdfData?.summary?.map((ele: any) => {
                return (
                  <li>
                    <p>
                      <b>{ele?.summary}</b>
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {pdfData?.qualifications?.length > 0 && (
          <div className="pt-2">
            <h4>
              <b>Educational Info : </b>
            </h4>
            <div className="table table-responsive">
              <table className="table-main table table-sm mt-3">
                <thead className="table-head">
                  <tr>
                    <th className="table-head-row">Qualification</th>
                    <th className="table-head-row">Branch / Stream</th>
                    <th className="table-head-row">
                      Institute / School / College
                    </th>
                    <th className="table-head-row">Board / University</th>
                    <th className="table-head-row">Year of Passing</th>
                    <th className="table-head-row">Percentage / CGPA</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {pdfData?.qualifications?.map((ele: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td className="table-body-row">{ele?.qualification}</td>
                        <td className="table-body-row">{ele?.branch}</td>
                        <td className="table-body-row">{ele?.institute}</td>
                        <td className="table-body-row">{ele?.board}</td>
                        <td className="table-body-row">{ele?.year}</td>
                        <td className="table-body-row">{ele?.marks}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {pdfData?.experiences?.length > 0 && (
          <div className="pt-2">
            <h4>
              <b>Employment History : </b>
            </h4>
            <div className="table table-responsive">
              <table className="table-main table table-sm mt-3">
                <thead className="table-head">
                  <tr>
                    <th className="table-head-row">Company Name</th>
                    <th className="table-head-row">Designation</th>
                    <th className="table-head-row">Location</th>
                    <th className="table-head-row">Roles & Responsibility</th>
                    <th className="table-head-row">Date of Joining</th>
                    <th className="table-head-row">Date of Relieving</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {pdfData?.experiences?.map((ele: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td className="table-body-row">{ele?.companyName}</td>
                        <td className="table-body-row">{ele?.designation}</td>
                        <td className="table-body-row">{ele?.location}</td>
                        <td className="table-body-row">
                          {ele?.rolesAndResponsibility}
                        </td>
                        <td className="table-body-row">
                          {moment(ele?.startDate).format("DD/MM/YYYY")}
                        </td>
                        <td className="table-body-row">
                          {moment(ele?.endDate).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* <div className="p-4">
          <img src={Footer} alt="no_footer_img" className="footer-img" />
        </div> */}
      </div>
      <ModalComponents
        show={editProfileSummary}
        onHide={handleProfileSummary}
        title="Edit Profile Summary"
        modalBody={
          <EditProfileSummary
            handleProfileSummary={handleProfileSummary}
            apiCallCompleted={apiCallCompleted}
            summary={summary}
            getApiDispatch={getApiDispatch}
            employee={true}
            draftSummary={draftSummary}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={previewPdf}
        onHide={() => setPreviewPdf(false)}
        title="Profile Info Preview"
        modalBody={
          <PdfModal
            setPreviewPdf={setPreviewPdf}
            pdfData={pdfData}
            Header={Header}
            printPdf={printPdf}
          />
        }
        className="modal-lg"
      />
      <ModalComponents
        show={employeeResumeModal}
        onHide={() => setEmployeeResumeModal(false)}
        title="Employee Resume"
        modalBody={
          <div className="modalResumeSection profile-width">
            {resume?.document_path_url &&
              <button className="modal-resume-button" onClick={veiwResume}>
                View Resume
              </button>
            }
            {!resume?.document_path_url &&
              <div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleSubmitResume}
                />
                <button className="modal-resume-button" onClick={handleButtonClick}>
                  Update Resume
                </button>
              </div>
            }
          </div>
        }
        className="modal-lg"
      />
    </>
  );
};

export default ProfileInfo;
