import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../component/Toasts/Toasts";
import {
  Strings,
  selectStatusDropdownSkillLinks,
} from "../../constant/Strings";

const ChangeStatus = (props: any) => {
  const [btnDisable, setBtnDisable] = useState(false);
  const { hideChangeStatus, status, apiCallCompleted } = props;
  const dispatch = useDispatch();
  const [changeStatus, setChangeStatus] = useState({
    id: status.id,
    remarks: status.remarks,
    status: status.status,
  });

  const { select_status, cancel, submit, remarks, rating_title } = Strings;
  useEffect(() => {
    if (changeStatus.status) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [changeStatus]);

  const handleRejectionSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editSkilLinksReferResourceAction(
        changeStatus,
        changeStatus.id,
        (res: any) => {
          if (res) {
            if (res.error) {
              if (res.error.error_description) {
                showErrorToastMessage(res.error.error_description);
                apiCallCompleted(true);
              }
            } else {
              showSuccessToastMessage("Status Changed Successfully!");
              apiCallCompleted(true);
            }
          }
        }
      )
    );
    hideChangeStatus();
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <label className="form-label ">{select_status}</label>
            <Dropdown
              onSelect={(eventKey: any) => {
                const acceptedDate =
                  eventKey === "Accepted" ? new Date() : null;
                setChangeStatus({
                  ...changeStatus,
                  status:
                    eventKey === "New"
                      ? 0
                      : eventKey === "Accepted"
                      ? 1
                      : eventKey === "Rejected"
                      ? 2
                      : eventKey === "KIV"
                      ? 3
                      : "",
                  ...(acceptedDate && { acceptedDate }),
                });
              }}
            >
              <Dropdown.Toggle variant="light" className="form-control col">
                {changeStatus.status === 0
                  ? "New"
                  : changeStatus.status === 1
                  ? "Accepted"
                  : changeStatus.status === 2
                  ? "Rejected"
                  : changeStatus.status === 3
                  ? "KIV"
                  : ""}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {selectStatusDropdownSkillLinks.map((value: any) => (
                  <Dropdown.Item eventKey={value.title}>
                    {value.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="col-md-12 mt-2 mb-3">
            <label className="form-label">{remarks}</label>
            <textarea
              id="exampleFormControlTextarea1"
              className="form-control"
              style={{
                backgroundColor: "#FFF9F4",
                width: "24rem",
                height: "10rem",
              }}
              value={changeStatus.remarks}
              onChange={(e) =>
                setChangeStatus({
                  ...changeStatus,
                  remarks: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideChangeStatus}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleRejectionSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeStatus;
