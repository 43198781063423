import React from "react";

const EditCondition = (rejectedString: any, setIsGreaterThan3Months: any) => {
  const rejectedDate = new Date(rejectedString);
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const currentDate = new Date(formattedDate);

  const isThreeMonthsDifference = (date1: any, date2: any) => {
    const diffInMonths = Math.abs(
      (date2.getFullYear() - date1.getFullYear()) * 12 +
        date2.getMonth() -
        date1.getMonth()
    );
    return diffInMonths >= 3;
  };
  if (isThreeMonthsDifference(rejectedDate, currentDate)) {
    setIsGreaterThan3Months(true);
  } else {
    setIsGreaterThan3Months(false);
  }
};
export default EditCondition;
