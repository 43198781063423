import React from "react";
import { useDispatch } from "react-redux";
import { showErrorToastMessage } from "../../../../component/Toasts/Toasts";
import * as actions from "../../../../Redux/Actions/index";
import { Strings } from "../../../../constant/Strings";

const DeleteProject = (props: any) => {
  const { id, hideDeleteProject } = props;
  const { deleteSuccess, confirmDelete, cancel, submit } = Strings;
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(
      actions.deleteProjectRepositoryAction(id, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              showErrorToastMessage(deleteSuccess);
            }
          }
        }
      })
    );

    hideDeleteProject();
  };
  return (
    <div className="container">
      <div className="row mx-auto">{confirmDelete}</div>
      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideDeleteProject}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className="btn add-submit-button buttonsize"
            onClick={handleDelete}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteProject;
