import "./SkillLinks.scss";
import { icons } from "../../assets";
import { SkillLinksStrings } from "../../constant/Strings";

const CandidateRatingDesclaimer = (props: any) => {
  const { description } = props;
  const { warning_icon } = icons;
  const {
    desclaimer_title1,
    desclaimer_title2,
    bullet_text1,
    bullet_text2,
    bullet_text3,
    bullet_text4,
    bullet_text5,
    bullet_text6,
  } = SkillLinksStrings;
  return (
    <div>
      <div className="desclaimer-card my-4 pt-3 d-flex g-3">
        <div className="mx-2">
          <img src={warning_icon} alt="warning_icon"></img>
        </div>
        <div>
          <p className="title">{desclaimer_title1}</p>
          <ul>
            <li className="bullet-text">{bullet_text1}</li>
            <li className="bullet-text">{bullet_text2}</li>
            <li className="bullet-text">{bullet_text3}</li>
          </ul>
          <p className="title">{desclaimer_title2}</p>
          <ul>
            <li className="bullet-text">{bullet_text4}</li>
            <li className="bullet-text">{bullet_text5}</li>
            <li className="bullet-text">{bullet_text6}</li>
          </ul>
          <p className="bullet-text">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default CandidateRatingDesclaimer;
