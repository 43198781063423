import add_icon from "./add-icon.png";
import user_icon from "./User-icon.png";
import edit_icon from "./edit.png";
import delete_icon from "./delete.png";
import timer_icon from "./wall-clock.png";
import select_icon from "./select.png";
import processing_icon from "./processing-time.png";
import rejected_icon from "./cross.png";
import coach_icon from "./coach.png";
import trainee_icon from "./group.png";
import laptop_icon from "./laptop.png";
import hamburger_icon from "./sidebaricons/hamburger-menu.svg";
import profile_icon from "./profile-avatar.svg";
import edit_orange_icon from "./edit-icon.svg";
import upload_icon from "./upload-icon.svg";
import export_report from "./export-report-icon.svg";
import template_icon from "./template-icon.svg";
import bulk_upload_icon from "./bulk-upload-icon.svg";
import edit_blue_icon from "./edit-blue-icon.svg";
import backIcon from "./keyboard_backspace.png";
import description from "./description.png";
import upload_white_icon from "./upload-white.svg";
import back_space_icon from "./keyboard_backspace.png";
import close_icon from "./close.png";
import download_icon from "./download.png";
import edit_icon_blue from "./subedit.png";
import upgrade_icon from "./upgrade.png";
import arrow_drop_down from "./arrow_drop_down.svg";
import skill_edit from "./skillEdit.png";
import skill_add from "./addSkill.png";
import totalSkill from "./totalSkill.png";
import redeem from "./redeem.png";
import allocated from "./allocated.png";
import deallocated from "./deallocate.png";
import expandArrow from "./arrow_drop_down.png";
import active_expand from "./active_expand.png";
import download_white from "./download_white.png";
import blue_tick_icon from "./blue-check.svg";
import orange_cross from "./orange-close.svg";
import no_of_coaches from "./no_of_coaches_icon.svg";
import total_no_sessions from "./total_no_sessions.svg";
import total_no_trainee from "./total_no_trainee.svg";
import draft from "./draft.png";
import location_on from "./location_on.svg";
import person_icon from "./person.svg";
import contract_icon from "./contract.svg";
import work_icon from "./work.svg";
import check_icon from "./check.svg";
import warning_icon from "./warning.svg";
import edit_disable_icon from "./edit.svg";
import calender_month_icon from "./calendar_month.svg";
import disableEditIcon from "./disablededit.svg";
import course_link_icon from "./course-link-icon.svg";
import green_tick_icon from "./green_tick_icon.svg";
import green_circle_icon from "./green-circle-tick-icon.svg";
import disabled_tick_icon from "./disabled-tick-icon.svg";
import disabled_cross_icon from "./disabled-cross-icon.svg";
import default_avatar from "./default-avatar.jpg";
import white_tick_icon from "./white-tick-icon.svg";

export const icons = {
  add_icon: add_icon,
  user_icon: user_icon,
  edit_icon: edit_icon,
  delete_icon: delete_icon,
  timer_icon: timer_icon,
  select_icon: select_icon,
  processing_icon: processing_icon,
  rejected_icon: rejected_icon,
  coach_icon: coach_icon,
  trainee_icon: trainee_icon,
  laptop_icon: laptop_icon,
  profile_icon: profile_icon,
  edit_orange_icon: edit_orange_icon,
  upload_icon: upload_icon,
  hamburger_icon: hamburger_icon,
  export_report: export_report,
  template_icon: template_icon,
  bulk_upload_icon: bulk_upload_icon,
  edit_blue_icon: edit_blue_icon,
  backIcon: backIcon,
  description: description,
  upload_white_icon: upload_white_icon,
  back_space_icon: back_space_icon,
  close_icon: close_icon,
  upgrade_icon: upgrade_icon,
  download_icon: download_icon,
  edit_icon_blue: edit_icon_blue,
  arrow_drop_down: arrow_drop_down,
  skill_edit: skill_edit,
  skill_add: skill_add,
  totalSkill: totalSkill,
  redeem: redeem,
  allocated: allocated,
  deallocated: deallocated,
  expandArrow: expandArrow,
  active_expand: active_expand,
  download_white: download_white,
  blue_tick_icon: blue_tick_icon,
  orange_cross: orange_cross,
  no_of_coaches: no_of_coaches,
  total_no_sessions: total_no_sessions,
  total_no_trainee: total_no_trainee,
  draft: draft,
  location_on: location_on,
  work_icon: work_icon,
  person_icon: person_icon,
  contract_icon: contract_icon,
  check_icon: check_icon,
  warning_icon: warning_icon,
  edit_disable_icon: edit_disable_icon,
  calender_month_icon: calender_month_icon,
  disableEditIcon: disableEditIcon,
  course_link_icon: course_link_icon,
  green_tick_icon: green_tick_icon,
  green_circle_icon: green_circle_icon,
  disabled_tick_icon: disabled_tick_icon,
  disabled_cross_icon: disabled_cross_icon,
  default_avatar: default_avatar,
  white_tick_icon: white_tick_icon,
};
