import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showErrorToastMessage } from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";
import { Strings } from "../../../../../constant/Strings";

const DeleteCertifications = (props: any) => {
  const { deleteSuccess, confirmDelete, cancel, submit } = Strings;
  const { id, hideDeleteCertifications } = props;
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(actions.deleteEmployeeCertificationsAction(id));
    hideDeleteCertifications();
    showErrorToastMessage(deleteSuccess);
  };

  return (
    <div className="container">
      <div className="row mx-auto">{confirmDelete}</div>
      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideDeleteCertifications}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className="btn add-submit-button buttonsize"
            onClick={handleDelete}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteCertifications;
