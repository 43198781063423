import MainCard from "../../../component/MainCard/MainCard";
import { ToastContainer } from "react-toastify";
import CoachingRequestTable from "./CoachingRequestTable";

const CoachingRequest = () => {
  return (
    <div>
      <MainCard>
        <CoachingRequestTable />
      </MainCard>
      <ToastContainer />
    </div>
  );
};

export default CoachingRequest;
