export const GET_EMPLOYEE_DATA = "GET_EMPLOYEE_DATA";
export const GET_EMPLOYEE_DATA_PAGINATION = "GET_EMPLOYEE_DATA_PAGINATION";
export const ADD_EMPLOYEE_DATA = "ADD_EMPLOYEE_DATA";
export const EDIT_EMPLOYEE_DATA = "EDIT_EMPLOYEE_DATA";
export const EDIT_EMPLOYEE_PICTURE = "EDIT_EMPLOYEE_PICTURE";
export const DELETE_EMPLOYEE_DATA = "DELETE_EMPLOYEE_DATA";
export const EMPLOYEE_BULK_UPLOAD = "EMPLOYEE_BULK_UPLOAD";

export const GET_EMPLOYEE_QUALIFICATION = "GET_EMPLOYEE_QUALIFICATION";
export const ADD_EMPLOYEE_QUALIFICATION = "ADD_EMPLOYEE_QUALIFICATION";
export const EDIT_EMPLOYEE_QUALIFICATION = "EDIT_EMPLOYEE_QUALIFICATION";
export const DELETE_EMPLOYEE_QUALIFICATION = "DELETE_EMPLOYEE_QUALIFICATION";

export const GET_EMPLOYEE_SKILLS = "GET_EMPLOYEE_SKILLS";
export const ADD_EMPLOYEE_SKILLS = "ADD_EMPLOYEE_SKILLS";
export const EDIT_EMPLOYEE_SKILLS = "EDIT_EMPLOYEE_SKILLS";
export const DELETE_EMPLOYEE_SKILLS = "DELETE_EMPLOYEE_SKILLS";

export const GET_EXPERIENCE_DATA = "GET_EXPERIENCE_DATA";
export const ADD_EXPERIENCE_DATA = "ADD_EXPERIENCE_DATA";
export const EDIT_EXPERIENCE_DATA = "EDIT_EXPERIENCE_DATA";
export const DELETE_EXPERIENCE_DATA = "DELETE_EXPERIENCE_DATA";

export const GET_EMPLOYEE_CERTIFICATIONS = "GET_EMPLOYEE_CERTIFICATIONS";
export const ADD_EMPLOYEE_CERTIFICATIONS = "ADD_EMPLOYEE_CERTIFICATIONS";
export const EDIT_EMPLOYEE_CERTIFICATIONS = "EDIT_EMPLOYEE_CERTIFICATIONS";
export const DELETE_EMPLOYEE_CERTIFICATIONS = "DELETE_EMPLOYEE_CERTIFICATIONS";

export const GET_EMPLOYEE_PREFERENCE = "GET_EMPLOYEE_PREFERENCE";
export const ADD_EMPLOYEE_PREFERENCE = "ADD_EMPLOYEE_PREFERENCE";
export const EDIT_EMPLOYEE_PREFERENCE = "EDIT_EMPLOYEE_PREFERENCE";
export const DELETE_EMPLOYEE_PREFERENCE = "DELETE_EMPLOYEE_PREFERENCE";

export const GET_EMPLOYEE_WORKACHIEVEMENTS = "GET_EMPLOYEE_WORKACHIEVEMENTS";
export const ADD_EMPLOYEE_WORKACHIEVEMENTS = "ADD_EMPLOYEE_WORKACHIEVEMENTS";
export const EDIT_EMPLOYEE_WORKACHIEVEMENTS = "EDIT_EMPLOYEE_WORKACHIEVEMENTS";
export const DELETE_EMPLOYEE_WORKACHIEVEMENTS =
  "DELETE_EMPLOYEE_WORKACHIEVEMENTS";

export const GET_EMPLOYEE_CAREERGOALS = "GET_EMPLOYEE_CAREERGOALS";
export const ADD_EMPLOYEE_CAREERGOALS = "ADD_EMPLOYEE_CAREERGOALS";
export const EDIT_EMPLOYEE_CAREERGOALS = "EDIT_EMPLOYEE_CAREERGOALS";
export const DELETE_EMPLOYEE_CAREERGOALS = "DELETE_EMPLOYEE_CAREERGOALS";

export const USER_LOGIN = "USER_LOGIN";
export const EMPLOYEE_DETAILS = "EMPLOYEE_DETAILS";
export const UPDATE_SELECTED_ID = "UPDATE_SELECTED_ID";
export const LOG_OUT = "LOG_OUT";

export const QUALIFICATION_DOC_UPLOAD = "QUALIFICATION_DOC_UPLOAD";
export const WORKACHIEVEMENTS_DOC_UPLOAD = "WORKACHIEVEMENTS_DOC_UPLOAD";
export const EXPERIENCE_DOC_UPLOAD = "EXPERIENCE_DOC_UPLOAD";
export const EMPLOYEE_DETAILS_DOC_UPLOAD = "EMPLOYEE_DETAILS_DOC_UPLOAD";
export const EMPLOYEE_DETAILS_IMAGE_UPLOAD = "EMPLOYEE_DETAILS_IMAGE_UPLOAD";
export const GET_STATES_DETAILS = "GET_STATES_DETAILS";

export const GET_PROJECT_REPOSITORY = "GET_PROJECT_REPOSITORY";
export const GET_PROJECT_REPOSITORY_BYID = "GET_PROJECT_REPOSITORY_BYID";
export const ADD_PROJECT_REPOSITORY = "ADD_PROJECT_REPOSITORY";
export const EDIT_PROJECT_REPOSITORY = "EDIT_PROJECT_REPOSITORY";
export const DELETE_PROJECT_REPOSITORY = "DELETE_PROJECT_REPOSITORY";
export const PROJECT_DETAILS_DOC_UPLOAD = "PROJECT_DETAILS_DOC_UPLOAD";

export const GET_PROJECT_REPOSITORY_INVOICE = "GET_PROJECT_REPOSITORY_INVOICE";
export const ADD_PROJECT_REPOSITORY_INVOICE = "ADD_PROJECT_REPOSITORY_INVOICE";
export const EDIT_PROJECT_REPOSITORY_INVOICE =
  "EDIT_PROJECT_REPOSITORY_INVOICE";
export const PROJECT_INVOICE_DETAILS_DOC_UPLOAD =
  "PROJECT_INVOICE_DETAILS_DOC_UPLOAD";
export const DELETE_PROJECT_REPOSITORY_INVOICE =
  "DELETE_PROJECT_REPOSITORY_INVOICE";

export const GET_RESOURCE_ALLOCATION = "GET_RESOURCE_ALLOCATION";
export const GET_ALL_RESOURCE_ALLOCATION = "GET_ALL_RESOURCE_ALLOCATION";
export const ADD_RESOURCE_ALLOCATION = "ADD_RESOURCE_ALLOCATION";
export const EDIT_RESOURCE_ALLOCATION = "EDIT_RESOURCE_ALLOCATION";
export const DELETE_RESOURCE_ALLOCATION = "DELETE_RESOURCE_ALLOCATION";
export const GET_PROJECT_BY_CLIENT = "GET_PROJECT_BY_CLIENT";
export const GET_PROJECT_DETAILS_BY_EMPLOYEEID =
  "GET_PROJECT_DETAILS_BY_EMPLOYEEID";

export const GET_ALL_COMPETENCY = "GET_ALL_COMPETENCY";
export const GET_ALL_TECH_STACK = "GET_ALL_TECH_STACK";
export const DELETE_COMPETENCY_COE = "DELETE_COMPETENCY_COE";
export const ADD_TECH_STACK = "ADD_TECH_STACK";
export const ADD_BRANCH_STACK = "ADD_BRANCH_STACK";
export const GET_TECH_STACK_BY_COMPETENCY = "GET_TECH_STACK_BY_COMPETENCY";
export const DELETE_BRANCH_STACK = "DELETE_BRANCH_STACK";
export const ADD_SUB_BRANCH = "ADD_SUB_BRANCH";
export const GET_ALL_SUB_BRANCH = "GET_ALL_SUB_BRANCH";
export const ADD_REQUIREMENTS = "ADD_REQUIREMENTS";
export const EDIT_REQUIREMENTS = "EDIT_REQUIREMENTS";
export const DELETE_SUB_BRANCH_STACK = "DELETE_SUB_BRANCH_STACK";
export const ADD_RATE_MYSELF = "ADD_RATE_MYSELF";

export const GET_ALL_COMPETENCY_REQUESTS = "GET_ALL_COMPETENCY_REQUESTS";
export const GET_ALL_SUBDOMAIN = "GET_ALL_SUBDOMAIN";
export const ADD_ENROLLMENT_DATA = "ADD_ENROLLMENT_DATA";
export const GET_ALL_ENROLLMENT_REQUEST = "GET_ALL_ENROLLMENT_REQUEST";
export const UPDATE_STATUS_REQUEST = "UPDATE_STATUS_REQUEST";
export const GET_STATUS_COUNT = "GET_STATUS_COUNT";

export const GET_ALL_COMPETENCY_DEV = "GET_ALL_COMPETENCY_DEV";
export const EDIT_COMPETENCY_DEV = "EDIT_COMPETENCY_DEV";
export const COMPETENCY_DEV_PDP_PLAN_UPLOAD = "COMPETENCY_DEV_PDP_PLAN_UPLOAD";

export const GET_COUNT_STATUS = "GET_COUNT_STATUS";
export const GET_ALL_PROFICIENCY_BY_TECH_ID = "GET_ALL_PROFICIENCY_BY_TECH_ID";
export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const ADD_POST_A_JOB = "ADD_POST_A_JOB";
export const JOB_DESCRIPTION_UPLOAD = "JOB_DESCRIPTION_UPLOAD";
export const BULK_UPLOAD = "BULK_UPLOAD";
export const PROJECT_BULK_UPLOAD = "PROJECT_BULK_UPLOAD";
export const RESOURCE_BULK_UPLOAD = "RESOURCE_BULK_UPLOAD";
export const GET_JOB_BY_JOB_ID = "GET_JOB_BY_JOB_ID";

export const GET_ALL_INTERNAL_JOBS = "GET_ALL_INTERNAL_JOBS";
export const GET_INTERNAL_JOB_BY_ID = "GET_INTERNAL_JOB_BY_ID";
export const APPLY_INTERNAL_JOBS = "APPLY_INTERNAL_JOBS";
export const GET_ALL_INTERNAL_JOBS_EMPLOYEE_VIEW =
  "GET_ALL_INTERNAL_JOBS_EMPLOYEE_VIEW";
export const ADD_REFER_A_RESOURCE = "ADD_REFER_A_RESOURCE";
export const REFER_RESOURCE_RESUME_UPLOAD = "REFER_RESOURCE_RESUME_UPLOAD";
export const GET_ALL_REFERRAL_LIST = "GET_ALL_REFERRAL_LIST";
export const EDIT_SKILL_LINKS_JOB_STATUS = "EDIT_SKILL_LINKS_JOB_STATUS";
export const GET_INTERNALLY_APPLIED_JOB = "GET_INTERNALLY_APPLIED_JOB";
export const EDIT_APPLICATION_STATUS = "EDIT_APPLICATION_STATUS";
export const EDIT_SKILL_LINKS_REFER_RESOURCE =
  "EDIT_SKILL_LINKS_REFER_RESOURCE";

export const GET_INSEED_DATA = "GET_INSEED_DATA";
export const ADD_INSEED_DATA = "ADD_INSEED_DATA";
export const EDIT_INSEED_DATA = "EDIT_INSEED_DATA";
export const DELETE_INSEED_DATA = "DELETE_INSEED_DATA";

export const GET_INSEED_STATUS_COUNT = "GET_INSEED_STATUS_COUNT";
export const GET_ALL_INSEED_REPORT = "GET_ALL_INSEED_REPORT";

export const GET_INSEED_RESOURCE = "GET_INSEED_RESOURCE";
export const ADD_INSEED_RESOURCE = "ADD_INSEED_RESOURCE";
export const EDIT_INSEED_RESOURCE_DATA = "EDIT_INSEED_RESOURCE_DATA";
export const DELETE_INSEED_RESOURCE = "DELETE_INSEED_RESOURCE";
export const INSEED_DOC_UPLOAD = "INSEED_DOC_UPLOAD";

export const GET_JOB_CATEGORY = "GET_JOB_CATEGORY";
export const ADD_JOB_CATEGORY = "ADD_JOB_CATEGORY";

export const GET_ALL_COACH = "GET_ALL_COACH";
export const ADD_BE_A_COACH = "ADD_BE_A_COACH";
export const GET_ALL_COACH_LIST = "GET_ALL_COACH_LIST";
export const ADD_COACH_REQUEST = "ADD_COACH_REQUEST";
export const GET_ALL_COACH_REQUEST = "GET_ALL_COACH_REQUEST";
export const GET_ALL_COACH_REPORT_COUNT = "GET_ALL_COACH_REPORT_COUNT";
export const GET_ALL_COACH_REPORT = "GET_ALL_COACH_REPORT";
export const UPDATE_STATUS_REMARKS = "UPDATE_STATUS_REMARKS";
export const GET_ALL_ACCEPTED_COACH_LIST = "GET_ALL_ACCEPTED_COACH_LIST";
export const GET_ALL_ACCEPTED_EMPLOYEE_LIST = "GET_ALL_ACCEPTED_EMPLOYEE_LIST";
export const GET_ALL_SESSION_BY_EMPID = "GET_ALL_SESSION_BY_EMPID";
export const COACH_EMPLOYEE_DOC_UPLOAD = "COACH_EMPLOYEE_DOC_UPLOAD";
export const ADD_NEW_SESSION = "ADD_NEW_SESSION";
export const RATING_FOR_SESSION = "RATING_FOR_SESSION";
export const COACH_UPDATE_HW_STATUS = "COACH_UPDATE_HW_STATUS";
export const UPLOAD_EMP_HW = "UPLOAD_EMP_HW";

export const GET_ALL_SKILL_COINS = "GET_ALL_SKILL_COINS";
export const ASSIGN_SKILL_COINS = "ASSIGN_SKILL_COINS";
export const ASSIGNMENT_LETTER_UPLOAD_DOC = "ASSIGNMENT_LETTER_UPLOAD_DOC";
export const DEALLOCATE_SKILL_COINS = "DEALLOCATE_SKILL_COINS";
export const GET_FETCH_SKILL_COINS = "GET_FETCH_SKILL_COINS";
export const EDIT_REDEEM_SKILL_COINS = "EDIT_REDEEM_SKILL_COINS";
export const GET_SKILL_COINS_REPORT = "GET_SKILL_COINS_REPORT";
export const GET_TRAININGS_BY_ID = "GET_TRAININGS_BY_ID";
export const CREATE_TRAINING = "CREATE_TRAINING";
export const TRAINING_PLAN_UPLOAD_DOC = "TRAINING_PLAN_UPLOAD_DOC";
export const EDIT_TRAINING_PLAN = "EDIT_TRAINING_PLAN";
export const DELETE_TRAINING_MODULE = "DELETE_TRAINING_MODULE";
export const GET_DOWNLOAD_POLICY = "GET_DOWNLOAD_POLICY";
export const GET_PROFICIENCY_BY_ID = "GET_PROFICIENCY_BY_ID";

export const GET_EVALUATION_DETAILS = "GET_EVALUATION_DETAILS";
export const EDIT_EVALUATION_DATA = "EDIT_EVALUATION_DATA";
export const FETCH_SEARCH_DATA = "FETCH_SEARCH_DATA";
export const ADD_CLIENT_NAME = "ADD_CLIENT_NAME";
export const GET_CLIENT_NAME = "GET_CLIENT_NAME";

export const GET_COACHING_REPORT_PDF = "GET_COACHING_REPORT_PDF";
export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATION";
export const EDIT_NOTIFICATION_DETAILS = "EDIT_NOTIFICATION_DETAILS";
export const GET_ALL_INSEED_MODULES = "GET_ALL_INSEED_MODULES";
export const ADD_INSEED_MODULE = "ADD_INSEED_MODULE";
export const GET_INSEED_MODULES_BY_ID = "GET_INSEED_MODULES_BY_ID";
export const EDIT_INSEED_MODULE = "EDIT_INSEED_MODULE";
export const DELETE_INSEED_MODULE = "DELETE_INSEED_MODULE";
export const ADD_INSEED_ASSESSMENT = "ADD_INSEED_ASSESSMENT";
export const GET_INSEED_ASSESSMENT_BY_ID = "GET_INSEED_ASSESSMENT_BY_ID";
export const EDIT_INSEED_ASSESSMENT = "EDIT_INSEED_ASSESSMENT";
export const DELETE_INSEED_ASSESSMENT = "DELETE_INSEED_ASSESSMENT";
export const GET_ALL_TRAININGS = "GET_ALL_TRAININGS";
export const GET_ALL_MODULES_BY_TRAINING_ID = "GET_ALL_MODULES_BY_TRAINING_ID";
export const POST_EMAIL_NOTIFICATION = "POST_EMAIL_NOTIFICATION";
export const GET_REPORT_LESSTHAN_3_DAYS = "GET_REPORT_LESSTHAN_3_DAYS";
export const GET_REPORT_GREATERTHAN_3_DAYS = "GET_REPORT_GREATERTHAN_3_DAYS";
export const EMPLOYEE_SKILLS_BULK_UPLOAD = "EMPLOYEE_SKILLS_BULK_UPLOAD";
export const GET_SKILL_COIN_VALUE = "GET_SKILL_COIN_VALUE";
export const UPDATE_SKILL_COIN_VALUE = "UPDATE_SKILL_COIN_VALUE";
export const GET_SKILL_COINS_YEARLY_REPORT = "GET_SKILL_COINS_YEARLY_REPORT";
export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const GET_EMPLOYEE_MAKER_CHECKER_DETAILS =
  "GET_EMPLOYEE_MAKER_CHECKER_DETAILS";
export const MODULE_BULK_UPLOAD = "MODULE_BULK_UPLOAD";
export const UPLOAD_MODULE_SYLLABUS_DETAILS = "UPLOAD_MODULE_SYLLABUS_DETAILS";
export const EDIT_BE_A_COACH_DETAILS = "EDIT_BE_A_COACH_DETAILS";
export const UPLOAD_PROFILE_BE_A_COACH = "UPLOAD_PROFILE_BE_A_COACH";
export const VIEW_UPLOAD_PROFILE_BE_A_COACH = "VIEW_UPLOAD_PROFILE_BE_A_COACH";
export const GET_INVOICES_BY_PROJECT_ID = "GET_INVOICES_BY_PROJECT_ID";
export const GET_INVOICES_BY_INVOICE_ID = "GET_INVOICES_BY_INVOICE_ID";

export const GET_SKILL_COINS_DATA = "GET_SKILL_COINS_DATA";
export const GET_BULK_ASSIGN_SKILL_COINS_DATA =
  "GET_BULK_ASSIGN_SKILL_COINS_DATA";
export const GET_SkillCOIN_BY_EMPID = "GET_SkillCOIN_BY_EMPID";

export const GET_TECH_SPEC_REPORT_DETAILS = "GET_TECH_SPEC_REPORT_DETAILS";
export const GET_TECH_SPEC_DETAILS = "GET_TECH_SPEC_DETAILS";
export const GET_SKILL_LINKS_EMPLOYEE_VIEW_DETAILS =
  "GET_SKILL_LINKS_EMPLOYEE_VIEW_DETAILS";
export const GET_APPLICATION_DETAILS_DATA = "GET_APPLICATION_DETAILS_DATA";
export const RESUME_UPLOAD = "RESUME_UPLOAD";
export const VIEW_UPLOAD_PROFILE_RESUME = "VIEW_UPLOAD_PROFILE_RESUME";
export const ADD_POST_A_JOB_IJP = "ADD_POST_A_JOB_IJP";
export const EDIT_IJP_JOB_STATUS = "EDIT_IJP_JOB_STATUS";
export const JOB_DESCRIPTION = "JOB_DESCRIPTION";
export const VIEW_JOB_DESCRIPTION = "VIEW_JOB_DESCRIPTION";
export const IJP_BULK_UPLOAD = "IJP_BULK_UPLOAD";
export const GET_RESOURCE_ALLOCATION_APPROVED =
  "GET_RESOURCE_ALLOCATION_APPROVED";
export const GET_APPROVED_INSEED_DATA = "GET_APPROVED_INSEED_DATA";
export const ADD_INSEED_LOCATION_DATA = "ADD_INSEED_LOCATION_DATA";
export const GET_INSEED_LOCATION_DATA = "GET_INSEED_LOCATION_DATA";
export const GET_COACH_MASTER_LIST = "GET_COACH_MASTER_LIST";
export const ADD_LEARNING_DATA = "ADD_LEARNING_DATA";
export const CHECK_LEARNING_MASTER = "CHECK_LEARNING_MASTER";
export const CHECK_LEARNING_MANAGER = "CHECK_LEARNING_MANAGER";
export const MANAGER_REQUEST_LIST = "MANAGER_REQUEST_LIST";
export const HR_REQUEST_LIST = "HR_REQUEST_LIST";
export const LEARNING_DEV_DETAILS = "LEARNING_DEV_DETAILS";
export const SPECIFIC_EMPLOYEE_REFERRED_LIST =
  "SPECIFIC_EMPLOYEE_REFERRED_LIST";
export const ALL_EMPLOYEE_REFERRAL_HISTORY = "ALL_EMPLOYEE_REFERRAL_HISTORY";
export const CHECK_FINANCE_MASTER = "CHECK_FINANCE_MASTER";
export const FINANCE_REQUEST_LIST = "FINANCE_REQUEST_LIST";
export const EMPLOYEE_COUNTRY_LIST = "EMPLOYEE_COUNTRY_LIST";
export const ADD_EMPLOYEE_COUNTRY_LIST = "ADD_EMPLOYEE_COUNTRY_LIST";
export const GET_EMPLOYEE_HRBP_LIST = "GET_EMPLOYEE_HRBP_LIST";
export const CHECK_COACHING_REQUEST_LIST = "CHECK_COACHING_REQUEST_LIST";
export const GET_ELIGIBLE_REFERRAL_PAYOUTS = "GET_ELIGIBLE_REFERRAL_PAYOUTS";
export const PUT_ELIGIBLE_REFERRAL_PAYOUTS = "PUT_ELIGIBLE_REFERRAL_PAYOUTS";
export const GET_SKILLCOIN_UPLOAD = "GET_SKILLCOIN_UPLOAD";

export const ADD_COMPETENCY = "ADD_COMPETENCY";
export const ALL_STREAMS = "ALL_STREAMS";
export const ALL_SUB_STREAMS = "ALL_SUB_STREAMS";
export const ALL_QUESTIONS = "ALL_QUESTIONS";
export const ADD_SELF_ASSESSMENT = "ADD_SELF_ASSESSMENT";
export const CHECK_COMPETENCY = "CHECK_COMPETENCY";
export const GET_ASSESSMENT_DATA = "GET_ASSESSMENT_DATA";
export const GET_ASSESSMENT_LIST_BYID = "GET_ASSESSMENT_LIST_BYID";
export const GET_CERTIFICTIONS_LIST = "GET_CERTIFICTIONS_LIST";
export const DOWNLOAD_CERTIFICATE = "DOWNLOAD_CERTIFICATE";
export const COMPETANCY_LIST = "COMPETANCY_LIST";
export const ADD_CERTIFICATE = "ADD_CERTIFICATE";
export const UPLOAD_CERTIFICATE = "UPLOAD_CERTIFICATE";
export const UPDATE_CERTIFICATE = "UPDATE_CERTIFICATE";
export const GET_STREAM_LIST = "GET_STREAM_LIST";
export const ADD_STREAM = "ADD_STREAM";
export const UPDATE_STREAM = "UPDATE_STREAM";
export const LEADER_LIST = "LEADER_LIST";
export const ADD_BENCH = "ADD_BENCH";
export const GET_BECNH_TABLE_API = "GET_BECNH_TABLE_API";
export const GET_PROJECT_HISTORY_API = "GET_PROJECT_HISTORY_API";
export const COMPLETE_REALESE = "COMPLETE_REALESE";
export const BULK_APPROVE = "BULK_APPROVE";
export const GET_ALLRESOURCE_EXPORT_API = "GET_ALLRESOURCE_EXPORT_API";
export const GET_GENERATE_RESUME_DATA = "GET_GENERATE_RESUME_DATA";
export const POST_PROFILE_SUMMARY_API = "POST_PROFILE_SUMMARY_API";
export const PROFILE_REMINDER_API = "PROFILE_REMINDER_API";
export const GET_BENCH_RESOURCE_API = "GET_BENCH_RESOURCE_API";
