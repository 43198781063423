import React, { useMemo, useState } from "react";
import "./InvoiceDetails.scss";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { Button } from "react-bootstrap";
import Pagination from "../../../../component/Pagination/Pagination";
import ModalComponents from "../../../../component/ModalComponent/ModalComponent";
import { Strings } from "../../../../constant/Strings/index";
import ShowEntries from "../../../../component/ShowEntries/ShowEntries";
import { TiArrowUnsorted } from "react-icons/ti";
import AddInvoice from "./AddInvoice";
import EditInvoice from "./EditProjectInvoice";
import { icons } from "../../../../assets/index";
import DeleteInvoice from "./DeleteInvoice";
import { Link } from "react-router-dom";
import { showErrorToastMessage } from "../../../../component/Toasts/Toasts";
import { timeDateFormat } from "../../../../component/FormatDate";

const InvoiceDetails = (props: any) => {
  const { data, projectRef } = props;
  const { add_invoice, errorAccess, invoiceDetails, noData } = Strings;
  const [totalRecords, setTotalRecords] = useState(0);
  const [addProjectInvoice, setAddProjectInvoice] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [showEditProjectInvoice, setShowEditProjectInvoice] = useState(false);
  const role = localStorage.getItem("role");
  const checkerData = role?.includes("rmg-checker");
  const makerData = role?.includes("rmg-maker");
  const handleEditProjectInvoice = () => {
    setShowEditProjectInvoice(!showEditProjectInvoice);
  };
  const [showDeleteProjectInvoice, setShowDeleteProjectInvoice] =
    useState(false);
  const [deleteInvoiceId, setdeleteInvoiceId] = useState("");
  const [projectInvoiceDetails, setProjectInvoiceDetails] = useState({
    id: "",
    projectRef: "",
    revenueMonth: "",
    baseAmount: "",
    cgst: "",
    sgst: "",
    igst: "",
    vat: "",
    tds: "",
    totalAmount: "",
    paidAmount: "",
    differenceAmount: "",
    status: "",
    remarks: "",
    invoiceNumber: "",
    invoiceDate: "",
    paidDate: "",
    invoicePdf: "",
  });
  const handleEditProjectInvoiceSubmit = (
    id: any,
    projectRef: any,
    revenueMonth: any,
    baseAmount: any,
    cgst: any,
    sgst: any,
    igst: any,
    vat: any,
    tds: any,
    totalAmount: any,
    paidAmount: any,
    differenceAmount: any,
    status: any,
    remarks: any,
    invoiceNumber: any,
    invoiceDate: any,
    paidDate: any,
    invoicePdf: any
  ) => {
    setProjectInvoiceDetails((obj: any) => ({
      ...obj,
      id: id,
      projectRef: projectRef,
      revenueMonth: revenueMonth,
      baseAmount: baseAmount,
      cgst: cgst,
      sgst: sgst,
      igst: igst,
      vat: vat,
      tds: tds,
      totalAmount: totalAmount,
      paidAmount: paidAmount,
      differenceAmount: differenceAmount,
      status: status,
      remarks: remarks,
      invoiceNumber: invoiceNumber,
      invoiceDate: invoiceDate,
      paidDate: paidDate,
      invoicePdf: invoicePdf,
    }));
    setShowEditProjectInvoice(true);
  };
  const handleAddProjectInvoice = (id: any) => {
    setProjectId(id);
    setAddProjectInvoice(true);
  };
  const hideAddProjectInvoice = () => {
    setAddProjectInvoice(false);
  };
  const hideDeleteInvoice = () => {
    setShowDeleteProjectInvoice(false);
  };
  const handleDeleteInvoice = (id: any) => {
    setdeleteInvoiceId(id);
    setShowDeleteProjectInvoice(!showDeleteProjectInvoice);
  };
  const COLUMNS: any = [
    {
      Header: "Invoice No",
      accessor: "invoiceNumber",
      Cell: (Action: any) => {
        return (
          <>
            {Action.value && (
              <Link
                to={`/invoices/${Action.row.original._id}/${Action.row.original.projectRef}`}
              >
                {Action.value}
              </Link>
            )}
          </>
        );
      },
    },
    {
      Header: " Invoice Date",
      accessor: "invoiceDate",
      Cell: (Action: any) => {
        const invoiceDate = Action.row.original.invoiceDate;
        return timeDateFormat(invoiceDate);
      },
    },
    {
      Header: "Paid Date",
      accessor: "paidDate",
      Cell: (Action: any) => {
        const paidDate = Action.row.original.paidDate;
        return timeDateFormat(paidDate);
      },
    },
    {
      Header: "Revenue Month",
      accessor: "revenueMonth",
    },
    {
      Header: "CGST",
      accessor: "cgst",
    },
    {
      Header: "SGST",
      accessor: "sgst",
    },
    {
      Header: "IGST",
      accessor: "igst",
    },
    {
      Header: "VAT",
      accessor: "vat",
    },
    {
      Header: "TDS",
      accessor: "tds",
    },
    {
      Header: "Total",
      accessor: "totalAmount",
    },
    {
      Header: "Paid",
      accessor: "paidAmount",
    },
    { Header: "Diff", accessor: "differenceAmount" },
    { Header: "Status", accessor: "status" },
    {
      Header: "Action",
      accessor: "action",
      Cell: (Action: any) => {
        const _id = Action.row.original._id;
        const projectRef = Action.row.original.projectRef;
        const revenueMonth = Action.row.original.revenueMonth;
        const baseAmount = Action.row.original.baseAmount;
        const cgst = Action.row.original.cgst;
        const sgst = Action.row.original.sgst;
        const igst = Action.row.original.igst;
        const vat = Action.row.original.vat;
        const tds = Action.row.original.tds;
        const totalAmount = Action.row.original.totalAmount;
        const paidAmount = Action.row.original.paidAmount;
        const differenceAmount = Action.row.original.differenceAmount;
        const status = Action.row.original.status;
        const remarks = Action.row.original.remarks;
        const invoiceNumber = Action.row.original.invoiceNumber;
        const invoiceDate = Action.row.original.invoiceDate;
        const paidDate = Action.row.original.paidDate;
        const invoicePdf = Action.row.original.invoicePdf;
        return (
          <>
            {checkerData || makerData ? (
              <>
                <div className="action-btn">
                  <img
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Edit"
                    src={icons.edit_blue_icon}
                    alt="edit_icon"
                    className="edit-icon"
                    onClick={() =>
                      handleEditProjectInvoiceSubmit(
                        _id,
                        projectRef,
                        revenueMonth,
                        baseAmount,
                        cgst,
                        sgst,
                        igst,
                        vat,
                        tds,
                        totalAmount,
                        paidAmount,
                        differenceAmount,
                        status,
                        remarks,
                        invoiceNumber,
                        invoiceDate,
                        paidDate,
                        invoicePdf
                      )
                    }
                  />
                  <img
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Delete"
                    src={icons.delete_icon}
                    alt="delete-icon"
                    className="edit-icon"
                    onClick={() => handleDeleteInvoice(_id)}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="action-btn">
                  <img
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Edit"
                    src={icons.edit_blue_icon}
                    alt="edit_icon"
                    className="edit-icon"
                    onClick={() => showErrorToastMessage(errorAccess)}
                  />
                  <img
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Delete"
                    src={icons.delete_icon}
                    alt="delete-icon"
                    className="edit-icon"
                    onClick={() => showErrorToastMessage(errorAccess)}
                  />
                </div>
              </>
            )}
          </>
        );
      },
    },
  ];
  const PMOCOLUMNS: any = [
    {
      Header: "Invoice No",
      accessor: "invoiceNumber",
      Cell: (Action: any) => {
        return (
          <>
            {Action.value && (
              <Link
                to={`/invoices/${Action.row.original._id}/${Action.row.original.projectRef}`}
              >
                {Action.value}
              </Link>
            )}
          </>
        );
      },
    },
    {
      Header: " Invoice Date",
      accessor: "invoiceDate",
      Cell: (Action: any) => {
        const invoiceDate = Action.row.original.invoiceDate;
        return timeDateFormat(invoiceDate);
      },
    },
    {
      Header: "Paid Date",
      accessor: "paidDate",
      Cell: (Action: any) => {
        const paidDate = Action.row.original.paidDate;
        return timeDateFormat(paidDate);
      },
    },
    {
      Header: "Revenue Month",
      accessor: "revenueMonth",
    },
    {
      Header: "CGST",
      accessor: "cgst",
    },
    {
      Header: "SGST",
      accessor: "sgst",
    },
    {
      Header: "IGST",
      accessor: "igst",
    },
    {
      Header: "VAT",
      accessor: "vat",
    },
    {
      Header: "TDS",
      accessor: "tds",
    },
    {
      Header: "Total",
      accessor: "totalAmount",
    },
    {
      Header: "Paid",
      accessor: "paidAmount",
    },
    { Header: "Diff", accessor: "differenceAmount" },
    { Header: "Status", accessor: "status" },
  ];
  const columns = useMemo(
    () => (checkerData || makerData ? COLUMNS : PMOCOLUMNS),
    [checkerData, makerData]
  );
  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="heading-button-wrapper">
        <div>
          <h2>{invoiceDetails}</h2>
        </div>
        {makerData && (
          <div className="btn-wrapper">
            <Button
              title="+ Add New Invoice"
              onClick={() => {
                makerData
                  ? handleAddProjectInvoice(projectRef)
                  : showErrorToastMessage(errorAccess);
              }}
              className="add-invoice-btn"
            >
              {add_invoice}
            </Button>
          </div>
        )}
      </div>

      <div className="table table-responsive">
        <table className="table-main table table-sm mt-3" {...getTableProps()}>
          <thead className="table-head">
            {headerGroups.map((headerGroup) => (
              <tr
                className="table-head-row"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any) => (
                  <th
                    className="table-head-row"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    scope="col"
                  >
                    {column.render("Header")}

                    <span>
                      {column.id !== "Action" && column.id !== "id" ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <TiArrowUnsorted />
                          ) : (
                            <TiArrowUnsorted />
                          )
                        ) : (
                          <TiArrowUnsorted />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {data.length > 0 ? (
            <>
              <tbody className="table-body" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="table-body-row" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </>
          ) : (
            <>
              <p className="projectRepoData mt-4">{noData}</p>
            </>
          )}
        </table>
        <div className="show-entries-wrapper">
          <ShowEntries
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            totalEntries={data.length}
          />
          <Pagination
            nextPage={nextPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            pageIndex={pageIndex}
          />
        </div>
      </div>

      <ModalComponents
        show={addProjectInvoice}
        onHide={hideAddProjectInvoice}
        title="Add New Invoice"
        modalBody={
          <AddInvoice
            projectId={projectId}
            hideAddProjectInvoice={hideAddProjectInvoice}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showEditProjectInvoice}
        onHide={handleEditProjectInvoice}
        title="Edit Project Invoice"
        modalBody={
          <EditInvoice
            id={projectInvoiceDetails.id}
            projectInvoiceDetails={projectInvoiceDetails}
            handleEditProjectInvoice={handleEditProjectInvoice}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showDeleteProjectInvoice}
        onHide={hideDeleteInvoice}
        title="Delete Project Invoice"
        modalBody={
          <DeleteInvoice
            id={deleteInvoiceId}
            hideDeleteInvoice={hideDeleteInvoice}
          />
        }
        className="modal-lg"
      />
    </>
  );
};

export default InvoiceDetails;
