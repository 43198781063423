import React, { useEffect, useMemo, useRef, useState } from "react";
import { Strings } from "../../../constant/Strings/index";
import { icons } from "../../../assets/index";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import * as actions from "../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { TiArrowUnsorted } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import "./RedeemSkillCoins.scss";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Header from "../../../assets/Header.jpg";
import Footer from "../../../assets/Footer.jpg";
import Signature from "../../../assets/Signature.jpg";
import useLoadingIndicator from "../../../component/Loader/Loader";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import RedeemSkillCoins from "./RedeemSkillCoins";
import { timeDateFormat } from "../../../component/FormatDate";
const RedeemSkillCoinsTable = (props: any) => {
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  const {
    transaction_label,
    download_label,
    refNo,
    empId,
    desc,
    assignDate,
    noSkill,
    bookValue,
    dateOfVesting,
    aggreDesc,
    forwarDesc,
    bestWish,
    skillName,
    signature_name,
    role,
    redeem_skill_coins_label,
  } = Strings;
  const { hamburger_icon, template_icon, download_icon, download_white } =
    icons;
  const { pagePerSize } = props;
  const [skillCoinsId, setSkillCoinsId] = useState("");
  const [showExportReport, setShowExportReport] = useState(false);
  const [showRedeemSkillCoins, setShowRedeemSkillCoins] = useState(false);
  const { noData } = Strings;

  const [data, setdata] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const dispatch = useDispatch();

  const [pData, setPData] = useState<any>({
    candidateName: "",
    middleName: "",
    lastname: "",
    referenceNo: "",
    employeeId: "",
    assigned_date: "",
    skillcoins: "",
    skValue: "",
    maturity_date: "",
  });
  const initializePData = () => {
    const selectedData = data[0];
    const updatedPData = {
      candidateName: selectedData?.EmployeeData[0].candidateName,
      middleName: selectedData?.EmployeeData[0].middleName,
      lastname: selectedData?.EmployeeData[0].lastname,
      referenceNo: "",
      employeeId: selectedData?.EmployeeData[0].employeeId,
      assigned_date: timeDateFormat(selectedData?.assigned_date),
      skillcoins: selectedData?.skillcoins,
      skValue: "",
      maturity_date: timeDateFormat(selectedData?.maturity_date),
    };

    setPData(updatedPData);
  };

  useEffect(() => {
    dispatch(
      actions.getSkillCoinValueAction((res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              setPData((obj: any) => ({
                ...obj,
                skValue: res.data[0].skillCoinValue,
              }));
            }
          }
        }
      })
    );
  }, [pData.skValue]);

  useEffect(() => {
    setTimeout(() => {
      const pdfContentDiv: any = document.getElementById("pdf-content");
      if (pdfContentDiv) {
        if (pdfContentDiv.style.display === "block") {
          const pdfDocument = new jsPDF();
          const table = pdfContentDiv;
          html2canvas(table).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const imgProps = pdfDocument.getImageProperties(imgData);
            const pdfWidth = pdfDocument.internal.pageSize.getWidth() - 20;
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdfDocument.addImage(imgData, "PNG", 10, 10, pdfWidth, pdfHeight);
            pdfDocument.save("Assignment Letter.pdf");

            pdfContentDiv.style.display = "none";
          });
        }
      }
      hideLoadingIndicator();
    }, 2000);
  }, [pData]);
  const printPdf = (
    e: any,
    candidateName: any,
    middleName: any,
    lastname: any,
    employeeId: any,
    assigned_date: any,
    skillcoins: any,
    maturity_date: any
  ) => {
    e.preventDefault();
    let assignedDateFormat = timeDateFormat(pData.assignedDate);
    let generateReference =
      "SMTC-SCA-" + pData.employeeId + "-" + assignedDateFormat;
    setPData((obj: any) => ({
      ...obj,
      candidateName: candidateName,
      middleName: middleName,
      lastname: lastname,
      employeeId: employeeId,
      assigned_date: assigned_date,
      skillcoins: skillcoins,
      maturity_date: maturity_date,
      referenceNo: generateReference,
    }));
    const pdfContentDiv: any = document.getElementById("pdf-content");
    if (pdfContentDiv) {
      showLoadingIndicator();
      pdfContentDiv.style.display = "block";
    }
  };

  const COLUMNS: any = [
    {
      Header: "Available Skill Coins",
      accessor: "skillcoins",
    },
    {
      Header: "Assigned Date",
      accessor: "assigned_date",
      Cell: (Action: any) => {
        const assigned_date = Action.row.original.assigned_date;
        return timeDateFormat(assigned_date);
      },
    },
    {
      Header: "Maturity Date",
      accessor: "maturity_date",
      Cell: (Action: any) => {
        const maturityDate = Action.row.original.maturity_date;
        return timeDateFormat(maturityDate);
      },
    },
    {
      Header: "Download",
      accessor: "assignment_letter",
      Cell: (Action: any) => {
        const remarks = Action.row.original.EmployeeData;

        return (
          <div>
            {remarks &&
            Action.row.original.acceptance == 1 &&
            Action.row.original.status != "deallocated" ? (
              <div
                className="action-btn"
                data-toggle="tooltip"
                data-placement="top"
                title="Assignment Letter"
              >
                {remarks.map((remark: any, index: any) => (
                  <button
                    key={index}
                    className="border-none"
                    onClick={(e) => {
                      printPdf(
                        e,
                        remark?.candidateName,
                        remark?.middleName,
                        remark?.lastname,
                        remark?.employeeId,
                        Action.row.original.assigned_date,
                        Action.row.original.skillcoins,
                        Action.row.original.maturity_date
                      );
                    }}
                  >
                    <img src={download_icon} alt="downloadIcon" />
                  </button>
                ))}
              </div>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (Action: any) => {
        return (
          <>
            {Action.value && (
              <button
                type="button"
                className={
                  Action.value === "allocated"
                    ? "btn projectRepo-status-btn redeem-allocated-btn"
                    : Action.value === "deallocated"
                    ? "btn projectRepo-status-btn redeem-dellocated-btn"
                    : Action.value === "settled"
                    ? "btn projectRepo-status-btn redeem-settled-btn"
                    : ""
                }
              >
                {Action.value == "allocated"
                  ? "Allocated"
                  : Action.value == "deallocated"
                  ? "Deallocated"
                  : Action.value == "settled"
                  ? "Settled"
                  : Action.value}
              </button>
            )}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "recordStatus",
      width: "200%",
      colSpan: 2,
      Cell: (Action: any) => {
        const remarks = Action.row.original;

        const handleRedeemSkillCoins = () => {
          setSkillCoinsId(Action.row.original._id);
          setShowRedeemSkillCoins(true);
        };

        const onClickAccept = () => {
          showLoadingIndicator();
          const acceptanceValue = { acceptance: 1, recordStatus: "Accepted" };

          dispatch(
            actions.deallocateSkillCoinsAction(
              Action.row.original._id,
              acceptanceValue,
              (res: any) => {
                if (res) {
                  if (res.error) {
                    if (res.error.error_description) {
                      showErrorToastMessage(res.error.error_description);
                    }
                  } else {
                    if (res.code === 200) {
                      hideLoadingIndicator();
                      showSuccessToastMessage("Accepted successfully");
                    }
                  }
                }
              }
            )
          );
        };

        return (
          <>
            {Action.value && (
              <button
                type="button"
                className={
                  remarks.status === "deallocated"
                    ? "radeem-actio-btn accepted-btn-disabled"
                    : remarks.acceptance === 0
                    ? "radeem-actio-btn accepted-btn"
                    : remarks.acceptance === 1 && remarks.status === "settled"
                    ? "radeem-actio-btn redeem-btn-disabled"
                    : remarks.acceptance === 1
                    ? "radeem-actio-btn redeem-btn"
                    : remarks.status === "deallocated"
                    ? "radeem-actio-btn accepted-btn-disabled"
                    : ""
                }
                onClick={
                  remarks.acceptance === 0
                    ? onClickAccept
                    : handleRedeemSkillCoins
                }
              >
                {remarks.acceptance === 0
                  ? "Accept"
                  : remarks.acceptance === 1
                  ? "Redeem"
                  : Action.value}
              </button>
            )}
          </>
        );
      },
    },
  ];
  const columns = useMemo(() => COLUMNS, [pagePerSize]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pagePerSize,
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const skillCoinsData = useSelector(
    (state: any) => state.employeeReducer.getFetchSkillCoinsDetails
  );
  useEffect(() => {
    if (skillCoinsData && skillCoinsData.data) {
      setdata(skillCoinsData.data);
      setTotalRecords(skillCoinsData.data.length);
      initializePData();
    }
  }, [skillCoinsData, data, pagePerSize, pageSize]);
  const navigate = useNavigate();

  const [policy, setPolicy] = useState<any>([]);
  useEffect(() => {
    dispatch(actions.getDownloadPolicyAction("SkillCoin"));
  }, []);

  const downloadPolicyDetails = useSelector(
    (state: any) => state.employeeReducer.getDownloadPolicyData
  );

  useEffect(() => {
    if (downloadPolicyDetails) {
      if (downloadPolicyDetails.data) {
        setPolicy(downloadPolicyDetails.data);
      }
    }
  }, [downloadPolicyDetails, policy]);

  const hideRedeemSkillCoins = () => {
    setShowRedeemSkillCoins(false);
  };

  const exportReportRef = useRef<HTMLInputElement | null>(null);
  const imgIcon = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (exportReportRef.current && !exportReportRef.current.contains(event.target as Node) ) {
        if(imgIcon.current && !imgIcon.current.contains(event.target as Node)){
        setShowExportReport(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showExportReport]);

  return (
    <>
      {loadingComponent()}
      <div className="redeem-heading-button-wrapper">
        <div>
          <h2>{redeem_skill_coins_label}</h2>
        </div>

        <div className="export-report-wrapper">
          <img
            src={hamburger_icon}
            onClick={() => setShowExportReport(!showExportReport)}
            alt="hamburger_icon"
            height={30}
            width= {30}
            ref={imgIcon} 
          />
          {showExportReport && ( 
            <div className="report-btn-wrapper" ref={exportReportRef}>
              <div className="export-btn">
                <button onClick={() => navigate("/transaction-history")}>
                  <img src={template_icon} alt="template_icon" />
                  {transaction_label}
                </button>
              </div>
              <div className="export-btn">
                <a
                  className="text-decoration-color-white"
                  href={policy[0]?.uploadPolicy?.document_path_url}
                  target="_blank"
                >
                  <button>
                    <img
                      src={download_white}
                      alt="download_white"
                      className="color-white"
                    />
                    {download_label}
                  </button>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>

      {data.length > 0 ? (
        <>
          <div className="table table-responsive">
            <table
              className="table-main table table-sm mt-3"
              {...getTableProps()}
            >
              <thead className="table-head">
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="table-head-row"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(
                      (column: any, columnIndex: number) => (
                        <th
                          className="table-head-row"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          scope="col"
                          colSpan={
                            columnIndex === headerGroup.headers.length - 1
                              ? 2
                              : 1
                          }
                        >
                          {column.render("Header")}

                          <span>
                            {column.id !== "Action" && column.id !== "id" ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TiArrowUnsorted />
                                ) : (
                                  <TiArrowUnsorted />
                                )
                              ) : (
                                <TiArrowUnsorted />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      )
                    )}
                  </tr>
                ))}
              </thead>

              <tbody className="table-body" {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="table-body-row" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <p className="projectRepoData mt-4">{noData}</p>
      )}

      <ModalComponents
        show={showRedeemSkillCoins}
        onHide={hideRedeemSkillCoins}
        title="Redeem Skill Coins"
        modalBody={
          <RedeemSkillCoins
            apiCallCompleted={apiCallCompleted}
            hideRedeemSkillCoins={hideRedeemSkillCoins}
            skillCoinsId={skillCoinsId}
          />
        }
        className="modal-md"
      />

      <div id="pdf-content" style={{ display: "none" }}>
        <div className="p-4">
          <img src={Header} alt="no_header_img" className="footer-img" />
        </div>
        <div className="p-4">
          <h5>
            <b>{refNo}</b>
            {pData.referenceNo}
          </h5>
          <p className="mt-3">To,</p>
          <p className="m-0">
            <b>
              {pData.candidateName +
                " " +
                pData.middleName +
                " " +
                pData.lastname}
            </b>
          </p>
          <h5 className="pt-3">
            {empId}
            {pData.employeeId}
          </h5>
          <p className="paragraph-section">{desc}</p>
        </div>
        <div className="table table-responsive">
          <table className="table-main table table-sm mt-3">
            <thead className="table-head">
              <tr>
                <th className="table-head-row">{assignDate}</th>
                <th className="table-head-row">{noSkill}</th>
                <th className="table-head-row">{bookValue}</th>
                <th className="table-head-row">{dateOfVesting}</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr>
                <td className="table-body-row">
                  {timeDateFormat(pData.assigned_date)}
                </td>
                <td className="table-body-row">{pData.skillcoins}</td>
                <td className="table-body-row">{pData.skValue}</td>
                <td className="table-body-row">
                  {timeDateFormat(pData.maturity_date)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="p-4">
          <p className="font-main-content">{aggreDesc}</p>
          <p className="font-main-content">{forwarDesc}</p>
          <p className="wishes-title">{bestWish}</p>
          <h4>{skillName}</h4>
          <img src={Signature} alt="no_img" />
          <h5>{signature_name}</h5>
          <h5>{role}</h5>
        </div>
        <div className="p-4">
          <img src={Footer} alt="no_footer_img" className="footer-img" />
        </div>
      </div>
    </>
  );
};

export default RedeemSkillCoinsTable;
