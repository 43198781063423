import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../component/Toasts/Toasts";
import { Strings, selectStatusDropdown } from "../../constant/Strings/index";

const ApplicationStatus = (props: any) => {
  const { hideChangeStatus, status, apiCallCompleted } = props;
  const dispatch = useDispatch();
  const { cancel, submit, selectStatus, statusChangeSuccess, remarksLabel } =
    Strings;
  const [applicationChangeStatus, setChangeStatus] = useState({
    id: status.id,
    remarks: status.remarks,
    status: status.status,
  });
  const handleRejectionSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editApplicationStatusAction(
        applicationChangeStatus,
        applicationChangeStatus.id,
        (res: any) => {
          if (res) {
            if (res.error) {
              if (res.error.error_description) {
                showErrorToastMessage(res.error.error_description);
                apiCallCompleted(true);
              }
            } else {
              showSuccessToastMessage(statusChangeSuccess);
              apiCallCompleted(true);
            }
          }
        }
      )
    );
    hideChangeStatus();
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <label className="form-label ">{selectStatus}</label>
          <div className="col-6">
            <Dropdown
              onSelect={(eventKey: any) =>
                setChangeStatus({
                  ...applicationChangeStatus,
                  status:
                    eventKey === "New"
                      ? 0
                      : eventKey === "Accepted"
                      ? 1
                      : eventKey === "Rejected"
                      ? 2
                      : eventKey === "KIV"
                      ? 3
                      : "",
                })
              }
            >
              <Dropdown.Toggle variant="light" className="form-control col">
                {applicationChangeStatus.status === 0
                  ? "New"
                  : applicationChangeStatus.status === 1
                  ? "Accepted"
                  : applicationChangeStatus.status === 2
                  ? "Rejected"
                  : applicationChangeStatus.status === 3
                  ? "KIV"
                  : ""}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {selectStatusDropdown.map((element: any) => (
                  <Dropdown.Item eventKey={element.title} value={element.value}>
                    {element.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-md-12 mt-2 mb-3">
            <label className="form-label">{remarksLabel}</label>
            <textarea
              id="exampleFormControlTextarea1"
              className="form-control"
              style={{
                width: "24rem",
                height: "10rem",
              }}
              value={applicationChangeStatus.remarks}
              onChange={(e) =>
                setChangeStatus({
                  ...applicationChangeStatus,
                  remarks: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideChangeStatus}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className="btn add-submit-button buttonsize"
              onClick={handleRejectionSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationStatus;
