
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { showErrorToastMessage } from "../../../../../component/Toasts/Toasts";
import * as actions from '../../../../../Redux/Actions/index'
import { Strings } from "../../../../../constant/Strings";


const DeleteSkills = (props: any) => {
  const { hideDeleteSkill, setModalFinished } = props;
  const { deleteSuccess, confirmDelete, cancel, submit } = Strings;
  const { id, showDeleteSkill } = props;
  const dispatch = useDispatch();

  const deleteEmployeeSkills = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeeSkills
  );

  const handleDelete = () => {
    dispatch(actions.deleteEmployeeSkillsAction(id));
    hideDeleteSkill();
    showErrorToastMessage(deleteSuccess)
  };

  return (
      <div className="container">
      <div className="row mx-auto">{confirmDelete}</div>
      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideDeleteSkill}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className="btn add-submit-button buttonsize"
            onClick={handleDelete}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteSkills;
