import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { icons } from "../../../../../assets";
import Button from "../../../../../component/Buttons/Button";
import ModalComponents from "../../../../../component/ModalComponent/ModalComponent";
import AddPreferences from "./AddPreferences";
import DeletePreferences from "./DeletePreferences";
import EditPreferences from "./EditPreferences";
import * as actions from "./../../../../../Redux/Actions";
import PreferenceCard from "./PreferenceCard";
import { GrFormPrevious } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function Preferences() {
  const [info, setInfo] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [preferences, setPreferences] = useState({
    id: "",
    preferredLocation: "",
    preferredMode: "",
    preferredCompany: "",
  });

  const [showAddPreferences, setShowAddPreferences] = useState(false);
  const handleAddPreferences = () => setShowAddPreferences(!showAddPreferences);
  const hideAddPreferences = () => setShowAddPreferences(false);

  const [showEditPreferences, setShowEditPreferences] = useState(false);
  const handleEditPreferences = (
    id: any,
    preferredLocation: any,
    preferredMode: any,
    preferredCompany: any
  ) => {
    setPreferences({
      id: id,
      preferredLocation: preferredLocation,
      preferredMode: preferredMode,
      preferredCompany: preferredCompany,
    });
    setShowEditPreferences(true);
  };
  const hideEditPreferences = () => {
    setShowEditPreferences(false);
  };

  const [showDeletePreferences, setShowDeletePreferences] = useState(false);
  const handleDeletePreferences = (id: any) => {
    setDeleteId(id);
    setShowDeletePreferences(!showDeletePreferences);
  };
  const hideDeletePreferences = () => {
    setShowDeletePreferences(false);
  };

  const addPreference = useSelector(
    (state: any) => state.employeeReducer.addEmployeePreference
  );
  const deletePreference = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeePreference
  );
  const editPreference = useSelector(
    (state: any) => state.employeeReducer.editEmployeePreference
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getEmployeePreferenceAction());
  }, [addPreference, editPreference, deletePreference]);

  const employeePreference = useSelector(
    (state: any) => state.employeeReducer.getEmployeePreference
  );
  useEffect(() => {
    if (employeePreference) {
      if (employeePreference.data) {
        setInfo(employeePreference.data);
      }
    }
  }, [employeePreference, info]);
  let navigate = useNavigate();

  return (
    <div className="right__container">
      <div className="title">
        <h3>Preferences</h3>
        <div className="btn-wrapper">
          <Button
            icon={icons.add_icon}
            title="Add"
            onClick={handleAddPreferences}
          />
        </div>
      </div>
      <div>
        {info.map((preferences: any, index: any) => (
          <PreferenceCard
            key={index}
            preferredLocation={preferences.preferredLocation}
            preferredMode={preferences.preferredMode}
            preferredCompany={preferences.preferredCompany}
            onClick_edit={() =>
              handleEditPreferences(
                preferences.id,
                preferences.preferredLocation,
                preferences.preferredMode,
                preferences.preferredCompany
              )
            }
            onClick_delete={() => handleDeletePreferences(preferences.id)}
          />
        ))}
        <div>
          <ModalComponents
            show={showAddPreferences}
            onHide={hideAddPreferences}
            title="Add Preference"
            modalBody={
              <AddPreferences hideAddPreferences={hideAddPreferences} />
            }
            className="modal-lg"
          />

          <ModalComponents
            show={showEditPreferences}
            onHide={hideEditPreferences}
            title="Edit Preference"
            modalBody={
              <EditPreferences
                id={preferences.id}
                preferredLocation={preferences.preferredLocation}
                preferredMode={preferences.preferredMode}
                preferredCompany={preferences.preferredCompany}
                hideEditPreferences={hideEditPreferences}
              />
            }
            className="modal-lg"
          />

          <ModalComponents
            show={showDeletePreferences}
            onHide={hideDeletePreferences}
            title="Delete Preference"
            modalBody={
              <DeletePreferences
                id={deleteId}
                hideDeletePreferences={hideDeletePreferences}
              />
            }
            className="modal-sm"
          />
        </div>
      </div>
      <button
        className="btn-next-pre"
        data-toggle="tooltip"
        data-placement="bottom"
        title="Previous"
        onClick={() => navigate("/DbSidebar/certifications")}
      >
        <GrFormPrevious />
      </button>
      <ToastContainer />
    </div>
  );
}

export default Preferences;
