import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginUrl } from "./helper";
import { useDispatch } from "react-redux";
import { employeeIdBytoken, loginUserApi } from "../Redux/Services";

const HandleToken = () => {
  const navigate = useNavigate();

  const getToken = async () => {
    let access_token;
    if (!localStorage.getItem("access_token")) {
      access_token = new URLSearchParams(window.location.hash).get(
        "access_token"
      );
    } else {
      access_token = localStorage.getItem("access_token");
    }
    if (access_token) {
      localStorage.setItem("access_token", access_token);
      await loginUserApi(access_token);
      await employeeIdBytoken();
      navigate("/");
    } else {
      checkToken();
    }
  };

  const checkToken = () => {
    if (!localStorage.getItem("access_token")) {
      localStorage.clear();
      window.location.href = loginUrl();
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    getToken();
  }, []);
  return null;
};

export default HandleToken;
