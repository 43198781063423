

export const isEmailValid = (email: any) => {
  const email_regex =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return email_regex.test(String(email));
};

export const isMobileValid = (mobile: any) => {
  const mobile_regex = /^\d{10}$/;
  return mobile_regex.test(String(mobile));
};

export const whiteSpaceValid = (name: any) => {
  const spac_reg = /^[^\s].*/;
  return spac_reg.test(String(name));
};

export const isNumberValid = (number: any) => {
  const number_regex = /^[0-9]+$/;
  return number_regex.test(String(number));
};

export const isCharactersValid = (name: any) => {
  const spac_reg = /^[A-Za-z\s]+$/;
  return spac_reg.test(String(name));
};

export const isValidAccountNumber = (number: any) => {
  const accountno_regex = /^[0-9]{9,18}$/;
  return accountno_regex.test(String(number));
};

export const isValidURL = (url: any)=>{
  const URL_regex = /^(https?|ftp|file):\/\/[^\s/$.?#].[^\s]*$/
  return URL_regex.test(String(url))
}

export const validatePAN = (panVal: any) => {
  const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  return regpan.test(String(panVal));
};

export const validationAlphaNumeric = (clientName: any) => {
  const clientNameRegex = /^[A-Za-z0-9\s.-]+$/;
  return clientNameRegex.test(String(clientName));
};

export const validateAadhar = (aadharVal: any) => {
  const regaadhar = /^\d{4}\d{4}\d{4}$/;
  return regaadhar.test(String(aadharVal));
};

export const validatePassport = (passportVal: any) => {
  const regpassport = /^[A-Z0-9]{2,12}$/;
  return regpassport.test(String(passportVal));
};

export const validatePfNumber = (pfNumber: any) => {
  const regpfNumber = /^(?:NA|N\/A|na|n\/a|[A-Za-z]{2}[\s\/]?[A-Za-z]{3}[\s\/]?[0-9]{7}[\s\/]?[0-9]{3}[\s\/]?[0-9]{7})$/;
  return regpfNumber.test(String(pfNumber));
};

export const validateUANNumber = (uanNumber: any) => {
  const reguanNumber = /^(?:NA|N\/A|na|n\/a|[0-9]{12})$/;
  return reguanNumber.test(String(uanNumber));
};

export const validateInsuranceNumber = (insuranceNumber: any) => {
  const reginsuranceNumber = /^[A-Z0-9]{6,12}$/;
  return reginsuranceNumber.test(String(insuranceNumber));
};

//project repo validations

export const projectLocationValidation = (location: any) => {
  const locationRegex = /^[A-Za-z\s]+$/;
  return locationRegex.test(String(location));
};

export const projectClientNameValidation = (clientName: any) => {
  const clientNameRegex = /^[A-Za-z\s]+$/;
  return clientNameRegex.test(String(clientName));
};

export const projectProjectNameValidation = (projectName: any) => {
  const projectNameRegex = /^[A-Za-z0-9\W\s]+$/;
  return projectNameRegex.test(String(projectName));
};

export const projectProjectCodeValidation = (projectCode: any) => {
  const projectCodeRegex = /^[a-zA-Z0-9/-]*$/;
  return projectCodeRegex.test(String(projectCode));
};

export const projectSubProjectCodeValidation = (subProjectCode: any) => {
  const subProjectCodeRegex = /^[a-zA-Z0-9/-]*$/;
  return subProjectCodeRegex.test(String(subProjectCode));
};
export const projectTdmValidation = (tdm: any) => {
  const tdmRegex = /^[A-Za-z\s]+$/;
  return tdmRegex.test(String(tdm));
};
export const projectHrbpValidation = (hrbp: any) => {
  const hrbpRegex = /^[A-Za-z\s]+$/;
  return hrbpRegex.test(String(hrbp));
};
export const projectTechTypeValidation = (techType: any) => {
  const techTypeRegex = /^[A-Za-z0-9\W\s]+$/;
  return techTypeRegex.test(String(techType));
};
export const projectPoNumberValidation = (poNumber: any) => {
  const poNumberRegex = /^[a-zA-Z0-9/@#$%&*-]*$/;
  return poNumberRegex.test(String(poNumber));
};
export const projectApprovedHcValidation = (approvedHc: any) => {
  const approvedHcRegex = /^[0-9]\d{0,3}$/;
  return approvedHcRegex.test(String(approvedHc));
};
export const projectPoAmountValidation = (poAmount: any) => {
  const poAmountRegex = /^[0-9]{1,9}$/;
  return poAmountRegex.test(String(poAmount));
};

//project repo invoice validation

export const invoiceInvoiceNumberValidation = (invoiceNumber: any) => {
  const invoiceNumberRegex = /^[a-zA-Z0-9.-]*$/;
  return invoiceNumberRegex.test(String(invoiceNumber));
};

export const invoiceRevenueMonthValidation = (revenueMonth: any) => {
  const revenueMonthRegex = /^[A-Za-z\s]+$/;
  return revenueMonthRegex.test(String(revenueMonth));
};

export const invoiceBaseAmountValidation = (baseAmount: any) => {
  const baseAmountRegex = /^[0-9]{1,9}$/;
  return baseAmountRegex.test(String(baseAmount));
};

export const invoiceCgstValidation = (cgst: any) => {
  const cgstRegex = /^[0-9]{1,9}$/;
  return cgstRegex.test(String(cgst));
};

export const invoiceSgstValidation = (sgst: any) => {
  const sgstRegex = /^[0-9]{1,9}$/;
  return sgstRegex.test(String(sgst));
};
export const invoiceIgstValidation = (igst: any) => {
  const igstRegex = /^[0-9]{1,9}$/;
  return igstRegex.test(String(igst));
};
export const invoiceVatValidation = (vat: any) => {
  const vatRegex = /^[0-9]{1,9}$/;
  return vatRegex.test(String(vat));
};
export const invoiceTdsValidation = (tds: any) => {
  const tdsRegex = /^[0-9]{1,9}$/;
  return tdsRegex.test(String(tds));
};
export const invoiceTotalAmountValidation = (totalAmount: any) => {
  const totalAmountRegex = /^[0-9]{1,9}$/;
  return totalAmountRegex.test(String(totalAmount));
};
export const invoicePaidAmountValidation = (paidAmount: any) => {
  const paidAmountRegex = /^[0-9]{1,9}$/;
  return paidAmountRegex.test(String(paidAmount));
};
export const invoiceDifferenceAmountValidation = (differenceAmount: any) => {
  const differenceAmountRegex = /^[0-9]{1,9}$/;
  return differenceAmountRegex.test(String(differenceAmount));
};

//module master validations

export const moduleMasterTrainerNameValidation = (trainerName: any) => {
  const trainerNameRegex = /^[A-Za-z\s]+$/;
  return trainerNameRegex.test(String(trainerName));
};
export const moduleMasterInstituteNameValidation = (instituteName: any) => {
  const instituteNameRegex = /^[A-Za-z\s]+$/;
  return instituteNameRegex.test(String(instituteName));
};
export const moduleMasterTrainerContactNumberValidation = (
  trainerContactNumber: any
) => {
  const trainerContactNumberRegex = /^\d{10}$/;
  return trainerContactNumberRegex.test(String(trainerContactNumber));
};

//Bench management Validations
export const roleDescriptionValidation = (roleDescription: any) => {
  const roleDescriptionRegex = /^[A-Za-z\s]+$/;
  return roleDescriptionRegex.test(String(roleDescription));
};
export const utilizationValidation = (utilization: any) => {
  const utilizationRegex = /^(?:\d{1,2}(?:.\d{1,2})?|100(?:.\d{1,2})?)$/;
  return utilizationRegex.test(String(utilization));
};
export const poNumberValidation = (poNumber: any) => {
  const poNumberRegex = /^[a-zA-Z0-9/@#$%&*-]*$/;
  return poNumberRegex.test(String(poNumber));
};
export const poAmountValidation = (poAmount: any) => {
  const poAmountRegex = /^(?:\d{1,2}(?:.\d{1,2})?|100(?:.\d{1,2})?)$/;
  return poAmountRegex.test(String(poAmount));
};

export const isYearValid = (year: any) => {
  const yearRegex = /^[12][0-9]{3}$/;
  return yearRegex.test(String(year));
};

export const isMarks1Valid = (marks: any) => {
  const marksRegex = /^\d{0,2}(\.\d{1,2})?$/;
  return marksRegex.test(String(marks));
};

export const isMarks2Valid = (marks: any) => {
  const marksRegex = /^((99)|(\d{1,2}(.\d*)?))%$/;
  return marksRegex.test(String(marks));
};

//training card
export const trainingCardValidation = (trainingName: any) => {
  const trainingCardRegex = /^[A-Za-z\s\W0-9]+$/;
  return trainingCardRegex.test(String(trainingName));
};

//employee module
export const employeeDesignationValidtion = (designation: any) => {
  const designationRegex = /^[a-zA-Z\s\W0-9]+$/;
  return designationRegex.test(String(designation));
};
export const employeeMobileNumberValidtion = (mobileNumber: any) => {
  const mobileNumberRegex = /^[0-9\s\W]+$/;
  return mobileNumberRegex.test(String(mobileNumber)) && String(mobileNumber).length <= 15 && String(mobileNumber).length >= 10;
};
export const employeeEmailIdValidation = (emailId: any) => {
  const emailIdRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return emailIdRegex.test(String(emailId));
};
export const employeeEmployeeIdValidtion = (employeeId: any) => {
  const employeeIdRegex = /^[a-zA-Z0-9-]+$/;
  return employeeIdRegex.test(String(employeeId));
};
export const employeeLastNameValidtion = (lastname: any) => {
  const lastnameRegex = /^[A-Za-z.\s]+$/;
  return lastnameRegex.test(String(lastname));
};
export const employeeCandidateNameValidtion = (candidateName: any) => {
  const candidateNameRegex = /^[A-Za-z\s]+$/;
  return candidateNameRegex.test(String(candidateName));
};
//Skill links validations
export const jobTitleValidation = (jobTitle: any) => {
  const jobTitleRegex = /^[A-Za-z\s\W0-9]+$/;
  return jobTitleRegex.test(String(jobTitle));
};
export const locationValidation = (location: any) => {
  const locationRegex = /^[A-Za-z\s]+$/;
  return locationRegex.test(String(location));
};
export const workLevelValidation = (workLevel: any) => {
  const workLevelRegex = /^[A-Za-z\s\W]+$/;
  return workLevelRegex.test(String(workLevel));
};
export const experienceValidation = (experience: any) => {
  const experienceRegex = /^[a-zA-Z\s\W0-9]+$/;
  return experienceRegex.test(String(experience));
};
export const referralAmountValidation = (referralAmount: any) => {
  const referralAmountRegex = /^[0-9]{1,9}$/;
  return referralAmountRegex.test(String(referralAmount));
};
export const emailIdValidation = (emailId: any) => {
  const emailIdRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return emailIdRegex.test(String(emailId));
};
export const contactNumberValidation = (contactNumber: any) => {
  const contactNumberRegex = /^[6-9]\d{9}$/;
  return contactNumberRegex.test(String(contactNumber));
};
export const currentCTCValidation = (currentCTC: any) => {
  const currentCTCRegex = /^[0-9]\d{0,9}$/;
  return currentCTCRegex.test(String(currentCTC));
};

//applyjob validations
export const preferredLocationValidation = (preferredLocation: any) => {
  const preferredLocationRegex = /^[A-Za-z\s]+$/;
  return preferredLocationRegex.test(String(preferredLocation));
};
export const currentCtcValidation = (currentCtc: any) => {
  const currentCtcRegex = /^[0-9]+$/;
  return currentCtcRegex.test(String(currentCtc));
};
export const inseedLocationValidation = (contactNumber: any) => {
  const inseedLocationRegex = /^(?!\s)[a-zA-Z\s]+$/;
  return inseedLocationRegex.test(String(contactNumber));
};

export const addressValidation = (address: any) => {
  const addressRegex = /^(?!\s)[#./0-9a-zA-Z\s,-]+$/;
  return addressRegex.test(String(address));
};

export const departmentValidation = (department: any) => {
  const departmentRegex = /^[A-Za-z0-9\W\s]+$/;
  return departmentRegex.test(String(department));
};

export const accountNumberValidation = (accountNumber: any) => {
  const accountNumberRegex = /^[0-9]{10,18}$/;
  return accountNumberRegex.test(String(accountNumber));
};

export const ifscCodeValidation = (ifsc: any) => {
  const ifscRegex = /^[A-Z]{4}[0][A-Z0-9]{6}/;
  return ifscRegex.test(String(ifsc));
};
// Different countries mobile number regex 
export const indiaNumberValidation = (number: any) => {
  const numberRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
  return numberRegex.test(String(number));
};
export const usaNumberValidation = (number: any) => {
  const numberRegex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
  return numberRegex.test(String(number));
};
export const ukNumberValidation = (number: any) => {
  const numberRegex = /^\+?(?:\d\s?){10,12}$/;
  return numberRegex.test(String(number));
};
export const ksaNumberValidation = (number: any) => {
  const numberRegex = /^((?:[+?0?0?966]+)(?:\s?\d{2})(?:\s?\d{7}))$/;
  return numberRegex.test(String(number));
};

export const PreviousExpValidation = (number: any) => {
  const numberRegex = /^[0-9](\.\d{1,2})?$/;
  return numberRegex.test(String(number));
};
