import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { Strings } from "../../../constant/Strings";

const ReasonForRejection = (props: any) => {
  const { hideRejection, requestId, role, apiCallCompleted } = props;
  const [btnDisable, setBtnDisable] = useState(true);

  const { reason_rejection, rejected_reason_info } = Strings;

  let rejectObj: any;
  if (role === "Manager") {
    rejectObj = {
      reason: "",
      managerApprovalStatus: "Rejected",
    };
  } else if (role === "Finance") {
    rejectObj = {
      reason: "",
      financeApprovalStatus: "Rejected",
    };
  } else {
    rejectObj = {
      reason: "",
      hrApprovalStatus: "Rejected",
    };
  }
  const [rejectionData, setRequirementData] = useState(rejectObj);

  const dispatch = useDispatch();
  const handleRejectionSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      actions.updateStatusRequestAction(
        requestId,
        rejectionData,
        (res: any) => {
          if (res) {
            if (res.error) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            } else {
              showSuccessToastMessage("Rejected Successfully!");
              apiCallCompleted(true);
            }
          }
        }
      )
    );
    hideRejection();
  };

  const handleChange = (e: any) => {
    setRequirementData({
      ...rejectionData,
      reason: e.target.value.replace(/^\s+/, ""),
    });
  };

  useEffect(() => {
    if (rejectionData.reason) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }, [rejectionData.reason]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 mt-2 mb-3">
          <label className="form-label">{reason_rejection}</label>
          <span className="required">{rejected_reason_info}</span>
          <textarea
            id="exampleFormControlTextarea1"
            className="form-control mt-3"
            style={{
              backgroundColor: "#FFF9F4",
              width: "24rem",
              height: "10rem",
            }}
            value={rejectionData.reason}
            onChange={handleChange}
            placeholder="Enter Reason"
          />
        </div>
      </div>
      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button className="btn cancel-btn buttonsize" onClick={hideRejection}>
            Cancel
          </button>
        </div>
        <div>
          <button
            className={
              !btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleRejectionSubmit}
          >
            Send & Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReasonForRejection;
