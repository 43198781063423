import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaFileAlt } from "react-icons/fa";
import * as actions from "../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { Strings, requiredFeild } from "../../../constant/Strings";
import { icons } from "../../../assets/index";
import "../CoachingProgress/Coaching.scss";

const CoachingProgressCard = (props: any) => {
  const {
    sessionNumber,
    topicName,
    sessionDate,
    dueDate,
    sessionId,
    homeWork,
    homeWorkStatus,
    coachUploadFile,
    employeeUploadFile,
    employeeFeedback,
    employeeRating,
    remarks,
  } = props;
  const { required } = requiredFeild;
  const dispatch = useDispatch();
  const {
    session,
    sessionDateAndTime,
    dueDateAndTime,
    homeWorkLabel,
    viewAssignment,
    status,
    remarksLabel,
    updateRating,
    updatedHomeWork,
    ratingLabel,
    update,
    uploadFile,
    fileSize,
    feedbackLabel,
  } = Strings;
  const { draft } = icons;
  const [coachProgressData, setcoachProgressData] = useState<any>({
    employeeRating: "",
    employeeUploadFile: employeeUploadFile,
    employeeFeedBack: "",
  });
  const [checkProgressData, setCheckProgressData] = useState({
    employeeRating: employeeRating,
    employeeFeedBack: employeeFeedback,
  });
  const [employeeFile, setEmployeeFile] = useState<any>({
    employeeUploadFile: employeeUploadFile,
  });

  const [docFile, setDocFile] = useState<any>({
    employeeUploadFile: null,
  });

  const [feildDisabled, setFeildDisabled] = useState(false);

  const handleSubmituploadFile = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      // 2 MB limit
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, employeeUploadFile: e.target.files[0] });

      dispatch(
        actions.coachEmployeeDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              if (homeWorkStatus == 1) {
                setcoachProgressData({
                  employeeRating: "",
                  employeeFeedBack: "",
                  employeeUploadFile: res.data,
                });
              } else {
                setEmployeeFile({
                  employeeUploadFile: res.data,
                });
              }
            }
          }
        )
      );
    }
  };

  useEffect(() => {
    disabledStatus(homeWorkStatus);
  }, [homeWorkStatus]);

  const handleUpdate = () => {
    dispatch(
      actions.ratingForSessionAction(
        sessionId,
        coachProgressData,
        (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
          } else {
            if (res.code === 200) {
              showSuccessToastMessage(updateRating);
              location.reload();
            }
          }
        }
      )
    );
  };

  const handleUpdateEmpHw = () => {
    dispatch(
      actions.uploadEmpHwAction(sessionId, employeeFile, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
        } else {
          if (res.code === 200) {
            showSuccessToastMessage(updatedHomeWork);
          }
        }
      })
    );
  };

  const disabledStatus = (homeWorkStatus: any) => {
    if (homeWorkStatus == 1) {
      if (
        checkProgressData.employeeFeedBack ||
        checkProgressData.employeeRating
      ) {
        setFeildDisabled(true);
      } else {
        setFeildDisabled(false);
      }
    } else {
      setFeildDisabled(true);
    }
  };

  return (
    <>
      <div className="mt-4">
        <Card>
          <CardBody>
            <div className="d-flex flex-row justify-content-between mx-2">
              <div>
                <p className="card-text mx-2">
                  <span className="card-text-label">
                    {session} {sessionNumber} :&nbsp;
                  </span>
                  {topicName}
                </p>
              </div>
              <div className="d-flex flex-row justify-content-between mx-2">
                <p className="card-text mx-2">
                  <span className="card-text-label">
                    {sessionDateAndTime} :&nbsp;
                  </span>
                  {sessionDate}
                </p>
                <p className="card-text mx-2">
                  <span className="card-text-label">
                    {dueDateAndTime} :&nbsp;
                  </span>
                  {dueDate}
                </p>
              </div>
            </div>
            <div className="mx-2">
              <p className="card-text mx-2">
                <span className="card-text-label">{homeWorkLabel} :&nbsp;</span>
                {homeWork}
              </p>
            </div>
            <div className="mx-2 mt-2 d-flex flex-row justify-content-start">
              <div>
                <a
                  className="card-text mx-2"
                  href={coachUploadFile?.document_path_url}
                  target="_blank"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="View Assignment"
                >
                  <span className="card-text-label">
                    {viewAssignment} :&nbsp;{" "}
                    <img
                      src={draft}
                      alt="view assignment"
                      className="draft-icon"
                    />
                  </span>
                </a>
              </div>
              <div>
                <p className="card-text mx-4">
                  <span className="card-text-label">{status} :&nbsp;</span>
                  <button
                    type="button"
                    className={`btn ${
                      homeWorkStatus === 0
                        ? "coahing-inprogress-status-wip"
                        : homeWorkStatus === 1
                        ? "coahing-inprogress-status-completed"
                        : homeWorkStatus === 2
                        ? "coahing-inprogress-status-reworks"
                        : ""
                    }`}
                  >
                    {homeWorkStatus === 0
                      ? "WIP"
                      : homeWorkStatus === 1
                      ? "Completed"
                      : homeWorkStatus === 2
                      ? "Rework"
                      : ""}
                  </button>
                </p>
              </div>
            </div>
            <hr></hr>
            <div className="row mt-2">
              <div className="d-flex flex-row justify-content-start align-items-center">
                <div className="mx-2">
                  <p className="card-text-label mt-3">{ratingLabel}</p>
                </div>
                <div className="mx-2">
                  <input
                    type="text"
                    className="form-control mx-2"
                    disabled={feildDisabled}
                    value={checkProgressData.employeeRating}
                    onChange={(e: any) => {
                      setcoachProgressData({
                        ...coachProgressData,
                        employeeRating: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mx-2">
                  <p className="card-text-label mt-3 mx-2">{feedbackLabel}</p>
                </div>
                <div>
                  <input
                    type="text"
                    className="form-control mx-2"
                    disabled={feildDisabled}
                    value={checkProgressData.employeeRating}
                    onChange={(e: any) => {
                      setcoachProgressData({
                        ...coachProgressData,
                        employeeRating: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4 mx-2">
              <div className="col-md-4">
                {homeWorkStatus != 1 && (
                  <>
                    <label className="form-label titlefont">
                      {uploadFile}
                      <span className="required">{required}</span>
                    </label>
                    <div>
                      <input
                        type="file"
                        className="form-control"
                        onChange={handleSubmituploadFile}
                        hidden
                        id={sessionId}
                      />
                      <label
                        htmlFor={sessionId}
                        className="btn upload-file-button"
                      >
                        {uploadFile}
                      </label>
                    </div>
                    <p className="file-size-text">
                      {fileSize}
                      <span className="required">*</span>
                    </p>
                    <a
                      href={
                        homeWorkStatus == 1
                          ? coachProgressData.employeeUploadFile
                              ?.document_path_url
                          : employeeFile.employeeUploadFile?.document_path_url
                      }
                      target="_blank"
                      className="buttonsize"
                    >
                      {homeWorkStatus == 1
                        ? coachProgressData.employeeUploadFile
                            ?.document_path_url
                        : employeeFile.employeeUploadFile?.document_path_url
                        ? "View File"
                        : ""}
                    </a>
                    <p className="form-label">
                      {docFile.employeeUploadFile?.name}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-8">
                <p className="card-text mx-2">
                  <span className="card-text-label">
                    {remarksLabel} :&nbsp;
                  </span>
                  {homeWork}
                </p>
              </div>
            </div>
            <div className="row mt-2">
              {homeWorkStatus === 1 &&
              (checkProgressData.employeeFeedBack ||
                checkProgressData.employeeRating) ? (
                <>
                  <div className="d-flex flex-row justify-content-center">
                    <button
                      type="button"
                      className="btn update-button p-2"
                      onClick={(e) => {
                        showErrorToastMessage(
                          "Cannot Update completed Session"
                        );
                      }}
                    >
                      {update}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex flex-row justify-content-center">
                    <button
                      type="button"
                      className="btn update-button p-2"
                      onClick={
                        homeWorkStatus === 1 ? handleUpdate : handleUpdateEmpHw
                      }
                    >
                      {update}
                    </button>
                  </div>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default CoachingProgressCard;
