import React, { useEffect, useState } from "react";
import MainCard from "../../../component/MainCard/MainCard";
import ProjectDetailsTab from "../../../component/ProjectDetailsTab/ProjectDetailsTab";
import { Strings } from "../../../constant/Strings";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import * as actions from "../../../Redux/Actions/index";
import moment from "moment";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import ApprovalRemark from "./ApprovalRemark";
import ReasonForRejection from "./ReasonForRejection";
import useLoadingIndicator from "../../../component/Loader/Loader";

const LearningDevelopmentDetails = () => {
  const {
    Purpose_description,
    accept,
    reject_label,
    action,
    major_skills,
    course_link,
  } = Strings;
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const [showApproval, setShowApproval] = useState(false);
  const [showAddRequirement, setShowAddRequirement] = useState(false);
  const [requestId, setrequestId] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState({
    employeeName: "",
    trainingType: "External Training",
    trainingMode: "",
    currentDesignation: "",
    courseName: "",
    instituteName: "",
    courseStartDate: "",
    courseEndDate: "",
    trainerName: "",
    courseFees: "",
    purpose: "",
    majorSkills: "",
    hrApprovalStatus: "",
    reason: "",
    hrRemarks: "",
    clientName: "",
    status: "",
    experience: "",
    courseLink: "",
    tenure: "",
    teamTraining: 0,
  });
  const navigate = useNavigate();

  const [managerCheckerList, setManagerCheckerList] = useState<any>();
  const userLogin: any = localStorage.getItem("login");
  const { inValidModuleAccess } = Strings;
  const role = localStorage.getItem("role");
  const checkManagerList = role?.includes("learninganddevelopment-manager");
  useEffect(() => {
    if (checkManagerList) {
      setManagerCheckerList(true);
    } else {
      setManagerCheckerList(false);
    }

    if (checkManagerList == undefined) {
      showErrorToastMessage(inValidModuleAccess);
      navigate("/");
    }
  }, [checkManagerList]);
  const dispatch = useDispatch();

  const updateStatusRequest = useSelector(
    (state: any) => state.employeeReducer.updateStatusRequest
  );

  let { id } = useParams();
  const handleApproval = (id: any) => {
    setShowApproval(true);
    setrequestId(id);
  };
  const hideApproval = () => {
    setShowApproval(false);
  };
  const handleRejection = (id: any) => {
    setShowAddRequirement(true);
    setrequestId(id);
  };
  const hideRejection = () => {
    setShowAddRequirement(false);
  };

  useEffect(() => {
    dispatch(
      actions.LearningDevDetailsAction(id, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              setEmployeeDetails({
                ...employeeDetails,
                employeeName:
                  res.data[0].EmployeeData[0].candidateName +
                  " " +
                  res.data[0].EmployeeData[0].middleName +
                  " " +
                  res.data[0].EmployeeData[0].lastname,
                trainingType:
                  res.data[0].internalTraining === 1
                    ? "Udemy"
                    : "External Training",
                trainingMode: res.data[0].trainingMode,
                currentDesignation: res.data[0].EmployeeData[0]?.designation,
                courseName: res.data[0].courseName,
                instituteName: res.data[0].instituteName,
                courseStartDate: res.data[0].courseStartDate,
                courseEndDate: res.data[0].courseEndDate,
                trainerName: res.data[0].trainerName,
                courseFees: res.data[0].courseFees,
                purpose: res.data[0].purpose,
                majorSkills: res.data[0].majorSkills,
                hrApprovalStatus: res.data[0].hrApprovalStatus,
                reason: res.data[0].reason,
                hrRemarks: res.data[0].hrRemarks,
                clientName: res.data[0].ManagerData[0].clientName,
                experience: res.data[0].EmployeeData[0].employementDuration,
                courseLink: res.data[0].courseLink,
                tenure: res.data[0].tenure,
                teamTraining: res.data[0].teamTraining,
              });
            }
          }
        }
      })
    );
  }, [updateStatusRequest]);

  const udemyLearningDevColumn1 = [
    "Training Type:",
    "Tenure (In Days):",
    "Training Mode:",
    "Current Designation:",
    "Experience:",
    "Course Name:",
    "Client Name:",
  ];

  const udemyLearningDevColumn2 = [
    employeeDetails.hrApprovalStatus !== "New" && "Status:",
  ];

  const learningDevColumn1 = [
    "Training Type:",
    "Training Mode:",
    "Current Designation:",
    "Experience:",
    "Course Name:",
    "Client Name:",
  ];

  const learningDevColumn2 = [
    "Institue name:",
    "Start Date:",
    "End Date:",
    "Trainer Name:",
    "Course Fees:",
    employeeDetails.hrApprovalStatus !== "New" && "Status:",
  ];

  const udemyValueArray1 = [
    employeeDetails.trainingType,
    employeeDetails.tenure ? `${employeeDetails.tenure} Days` : "-",
    employeeDetails.trainingMode,
    employeeDetails.currentDesignation,
    employeeDetails.experience,
    employeeDetails.courseName,
    employeeDetails.clientName ? employeeDetails.clientName : "-",
  ];

  const udemyValueArray2 = [
    employeeDetails.hrApprovalStatus !== "New" ? (
      <span
        className={
          employeeDetails?.hrApprovalStatus === "New"
            ? "new-text-status"
            : employeeDetails?.hrApprovalStatus === "Approved"
            ? "accepted-text-status"
            : employeeDetails?.hrApprovalStatus === "Rejected"
            ? "declined-text-status"
            : ""
        }
      >
        {employeeDetails.hrApprovalStatus}
      </span>
    ) : null,
  ];

  const valueArray1 = [
    employeeDetails.trainingType,
    employeeDetails.trainingMode ? employeeDetails.trainingMode : "-",
    employeeDetails.currentDesignation
      ? employeeDetails.currentDesignation
      : "-",
    employeeDetails.experience ? employeeDetails.experience : "-",
    employeeDetails.courseName ? employeeDetails.courseName : "-",
    employeeDetails.clientName ? employeeDetails.clientName : "-",
  ];

  const valueArray2 = [
    employeeDetails.instituteName ? employeeDetails.instituteName : "-",
    moment(employeeDetails.courseStartDate).format("DD/MM/YYYY"),
    moment(employeeDetails.courseEndDate).format("DD/MM/YYYY"),
    employeeDetails.trainerName ? employeeDetails.trainerName : "-",
    employeeDetails.courseFees ? `₹${employeeDetails.courseFees}` : `-`,
    employeeDetails.hrApprovalStatus !== "New" ? (
      <span
        className={
          employeeDetails?.hrApprovalStatus === "New"
            ? "new-text-status"
            : employeeDetails?.hrApprovalStatus === "Approved"
            ? "accepted-text-status"
            : employeeDetails?.hrApprovalStatus === "Rejected"
            ? "declined-text-status"
            : ""
        }
      >
        {employeeDetails.hrApprovalStatus}
      </span>
    ) : null,
  ];

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="mt-2">
          <ProjectDetailsTab
            navigateTo={"/enrollment-request"}
            tabHeading={"Employee Name"}
            headingValue={employeeDetails.employeeName}
            firstColumnHeadings={
              employeeDetails.trainingType == "Udemy"
                ? employeeDetails.hrApprovalStatus === "New"
                  ? null
                  : udemyLearningDevColumn1
                : learningDevColumn1
            }
            firstColumnValues={
              employeeDetails.trainingType == "Udemy"
                ? employeeDetails.hrApprovalStatus === "New"
                  ? null
                  : udemyValueArray1
                : valueArray1
            }
            secondColumnHeadings={
              employeeDetails.trainingType == "Udemy"
                ? employeeDetails.hrApprovalStatus === "New"
                  ? udemyLearningDevColumn1
                  : udemyLearningDevColumn2
                : learningDevColumn2
            }
            secondColumnValues={
              employeeDetails.trainingType == "Udemy"
                ? employeeDetails.hrApprovalStatus === "New"
                  ? udemyValueArray1
                  : udemyValueArray2
                : valueArray2
            }
          />
        </div>

        {employeeDetails.trainingType == "Udemy" && (
          <div className="learning-dev-purpose-container">
            <h6>{course_link}</h6>
            <div className="learning-dev-purpose-wrapper">
              {employeeDetails.courseLink ? employeeDetails.courseLink : "-"}
            </div>
          </div>
        )}

        <div className="learning-dev-purpose-container">
          <h6>{major_skills}</h6>
          <div className="learning-dev-purpose-wrapper">
            {employeeDetails.majorSkills ? employeeDetails.majorSkills : "-"}
          </div>
        </div>

        <div className="learning-dev-purpose-container">
          <h6>{Purpose_description}</h6>
          <div className="learning-dev-purpose-wrapper">
            {employeeDetails.purpose ? employeeDetails.purpose : "-"}
          </div>
        </div>

        {employeeDetails.hrApprovalStatus === "New" ? (
          <div className="learning-dev-purpose-container">
            <h6>{action}</h6>
            <div className="learning-dev-purpose-wrapper">
              <div className="d-flex">
                <button
                  className="green-tick-btn approve-btn"
                  title="Accept"
                  onClick={() => handleApproval(id)}
                >
                  {accept}
                </button>
                <button
                  className="orange-cross-btn approve-btn"
                  title="Reject"
                  onClick={() => handleRejection(id)}
                >
                  {reject_label}
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {employeeDetails.hrApprovalStatus === "Approved" ? (
          <div className="learning-dev-purpose-container">
            <h6>Remarks</h6>
            <div className="learning-dev-purpose-wrapper">
              {employeeDetails.hrRemarks ? employeeDetails.hrRemarks : "-"}
            </div>
          </div>
        ) : null}

        {employeeDetails.hrApprovalStatus === "Rejected" ? (
          <div className="learning-dev-purpose-container">
            <h6>Reason</h6>
            <div className="learning-dev-purpose-wrapper">
              {employeeDetails.reason}
            </div>
          </div>
        ) : null}

        <ModalComponents
          show={showAddRequirement}
          onHide={hideRejection}
          title={"Rejected"}
          modalBody={
            <ReasonForRejection
              apiCallCompleted={apiCallCompleted}
              hideRejection={hideRejection}
              requestId={requestId}
              role={managerCheckerList ? "Manager" : "Hr"}
            />
          }
          className="modal-lg"
        />

        <ModalComponents
          show={showApproval}
          onHide={hideApproval}
          title={"Approved"}
          modalBody={
            <ApprovalRemark
              apiCallCompleted={apiCallCompleted}
              hideApproval={hideApproval}
              requestId={requestId}
              role="Hr"
            />
          }
          className="modal-lg"
        />
      </MainCard>
    </>
  );
};

export default LearningDevelopmentDetails;
