import React, { useEffect, useRef, useState } from "react";
import Card from "../../../component/Card/Card";
import { Strings, requiredFeild } from "../../../constant/Strings";
import { icons } from "../../../assets";
import "./PersonalInfo.scss";
import EditButton from "../../../component/Buttons/EditButton/EditButton";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// import Header from "../../../assets/Header.svg";
import Header from "../../../assets/SkillmineLogoPng.png";
import * as actions from "../../../Redux/Actions";
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import ImageUploading, { ImageListType } from "react-images-uploading";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import EditPersonalInfo from "./EditPersonalInfo/EditPersonalInfo";
import EditDocumentInfo from "./EditDocumentInfo/EditDocumentInfo";
import EditPersonalContactInfo from "../ContactInfo/EditPersonalContactInfo/EditPersonalContactInfo";
import moment from "moment";
import useLoadingIndicator from "../../../component/Loader/Loader";
// import Footer from "../../../assets/Footer.jpg";
import Footer from "../../../assets/Footer.jpg";
import PdfModal from "./PdfModal/PdfModal";

const PersonalInfo = (props: any) => {
  const {
    profileImage,
    candidateName,
    lastName,
    middleName,
    emailId,
    designation,
    title,
    dateOfBirth,
    pan,
    aadharNumber,
    passportNumber,
    recordStatus,
    eId,
    aadharCardImage,
    panCardImage,
    resume,
    bloodGroup,
    status,
    profileInfoDisplayCondition,
    employeeId,
  } = props;
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    apiCallCompleted,
  } = useLoadingIndicator();
  const [images, setImages] = React.useState([]);
  const [editPersonalInfo, setEditPersonalInfo] = useState(false);
  const [editDocumentInfo, setEditDocumentInfo] = useState(false);
  const [pData, setPData] = useState(false);
  const [pdfData, setPdfData] = useState<any>();
  const [previewPdf, setPreviewPdf] = useState(false);
  const pdfContentRef = useRef(null);
  const [docFile, setDocFile] = useState<any>({
    data: null,
  });
  const [employeeResumeModal,setEmployeeResumeModal] = useState(false)
  const {
    personal_info_heading,
    first_name,
    last_name,
    employee_title,
    middle_name,
    date_of_birth,
    pan_number,
    aadhar_no,
    passport_no,
    documents,
    clickUpload,
    dragDrop,
    fileTypeSize,
    fileTypeSizeResume,
    resume_title,
    blood_group,
  } = Strings;
  const { default_avatar, edit_orange_icon, upload_icon } = icons;

  const dispatch = useDispatch();
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const { required } = requiredFeild;

  const editShowCondition =
    (status !== "Active" && status !== "Inactive") ||
    (role && role.includes("hr") && status === "Active" && eId != loginId);

  useEffect(() => {
    const loadImage = (src: string) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    const loadImages = async () => {
      const images = Array.from(
        document.querySelectorAll<HTMLImageElement>("#pdf-content-profile img")
      );
      const promises = images.map((img) => loadImage(img.src));
      await Promise.all(promises);
    };

    const generatePdf = async () => {
      const pdfContentDiv = document.getElementById("pdf-content-profile");
      if (pdfContentDiv) {
        if (pdfContentDiv.style.display === "block") {
          await loadImages();
          const pdfDocument = new jsPDF();
          const canvas = await html2canvas(pdfContentDiv, {
            useCORS: true,
            allowTaint: true,
          });

          const imgData = canvas.toDataURL("image/png");
          const imgProps = pdfDocument.getImageProperties(imgData);
          const pdfWidth = pdfDocument.internal.pageSize.getWidth() - 20;
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdfDocument.addImage(imgData, "PNG", 10, 10, pdfWidth, pdfHeight);
          pdfDocument.save(
            `${employeeId + "_" + candidateName + "_"}Resume.pdf`
          );
          pdfContentDiv.style.display = "none";
          hideLoadingIndicator();
        }
      }
    };

    setTimeout(generatePdf, 2000);
  }, [pData]);
 
  

  const handleSubmitImage = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 5 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 5 MB.");
      return;
    } else if (!["image/jpeg", "image/png", "image/gif"].includes(fileType)) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, PNG and GIF"
      );
      return;
    } else {
      showLoadingIndicator();
      dispatch(
        actions.employeeDetailsImageUploadAction(
          { file: e.target.files[0], eId: eId },
          (res: any) => {
            if (res.code == 200 && res.data) {
              dispatch(
                actions.editEmployeeDataAction(
                  { candidatePhoto: res.data, updatedBy: eId, eId: eId },
                  (res: any) => {
                    if (res.code == 200 && res.data) {
                      hideLoadingIndicator();
                      showSuccessToastMessage("Picture Added Successfully!");
                    }
                  }
                )
              );
            }
          }
        )
      );
    }
  };

  const handleEditPersonalInfo = () => {
    setEditPersonalInfo(!editPersonalInfo);
  };

  const handleEditDocumentInfo = () => {
    setEditDocumentInfo(!editDocumentInfo);
  };

 
  // useEffect(() => {
  //   if (pdfData && isClicked) {
  //     printPdf();
  //   }
  // }, [pdfData, isClicked]);

  return (
    <>
      {loadingComponent()}
      <div>
        <h5>{personal_info_heading}</h5>
        {/* <Card>
          <div className="personal__info-container">
            <div className="personal__info-profile-details">
              <img
                src={profileImage ? profileImage : default_avatar}
                alt="icon"
              />
              <div>
                <p>{`${candidateName} ${middleName} ${lastName}`}</p>
                <div className="profile-position">{designation}</div>
                <div className="profile-position">{emailId}</div>
              </div>
            </div>

            <div className="upload-wrapper">
              <div>
                <img src={upload_icon} alt="icon" />
                <p>
                  <b>{clickUpload}</b> {dragDrop} <br />
                  {fileTypeSize}
                </p>
              </div>
              <input type="file" value={""} onChange={handleSubmitImage} />
            </div>
          </div>
        </Card> */}
        <Card>
          <div className="edit-icon-wrapper">
            <h5>{personal_info_heading}</h5>
            {editShowCondition && (
              <EditButton
                title="Edit"
                icon={edit_orange_icon}
                onClick={handleEditPersonalInfo}
              />
            )}
          </div>

          <div className="edit-icon-wrapper">
            <div className="profile-details">
              <span>{employee_title}</span> <p>{title}</p>
            </div>
            <div className="profile-details">
              <span>{first_name}</span> <p>{candidateName}</p>
            </div>
            <div className="profile-details">
              <span>{middle_name}</span> <p>{middleName}</p>
            </div>
            <div className="profile-details">
              <span>{last_name}</span> <p>{lastName}</p>
            </div>
          </div>

          <div className="edit-icon-wrapper">
            <div className="profile-details">
              <span>{date_of_birth}</span> <p>{dateOfBirth}</p>
            </div>
            <div className="profile-details">
              <span>{blood_group}</span> <p>{bloodGroup}</p>
            </div>
            <div className="profile-details"></div>
            <div className="profile-details"></div>
          </div>
        </Card>
        <Card>
          <div className="edit-icon-wrapper">
            <h5>{documents}</h5>
            <EditButton
              title="Edit"
              icon={edit_orange_icon}
              onClick={handleEditDocumentInfo}
            />
          </div>

          <div className="edit-icon-wrapper personal__info-container">
            <div className="profile-details">
              <span>{pan_number}</span>
              <p>
                {pan}
                <span className="mx-3">
                  {panCardImage?.document_path_url ? (
                    <a
                      className="view-resume"
                      href={panCardImage?.document_path_url}
                      target="_blank"
                    >
                      View File
                    </a>
                  ) : (
                    ""
                  )}
                </span>
              </p>
            </div>
            <div className="profile-details">
              <span>{aadhar_no}</span>{" "}
              <p>
                {aadharNumber}
                <span className="mx-3">
                  {aadharCardImage?.document_path_url ? (
                    <a
                      className="view-resume"
                      href={aadharCardImage?.document_path_url}
                      target="_blank"
                    >
                      View File
                    </a>
                  ) : (
                    ""
                  )}
                </span>
              </p>
            </div>
            <div className="profile-details">
              <span>{passport_no}</span> <p>{passportNumber}</p>
            </div>
          </div>
        </Card>
        {/* <Card>
       <div className="personal__info-container">
            <div>
              <h5>
                {resume_title}
                <span className="required">{required}</span>
              </h5>
              {resume?.document_path_url ? (
                <a
                  className="view-resume"
                  href={resume?.document_path_url}
                  target="_blank"
                >
                  View File
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="upload-wrapper">
              <div>
                <img src={upload_icon} alt="icon" />
                <p>
                  <b>{clickUpload}</b> {dragDrop} <br />
                  {fileTypeSizeResume}
                </p>
              </div>
              <input type="file" value={""} onChange={handleSubmitResume} />
            </div>
          </div> 
          </Card> */}
          {/* <div className="personalinfo-resume-section">
              <button className="resume-button" onClick={() =>  setEmployeeResumeModal(true)}>
                Employee Resume
              </button>
              <button className="resume-button"  onClick={(e) => {
                  getApiDispatch(e);
                }}>
                Skillmine Resume Generator
              </button>
          </div> */}
    
        {/* {profileInfoDisplayCondition && (
          <Card>
            <div className="personal__info-container">
              <div>
                <h5>Generate Resume</h5>
              </div>
              <div
                className="download-wrapper"
                onClick={(e) => {
                  getApiDispatch(e);
                }}
              >
                <div>
                  <img src={upload_icon} alt="icon" />
                  <p>
                    <b>Click Here to Generate & Download the Resume</b>
                  </p>
                </div>
              </div>
            </div>
          </Card>
        )} */}
      </div>
      {/* <div
        ref={pdfContentRef}
        id="pdf-content-profile"
        style={{ display: "none" }}
      >
        <div className="skillminelogoSection">
          <img src={Header} alt="no_header_img" className="skillmine_Logo" />
        </div>
        <div className="imageDiv">
          <div>
            <b>Profile Name : </b>
            {pdfData?.candidateName +
              " " +
              pdfData?.middleName +
              " " +
              pdfData?.lastname}

            <div className="pt-2">
              <b>Designation : </b>
              {pdfData?.designation}
            </div>
            <div className="pt-2">
              <b>Experience : </b>
              {pdfData?.totalExperience} year(s)
            </div>
          </div>
          <img
            src={
              pdfData?.candidatePhoto?.document_path_url
                ? pdfData.candidatePhoto.document_path_url
                : pdfData?.candidatePhoto?.candidatePhoto?.document_path_url
                ? pdfData.candidatePhoto.candidatePhoto.document_path_url
                : icons.default_avatar
            }
            alt="icon"
            className="profilePic"
          />
        </div>
        {pdfData?.summary?.length > 0 && (
          <div className="pt-2">
            <h4>
              <b>Profile Summary : </b>
            </h4>
            <ul>
              {pdfData?.summary?.map((ele: any) => {
                return (
                  <li>
                    <p>
                      <b>{ele?.summary}</b>
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {pdfData?.qualifications?.length > 0 && (
          <div className="pt-2">
            <h4>
              <b>Educational Info : </b>
            </h4>
            <div className="table table-responsive">
              <table className="table-main table table-sm mt-3">
                <thead className="table-head">
                  <tr>
                    <th className="table-head-row">Qualification</th>
                    <th className="table-head-row">Branch / Stream</th>
                    <th className="table-head-row">
                      Institute / School / College
                    </th>
                    <th className="table-head-row">Board / University</th>
                    <th className="table-head-row">Year of Passing</th>
                    <th className="table-head-row">Percentage / CGPA</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {pdfData?.qualifications?.map((ele: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td className="table-body-row">{ele?.qualification}</td>
                        <td className="table-body-row">{ele?.branch}</td>
                        <td className="table-body-row">{ele?.institute}</td>
                        <td className="table-body-row">{ele?.board}</td>
                        <td className="table-body-row">{ele?.year}</td>
                        <td className="table-body-row">{ele?.marks}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {pdfData?.experiences?.length > 0 && (
          <div className="pt-2">
            <h4>
              <b>Employment History : </b>
            </h4>
            <div className="table table-responsive">
              <table className="table-main table table-sm mt-3">
                <thead className="table-head">
                  <tr>
                    <th className="table-head-row">Company Name</th>
                    <th className="table-head-row">Designation</th>
                    <th className="table-head-row">Location</th>
                    <th className="table-head-row">Roles & Responsibility</th>
                    <th className="table-head-row">Date of Joining</th>
                    <th className="table-head-row">Date of Relieving</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {pdfData?.experiences?.map((ele: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td className="table-body-row">{ele?.companyName}</td>
                        <td className="table-body-row">{ele?.designation}</td>
                        <td className="table-body-row">{ele?.location}</td>
                        <td className="table-body-row">
                          {ele?.rolesAndResponsibility}
                        </td>
                        <td className="table-body-row">
                          {moment(ele?.startDate).format("DD/MM/YYYY")}
                        </td>
                        <td className="table-body-row">
                          {moment(ele?.endDate).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

      </div> */}
      <ModalComponents
        show={editPersonalInfo}
        onHide={handleEditPersonalInfo}
        title="Edit Personal Details"
        modalBody={
          <EditPersonalInfo
            apiCallCompleted={apiCallCompleted}
            candidateName={candidateName}
            lastName={lastName}
            middleName={middleName}
            emailId={emailId}
            designation={designation}
            title={title}
            dateOfBirth={moment(dateOfBirth).format("YYYY-MM-DD")}
            recordStatus={recordStatus}
            handleEditPersonalInfo={handleEditPersonalInfo}
            eId={eId}
            bloodGroup={bloodGroup}
          />
        }
        className="modal-lg"
      />
      <ModalComponents
        show={editDocumentInfo}
        onHide={handleEditDocumentInfo}
        title="Edit Documents Details"
        modalBody={
          <EditDocumentInfo
            apiCallCompleted={apiCallCompleted}
            pan={pan}
            aadharNumber={aadharNumber}
            passportNumber={passportNumber}
            panCardImage={panCardImage}
            aadharCardImage={aadharCardImage}
            handleEditDocumentInfo={handleEditDocumentInfo}
            eId={eId}
            status={status}
          />
        }
        className="modal-lg"
      />
      {/* <ModalComponents
        show={previewPdf}
        onHide={() => setPreviewPdf(false)}
        title="Resume Preview"
        modalBody={
          <PdfModal
            setPreviewPdf={setPreviewPdf}
            pdfData={pdfData}
            Header={Header}
            printPdf={ printPdf}
          />
        }
        className="modal-lg"
      /> */}
       {/* <ModalComponents
        show={employeeResumeModal}
        onHide={() => setEmployeeResumeModal(false)}
        title="Employee Resume"
        modalBody={
        <div className="modalResumeSection">
           <button className="modal-resume-button" onClick={() => window.open(resume?.document_path_url,"_blank")}>
                View Resume
              </button>
              <div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleSubmitResume}
      />
      <button className="modal-resume-button" onClick={handleButtonClick}>
        Update Resume
      </button>
    </div>
        </div>
        }
        className="modal-lg"
      /> */}
    </>
  );
};

export default PersonalInfo;
