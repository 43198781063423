import React, { useEffect, useMemo, useState } from "react";
import SearchBar from "../../component/SearchBar/SearchBar";
import { FaSort } from "react-icons/fa";
import { Table } from "react-bootstrap";
import ModalComponents from "../../component/ModalComponent/ModalComponent";
import { useSelector } from "react-redux";
import ChangeStatus from "./ChangeStatus";
import ShowEntries from "../../component/ShowEntries/ShowEntries";
import BackButton from "../../component/Buttons/BackButton";
import FilterValues from "../../component/FieldFilter/FilterValues";
import {
  SkillLinksReferralListFilters,
  Strings,
  skillLinksReferralListTable,
} from "../../constant/Strings";
import TablePagination from "../../component/TablePagination/TablePagination";
import { icons } from "../../assets";
import "./SkillLinks.scss";
import useLoadingIndicator from "../../component/Loader/Loader";

const SkillLinksTable = (props: any) => {
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const [changeStatus, setChangeStatus] = useState({
    id: "",
    remarks: "",
    status: "",
    rating: "",
  });
  const {
    totalPages,
    onPage,
    handlePageChange,
    searchValue,
    handleSearch,
    filterValue,
    filterChange,
    pagePerSize,
    setPagePerSize,
    totalEntries,
    onSearchClicked,
  } = props;
  const [totalRecords, setTotalRecords] = useState(0);
  const allReferralList = useSelector(
    (state: any) => state.employeeReducer.getAllReferralListDetails
  );
  const {
    no_data_found,
    referral_list,
    filters,
    view,
    referred_by,
    referral_amount,
    referred_by_email,
    employeeId,
  } = Strings;
  const [expansion, setExpansion] = useState(false);
  const [rowid, setRowid] = useState("");
  const [data, setdata] = useState<any>([]);
  const [order, setorder] = useState("ASC");
  const role = localStorage.getItem("role");
  const skillLinksChecker = role && role.includes("skill-link-checker");
  function handleExpansion(id: any) {
    setRowid(id);
    setExpansion(!expansion);
  }

  const [isSorted, setisSorted] = useState(false);
  const [sortedData, setsortedData] = useState<any>([]);
  const sorting = (col: any) => {
    setisSorted(true);
    if (order === "ASC") {
      const sorted = [...data].sort((a: any, b: any) => {
        if (a[col] < b[col]) {
          return 1;
        }
        if (a[col] > b[col]) {
          return -1;
        }
        return 0;
      });
      setsortedData(sorted);
      setorder("DSC");
    } else {
      const sorted = [...data].sort((a: any, b: any) => {
        if (a[col] < b[col]) {
          return -1;
        }
        if (a[col] > b[col]) {
          return 1;
        }
        return 0;
      });
      setsortedData(sorted);
      setorder("ASC");
    }
  };
  const [showChangeStatus, setShowChangeStatus] = useState(false);

  const handleChangeStatus = (
    id: any,
    status: any,
    remarks: any,
    rating: any
  ) => {
    setShowChangeStatus(true);
    setChangeStatus({
      id: id,
      status: status,
      remarks: remarks,
      rating: rating,
    });
  };
  const hideChangeStatus = () => {
    setShowChangeStatus(false);
  };

  useEffect(() => {
    if (allReferralList && allReferralList.data) {
      setdata(allReferralList.data);
      setTotalRecords(allReferralList.data.length);
    }
  }, [allReferralList, data]);
  const makerCheckerColumns = [
    {
      Header: "Resource Name",
      accessor: "fullName",
    },
    {
      Header: "Email Id",
      accessor: "emailId",
    },
    {
      Header: "Contact Number",
      accessor: "contactNumber",
    },
    {
      Header: "Current CTC",
      accessor: "currentCtc",
    },
    {
      Header: "Notice Period",
      accessor: "noticePeriod",
    },
    {
      Header: "Resume",
      accessor: "resume",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Remarks",
      accessor: "remarks",
    },
  ];
  const columns = useMemo(
    () =>
      skillLinksChecker ? makerCheckerColumns : skillLinksReferralListTable,
    [skillLinksChecker]
  );

  return (
    <>
      {loadingComponent()}
      <BackButton navigateTo={"/refer-resource"} />
      <p className="mt-3">
        <strong>{referral_list}</strong>
      </p>
      <div>
        <div className="mt-3 buttonsize filter-module-wrapper">
          <div className="module-searchbar-wrapper">
            <SearchBar
              filter={searchValue}
              setFilter={handleSearch}
              onSearchClicked={onSearchClicked}
            />
          </div>
          <div className="module-filter-container">
            <select
              id="select-filters"
              value={filterValue}
              onChange={(event) => {
                const selectedValue = event.target.value;
                const selectedOption = SkillLinksReferralListFilters.find(
                  (item: any) => item.value === selectedValue
                );
                if (selectedOption) {
                  filterChange(selectedOption);
                }
              }}
            >
              <option hidden>{filters}</option>
              {SkillLinksReferralListFilters.map((item: any, index: any) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
        />
        {data.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="table-margin">
                <thead className="employeedb">
                  <tr className="custom-table-head-row">
                    {columns.map((ele: any) => (
                      <React.Fragment key={ele.Header}>
                        <td
                          onClick={() => sorting(ele.accessor)}
                          className="sorting-cursor"
                        >
                          {ele.Header}
                          {ele.Header !== "" && ele.Header != "Remarks" ? (
                            <span>{<FaSort />}</span>
                          ) : (
                            " "
                          )}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody className="employeedata">
                  {data?.length === 0 ? (
                    <tr>
                      <td colSpan={12}>{no_data_found}</td>
                    </tr>
                  ) : (
                    (isSorted ? sortedData : data).map(
                      (ele: any, index: any) => (
                        <>
                          <tr key={ele?._id}>
                            <td
                              onClick={() => handleExpansion(index)}
                              className="text-start"
                            >
                              <img
                                src={icons?.arrow_drop_down}
                                className={
                                  index === rowid
                                    ? expansion
                                      ? "rotate-arrow-icon"
                                      : ""
                                    : ""
                                }
                                alt="expand"
                              />
                              <span className="mx-3">{ele?.fullName}</span>
                            </td>

                            <td>{ele?.emailId}</td>
                            <td>{ele?.contactNumber}</td>
                            <td>{ele?.currentCtc}</td>
                            <td>{ele?.noticePeriod}</td>
                            <td>
                              {ele.uploadResume?.document_path_url ? (
                                <div className="action-btn">
                                  <a
                                    href={ele.uploadResume?.document_path_url}
                                    target="_blank"
                                  >
                                    {view}
                                  </a>
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                            {skillLinksChecker && (
                              <td className="skill-links-btns">
                                {
                                  <button
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Change Status"
                                    type="button"
                                    className={`common-css ${
                                      ele.status === 1
                                        ? "approved-btn"
                                        : ele.status === 0
                                        ? "draft-btn"
                                        : ele.status === 2
                                        ? "rejected-btn"
                                        : ele.status === 3
                                        ? "updated-btn"
                                        : ""
                                    }`}
                                    disabled={ele.status == 1}
                                    onClick={() =>
                                      handleChangeStatus(
                                        ele._id,
                                        ele.status,
                                        ele.remarks,
                                        ele.rating
                                      )
                                    }
                                  >
                                    {ele?.status === 0
                                      ? "New"
                                      : ele?.status === 1
                                      ? "Accepted"
                                      : ele?.status === 2
                                      ? "Rejected"
                                      : ele?.status === 3
                                      ? "KIV"
                                      : ""}
                                  </button>
                                }
                              </td>
                            )}
                            <td className="remarks-cell">
                              {ele.remarks ?? "-"}
                            </td>
                          </tr>
                          {index === rowid && expansion && (
                            <tr>
                              <td colSpan={9}>
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <div className="invoice_heading">
                                      {referred_by}
                                    </div>
                                    <div>
                                      {ele?.ReferredBy[0]?.candidateName +
                                        " " +
                                        ele?.ReferredBy[0]?.middleName +
                                        " " +
                                        ele?.ReferredBy[0]?.lastname}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="invoice_heading">
                                      {employeeId}
                                    </div>
                                    <div>{ele?.ReferredBy[0]?.employeeId}</div>
                                  </div>
                                  <div>
                                    <div className="invoice_heading">
                                      {referred_by_email}
                                    </div>
                                    <div>{ele?.ReferredBy[0]?.emailId}</div>
                                  </div>
                                  <div>
                                    <div className="invoice_heading">
                                      {referral_amount}
                                    </div>
                                    <div>{ele?.PostJob[0].referralAmount}</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      )
                    )
                  )}
                </tbody>
              </Table>
            </div>
            <div className="show-entries-wrapper my-3">
              <div className="ml-3">
                <ShowEntries
                  pageSize={pagePerSize}
                  setPageSize={setPagePerSize}
                  totalRecords={totalRecords}
                  totalEntries={totalEntries}
                />
              </div>
              <TablePagination
                totalPages={totalPages}
                currentPage={onPage}
                onClick={handlePageChange}
              />
            </div>
          </>
        ) : (
          <p className="no-data">{no_data_found}</p>
        )}
      </div>

      <ModalComponents
        show={showChangeStatus}
        onHide={hideChangeStatus}
        title={"Change Status"}
        modalBody={
          <ChangeStatus
            apiCallCompleted={apiCallCompleted}
            hideChangeStatus={hideChangeStatus}
            status={changeStatus}
          />
        }
        className="modal-md"
      />
    </>
  );
};

export default SkillLinksTable;
