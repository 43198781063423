import { useState } from "react";
import { Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./SkillLinks.scss";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../component/Toasts/Toasts";
import { icons } from "../../assets";
import { Strings } from "../../constant/Strings";
import useLoadingIndicator from "../../component/Loader/Loader";
import cashIcon from "../../assets/cash-stack.svg";

const ReferResourceCard = (props: any) => {
  const {
    jobTitle,
    jobType,
    jobDescription,
    buttonTitle,
    modeOfJob,
    onClickEdit,
    id,
    jobStatus,
    recordStatus,
    location,
    workLevel,
    majorSkills,
    view_referral_list,
    route_link,
    navigationLink,
    editJobStatusApi,
    onClickJD,
    pageIn,
    jobId,
    uploadDescription,
    ctcRange,
  } = props;
  const { showLoadingIndicator, hideLoadingIndicator, loadingComponent } =
    useLoadingIndicator();
  let statusChange: any = jobStatus;
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const onClick = () => {
    navigate(navigationLink);
  };

  const {
    location_on,
    person_icon,
    contract_icon,
    work_icon,
    edit_blue_icon,
    edit_disable_icon,
  } = icons;
  const {
    open,
    close,
    download_jd,
    view_job,
    jobCloseSuccess,
    jonOpenSuccess,
  } = Strings;
  const dispatch = useDispatch();
  const [status, setStatus] = useState<any>(statusChange);
  const handleEditJobStatus = () => {
    if (statusChange == 1) {
      statusChange = 0;
      setStatus(0);
    } else {
      statusChange = 1;
      setStatus(1);
    }
    handleDispatch();
  };
  const skillLinksCondition =
    pageIn === "SkillLinks" &&
    role &&
    (role.includes("skill-link-maker") || role.includes("skill-link-checker"));
  const ijpCondition =
    pageIn === "IJP" &&
    role &&
    (role?.includes("internal-job-maker") ||
      role?.includes("internal-job-checker"));
  const hrCondition = role?.includes("hr");
  const handleDispatch = () => {
    showLoadingIndicator();
    dispatch(
      editJobStatusApi(id, { jobStatus: statusChange }, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          hideLoadingIndicator();
        } else {
          if (res?.code === 200) {
            if (statusChange == 0) {
              showSuccessToastMessage(jobCloseSuccess);
            } else {
              showSuccessToastMessage(jonOpenSuccess);
            }
            hideLoadingIndicator();
          }
        }
      })
    );
  };

  return (
    <>
      {loadingComponent()}
      <Card
        className={
          buttonTitle === "Open"
            ? "card p-3 mt-4"
            : "card p-3 mt-4 disable-card"
        }
      >
        <div className="row">
          <div className="col-5">
            <h5
              className={
                buttonTitle === "Open" ? "job-title" : "job-title disable-title"
              }
            >
              {jobTitle}
            </h5>
            <div className="sub-title">Job Id : {jobId}</div>
          </div>
          <div className="col-4 d-flex">
            {(skillLinksCondition || ijpCondition) && (
              <>
                <span className="job-desc button-gap pt-1">{open}</span>
                <Form className="mx-2 custom-form-check">
                  {status == 0 ? (
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={true}
                      onChange={() => handleEditJobStatus()}
                    />
                  ) : (
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={false}
                      onChange={() => handleEditJobStatus()}
                    />
                  )}
                </Form>
                <span className="job-desc pt-1">{close}</span>
              </>
            )}
            <div className="mx-5 skill-links-btns">
              {hrCondition && (
                <button
                  className={
                    recordStatus == "Approved"
                      ? "common-css approved-btn"
                      : recordStatus == "Rejected"
                      ? "common-css rejected-btn"
                      : recordStatus == "Draft"
                      ? "common-css draft-btn"
                      : recordStatus == "Updated"
                      ? "common-css updated-btn"
                      : ""
                  }
                >
                  {recordStatus}
                </button>
              )}
            </div>
          </div>
          <div className="col-3">
            <div className="d-flex justify-content-end">
              {hrCondition ? (
                <>
                  <Link
                    className="view-referral-list-link button-gap"
                    to={route_link}
                  >
                    {view_referral_list}
                  </Link>
                  {(skillLinksCondition || ijpCondition) && (
                    <>
                      <button
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Edit Resource"
                        className={
                          buttonTitle === "Open"
                            ? "edit-resource-icon mx-2"
                            : "edit-disable-resource mx-2"
                        }
                        onClick={onClickEdit}
                        disabled={buttonTitle === "Closed" ? true : false}
                      >
                        {buttonTitle === "Open" ? (
                          <img src={edit_blue_icon} alt="icon"></img>
                        ) : (
                          <img src={edit_disable_icon} alt="icon"></img>
                        )}
                      </button>
                    </>
                  )}
                </>
              ) : (
                <div className="skill-links-btns">
                  <button
                    className={
                      buttonTitle == "Open"
                        ? "common-css approved-btn"
                        : buttonTitle == "Closed"
                        ? "common-css rejected-btn"
                        : ""
                    }
                  >
                    {buttonTitle}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-3">
          <p
            className={
              buttonTitle === "Open" ? "job-desc" : "job-desc disable-title"
            }
          >
            {jobDescription == ""
              ? "Download JD for complete Job Details."
              : jobDescription?.length > 200
              ? `${jobDescription.slice(0, 200)}....`
              : jobDescription}
          </p>
        </div>
        <div className="d-flex">
          {majorSkills?.length > 0
            ? majorSkills?.map((value: any) => (
                <strong key={value} className="ux-list">
                  <li className="ux-li button-gap">{value}</li>
                </strong>
              ))
            : ""}
        </div>
        <hr className="line-hr"></hr>
        <div className="row">
          <div className="col-9 mt-2">
            <div className="row sub-title">
              <div className="col-3">
                <img src={location_on} className="mx-2" alt="icon"></img>
                {location}
              </div>
              <div className="col-2">
                <img src={work_icon} className="mx-2" alt="icon"></img>
                {modeOfJob}
              </div>
              <div className="col-2">
                <img src={person_icon} className="mx-2" alt="icon"></img>
                {workLevel}
              </div>
              <div className="col-2">
                <img src={contract_icon} className="mx-2" alt="icon"></img>
                {jobType}
              </div>
              {pageIn == "SkillLinks" && (
                <div className="col-2">
                  <img src={cashIcon} className="mx-2" alt="icon"></img>
                  {ctcRange}
                </div>
              )}
            </div>
          </div>
          <div className="view-btns-wrapper col-3 ">
            <div className="d-flex justify-content-end">
              {uploadDescription && (
                <button
                  className={
                    buttonTitle === "Open"
                      ? "view-jd-btn"
                      : "view-jd-btn disable-outline-btn"
                  }
                  onClick={onClickJD}
                  disabled={buttonTitle === "Closed"}
                >
                  {download_jd}
                </button>
              )}
              <button
                disabled={buttonTitle === "Closed" ? true : false}
                className={
                  buttonTitle === "Open"
                    ? "view-job-btn margin-button"
                    : "view-job-btn margin-button disable-button"
                }
                onClick={onClick}
              >
                {view_job}
              </button>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ReferResourceCard;
