import React from "react";
import "./EditButton.scss";

const EditButton = (props: any) => {
  const { icon, title, onClick } = props;
  return (
    <>
      <button className="edit-button" onClick={onClick}>
        {title}
        {icon && (
          <>
            <img src={icon} className="button-icon" alt="icon" />
          </>
        )}
      </button>
    </>
  );
};

export default EditButton;
