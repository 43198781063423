import React, { useEffect, useState } from "react";
import * as actions from "../../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dropdown, Form } from "react-bootstrap";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import Select from "react-select";
import {
  Strings,
  addressDetailsValidation,
} from "../../../../constant/Strings";
import {
  addressValidation,
  isCharactersValid,
  isNumberValid,
} from "../../../../constant/Validations/index";
import { AiOutlineCheck } from "react-icons/ai";

const EditAddressDetails = (props: any) => {
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [countryName, setCountryName] = useState([]);
  const [country, setCountry] = useState({
    countryName: "",
    added_by: loginId,
  });
  const {
    currentAddress1,
    currentAddress2,
    currentCountry,
    currentDistrict,
    currentState,
    currentPinCode,
    permanentAddress1,
    permanentAddress2,
    permanentCountry,
    permanentDistrict,
    permanentState,
    permanentPinCode,
    eId,
    handleEditAddress,
    currentAddressProof,
    permanentAddressProof,
    isSameComunicationAdderess,
    apiCallCompleted,
  } = props;
  const {
    permanentPinCodeValid,
    currentPinCodeValid,
    permanentAddress1Valid,
    currentAddress1Valid,
  } = addressDetailsValidation;
  const {
    current_address_1,
    current_address_2,
    address_for_communication,
    permanent_address,
    current_country,
    current_district,
    current_pincode,
    current_state,
    permanent_address_1,
    permanent_address_2,
    editSuccess,
    cancel,
    submit,
    fileSize,
    permanenetAddressProof,
    uploadFile,
    communicationAddressProof,
    addSuccess,
  } = Strings;
  const [checked, setChecked] = useState<any>(true);
  const [stateItem, setStateItem] = useState([]);
  const [employeeData, setEmployeeData] = useState({
    currentAddress1: currentAddress1,
    currentAddress2: currentAddress2,
    currentDistrict: currentDistrict,
    currentCountry: currentCountry,
    currentState: currentState,
    currentPinCode: currentPinCode,
    permanentAddress1: permanentAddress1,
    permanentAddress2: permanentAddress2,
    permanentDistrict: permanentDistrict,
    permanentState: permanentState,
    permanentCountry: permanentCountry,
    permanentPinCode: permanentPinCode,
    eId: eId,
    updatedBy: eId,
    permanentAddressProof: permanentAddressProof,
    currentAddressProof: currentAddressProof,
    isSameComunicationAdderess: isSameComunicationAdderess,
  });

  const [errors, setErrors] = useState({
    currentAddress1: "",
    currentAddress2: "",
    currentPinCode: "",
    permanentAddress1: "",
    permanentAddress2: "",
    permanentPinCode: "",
    countryName: "",
  });
  const [btnDisable, setBtnDisable] = useState(false);
  const [docFile, setDocFile] = useState<any>({
    permanentAddressProof: null,
    currentAddressProof: null,
  });
  const handleSubmitPermanentAddress = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, permanentAddressProof: e.target.files[0] });
      dispatch(
        actions.employeeDetailsDocUploadAction(
          { file: e.target.files[0], eId: employeeData.eId },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setEmployeeData({
                ...employeeData,
                permanentAddressProof: res.data,
              });
            }
          }
        )
      );
    }
  };

  const handleSubmitCurrentAddress = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, currentAddressProof: e.target.files[0] });
      dispatch(
        actions.employeeDetailsDocUploadAction(
          { file: e.target.files[0], eId: employeeData.eId },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setEmployeeData({
                ...employeeData,
                currentAddressProof: res.data,
              });
            }
          }
        )
      );
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getStatesAction());
  }, []);

  const getStates = useSelector(
    (state: any) => state.employeeReducer.getStatesDetails
  );

  useEffect(() => {
    if (getStates) {
      setStateItem(getStates);
    }
  }, [getStates, stateItem]);

  let valid: boolean = true;
  const validate = () => {
    if (addressValidation(employeeData.currentAddress1) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        currentAddress1: currentAddress1Valid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, currentAddress1: "" }));
    }
    if (employeeData.currentAddress2) {
      if (addressValidation(employeeData.currentAddress2) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          currentAddress2: currentAddress1Valid,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({ ...errorObj, currentAddress2: "" }));
      }
    }
    if (addressValidation(employeeData.permanentAddress1) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        permanentAddress1: permanentAddress1Valid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, permanentAddress1: "" }));
    }
    if (employeeData.permanentAddress2) {
      if (addressValidation(employeeData.permanentAddress2) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          permanentAddress2: permanentAddress1Valid,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({ ...errorObj, permanentAddress2: "" }));
      }
    }
    if (
      employeeData.currentCountry == "India" &&
      (employeeData.currentPinCode?.toString().length > 6 ||
        employeeData.currentPinCode?.toString().length < 6 ||
        isNumberValid(employeeData.currentPinCode) == false)
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        currentPinCode: currentPinCodeValid,
      }));
      setBtnDisable(false);
    } else if (
      employeeData.currentCountry !== "India" &&
      (employeeData.currentPinCode?.toString().length < 2 ||
        employeeData.currentPinCode?.toString().length > 10)
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        currentPinCode: currentPinCodeValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, currentPinCode: "" }));
    }
    if (
      employeeData.permanentCountry == "India" &&
      (employeeData.permanentPinCode?.toString().length > 6 ||
        employeeData.permanentPinCode?.toString().length < 6 ||
        isNumberValid(employeeData.permanentPinCode) == false)
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        permanentPinCode: permanentPinCodeValid,
      }));
      setBtnDisable(false);
    } else if (
      employeeData.permanentCountry !== "India" &&
      (employeeData.permanentPinCode?.toString().length < 2 ||
        employeeData.permanentPinCode?.toString().length > 10)
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        permanentPinCode: permanentPinCodeValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, permanentPinCode: "" }));
    }
  };
  const handleEdit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editEmployeeDataAction(employeeData, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            }
          } else {
            if (res.code === 200) {
              showSuccessToastMessage(editSuccess);
              apiCallCompleted(true);
            }
          }
        }
      })
    );
    handleEditAddress();
  };
  const currentStateOptions: any = stateItem.map((ele: any) => ({
    value: ele.states,
    label: ele.states,
  }));
  const handleCurrentStateManager = (selected: any) => {
    setEmployeeData({
      ...employeeData,
      currentState: selected.value,
    });
  };
  const permanentStateOptions: any = stateItem.map((ele: any) => ({
    value: ele.states,
    label: ele.states,
  }));
  const handlePermanentStateManager = (selected: any) => {
    setEmployeeData({
      ...employeeData,
      permanentState: selected.value,
    });
  };
  useEffect(() => {
    if (
      employeeData.currentAddress1 &&
      employeeData.permanentAddress1 &&
      employeeData.currentDistrict &&
      employeeData.permanentDistrict &&
      employeeData.permanentPinCode &&
      employeeData.currentPinCode &&
      employeeData.currentState &&
      employeeData.currentCountry &&
      employeeData.permanentCountry &&
      employeeData.permanentState &&
      employeeData.permanentAddressProof &&
      employeeData.currentAddressProof
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });
  const addCountryName = useSelector(
    (state: any) => state.employeeReducer.addEmployeeDbCountryList
  );
  useEffect(() => {
    dispatch(
      actions.employeeDbCountryListAction((res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              setCountryName(res.data);
            }
          }
        }
      })
    );
  }, [addCountryName]);
  const handleDropDownCountrySubmit = (e: any) => {
    e.preventDefault();
    if (isCharactersValid(country.countryName) == false) {
      setErrors({
        ...errors,
        countryName: "Enter valid country name",
      });
    } else {
      setErrors({
        ...errors,
        countryName: "",
      });
      dispatch(
        actions.addEmployeeDbCountryListAction(country, (res: any) => {
          if (res) {
            if (res.error) {
              if (res.error.error_description) {
                showErrorToastMessage(res.error.error_description);
              }
            } else {
              if (res.code === 200) {
                showSuccessToastMessage(addSuccess);
              }
            }
          }
        })
      );
      const inputField: any = document.getElementById("formAddOption");
      if (inputField) {
        inputField.value = "";
      }
    }
  };
  return (
    <div>
      <h6>{address_for_communication}</h6>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {current_address_1}
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={employeeData.currentAddress1}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                currentAddress1: e.target.value,
              })
            }
          />
          <p className="error">{errors.currentAddress1}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {current_address_2}
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.currentAddress2}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                currentAddress2: e.target.value,
              })
            }
          />
          <p className="error">{errors.currentAddress2}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{current_country}</label>
          <span className="required">*</span>

          <Dropdown
            onSelect={(eventKey: any) =>
              setEmployeeData({
                ...employeeData,
                currentCountry: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {employeeData.currentCountry
                ? `${employeeData.currentCountry?.slice(0, 30)}`
                : ""}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Form>
                <Form.Group controlId="formAddOption">
                  <div className="input-group d-flex flex-row justify-content-between align-items-center">
                    <div className="otherInputform">
                      <Form.Control
                        type="text"
                        placeholder="Enter Other"
                        onChange={(e) =>
                          setCountry({
                            ...country,
                            countryName: e.target.value,
                          })
                        }
                        id="formAddOption"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleDropDownCountrySubmit}
                      >
                        <AiOutlineCheck />
                      </button>
                    </div>
                  </div>
                </Form.Group>
              </Form>
              <p className="error">{errors.countryName}</p>
              {countryName?.map((ele: any) => (
                <Dropdown.Item eventKey={ele.countryName}>
                  {ele.countryName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {current_state}
          </label>
          <span className="required">*</span>
          {employeeData.currentCountry === "India" ? (
            <div>
              <Select
                className="dropdown-select"
                placeholder={employeeData.currentState}
                options={currentStateOptions}
                isSearchable={true}
                onChange={handleCurrentStateManager}
              />
            </div>
          ) : (
            <>
              <input
                type="text"
                className="form-control col ml-3"
                value={employeeData.currentState}
                onChange={(e) =>
                  setEmployeeData({
                    ...employeeData,
                    currentState: e.target.value,
                  })
                }
              />
            </>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">{current_district}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={employeeData.currentDistrict}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                currentDistrict: e.target.value,
              })
            }
          />
        </div>

        <div className="col-md-4">
          <label className="form-label">{current_pincode}</label>
          <span className="required">*</span>
          <input
            type="email"
            className="form-control"
            value={employeeData.currentPinCode}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                currentPinCode: e.target.value,
              });
            }}
          />
          <p className="error">{errors.currentPinCode}</p>
        </div>
        <div className="col-md-6">
          <label className="form-label">
            {communicationAddressProof}
            <span className="required">*</span>
          </label>

          <div>
            <input
              onChange={handleSubmitCurrentAddress}
              type="file"
              className="form-control"
              hidden
              id="address-upload"
            />
            <label
              htmlFor="address-upload"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <p className="image-size-condition">
            {fileSize}
            <span className="required">*</span>
          </p>
          <a
            href={employeeData.currentAddressProof?.document_path_url}
            target="_blank"
            className="buttonsize"
          >
            {employeeData.currentAddressProof?.document_path_url
              ? "View File"
              : ""}
          </a>
          <p className="image-size-condition">
            {docFile.currentAddressProof?.name}
          </p>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center mt-3">
        <h6 className="mt-3">{permanent_address}</h6>
        <div className="d-flex flex-row justify-content-start mt-2">
          <input
            type="checkbox"
            id="checkBox"
            className="mx-4"
            checked={employeeData.isSameComunicationAdderess}
            onChange={(e: any) => {
              setChecked(!checked);
              {
                {
                  checked === true
                    ? setEmployeeData({
                        ...employeeData,
                        permanentAddress1: employeeData.currentAddress1,
                        permanentAddress2: employeeData.currentAddress2,
                        permanentDistrict: employeeData.currentDistrict,
                        permanentCountry: employeeData.currentCountry,
                        permanentState: employeeData.currentState,
                        permanentPinCode: employeeData.currentPinCode,
                        permanentAddressProof: employeeData.currentAddressProof,
                        isSameComunicationAdderess: 1,
                      })
                    : setEmployeeData({
                        ...employeeData,
                        permanentAddress1: "",
                        permanentAddress2: "",
                        permanentDistrict: "",
                        permanentCountry: "",
                        permanentState: "",
                        permanentPinCode: "",
                        isSameComunicationAdderess: 0,
                        permanentAddressProof: "",
                      });
                }
              }
            }}
          />
          <label>Same as communication address</label>
        </div>
      </div>
      <div className="row g-3 mt-3">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {permanent_address_1}
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={employeeData.permanentAddress1}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                permanentAddress1: e.target.value,
              })
            }
          />
          <p className="error">{errors.permanentAddress1}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {permanent_address_2}
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.permanentAddress2}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                permanentAddress2: e.target.value,
              })
            }
          />
          <p className="error">{errors.permanentAddress2}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{current_country}</label>
          <span className="required">*</span>
          <Dropdown
            onSelect={(eventKey: any) =>
              setEmployeeData({
                ...employeeData,
                permanentCountry: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {employeeData.permanentCountry
                ? `${employeeData.permanentCountry?.slice(0, 30)}`
                : ""}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Form>
                <Form.Group controlId="formAddOption">
                  <div className="input-group d-flex flex-row justify-content-between align-items-center">
                    <div className="otherInputform">
                      <Form.Control
                        type="text"
                        placeholder="Enter Other"
                        onChange={(e) =>
                          setCountry({
                            ...country,
                            countryName: e.target.value,
                          })
                        }
                        id="formAddOption"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleDropDownCountrySubmit}
                      >
                        <AiOutlineCheck />
                      </button>
                    </div>
                  </div>
                  <p className="error">{errors.countryName}</p>
                </Form.Group>
              </Form>
              {countryName?.map((ele: any) => (
                <Dropdown.Item eventKey={ele.countryName}>
                  {ele.countryName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {current_state}
          </label>
          <span className="required">*</span>
          {employeeData.permanentCountry === "India" ? (
            <div>
              <Select
                className="dropdown-select"
                placeholder={employeeData.permanentState}
                options={permanentStateOptions}
                isSearchable={true}
                onChange={handlePermanentStateManager}
              />
            </div>
          ) : (
            <>
              <input
                type="text"
                className="form-control col ml-3"
                value={employeeData.permanentState}
                onChange={(e) =>
                  setEmployeeData({
                    ...employeeData,
                    permanentState: e.target.value,
                  })
                }
              />
            </>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">{current_district}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={employeeData.permanentDistrict}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                permanentDistrict: e.target.value,
              })
            }
          />
        </div>

        <div className="col-md-4">
          <label className="form-label">{current_pincode}</label>
          <span className="required">*</span>
          <input
            type="email"
            className="form-control"
            value={employeeData.permanentPinCode}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                permanentPinCode: e.target.value,
              });
            }}
          />
          <p className="error">{errors.permanentPinCode}</p>
        </div>
        <div className="col-md-6">
          <label className="form-label">
            {permanenetAddressProof} <span className="required">*</span>
          </label>

          <div>
            <input
              onChange={handleSubmitPermanentAddress}
              type="file"
              className="form-control"
              hidden
              id="actual-btn-5"
            />
            <label
              htmlFor="actual-btn-5"
              className="btn btn-outline-secondary buttonsize"
            >
              {uploadFile}
            </label>
          </div>
          <p className="image-size-condition">
            {fileSize}
            <span className="required">*</span>
          </p>
          <a
            href={employeeData.permanentAddressProof?.document_path_url}
            target="_blank"
            className="buttonsize"
          >
            {employeeData.permanentAddressProof?.document_path_url
              ? "View File"
              : ""}
          </a>
          <p className="image-size-condition">
            {docFile.permanentAddressProof?.name}
          </p>
        </div>
      </div>

      <div className="modal-btn-wrapper mt-4 mb-4">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={handleEditAddress}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAddressDetails;
