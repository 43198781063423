import React, { useEffect, useMemo, useRef, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import Button from "../../../component/Buttons/Button";
import { FaSort } from "react-icons/fa";
import { icons } from "../../../assets/index";
import { Table } from "react-bootstrap";
import * as actions from "../../../Redux/Actions/index";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import AddInseedProgram from "./AddInseedProgram";
import MainCard from "../../../component/MainCard/MainCard";
import { ToastContainer } from "react-toastify";
import DeleteInseedProgram from "./DeleteInseedProgram";
import EditInseedProgram from "./EditInseedProgram";
import useLoadingIndicator from "../../../component/Loader/Loader";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import { CSVLink } from "react-csv";
import TablePagination from "../../../component/TablePagination/TablePagination";
import {
  Strings,
  inseedMasterFilters,
  inseedMasterMakerCheckerTableColumn,
  inseedMasterTableColumn,
} from "../../../constant/Strings";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import "./InseedMaster.scss";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";
import { timeDateFormat } from "../../../component/FormatDate";

const ProgramMaster = () => {
  const authRole = localStorage.getItem("role");
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const isInseedChecker = authRole?.includes("inseed-program-checker");
  const isInseedMaker = authRole?.includes("inseed-program-maker");
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [totalEntriesLoaded, setTotalEntriesLoaded] = useState(false);
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const [inseedDetails, setinseedDetails] = useState({});
  const dispatch = useDispatch();
  const [expansion, setExpansion] = useState(false);
  const [rowid, setRowid] = useState("");
  const [data, setdata] = useState<any>([]);
  const [order, setorder] = useState("ASC");
  const [showExportReport, setShowExportReport] = useState(false);
  const [allRecordsValue, setAllRecordsValue] = useState("");
  const [onclickSearch, setOnclickSearch] = useState(false);

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  const handleFilterByStatus = (value: any) => {
    setOnclickSearch(true);
    if (value == "All Records") {
      setAllRecordsValue(value);
      setFilterValue([]);
      setSearchValue("");
    } else {
      setFilterValue([{ title: "Batch Status", value: "recordStatus" }]);
      setSearchValue(value);
      setAllRecordsValue("");
    }
    setPage(1);
  };
  function handleExpansion(id: any) {
    setRowid(id);
    setExpansion(!expansion);
  }

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const { hamburger_icon, export_report, arrow_drop_down } = icons;
  const {
    inseed_master,
    exportReport,
    filter_by_batch_status,
    no_data_found,
    technology_sub_stream,
    work_location,
    batch_size,
    proposed_ctc,
    training_cost,
    date_of_onBoarding,
    inseed_program_owner,
    required_headCount,
    stipend,
    spoc,
  } = Strings;
  const [isSorted, setisSorted] = useState(false);
  const [sortedData, setsortedData] = useState<any>([]);
  const sorting = (col: any) => {
    setisSorted(true);
    if (order === "ASC") {
      const sorted = [...data].sort((a: any, b: any) => {
        const isReversed = 1;

        return isReversed * a[col].localeCompare(b[col]);
      });
      setsortedData(sorted);
      setorder("DSC");
    } else {
      const sorted = [...data].sort((a: any, b: any) => {
        const isReversed = -1;

        return isReversed * a[col].localeCompare(b[col]);
      });
      setsortedData(sorted);
      setorder("ASC");
    }
  };
  const [showAddInseedProgram, setshowAddInseedProgram] = useState(false);
  const handleAddInseedProgram = () => {
    setshowAddInseedProgram(true);
  };
  const hideAddInseedProgram = () => {
    setshowAddInseedProgram(false);
  };

  const addInseedData = useSelector(
    (state: any) => state.employeeReducer.addInseedData
  );
  const editInseedData = useSelector(
    (state: any) => state.employeeReducer.editInseedData
  );

  const deleteInseedData = useSelector(
    (state: any) => state.employeeReducer.deleteInseedData
  );
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [dropDownData, setDropDownData] = useState("");
  const options: any = [
    { value: "All Records", label: "All Records" },
    { value: "Yet to Start", label: "Yet to Start" },
    { value: "Active/In Progress", label: "Active/In Progress" },
    { value: "On Hold", label: "On Hold" },
    { value: "Completed", label: "Completed" },
    { value: "Abandoned", label: "Abandoned" },
    {
      value: "Merged (with Other INSEED)",
      label: "Merged (with Other INSEED)",
    },
  ];
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);

  useEffect(() => {
    setDropDownData(selectedOptions.map((o: any) => o.value).join(","));
  }, [selectedOptions]);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    unAuthorizeUser();
    if (condition) {
      dispatch(
        actions.getInseedDataAction(
          page,
          pageSize,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                if (res.data) {
                  setdata(res.data);
                  setTotalPages(res.meta_data.totalPageCount);
                  setTotalEntries(res.meta_data.totalEntries);
                  setTotalRecords(res.data.length);
                  setTotalEntriesLoaded(true);
                  setOnclickSearch(false);
                }
              }
            }
          }
        )
      );
    }
  }, [
    dropDownData,
    addInseedData,
    editInseedData,
    deleteInseedData,
    page,
    pageSize,
    filterValue,
    searchValue,
    condition,
  ]);

  const [showEditInseedProgram, setshowEditInseedProgram] = useState(false);
  const hideEditInseedProgram = () => {
    setshowEditInseedProgram(false);
  };

  const handleEditInseedProgram = (ele: any) => {
    setinseedDetails(ele);
    setshowEditInseedProgram(true);
  };

  const [deleteId, setDeleteId] = useState("");
  const [showDeleteInseedProgram, setShowDeleteInseedProgram] = useState(false);
  const handleDeleteProject = (id: any) => {
    setDeleteId(id);
    setShowDeleteInseedProgram(true);
  };
  const hideDeleteInseedProgram = () => {
    setShowDeleteInseedProgram(false);
  };

  const [exportReportData, setExportReportData] = useState([]);
  useEffect(() => {
    if (totalEntriesLoaded) {
      dispatch(
        actions.getInseedDataAction(1, totalEntries,filterKey,searchValue,(res: any) => {
              if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
              } if (res.code === 200) {
                setExportReportData(res.data);
              }
            
          
        })
      );
    }
  }, [totalEntries, editInseedData]);

  const headers = [
    { label: "Inseed Batch", key: "inseedBatch" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" },
    { label: "Client", key: "client" },
    { label: "Technology Stream", key: "technology" },
    { label: "Technology Sub Stream", key: "technologySubStream" },
    { label: "Status", key: "status" },
    { label: "Batch Status", key: "recordStatus" },
    { label: "Batch Size", key: "intakeHC" },
    { label: "Required Head Count", key: "productionHC" },
    { label: "Training Location", key: "location" },
    { label: "Work Location", key: "trainingLocation" },
    { label: "Stipend", key: "stipend" },
    { label: "Proposed CTC", key: "ctc" },
    { label: "Training Cost", key: "trainingCost" },
    { label: "Date of Onboarding", key: "dateOfOnBoarding" },
    { label: "Inseed Program Owner", key: "inseedProgramOwner" },
    { label: "Client SPOC", key: "clientSpoc" },
    { label: "Remarks", key: "remarks2" },
  ];

  let reportdata: any = [];

  exportReportData?.forEach(
    (item: {
      inseedBatch: any;
      startDate: any;
      endDate: any;
      client: any;
      technology: any;
      status: any;
      recordStatus: any;
      intakeHC: any;
      productionHC: any;
      location: any;
      stipend: any;
      ctc: any;
      trainingCost: any;
      trainingLocation: any;
      dateOfOnBoarding: any;
      technologySubStream: any;
      remarks2: any;
      inseedProgramOwner: any;
      spoc: any;
    }) => {
      reportdata.push({
        inseedBatch: item.inseedBatch,
        startDate: timeDateFormat(item.startDate),
        endDate: timeDateFormat(item.endDate),
        client: item.client,
        technology: item.technology,
        status: item.status,
        intakeHC: item.intakeHC,
        productionHC: item.productionHC,
        location: item.location,
        stipend: item.stipend,
        recordStatus: item.recordStatus,
        ctc: item.ctc,
        trainingCost: item.trainingCost,
        trainingLocation: item.trainingLocation,
        dateOfOnBoarding: item.dateOfOnBoarding
          ? timeDateFormat(item.dateOfOnBoarding)
          : "-",
        technologySubStream: item.technologySubStream,
        remarks2: item.remarks2,
        inseedProgramOwner: item.inseedProgramOwner,
        clientSpoc: item.spoc,
      });
    }
  );

  const columns = useMemo(
    () =>
      isInseedMaker || isInseedChecker
        ? inseedMasterTableColumn
        : inseedMasterMakerCheckerTableColumn,
    [isInseedChecker, isInseedMaker]
  );

  const exportReportRef = useRef<HTMLInputElement | null>(null);
  const imgIcon = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        exportReportRef.current &&
        !exportReportRef.current.contains(event.target as Node)
      ) {
        if (
          imgIcon.current &&
          !imgIcon.current.contains(event.target as Node)
        ) {
          setShowExportReport(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showExportReport]);
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{inseed_master}</h2>
          </div>
          {isInseedMaker && (
            <div className="btn-wrapper">
              <Button
                title="+ Add Batch"
                onClick={handleAddInseedProgram}
                className="add-employee-btn"
              />
            </div>
          )}
        </div>

        <div className="inseed-master-table-container mt-3">
          <div className="inseed-master-searchbar-wrapper">
            <div className="searchbar-wrapper">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
              />
            </div>

            <div className="inseed-master-filter-container">
              <select
                id="select-filters"
                value={filterValue}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  const selectedOption = inseedMasterFilters.find(
                    (item: any) => item.value === selectedValue
                  );
                  if (selectedOption) {
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>Filters</option>
                {inseedMasterFilters.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="buttonsize select-batch-wrapper">
              <select
                value={allRecordsValue ? filter_by_batch_status : searchValue}
                onChange={(e: any) => handleFilterByStatus(e.target.value)}
              >
                <option hidden>{filter_by_batch_status}</option>
                {options.map((item: any, index: any) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="export-report-wrapper">
              <img
                src={hamburger_icon}
                onClick={() => setShowExportReport(!showExportReport)}
                alt="icon"
                height={30}
                width={30}
                ref={imgIcon}
              />
              {showExportReport && (
                <div className="report-btn-wrapper" ref={exportReportRef}>
                  <div className="export-btn">
                    <button>
                      <CSVLink
                        data={reportdata}
                        headers={headers}
                        filename={"Batch_report.csv"}
                        className="csvLink"
                      >
                        <img src={export_report} alt="icon" /> {exportReport}
                      </CSVLink>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <FilterValues
            filterValueArray={filterValue}
            onFilterChange={filterChange}
          />

          {data.length > 0 ? (
            <>
              <Table className="table-margin">
                <thead>
                  <tr className="custom-table-head-row">
                    {columns.map((ele: any) => (
                      <React.Fragment key={ele.Header}>
                        <td
                          onClick={() => sorting(ele.accessor)}
                          className="sorting-cursor"
                        >
                          {ele.Header}
                          {ele.Header !== "Action" &&
                          ele.Header !== "" &&
                          ele.Header != "Remark" ? (
                            <span>{<FaSort />}</span>
                          ) : (
                            " "
                          )}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>

                <tbody className="employeedata">
                  {data?.length === 0 ? (
                    <tr>
                      <td colSpan={12}>{no_data_found}</td>
                    </tr>
                  ) : (
                    (isSorted ? sortedData : data).map(
                      (ele: any, index: any) => (
                        <>
                          <tr key={ele?._id}>
                            <td
                              onClick={() => handleExpansion(index)}
                              className="text-start"
                            >
                              <img
                                src={arrow_drop_down}
                                className={
                                  index === rowid
                                    ? expansion
                                      ? "rotate-icon"
                                      : ""
                                    : ""
                                }
                                alt="icon"
                              />
                              <span className="ms-3">{ele?.inseedBatch}</span>
                            </td>
                            <td>{timeDateFormat(ele.startDate)}</td>
                            <td>{timeDateFormat(ele.endDate)}</td>
                            <td>{ele?.client}</td>
                            <td>{ele?.location}</td>
                            <td>{`${ele?.technology?.slice(0, 14)}...`}</td>
                            <td>
                              {
                                <div
                                  className={
                                    ele.status === "New"
                                      ? "new-status"
                                      : ele.status === "Approved"
                                      ? "approved-status"
                                      : ele.status === "Completed"
                                      ? "completed-status"
                                      : ele.status === "Rejected"
                                      ? "rejected-status"
                                      : ele.status === "Updated"
                                      ? "updated-status"
                                      : ""
                                  }
                                >
                                  {ele?.status}
                                </div>
                              }
                            </td>
                            <td>{ele?.recordStatus}</td>
                            {(isInseedChecker || isInseedMaker) && (
                              <td>
                                <div className="action-btn">
                                  {!(
                                    ele.recordStatus == "Completed" &&
                                    ele.status == "Approved"
                                  ) && (
                                    <img
                                      src={icons.edit_blue_icon}
                                      alt="edit_icon"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                      className="edit-icon"
                                      onClick={() =>
                                        handleEditInseedProgram(ele)
                                      }
                                    />
                                  )}
                                  <img
                                    src={icons.delete_icon}
                                    alt="delete-icon"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Delete"
                                    onClick={() => handleDeleteProject(ele._id)}
                                    className="edit-icon"
                                  />
                                </div>
                              </td>
                            )}
                            <td>{`${ele?.remarks2?.slice(0, 14)}...`}</td>
                          </tr>
                          {index === rowid && expansion && (
                            <tr>
                              <td colSpan={12}>
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <div className="invoice_heading">
                                      {technology_sub_stream}
                                    </div>
                                    <div>{ele?.technologySubStream}</div>
                                  </div>
                                  <div>
                                    <div className="invoice_heading">
                                      {batch_size}
                                    </div>
                                    <div>{ele?.intakeHC}</div>
                                  </div>
                                  <div>
                                    <div className="invoice_heading">
                                      {required_headCount}
                                    </div>
                                    <div>{ele?.productionHC}</div>
                                  </div>
                                  <div>
                                    <div className="invoice_heading">
                                      {work_location}
                                    </div>
                                    <div>
                                      {(ele?.trainingLocation).join(", ")}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="invoice_heading">
                                      {stipend}
                                    </div>
                                    <div>{ele?.stipend}</div>
                                  </div>
                                  <div>
                                    <div className="invoice_heading">
                                      {proposed_ctc}
                                    </div>
                                    <div>{ele?.ctc}</div>
                                  </div>
                                  <div>
                                    <div className="invoice_heading">
                                      {training_cost}
                                    </div>
                                    <div>{ele?.trainingCost}</div>
                                  </div>
                                  <div>
                                    <div className="invoice_heading">
                                      {date_of_onBoarding}
                                    </div>
                                    <div>
                                      {ele?.dateOfOnBoarding
                                        ? timeDateFormat(ele?.dateOfOnBoarding)
                                        : "-"}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="invoice_heading">
                                      {inseed_program_owner}
                                    </div>
                                    <div>{ele?.inseedProgramOwner}</div>
                                  </div>
                                  <div>
                                    <div className="invoice_heading">
                                      {spoc}
                                    </div>
                                    <div>{ele?.spoc}</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      )
                    )
                  )}
                </tbody>
              </Table>
            </>
          ) : (
            <p className="no-data">{no_data_found}</p>
          )}
          <div className="show-entries-wrapper">
            <ShowEntries
              pageSize={pageSize}
              setPageSize={setPageSize}
              totalRecords={totalRecords}
              totalEntries={totalEntries}
            />

            <TablePagination
              totalPages={totalPages}
              currentPage={page}
              onClick={handlePageChange}
            />
          </div>
        </div>

        <ModalComponents
          show={showAddInseedProgram}
          onHide={hideAddInseedProgram}
          title={"Add Batch"}
          modalBody={
            <AddInseedProgram
              hideAddInseedProgram={hideAddInseedProgram}
              apiCallCompleted={apiCallCompleted}
            />
          }
          className="modal-lg"
        />

        <ModalComponents
          show={showEditInseedProgram}
          onHide={hideEditInseedProgram}
          title="Edit Batch"
          modalBody={
            <EditInseedProgram
              inseedDetails={inseedDetails}
              hideEditInseedProgram={hideEditInseedProgram}
              apiCallCompleted={apiCallCompleted}
            />
          }
          className="modal-lg"
        />

        <ModalComponents
          show={showDeleteInseedProgram}
          onHide={hideDeleteInseedProgram}
          title="Delete Batch"
          modalBody={
            <DeleteInseedProgram
              id={deleteId}
              hideDeleteInseedProgram={hideDeleteInseedProgram}
            />
          }
          className="modal-lg"
        />
      </MainCard>
      <ToastContainer />
    </>
  );
};

export default ProgramMaster;
