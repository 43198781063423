import { useEffect, useState } from "react";
import MainCard from "../../component/MainCard/MainCard";
import { useParams } from "react-router-dom";
import ModalComponents from "../../component/ModalComponent/ModalComponent";
import ApplyJob from "./ApplyJob";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../Redux/Actions";
import { ToastContainer } from "react-toastify";
import { showErrorToastMessage } from "../../component/Toasts/Toasts";
import useLoadingIndicator from "../../component/Loader/Loader";
import BackButton from "../../component/Buttons/BackButton";
import { Strings } from "../../constant/Strings/index";
import { icons } from "../../assets";
import "../SkillLinks/SkillLinks.scss";

const ViewAppications = () => {
  const {
    loadingComponent,
    showLoadingIndicator,
    hideLoadingIndicator,
    apiCallCompleted,
  } = useLoadingIndicator();
  let { id } = useParams();
  const [info, setInfo] = useState<any>([]);
  const { jobStatus } = info;
  const {
    download_jd,
    major_skills,
    applyForIJP,
    applyNow,
    description,
    experience,
  } = Strings;
  const applicationChangeStatus = useSelector(
    (state: any) => state.employeeReducer.editApplicationStatusDetails
  );
  const { location_on, person_icon, contract_icon, work_icon } = icons;
  const dispatch = useDispatch();

  useEffect(() => {
    showLoadingIndicator();
    dispatch(
      actions.getInternalJobByIdAction(id, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              hideLoadingIndicator();
            }
          }
        }
      })
    );
  }, [applicationChangeStatus]);

  const internalJobDetails = useSelector(
    (state: any) => state.employeeReducer.getInternalJobByIdDetails
  );

  useEffect(() => {
    if (internalJobDetails) {
      if (internalJobDetails.data) {
        setInfo(internalJobDetails.data);
      }
    }
  }, [internalJobDetails, info]);

  const [showApplyJob, setShowApplyJob] = useState(false);
  const handleApplyJob = () => {
    setShowApplyJob(true);
  };
  const hideApplyJob = () => {
    setShowApplyJob(false);
  };

  const handleViewJD = (e: any) => {
    e.preventDefault();
    dispatch(
      actions.viewJobDescriptionDocAction(
        { filename: info.uploadJobDescription?.document_name },
        (res: any) => {
          if (res.code == 200 && res.data) {
            window.open(res.data, "_blank");
          }
        }
      )
    );
  };
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <BackButton navigateTo={"/jobpost"} />
        <div className="heading-button-wrapper">
          <div>
            <h2>{applyForIJP}</h2>
          </div>
          {info?.uploadJobDescription && (
            <div>
              <button
                disabled={jobStatus == 0}
                onClick={(e: any) => handleViewJD(e)}
                className={
                  jobStatus == 1
                    ? "view-jd-btn"
                    : "view-jd-btn disable-outline-btn"
                }
              >
                {download_jd}
              </button>
            </div>
          )}
        </div>
        <div className="card p-3 mt-2">
          <div className="d-flex">
            <h5 className="job-title col-10">{info.jobTitle}</h5>
            <div className="skill-links-btns justify-content-end mx-4">
              <button
                className={
                  jobStatus == 1
                    ? "common-css approved-btn"
                    : jobStatus == 0
                    ? "common-css rejected-btn"
                    : ""
                }
              >
                {jobStatus == 1 ? "Open" : jobStatus == 0 ? "Closed" : ""}
              </button>
            </div>
          </div>
          <div className="sub-title">
            <h5 className="sub-title">Job Id : {info.jobId}</h5>
          </div>
          <div className="row">
            <div className="col-10 mt-2">
              <div className="row sub-title">
                <div className="col-3">
                  <img src={location_on} className="mx-2" alt="icon"></img>
                  {info.location}
                </div>
                <div className="col-2">
                  <img src={work_icon} className="mx-2" alt="icon"></img>
                  {info.modeOfWork}
                </div>
                <div className="col-2">
                  <img src={person_icon} className="mx-2" alt="icon"></img>
                  {info.workLevel}
                </div>
                <div className="col-5">
                  <img src={contract_icon} className="mx-2" alt="icon"></img>
                  {info.jobCategory}
                </div>
              </div>
            </div>
            <div className="col-2">{info.timeSpan}</div>
          </div>
          <div className="d-flex mt-3">
            <div className="sub-title">{major_skills}:</div>
            {info?.majorSkills?.map((value: any, index: any) => (
              <strong key={index} className="ux-list mx-2">
                <li className="ux-li button-gap">{value}</li>
              </strong>
            ))}
          </div>
          <div className="d-flex mt-3">
            <div className="sub-title">{experience}:</div>
            <p className="job-desc mx-2">{info.experience}</p>
          </div>
          <hr className="line-hr"></hr>
          <div className="d-flex mt-3">
            <div className="sub-title">{description}:</div>
            <p className="job-desc mx-2">
              {info.description == ""
                ? "Download JD for complete Job Details."
                : info.description}
            </p>
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <button
              className={
                info.recordStatus == "Approved" && jobStatus == 1
                  ? "view-job-btn view-referral-list-btn"
                  : "view-job-btn view-referral-list-btn disable-button"
              }
              onClick={handleApplyJob}
              disabled={info.recordStatus != "Approved" || jobStatus == 0}
            >
              {applyNow}
            </button>
          </div>
        </div>
      </MainCard>

      <ModalComponents
        show={showApplyJob}
        onHide={hideApplyJob}
        title="Apply Job"
        modalBody={
          <ApplyJob
            jobId={id}
            experience={info.experience}
            hideApplyJob={hideApplyJob}
            apiCallCompleted={apiCallCompleted}
          />
        }
        className="modal-lg"
      />
      <ToastContainer />
    </>
  );
};

export default ViewAppications;
