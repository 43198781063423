import React, { useEffect, useMemo, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import Button from "../../../component/Buttons/Button";
import { FaSort } from "react-icons/fa";
import { icons } from "../../../assets/index";
import { Table } from "react-bootstrap";
import * as actions from "../../../Redux/Actions/index";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import BeACoachModal from "./BeACoachModal";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import ReasonForRejection from "../../../component/MakerChecker/ReasonForRejection";
import ModalWarning from "../../../component/MakerChecker/ModalWarning";
import EditBeACoachModal from "./EditBeACoach";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import {
  beACoachFilter,
  Strings,
  beaCoachCheckerColumns,
  beaCoachAllColumns,
} from "../../../constant/Strings";
import TablePagination from "../../../component/TablePagination/TablePagination";
import UndoPopup from "../CoachingRequest/UndoPopup";
import { useNavigate } from "react-router-dom";
import EditCondition from "../BeACoach/EditCondition";

const BeaCoachTable = (props: any) => {
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const dispatch = useDispatch();
  const [coachId, setCoachId] = useState("");
  const [data, setdata] = useState<any>([]);
  const [order, setorder] = useState("ASC");
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [pagePerSize, setPagePerSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [empName, setEmpName] = useState("");
  const [statusDetails, setStatusDetails] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const [isGreaterThan3Months, setIsGreaterThan3Months] = useState(false);
  const { no_data_found, be_a_coach, filters, inValidModuleAccess } = Strings;
  const { blue_tick_icon, orange_cross } = icons;
  //  pagination
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * pageSize;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [isSorted, setisSorted] = useState(false);
  const [sortedData, setsortedData] = useState<any>([]);
  const [showUndoPopup, setShowUndoPopup] = useState(false);
  const [isCoach, setIsCoach] = useState<any>(false);
  const [isChecker, setIsChecker] = useState<any>(false);
  const [showAddBeACoach, setshowAddBeACoach] = useState(false);
  const [editData, setEditData] = useState("");
  const [showEditBeACoach, setshowEditBeACoach] = useState(false);

  const authRole = localStorage.getItem("role");
  const navigate = useNavigate();
  useEffect(() => {
    authRole?.includes("coaching-and-mentoring-coach")
      ? setIsCoach(true)
      : setIsCoach(false);
    authRole?.includes("coaching-and-mentoring-checker")
      ? setIsChecker(true)
      : setIsChecker(false);

    if (
      authRole?.includes("coaching-and-mentoring-coach") ||
      authRole?.includes("coaching-and-mentoring-checker")
    ) {
      <></>;
    } else {
      showErrorToastMessage(inValidModuleAccess);
      navigate("/");
    }
  }, []);

  const sorting = (col: any) => {
    setisSorted(true);
    if (order === "ASC") {
      const sorted = [...data].sort((a: any, b: any) => {
        if (col == "candidateName") {
          if (a?.Employee[0]?.candidateName < b?.Employee[0]?.candidateName) {
            return 1;
          }
          if (a?.Employee[0]?.candidateName > b?.Employee[0]?.candidateName) {
            return -1;
          }
          return 0;
        } else if (col == "candidateName") {
          if (a?.Employee[0]?.lastname < b?.Employee[0]?.lastname) {
            return 1;
          }
          if (a?.Employee[0]?.lastname > b?.Employee[0]?.lastname) {
            return -1;
          }
          return 0;
        } else if (col == "candidateName") {
          if (a?.Employee[0]?.middleName < b?.Employee[0]?.middleName) {
            return 1;
          }
          if (a?.Employee[0]?.middleName > b?.Employee[0]?.middleName) {
            return -1;
          }
          return 0;
        } else {
          if (a[col] < b[col]) {
            return 1;
          }
          if (a[col] > b[col]) {
            return -1;
          }
          return 0;
        }
      });
      setsortedData(sorted);
      setorder("DSC");
    } else {
      const sorted = [...data].sort((a: any, b: any) => {
        if (a[col] < b[col]) {
          return -1;
        }
        if (a[col] > b[col]) {
          return 1;
        }
        return 0;
      });
      setsortedData(sorted);
      setorder("ASC");
    }
  };

  const handleAddBeACoach = () => {
    setshowAddBeACoach(true);
  };
  const hideAddBeACoach = () => {
    setshowAddBeACoach(false);
  };

  const handleEditBeACoach = (ele: any, e: any) => {
    e.preventDefault();
    setshowEditBeACoach(true);
    setEditData(ele);
  };
  const hideEditBeACoach = () => {
    setshowEditBeACoach(false);
  };

  const beACoachData = useSelector(
    (state: any) => state.employeeReducer.addBeACoachDetails
  );

  const [showReason, setShowReason] = useState(false);
  const [showReasonForRejection, setShowReasonForRejection] = useState(false);
  const undoData = { recordStatus: "New", remarks: "" };
  const handleRejectModal = (id: any, empName: any) => {
    setShowReasonForRejection(true);
    setCoachId(id);
    setEmpName(empName);
    setStatusDetails("Rejected");
  };
  const hideRejectModal = () => {
    setShowReasonForRejection(false);
  };
  const [modalWarning, setModalWarning] = useState("");
  const handleApproval = (text: any, id: any, empName: any) => {
    setCoachId(id);
    setEmpName(empName);
    setModalWarning(text);
    setShowReason(true);
    setStatusDetails("Approved");
  };
  const hideModal = () => {
    setShowReason(false);
  };
  const editBeACoach = useSelector(
    (state: any) => state.employeeReducer.editBeACoachDetails
  );
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    if (condition) {
      dispatch(
        actions.getAllCoachPagAction(
          page,
          pagePerSize,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                setdata(res.data);
                const checkIsRegistered = res.meta_data.isCoachRegistered == 1;
                const checkRegisteredCoach = res.data?.find((ele: any) => {
                  if (ele.employeeRef == loginId) {
                    return true;
                  }
                });
                if (checkIsRegistered || checkRegisteredCoach) {
                  if (checkRegisteredCoach?.rejectedOn) {
                    EditCondition(
                      checkRegisteredCoach?.rejectedOn.split("T")[0],
                      handleSetIsGreaterThan3Months
                    );
                  }
                  setIsRegistered(true);
                } else {
                  setIsRegistered(false);
                }
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setOnclickSearch(false);
              }
            }
          }
        )
      );
    }
  }, [
    beACoachData,
    editBeACoach,
    page,
    pagePerSize,
    searchKey,
    filterValue,
    searchValue,
    condition,
  ]);

  const handleSetIsGreaterThan3Months = (value: any) => {
    setIsGreaterThan3Months(value);
  };

  const getAllCoach = useSelector(
    (state: any) => state.employeeReducer.getAllCoachDetails
  );

  useEffect(() => {
    if (getAllCoach && getAllCoach.data) {
      setdata(getAllCoach.data);
      setTotalRecords(getAllCoach.data?.length);
    }
  }, [getAllCoach, data, pagePerSize]);

  const columns = useMemo(
    () => (isChecker ? beaCoachCheckerColumns : beaCoachAllColumns),
    [isChecker]
  );

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  return (
    <>
      {loadingComponent()}
      <div className="heading-button-wrapper">
        <div>
          <h2>{be_a_coach}</h2>
        </div>
        {isCoach && !isRegistered && (
          <div className="btn-wrapper">
            <Button
              title="+ Be a Coach"
              onClick={handleAddBeACoach}
              className="add-employee-btn"
            />
          </div>
        )}
      </div>

      <div className="be-a-coach-container">
        <div className="be-a-coach-searchbar-wrapper">
          <div className="searchbar-wrapper">
            <SearchBar
              filter={searchValue}
              setFilter={handleSearch}
              onSearchClicked={onSearchClicked}
            />
          </div>

          <div className="be-a-coach-filter-container">
            <select
              id="select-filters"
              value={filterValue}
              onChange={(e: any) => {
                const selectedValue = e.target.value;
                const selectedOption = beACoachFilter.find(
                  (item: any) => item.value === selectedValue
                );
                if (selectedOption) {
                  filterChange(selectedOption);
                }
              }}
            >
              <option hidden>{filters}</option>
              {beACoachFilter.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </div>

        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
        />

        {data?.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="table-margin">
                <thead className="employeedb">
                  <tr className="custom-table-head-row">
                    {columns.map((ele: any) => (
                      <React.Fragment key={ele.Header}>
                        <td
                          className="sorting-cursor"
                          onClick={() => sorting(ele.accessor)}
                        >
                          {ele.Header}
                          {ele.Header !== "Action" &&
                          ele.Header !== "" &&
                          ele.Header != "Remark" ? (
                            <span>{<FaSort />}</span>
                          ) : (
                            " "
                          )}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>

                <tbody className="employeedata">
                  {data?.length === 0 ? (
                    <tr>
                      <td colSpan={12}>{no_data_found}</td>
                    </tr>
                  ) : (
                    (isSorted ? sortedData : data)

                      ?.slice(pageVisited, pageVisited + pagePerSize)
                      .map((ele: any, index: any) => (
                        <>
                          <tr key={ele?._id}>
                            <td>
                              {ele.employeeRef == loginId &&
                              isGreaterThan3Months &&
                              ele?.recordStatus == "Rejected" ? (
                                <a
                                  href=""
                                  onClick={(e: any) =>
                                    handleEditBeACoach(ele, e)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit Details"
                                >
                                  {ele?.Employee[0]?.candidateName +
                                    " " +
                                    ele?.Employee[0]?.middleName +
                                    " " +
                                    ele?.Employee[0]?.lastname}
                                </a>
                              ) : (
                                ele?.Employee[0]?.candidateName +
                                " " +
                                ele?.Employee[0]?.middleName +
                                " " +
                                ele?.Employee[0]?.lastname
                              )}
                            </td>
                            <td>{ele?.yearsOfExperience}</td>
                            <td>{ele?.expertise}</td>
                            <td>{ele?.location}</td>

                            {isChecker && (
                              <>
                                {ele?.recordStatus == "Approved" ||
                                ele?.recordStatus == "Rejected" ? (
                                  <>
                                    <td>-</td>
                                  </>
                                ) : (
                                  <>
                                    <td className="d-flex flex-row justify-content-center">
                                      <button
                                        type="button"
                                        className="blue-tick-btn"
                                        onClick={() =>
                                          handleApproval(
                                            "Approve",
                                            ele?._id,
                                            ele?.Employee[0]?.candidateName +
                                              " " +
                                              ele?.Employee[0]?.middleName +
                                              " " +
                                              ele?.Employee[0]?.lastname
                                          )
                                        }
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Accept"
                                      >
                                        <img src={blue_tick_icon} alt="icon" />
                                      </button>
                                      <button
                                        type="button"
                                        className="orange-cross-btn"
                                        onClick={() =>
                                          handleRejectModal(
                                            ele?._id,
                                            ele?.Employee[0]?.candidateName +
                                              " " +
                                              ele?.Employee[0]?.middleName +
                                              " " +
                                              ele?.Employee[0]?.lastname
                                          )
                                        }
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Reject"
                                      >
                                        <img src={orange_cross} alt="icon" />
                                      </button>
                                    </td>
                                  </>
                                )}
                              </>
                            )}
                            <td>
                              <button
                                type="button"
                                className={`btn employeedb-record-status-btn ${
                                  ele.recordStatus === "Approved"
                                    ? "btn approved-btn"
                                    : ele?.recordStatus === "Rejected"
                                    ? "btn employee-inactive-btn"
                                    : ele?.recordStatus === "Updated"
                                    ? "btn employee-draft-btn"
                                    : ele?.recordStatus === "New"
                                    ? "btn employee-new-btn"
                                    : ""
                                }`}
                              >
                                {ele?.recordStatus}
                              </button>
                            </td>
                          </tr>
                        </>
                      ))
                  )}
                </tbody>
              </Table>
            </div>

            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pagePerSize}
                setPageSize={setPagePerSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />
              <TablePagination
                totalPages={totalPages}
                currentPage={page}
                onClick={handlePageChange}
              />
            </div>
          </>
        ) : (
          <p className="employeedata">{no_data_found}</p>
        )}
      </div>
      {showUndoPopup && (
        <UndoPopup
          apiCallCompleted={apiCallCompleted}
          onHide={() => setShowUndoPopup(false)}
          setShowUndoPopup={setShowUndoPopup}
          requestId={coachId}
          employeeName={empName}
          statusDetails={statusDetails}
          undoActionApi={actions.editBeACoachAction}
          data={undoData}
        />
      )}
      {/* Add coach Modal */}
      <ModalComponents
        show={showAddBeACoach}
        onHide={hideAddBeACoach}
        title={"Be a Coach"}
        modalBody={
          <BeACoachModal
            hideAddBeACoach={hideAddBeACoach}
            apiCallCompleted={apiCallCompleted}
          />
        }
        className="modal-lg"
      />
      {}
      {/* Edit coach Modal */}
      <ModalComponents
        show={showEditBeACoach}
        onHide={hideEditBeACoach}
        title={"Edit Be a Coach"}
        modalBody={
          <EditBeACoachModal
            apiCallCompleted={apiCallCompleted}
            editData={editData}
            hideEditBeACoach={hideEditBeACoach}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showReason}
        onHide={hideModal}
        title=""
        modalBody={
          <ModalWarning
            apiCallCompleted={apiCallCompleted}
            hideModal={hideModal}
            header={modalWarning}
            id={coachId}
            editApi={actions.editBeACoachAction}
            data={{ recordStatus: modalWarning + "d" }}
            hideEditModal={() => {}}
            setShowUndoPopup={setShowUndoPopup}
          />
        }
        className="modal-md"
      />
      <ModalComponents
        show={showReasonForRejection}
        onHide={hideRejectModal}
        title="Reason For Rejection"
        modalBody={
          <ReasonForRejection
            apiCallCompleted={apiCallCompleted}
            hideModal={hideRejectModal}
            id={coachId}
            editApi={actions.editBeACoachAction}
            statusKey="recordStatus"
            hideEditModal={() => {}}
            setShowUndoPopup={setShowUndoPopup}
          />
        }
        className="modal-md"
      />

      <ToastContainer />
    </>
  );
};

export default BeaCoachTable;
