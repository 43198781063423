import React from "react";
import { Table } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import { useState, useEffect } from "react";
import { icons } from "../../../../../assets";
import Button from "../../../../../component/Buttons/Button";
import ModalComponents from "../../../../../component/ModalComponent/ModalComponent";
import AddCareerGoals from "./AddCareerGoals";
import EditCareerGoals from "./EditCareerGoals";
import DeleteCareerGoals from "./DeleteCareerGoals";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./../../../../../Redux/Actions";
import { ToastContainer } from "react-toastify";

function Career() {
  const [info, setInfo] = useState([]);
  const [careerGoals, setCareerGoals] = useState({
    id: "",
    title: "",
    description: "",
  });
  const [deleteId, setDeleteId] = useState("");
  const [showAddCareerGoals, setShowAddCareerGoals] = useState(false);

  const handleAddCareerGoals = () => {
    setShowAddCareerGoals(true);
  };
  const hideAddCareerGoals = () => {
    setShowAddCareerGoals(false);
  };

  const [showEditCareerGoals, setShowEditCareerGoals] = useState(false);
  const handleEditCareerGoals = (id: string, title: any, description: any) => {
    setCareerGoals({
      id: id,
      title: title,
      description: description,
    });
    setShowEditCareerGoals(true);
  };
  const hideEditCareerGoals = () => setShowEditCareerGoals(false);

  const [showDeleteCareerGoals, setShowDeleteCareerGoals] = useState(false);
  const handleDeleteCareerGoals = (id: any) => {
    setDeleteId(id);
    setShowDeleteCareerGoals(true);
  };
  const hideDeleteCareerGoals = () => setShowDeleteCareerGoals(false);

  const addCareerGoals = useSelector(
    (state: any) => state.employeeReducer.addEmployeeCareerGoals
  );
  const editCareerGoals = useSelector(
    (state: any) => state.employeeReducer.editEmployeeCareerGoals
  );
  const deleteCareerGoals = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeeCareerGoals
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getEmployeeCareerGoalsAction());
  }, [addCareerGoals, editCareerGoals, deleteCareerGoals]);

  const employeeCareerGoals = useSelector(
    (state: any) => state.employeeReducer.getEmployeeCareerGoals
  );

  useEffect(() => {
    if (employeeCareerGoals) {
      if (employeeCareerGoals.data) {
        setInfo(employeeCareerGoals.data);
      }
    }
  }, [employeeCareerGoals, info]);

  return (
    <div className="right__container">
      <div className="title">
        <h3>Self Assessments</h3>

        <div className="btn-wrapper">
          <Button
            icon={icons.add_icon}
            title="Add"
            onClick={handleAddCareerGoals}
          />
        </div>
      </div>
      <div className="col">
        <Table hover>
          <thead className="employeedb">
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Certificate</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="employeedata">
            {info.length > 0 &&
              info.map((ele: any) => (
                <tr key={ele.id}>
                  <td>{ele.title}</td>
                  <td>{ele.description}</td>

                  <td>
                    <div className="center">
                      {" "}
                      <BsDownload className="downloadicon" />
                    </div>
                  </td>
                  <td>
                    <div className="action-btn">
                      <img
                        src={icons.edit_icon}
                        alt="edit_icon"
                        onClick={() =>
                          handleEditCareerGoals(
                            ele.id,
                            ele.title,
                            ele.description
                          )
                        }
                        className="edit-icon"
                      />
                      <img
                        src={icons.delete_icon}
                        alt="delete-icon"
                        onClick={() => handleDeleteCareerGoals(ele.id)}
                        className="edit-icon"
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div>
          <ModalComponents
            show={showAddCareerGoals}
            onHide={hideAddCareerGoals}
            title="Self Assessment"
            modalBody={
              <AddCareerGoals
                showAddCareerGoals={handleAddCareerGoals}
                hideAddCareerGoals={hideAddCareerGoals}
              />
            }
            className="modal-lg"
          />

          <ModalComponents
            show={showEditCareerGoals}
            onHide={hideEditCareerGoals}
            title="Self Assessment"
            modalBody={
              <EditCareerGoals
                id={careerGoals.id}
                title={careerGoals.title}
                description={careerGoals.description}
                showEditCareerGoals={showEditCareerGoals}
                hideEditCareerGoals={hideEditCareerGoals}
              />
            }
            className="modal-lg"
          />

          <ModalComponents
            show={showDeleteCareerGoals}
            onHide={hideDeleteCareerGoals}
            title="Delete Self Assessment"
            modalBody={
              <DeleteCareerGoals
                id={deleteId}
                hideDeleteCareerGoals={hideDeleteCareerGoals}
              />
            }
            className="modal-sm"
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Career;
