import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { GlobalStyle } from "./styles/global";
import { store } from "../src/store";
import { Provider } from "react-redux";
import App from "./App";

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <React.StrictMode> */}
        <GlobalStyle />
        <App />
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </Provider>
);
