import React, { useEffect, useState } from "react";
import logo from "../../assets/Logo.png";
import img from "../../assets/Background.png";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "../../component/Login/LoginPage.scss";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions";
// import { employeeLoginAction } from "../../Redux/Actions/Action";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import img2 from "../../assets/showPwdImg.svg";
import img3 from "../../assets/hidePwdImg.svg";

type UserLoginForm = {
  email: string;
  password: string;
};
const LoginForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const employeeLogin = useSelector(
    (state: any) => state.employeeReducer.employeeLogin
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Email is invalid")
      .email()
      .matches(/^(?!.*@[^,]*,)/),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(20, "Password must not exceed 20 characters")

      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Please enter a valid password"
      ),
    confirm: Yup.string().oneOf(
      [Yup.ref("pass"), null],
      'Must match "password" field value'
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserLoginForm>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: UserLoginForm) => {
    const loginDetails = { emailId: data.email };
  };

  useEffect(() => {
    if (employeeLogin && employeeLogin.code == 200) {
      navigate("/");
    }
  }, [employeeLogin]);

  const [pwd, setPwd] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  return (
    <div className="row">
      <div className="col-8">
        <img className="img-fluid image" src={img} alt="icon" />
      </div>
      <div className="col-4 mt-3 mb-3">
        <img className="img-fluid logo" src={logo} alt="icon" />
        <div className="m-2">
          <h5>Welcome!</h5>
          <p>Sign in to continue to spotlight</p>
        </div>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label>Email ID</label>
            <input
              type="email"
              {...register("email")}
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              placeholder="Enter Email ID"
            />
            <p className="error">{errors.email?.message}</p>
          </div>
          <div className="mt-3 pwd-container">
            <div className="password">
              <label>Password</label>
            </div>
            <input
              type={isRevealPwd ? "text" : "password"}
              {...register("password")}
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
              placeholder="Enter password"
              onChange={(e) => setPwd(e.target.value)}
            />
            <img
              title={isRevealPwd ? "Hide password" : "Show password"}
              src={isRevealPwd ? img2 : img3}
              onClick={() => setIsRevealPwd((prevState) => !prevState)}
              alt="icon"
            />
            <p className="error">{errors.password?.message}</p>

            <div className="forgotpwdiv">
              <Link to="/" className="forgotpw">
                Forgot password?
              </Link>
            </div>
          </div>
          <label className="mt-3 form-check-label" style={{ fontSize: "13px" }}>
            <input
              name="remember"
              className="form-check-input"
              type="checkbox"
            />{" "}
            Remember me
          </label>
          <div className="sumbit mt-3 mb-3">
            <button className="Login-Button" type="submit">
              {" "}
              Login{" "}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
