import React, { useEffect, useMemo, useRef, useState } from "react";
import { Strings } from "../../../constant/Strings/index";
import { icons } from "../../../assets/index";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import * as actions from "../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { TiArrowUnsorted } from "react-icons/ti";
import { Link } from "react-router-dom";
import TablePagination from "../../../component/TablePagination/TablePagination";
import "./RedeemSkillCoins.scss";
import * as XLSX from "xlsx";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { timeDateFormat } from "../../../component/FormatDate";
const TransactionHistoryTable = (props: any) => {
  const { loadingComponent, hideLoadingIndicator, showLoadingIndicator } =
    useLoadingIndicator();
  const { hamburger_icon, export_report, backIcon } = icons;
  const {
    totalPages,
    onPage,
    handlePageChange,
    pagePerSize,
    totalEntries,
    setPagePerSize,
  } = props;
  const [showExportReport, setShowExportReport] = useState(false);

  const { report, noData } = Strings;

  const [data, setdata] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const dispatch = useDispatch();

  const COLUMNS: any = [
    {
      Header: "Transaction Date",
      accessor: "transactionDate",
      Cell: (Action: any) => {
        const transactionDate = Action.row.original.transactionDate;
        return timeDateFormat(transactionDate);
      },
    },
    {
      Header: "Particulars",
      accessor: "particulars",
    },
    {
      Header: "Allocated",
      accessor: "skillCoinsAllocated",
    },
    {
      Header: "Deallocated",
      accessor: "skillCoinsDeallocated",
    },
    {
      Header: "Redeemed",
      accessor: "skillCoinsRedeemed",
    },

    {
      Header: "Balance Skill Coins",
      accessor: "balanceCoins",
    },
  ];
  const columns = useMemo(() => COLUMNS, [pagePerSize]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pagePerSize,
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const TransactionHistoryData = useSelector(
    (state: any) => state.employeeReducer.getTransactionHistory
  );

  useEffect(() => {
    if (TransactionHistoryData && TransactionHistoryData.data) {
      setdata(TransactionHistoryData.data);
      setTotalRecords(TransactionHistoryData.data.length);
    }
  }, [TransactionHistoryData, data, pagePerSize, pageSize]);

  const [policy, setPolicy] = useState<any>([]);
  useEffect(() => {
    dispatch(actions.getDownloadPolicyAction("SkillCoin"));
  }, []);

  const downloadPolicyDetails = useSelector(
    (state: any) => state.employeeReducer.getDownloadPolicyData
  );

  useEffect(() => {
    if (downloadPolicyDetails) {
      if (downloadPolicyDetails.data) {
        setPolicy(downloadPolicyDetails.data);
      }
    }
  }, [downloadPolicyDetails, policy]);

  const generateXlFile = () => {
    const login: any = localStorage.getItem("login");
    const employeeId = JSON.parse(login).id;
    showLoadingIndicator();
    dispatch(
      actions.getTransactionHistory(
        "",
        totalEntries,
        employeeId,
        (res: any) => {
          if (res.code === 200) {
            var skillCoinInfoData = [
              [
                "Transaction Date",
                "Particulars",
                "Allocated",
                "Deallocated",
                "Redeemed",
                "Balance Skill Coins",
              ],
            ];
            res?.data.length > 0 &&
              res?.data?.forEach((ele: any) => {
                let emptyArray: string[] = [];

                emptyArray.push(
                  timeDateFormat(ele.transactionDate),
                  ele.particulars,
                  ele.skillCoinsAllocated,
                  ele.skillCoinsDeallocated,
                  ele.skillCoinsRedeemed,
                  ele.balanceCoins
                );
                skillCoinInfoData.push(emptyArray);
              });
            var skillCoinInfo = XLSX.utils.aoa_to_sheet(skillCoinInfoData);

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, skillCoinInfo, "Skill Coins Info");
            hideLoadingIndicator();
            XLSX.writeFile(wb, "Employee SkillCoin Info.xlsx");
          }
        }
      )
    );
  };

  const exportReportRef = useRef<HTMLInputElement | null>(null);
  const imgIcon = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (exportReportRef.current && !exportReportRef.current.contains(event.target as Node) ) {
        if(imgIcon.current && !imgIcon.current.contains(event.target as Node)){
        setShowExportReport(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showExportReport]);
  return (
    <>
      {loadingComponent()}
      <div className="redeem-heading-button-wrapper">
        <Link to={"/skillcoins-redeem"} className="report-pdf-link">
          <img src={backIcon} className="backIcon" alt="backIcon" />
          <span>Back</span>
        </Link>

        <div className="export-report-wrapper">
          <img
            src={hamburger_icon}
            onClick={() => setShowExportReport(!showExportReport)}
            alt="hamburger_icon"
            height={30}
            width= {30}
            ref={imgIcon} 
          />
          {showExportReport && (
            <div className="report-btn-wrapper" ref={exportReportRef}>
              <div className="export-btn">
                <button onClick={generateXlFile}>
                  <img
                    src={export_report}
                    alt="export_report"
                    onClick={generateXlFile}
                  />
                  {report}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {data.length > 0 ? (
        <>
          <div className="table table-responsive">
            <table
              className="table-main table table-sm mt-3"
              {...getTableProps()}
            >
              <thead className="table-head">
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="table-head-row"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(
                      (column: any, columnIndex: number) => (
                        <th
                          className="table-head-row"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          scope="col"
                          colSpan={
                            columnIndex === headerGroup.headers.length - 1
                              ? 2
                              : 1
                          }
                        >
                          {column.render("Header")}

                          <span>
                            {column.id !== "Action" && column.id !== "id" ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TiArrowUnsorted />
                                ) : (
                                  <TiArrowUnsorted />
                                )
                              ) : (
                                <TiArrowUnsorted />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      )
                    )}
                  </tr>
                ))}
              </thead>

              <tbody className="table-body" {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="table-body-row" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pagePerSize}
                setPageSize={setPagePerSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />

              <TablePagination
                totalPages={totalPages}
                currentPage={onPage}
                onClick={handlePageChange}
              />
            </div>
          </div>
        </>
      ) : (
        <p className="projectRepoData mt-4">{noData}</p>
      )}
    </>
  );
};

export default TransactionHistoryTable;
