import { useEffect, useMemo, useState } from "react";
import { TiArrowUnsorted } from "react-icons/ti";
import Button from "../../../component/Buttons/Button";
import { icons } from "../../../assets";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import SearchBar from "../../../component/SearchBar/SearchBar";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import AddAssessment from "./AddAssessment";
import EditAssessment from "./EditAssessment";
import DeleteAssessment from "./DeleteAssessment";
import { Strings, assessmentMasterFilter } from "../../../constant/Strings";
import "../AssessmentMaster/assessmentMaster.scss";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import TablePagination from "../../../component/TablePagination/TablePagination";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import hanldeUnauthorizedUser from "../../../component/AccessConditions/UnauthorizedUser";
import { timeDateFormat } from "../../../component/FormatDate";

const AssessmentTable = (props: any) => {
  const { unAuthorizeUser } = hanldeUnauthorizedUser();
  const authRole = localStorage.getItem("role");
  const isInseedChecker = authRole?.includes("inseed-program-checker");
  const isInseedMaker = authRole?.includes("inseed-program-maker");
  const {
    inseedBatchId,
    setinseedBatchId,
    inseedBatches,
    inseedBatchRecordStatus,
    apiCallCompleted,
    inseedBatchStartDate,
    setInseedBatchStartDate,
  } = props;
  const [totalRecords, setTotalRecords] = useState(0);
  const { assessmentMaster, noData, select_by_batch, filters } = Strings;
  const [selectedBatchOptions, setSelectedBatchOptions] = useState<any>([]);
  const [inseedBatch, setinseedBatch] = useState<any>([]);
  const [info, setInfo] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState({ value: "", ref: "" });
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pagePerSize, setPagePerSize] = useState<any>(10);
  const [recordStatus, setRecordStatus] = useState<any>("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [searchKey, setSearchKey] = useState("");
  const [totalEntries, setTotalEntries] = useState<any>();

  const getInseedData = useSelector(
    (state: any) => state.employeeReducer.getInseedData
  );
  useEffect(() => {
    if (getInseedData && getInseedData.data) {
      setinseedBatch(getInseedData.data);
      setSelectedStatus({
        value: inseedBatch[0]?.inseedBatch,
        ref: inseedBatch[0]?._id,
      });
    }
  }, [getInseedData]);

  const addAssessment = useSelector(
    (state: any) => state.employeeReducer.addInseedAssessmentDetails
  );
  const editAssessment = useSelector(
    (state: any) => state.employeeReducer.editInseedAssessmentDetails
  );
  const deleteAssessment = useSelector(
    (state: any) => state.employeeReducer.deleteInseedAssessmentDetails
  );
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    unAuthorizeUser();
    if (inseedBatchId) {
      if (condition) {
        dispatch(
          actions.getInseedAssessmentByIdAction(
            pagePerSize,
            page,
            filterKey,
            searchValue,
            inseedBatchId,
            (res: any) => {
              if (res) {
                if (res.error) {
                  if (res.error.error_description) {
                    showErrorToastMessage(res.error.error_description);
                  }
                } else {
                  if (res.code === 200) {
                    setTotalPages(res.meta_data.totalPageCount);
                    setTotalEntries(res.meta_data.totalEntries);
                    setOnclickSearch(false);
                  }
                }
              }
            }
          )
        );
      }
    }
  }, [
    selectedStatus,
    addAssessment,
    editAssessment,
    deleteAssessment,
    page,
    searchValue,
    searchKey,
    filterValue,
    pagePerSize,
    inseedBatchId,
    recordStatus,
    condition,
  ]);
  useEffect(() => {
    if (getInseedData && getInseedData.data) {
      setinseedBatch(getInseedData.data);
      setSelectedStatus({
        value: getInseedData.data[0]?.inseedBatch,
        ref: getInseedData.data[0]?._id,
      });
      setSelectedBatchOptions([
        ...selectedBatchOptions,
        {
          label: getInseedData.data[0]?.inseedBatch,
          value:
            getInseedData.data[0]?.inseedBatch +
            "&" +
            getInseedData.data[0]?._id,
        },
      ]);
    }
  }, [getInseedData]);
  const batchOptions: any = [];

  inseedBatch?.forEach((ele: any) => {
    let obj = {
      value: ele.inseedBatch + "&" + ele._id,
      label: ele.inseedBatch,
    };
    batchOptions.push(obj);
  });
  const assessmentData = useSelector(
    (state: any) => state.employeeReducer.getAssessmentByIdDetails
  );
  useEffect(() => {
    if (assessmentData) {
      setInfo(assessmentData.data.assessmentData);
      setTotalRecords(assessmentData.data.assessmentData.length);
    }
  }, [assessmentData, info]);

  const [assessmentDetails, setAssessmentDetails] = useState({
    id: "",
    modulesCovered: "",
    assessmentDate: "",
    assessmentType: "",
    remarks: "",
    assessmentNumber: "",
    recordStatus: "",
  });

  const [showAddAssessment, setShowAddAssessment] = useState(false);
  const handleAddAssessment = () => {
    setShowAddAssessment(true);
  };
  const hideAddAssessment = () => {
    setShowAddAssessment(false);
  };
  const [showEditAssessment, setShowEditAssessment] = useState(false);
  const handleEditAssessment = (
    id: any,
    modulesCovered: any,
    assessmentDate: any,
    assessmentType: any,
    remarks: any,
    assessmentNumber: any,
    recordStatus: any
  ) => {
    setAssessmentDetails({
      id: id,
      modulesCovered: modulesCovered,
      assessmentDate: assessmentDate,
      assessmentType: assessmentType,
      remarks: remarks,
      assessmentNumber: assessmentNumber,
      recordStatus: recordStatus,
    });
    setShowEditAssessment(true);
  };
  const hideEditAssessment = () => {
    setShowEditAssessment(false);
  };
  const [showDeleteAssessment, setShowDeleteAssessment] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteAssessment = (id: any) => {
    setDeleteId(id);
    setShowDeleteAssessment(true);
  };
  const hideDeleteAssessment = () => {
    setShowDeleteAssessment(false);
  };
  const dispatch = useDispatch();

  const ResourceAddingColumns: any = [
    {
      Header: "Assessment Number",
      accessor: "assessmentNumber",
    },
    {
      Header: "Assessment Date",
      accessor: "assessmentDate",
      Cell: (Action: any) => {
        const assessmentDate = Action.row.original.assessmentDate;
        return timeDateFormat(assessmentDate);
      },
    },
    {
      Header: "Assessment Type",
      accessor: "assessmentType",
    },
    {
      Header: "Modules covered",
      accessor: "",
      Cell: (Action: any) => {
        const x = Action.row.original.modulesData;
        return x.map((value: any) => value.moduleName).join(", ");
      },
    },
    {
      Header: "Record Status",
      accessor: "recordStatus",
    },
    {
      Header: "Action",
      accessor: "action",
      disableSortBy: false,
      Cell: (Action: any) => {
        const id = Action.row.original._id;
        const modulesCovered = Action.row.original.modulesData.map(
          (value: any) => value.moduleName + "/" + value._id
        );
        const assessmentDate = Action.row.original.assessmentDate;
        const assessmentType = Action.row.original.assessmentType;
        const remarks = Action.row.original.remarks;
        const assessmentNumber = Action.row.original.assessmentNumber;
        const recordStatus = Action.row.original.recordStatus;
        return (
          <div className="action-btn">
            <img
              data-toggle="tooltip"
              data-placement="bottom"
              title="Edit"
              src={icons.edit_icon}
              alt="edit_icon"
              className="edit-icon"
              onClick={() =>
                handleEditAssessment(
                  id,
                  modulesCovered,
                  assessmentDate,
                  assessmentType,
                  remarks,
                  assessmentNumber,
                  recordStatus
                )
              }
            />
            <img
              data-toggle="tooltip"
              data-placement="bottom"
              title="Delete"
              src={icons.delete_icon}
              alt="delete-icon"
              className="edit-icon"
              onClick={() => handleDeleteAssessment(id)}
            />
          </div>
        );
      },
    },
    {
      Header: "Remarks",
      accessor: "remarks",
    },
  ];

  const ResourceAddingMakerCheckerColumns: any = [
    {
      Header: "Assessment Number",
      accessor: "assessmentNumber",
    },
    {
      Header: "Assessment Date",
      accessor: "assessmentDate",
      Cell: (Action: any) => {
        const assessmentDate = Action.row.original.assessmentDate;
        return timeDateFormat(assessmentDate);
      },
    },
    {
      Header: "Assessment Type",
      accessor: "assessmentType",
    },
    {
      Header: "Modules covered",
      accessor: "",
      Cell: (Action: any) => {
        const x = Action.row.original.modulesData;
        return x.map((value: any) => value.moduleName).join(", ");
      },
    },
    {
      Header: "Record Status",
      accessor: "recordStatus",
    },
    {
      Header: "Remarks",
      accessor: "remarks",
    },
  ];

  const data = info;
  const columns = useMemo(
    () =>
      isInseedMaker || isInseedChecker
        ? ResourceAddingColumns
        : ResourceAddingMakerCheckerColumns,
    [isInseedChecker, isInseedMaker]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setPageSize,
    state: { pageSize },
  } = useTable(
    { columns, data, initialState: { pageSize: 10, pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };

  return (
    <>
      <div className="assessment-heading-button-wrapper">
        <div>
          <h2>{assessmentMaster}</h2>
        </div>
        {isInseedMaker && !(inseedBatchRecordStatus == "Completed") && (
          <div className="btn-wrapper">
            <Button
              title="+ Add Assessment"
              onClick={handleAddAssessment}
              className="add-assessment-btn"
            />
          </div>
        )}
      </div>
      <div className="mt-2">
        <div
          className="mt-3 buttonsize filter-assessment-wrapper"
          style={{ marginLeft: "0px" }}
        >
          <div className="assessment-filter-container mx-2">
            <select
              onChange={(e: any) => {
                const selectedBatch = inseedBatches.find(
                  (obj: any) => obj._id == e.target.value.split("&")[0]
                );
                setinseedBatchId(e.target.value);
                setInseedBatchStartDate(selectedBatch.startDate);
              }}
            >
              {inseedBatches.map((item: any, index: any) => (
                <option
                  key={index}
                  value={
                    item._id +
                    "&" +
                    (item.recordStatus ? item.recordStatus : "")
                  }
                >
                  {item.inseedBatch}
                </option>
              ))}
            </select>
          </div>
          <div className="assessment-searchbar-wrapper mx-2">
            <SearchBar
              filter={searchValue}
              setFilter={handleSearch}
              onSearchClicked={onSearchClicked}
            />
          </div>
          <div className="assessment-filter-container mx-2">
            <select
              id="select-filters"
              value={filterValue}
              onChange={(e: any) => {
                const selectedValue = e.target.value;
                const selectedOption = assessmentMasterFilter.find(
                  (item: any) => item.value === selectedValue
                );
                if (selectedOption) {
                  filterChange(selectedOption);
                }
              }}
            >
              <option hidden>{filters}</option>
              {assessmentMasterFilter.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
        />
        {data.length > 0 ? (
          <>
            <div className="table table-responsive">
              <table
                className="table-main table table-sm mt-3"
                {...getTableProps()}
              >
                <thead className="table-head">
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className="table-head-row"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column: any) => (
                        <th
                          className="table-head-row"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          scope="col"
                        >
                          {column.render("Header")}

                          <span>
                            {column.id !== "Action" && column.id !== "id" ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TiArrowUnsorted />
                                ) : (
                                  <TiArrowUnsorted />
                                )
                              ) : (
                                <TiArrowUnsorted />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody className="table-body" {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr className="table-body-row" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />

              <TablePagination
                totalPages={totalPages}
                currentPage={page}
                onClick={handlePageChange}
              />
            </div>
          </>
        ) : (
          <>
            <p className="employeedata mt-2">{noData}</p>
          </>
        )}
      </div>
      <ModalComponents
        show={showAddAssessment}
        onHide={hideAddAssessment}
        title="Add Assessment Details"
        modalBody={
          <AddAssessment
            selectedStatus={inseedBatchId}
            hideAddAssessment={hideAddAssessment}
            apiCallCompleted={apiCallCompleted}
            inseedBatchStartDate={inseedBatchStartDate}
          />
        }
        className="modal-md"
      />

      <ModalComponents
        show={showEditAssessment}
        onHide={hideEditAssessment}
        title="Edit Assessment Details"
        modalBody={
          <EditAssessment
            assessmentDetails={assessmentDetails}
            hideEditAssessment={hideEditAssessment}
            apiCallCompleted={apiCallCompleted}
            inseedBatchStartDate={inseedBatchStartDate}
          />
        }
        className="modal-md"
      />

      <ModalComponents
        show={showDeleteAssessment}
        onHide={hideDeleteAssessment}
        title="Delete Assessment"
        modalBody={
          <DeleteAssessment
            id={deleteId}
            hideDeleteModal={hideDeleteAssessment}
          />
        }
        className="modal-lg"
      />
    </>
  );
};
export default AssessmentTable;
