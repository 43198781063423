import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showSuccessToastMessage } from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";
import {
  whiteSpaceValid,
  isYearValid,
  isMarks1Valid,
  isMarks2Valid,
  validationAlphaNumeric,
} from "../../../../../constant/Validations";
import { Strings } from "../../../../../constant/Strings";

const EditCertifications = (props: any) => {
  const {
    id,
    nameOfCertification,
    institute,
    board,
    year,
    marks,
    hideEditCertifications,
  } = props;
  const [certificationsData, setCertificationsData] = useState({
    id: id,
    nameOfCertification: nameOfCertification,
    institute: institute,
    board: board,
    year: year,
    marks: marks,
  });

  const [btnDisable, setBtnDisable] = useState(false);

  const [
    shownameOfCertificationErrorToggle,
    setShownameOfCertificationErrorToggle,
  ] = useState(false);
  const [showinstituteErrorToggle, setShowinstituteErrorToggle] =
    useState(false);
  const [showboardErrorToggle, setShowboardErrorToggle] = useState(false);
  const [showyearErrorToggle, setShowyearErrorToggle] = useState(false);
  const [showmarksErrorToggle, setShowmarksErrorToggle] = useState(false);
  const {
    valid_board_name,
    valid_institute,
    valid_year,
    submit,
    cancel,
    valid_certificate,
  } = Strings;
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      certificationsData.nameOfCertification &&
      certificationsData.institute &&
      certificationsData.board &&
      certificationsData.year &&
      certificationsData.marks
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [certificationsData]);
  const handleEdit = () => {
    if (
      whiteSpaceValid(certificationsData.nameOfCertification) &&
      validationAlphaNumeric(certificationsData.nameOfCertification)
    ) {
      setShownameOfCertificationErrorToggle(false);
    } else {
      setShownameOfCertificationErrorToggle(true);
    }
    if (
      whiteSpaceValid(certificationsData.institute) &&
      validationAlphaNumeric(certificationsData.institute)
    ) {
      setShowinstituteErrorToggle(false);
    } else {
      setShowinstituteErrorToggle(true);
    }
    if (
      whiteSpaceValid(certificationsData.board) &&
      validationAlphaNumeric(certificationsData.board)
    ) {
      setShowboardErrorToggle(false);
    } else {
      setShowboardErrorToggle(true);
    }
    if (
      certificationsData.year.toString().length == 4 &&
      isYearValid(certificationsData.year)
    ) {
      setShowyearErrorToggle(false);
    } else {
      setShowyearErrorToggle(true);
    }
    if (
      isMarks1Valid(certificationsData.marks) ||
      isMarks2Valid(certificationsData.marks)
    ) {
      setShowmarksErrorToggle(false);
    } else {
      setShowmarksErrorToggle(true);
    }

    if (
      whiteSpaceValid(certificationsData.nameOfCertification) &&
      validationAlphaNumeric(certificationsData.nameOfCertification) &&
      whiteSpaceValid(certificationsData.institute) &&
      validationAlphaNumeric(certificationsData.institute) &&
      whiteSpaceValid(certificationsData.board) &&
      validationAlphaNumeric(certificationsData.board) &&
      isYearValid(certificationsData.year) &&
      (isMarks2Valid(certificationsData.marks) ||
        isMarks1Valid(certificationsData.marks))
    ) {
      dispatch(
        actions.editEmployeeCertificationsAction(id, certificationsData)
      );
      showSuccessToastMessage("Edited Successfully!");
      hideEditCertifications();
    }
  };

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            Certification Name
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={certificationsData.nameOfCertification}
            onChange={(e) =>
              setCertificationsData({
                ...certificationsData,
                nameOfCertification: e.target.value,
              })
            }
          />
          <p className="error">
            {certificationsData.nameOfCertification.length !== 0 &&
              (shownameOfCertificationErrorToggle ? valid_certificate : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">Institute Name/Certifying Body</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={certificationsData.institute}
            onChange={(e) =>
              setCertificationsData({
                ...certificationsData,
                institute: e.target.value,
              })
            }
          />
          <p className="error">
            {certificationsData.institute.length !== 0 &&
              (showinstituteErrorToggle ? valid_institute : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">Board/Univ/Affiliation</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            value={certificationsData.board}
            onChange={(e) =>
              setCertificationsData({
                ...certificationsData,
                board: e.target.value,
              })
            }
          />
          <p className="error">
            {certificationsData.board.length !== 0 &&
              (showboardErrorToggle ? valid_board_name : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">Year Of Passing</label>
          <span className="required">*</span>
          <input
            type="text"
            maxLength={4}
            className="form-control"
            value={certificationsData.year}
            onChange={(e) =>
              setCertificationsData({
                ...certificationsData,
                year: e.target.value,
              })
            }
          />
          <p className="error">
            {certificationsData.year.length !== 0 &&
              (showyearErrorToggle ? valid_year : "")}
          </p>
        </div>

        <div className="col-md-4">
          <label className="form-label">Percentage Of Marks</label>
          <span className="required">*</span>
          <input
            type="text"
            maxLength={5}
            className="form-control"
            value={certificationsData.marks}
            onChange={(e) =>
              setCertificationsData({
                ...certificationsData,
                marks: e.target.value,
              })
            }
          />
          <p className="error">
            {certificationsData.marks.length !== 0 &&
              (showmarksErrorToggle ? valid_year : "")}
          </p>
        </div>
      </div>
      <div className="modal-btn-wrapper mt-5">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideEditCertifications}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCertifications;
