import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../component/Toasts/Toasts";
import {
  Strings,
  applyJobValidation,
  applyJobLabels,
  requiredFeild,
} from "../../constant/Strings";
import {
  currentCtcValidation,
  preferredLocationValidation,
} from "../../constant/Validations";

const ApplyJob = (props: any) => {
  const { hideApplyJob, jobId, experience, apiCallCompleted } = props;
  const { applySuccess, cancel, submit, uploadResume, upload, imageSize2mb } =
    Strings;
  const experienceText = experience.split("-");
  const minExperience = parseInt(experienceText[0], 10);
  const { preferredLocValid, currentCtcValid } = applyJobValidation;
  const {
    preferredLocation,
    currentCTC,
    interested,
    careerAspiration,
    updatedCredentials,
    ijpCondition,
    relevantExperience,
    totalExp,
  } = applyJobLabels;
  const { required } = requiredFeild;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;
  const [docFile, setDocFile] = useState<any>({
    uploadResume: null,
  });
  const [btnDisable, setBtnDisable] = useState(false);
  const [applyJob, setApplyJob] = useState<any>({
    preferredLocation: "",
    relevantExperience: "",
    totalExperience: "",
    currentCtc: "",
    interested: "",
    aspiration: "",
    acceptance1: "",
    acceptance2: "",
    resume: "",
    jobRef: jobId,
    employeeRef: loginId,
  });
  const handleResumeSubmit = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      ![
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/doc",
        "application/ms-doc",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are DOC or PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, uploadResume: e.target.files[0] });
      dispatch(
        actions.resumeUploadAction({ file: e.target.files[0] }, (res: any) => {
          if (res.code == 200 && res.data) {
            setApplyJob({
              ...applyJob,
              resume: res.data,
            });
          }
        })
      );
    }
  };
  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (preferredLocationValidation(applyJob.preferredLocation) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        preferredLocation: preferredLocValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        preferredLocation: "",
      }));
    }
    if (currentCtcValidation(applyJob.currentCtc) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        currentCtc: currentCtcValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        currentCtc: "",
      }));
    }
    if (applyJob.totalExperience < minExperience) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        totalExperience: `Experience should be greater than ${minExperience} Years`,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        totalExperience: "",
      }));
    }
  };
  const handleApplyJobsSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
      hideApplyJob();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.applyInternalJobsAction(applyJob, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            }
          } else {
            if (res.code === 200) {
              showSuccessToastMessage(applySuccess);
              apiCallCompleted(true);
            }
          }
        }
      })
    );
  };

  const [errors, setErrors] = useState({
    preferredLocation: "",
    currentCtc: "",
    interested: "",
    aspiration: "",
    acceptance1: "",
    acceptance2: "",
    totalExperience: "",
  });

  useEffect(() => {
    if (
      applyJob.preferredLocation &&
      applyJob.currentCtc &&
      applyJob.totalExperience &&
      applyJob.acceptance1 &&
      applyJob.acceptance2 &&
      applyJob.resume
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [applyJob]);

  const [checked1, setChecked1] = useState(true);
  const [checked2, setChecked2] = useState(true);

  const handleUploadProfileView = (e: any) => {
    e.preventDefault();
    dispatch(
      actions.viewUploadProfileResumeAction(
        { filename: applyJob.resume?.document_name },
        (res: any) => {
          if (res.code == 200 && res.data) {
            window.open(res.data, "_blank");
          }
        }
      )
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <label className="form-label">{preferredLocation}</label>
          <span className="required">{required}</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setApplyJob({
                ...applyJob,
                preferredLocation: e.target.value,
              })
            }
          />
          <p className="error">{errors.preferredLocation}</p>
        </div>
        <div className="col-md-5">
          <label className="form-label">{currentCTC}</label>
          <span className="required">{required}</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setApplyJob({
                ...applyJob,
                currentCtc: e.target.value,
              })
            }
          />
          <p className="error">{errors.currentCtc}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{relevantExperience}</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setApplyJob({
                ...applyJob,
                relevantExperience: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-3 mt-3">
          <label className="form-label">{interested}</label>
          <textarea
            className="form-control"
            style={{ height: "5rem" }}
            onChange={(e) =>
              setApplyJob({
                ...applyJob,
                interested: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-5 mt-3">
          <label className="form-label">{careerAspiration}</label>
          <textarea
            className="form-control"
            style={{ height: "5rem" }}
            onChange={(e) =>
              setApplyJob({
                ...applyJob,
                aspiration: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4 mt-3">
          <label className="form-label">{totalExp}</label>
          <span className="required">{required}</span>
          <input
            type="number"
            className="form-control"
            onChange={(e) =>
              setApplyJob({
                ...applyJob,
                totalExperience: e.target.value,
              })
            }
          />
          <p className="error">{errors.totalExperience}</p>
        </div>
        <div className="col-md-6 mt-3">
          <div className="col-md-12">
            <label className="form-label">{uploadResume}</label>
            <span className="required">*</span>
            <div>
              <input
                type="file"
                className="form-control"
                onChange={handleResumeSubmit}
                hidden
                id="actual-btn-uploadJob"
              />
              <label
                htmlFor="actual-btn-uploadJob"
                className="upload-jd-button upload-width"
              >
                {upload}
              </label>
            </div>
            <p className="image-size-condition">
              {imageSize2mb}
              <span className="required">*</span>
            </p>
            <a
              href=""
              onClick={handleUploadProfileView}
              target="_blank"
              className="buttonsize"
            >
              {applyJob.resume?.document_name ? "View File" : ""}
            </a>
            <p className="image-size-condition">{docFile.uploadResume?.name}</p>
          </div>
        </div>
        <div className="form-check mt-3">
          <input
            className="form-check-input"
            type="checkbox"
            value="option1"
            id="flexCheckDefault"
            onChange={() => {
              setChecked1(!checked1);
              {
                checked1 === true
                  ? setApplyJob({
                      ...applyJob,
                      acceptance1: true,
                    })
                  : setApplyJob({
                      ...applyJob,
                      acceptance1: false,
                    });
              }
            }}
            required
          />
          <label className="form-label" htmlFor="flexCheckDefault">
            {updatedCredentials}
          </label>
          <span className="required">{required}</span>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value="option2"
            id="flexCheckChecked"
            onChange={() => {
              setChecked2(!checked2);
              {
                checked2 === true
                  ? setApplyJob({
                      ...applyJob,
                      acceptance2: true,
                    })
                  : setApplyJob({
                      ...applyJob,
                      acceptance2: false,
                    });
              }
            }}
            required
          />
          <label className="form-label" htmlFor="flexCheckChecked">
            {ijpCondition}
          </label>
          <span className="required">{required}</span>
        </div>
      </div>

      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button className="btn cancel-btn buttonsize" onClick={hideApplyJob}>
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleApplyJobsSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApplyJob;
