import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import LearningDevManagerTable from "./LearningDevFinanceTable";
import MainCard from "../../../component/MainCard/MainCard";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { useNavigate } from "react-router-dom";
import { Strings } from "../../../constant/Strings";

const FinanceLearningDevelopment = () => {
  const [financeCheckerList, setFinanceCheckerList] = useState(false);

  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const checkFinanceList = role?.includes("learninganddevelopment-finance");
  const { inValidModuleAccess } = Strings;

  useEffect(() => {
    if (checkFinanceList) {
      setFinanceCheckerList(true);
    } else {
      setFinanceCheckerList(false);
    }

    if (checkFinanceList == undefined || !checkFinanceList) {
      showErrorToastMessage(inValidModuleAccess);
      navigate("/profile");
    }
  }, [checkFinanceList]);

  return (
    <>
      <MainCard>
        <>{financeCheckerList && <LearningDevManagerTable />}</>
      </MainCard>
      <ToastContainer />
    </>
  );
};

export default FinanceLearningDevelopment;
