import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../Redux/Actions";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import { useParams } from "react-router-dom";
import Select from "react-select";
import "../../../../App.scss";
import {
  roleDescriptionValidation,
  utilizationValidation,
  poAmountValidation,
  poNumberValidation,
} from "../../../../constant/Validations";
import {
  Strings,
  billingRateDropdown,
  poValueInDropdown,
  projectDetailsLabels,
} from "../../../../constant/Strings";

function AddResource(props: any) {
  const { hideAddResource, apiCallCompleted } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  let { id } = useParams();
  const [resourceData, setResourceData] = useState({
    employeeRef: "",
    projectRef: id,
    managerRef: "",
    resourceName: "",
    projectName: "",
    reportingManager: "",
    roleDescription: "",
    utilization: "",
    remarks: "",
    noOfDays: "",
    startDate: "",
    endDate: "",
    poStartDate: "",
    poEndDate: "",
    poNumber: "",
    currencyType: "",
    billingRate: "",
    poAmount: "",
  });
  const [employeeInfo, setEmployeeInfo] = useState([]);
  const [managerInfo, setManagerInfo] = useState([]);
  const [managerData, setManagerData] = useState("");
  const [errors, setErrors] = useState({
    roleDescription: "",
    utilization: "",
    poNumber: "",
    poAmount: "",
  });

  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (roleDescriptionValidation(resourceData.roleDescription) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        roleDescription: "Please enter valid role description",
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        roleDescription: "",
      }));
    }
    if (resourceData.utilization) {
      if (utilizationValidation(resourceData.utilization) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          utilization: "Please enter valid utilization",
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          utilization: "",
        }));
      }
    }
    // if (resourceData.poAmount) {
    //   if (poAmountValidation(resourceData.poAmount) == false) {
    //     valid = false;
    //     setErrors((errorObj) => ({
    //       ...errorObj,
    //       poAmount: "Please enter valid role poAmount",
    //     }));
    //     setBtnDisable(false);
    //   } else {
    //     setErrors((errorObj) => ({
    //       ...errorObj,
    //       poAmount: "",
    //     }));
    //   }
    // }
    if (resourceData.poNumber) {
      if (poNumberValidation(resourceData.poNumber) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          poNumber: "Please enter valid poNumber",
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({
          ...errorObj,
          poNumber: "",
        }));
      }
    }
  };
  const handleResourceSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
      hideAddResource();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    const userLogin: any = localStorage.getItem("login");
    const loginId = JSON.parse(userLogin)?.id;
    const payload ={
      ...resourceData,
      createdBy:loginId
    }
    dispatch(
      actions.addResourceAllocationAction(payload, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            }
          } else {
            if (res.code === 200) {
              showSuccessToastMessage("Added successfully");
              apiCallCompleted(true);
            }
          }
        }
      })
    );
  };
  const {
    start_date,
    estimated_end_date,
    resource_name,
    reporting_manager,
    role_desc,
    utilization,
    cancel,
    submit,
  } = Strings;
  const {
    purchaseOrderNoLabel,
    poStartDateLabel,
    poEndDateLabel,
    currencyTypeLabel,
    billingRateLabel,
    billingStatusLabel,
    remarksLabel,
  } = projectDetailsLabels;

  const employeeData = useSelector(
    (state: any) => state.employeeReducer.getEmployeeData
  );

  useEffect(() => {
    if (employeeData && employeeData.data) {
      setEmployeeInfo(employeeData.data);
    }
  }, [employeeData, employeeInfo, managerInfo]);

  useEffect(() => {
   
    if (
      resourceData.employeeRef &&
      resourceData.resourceName &&
      resourceData.roleDescription &&
      resourceData.startDate &&
      managerData
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });
  const options: any = employeeInfo.map((ele: any) => ({
    value:
      ele.candidateName +
      " " +
      ele.middleName +
      " " +
      ele.lastname +
      "-" +
      ele.employeeId +
      "/" +
      ele._id,
    label:
      ele.candidateName +
      " " +
      ele.middleName +
      " " +
      ele.lastname +
      "-" +
      ele.employeeId,
  }));
  const handleChange = (selected: any) => {
    const filteredEmployeeInfo: any = employeeInfo?.filter((ele: any) => {
      return ele?._id == selected.value.split("/")[1];
    });
    setManagerData(
      filteredEmployeeInfo?.[0]?.managerData?.length ?  filteredEmployeeInfo[0]?.managerData[0]?.candidateName +
        " " +
        filteredEmployeeInfo[0]?.managerData[0]?.middleName +
        " " +
        filteredEmployeeInfo[0]?.managerData[0]?.lastname :"-"
    );
    setResourceData({
      ...resourceData,
      employeeRef: selected.value.split("/")[1],
      resourceName: selected.value.split("/")[0]
    });
  };
 

  return (
    <div className="row g-3">
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {resource_name}
        </label>
        <span className="required">*</span>
        <Select
          className="dropdown-select"
          options={options}
          isSearchable={true}
          onChange={handleChange}
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {start_date}
        </label>
        <span className="required">*</span>
        <input
          max={moment(resourceData.endDate)
            .subtract(1, "day")
            .format("YYYY-MM-DD")}
          type="date"
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              startDate: e.target.value,
            })
          }
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {estimated_end_date}
        </label>
        <input
          min={moment(resourceData.startDate)
            .add(1, "day")
            .format("YYYY-MM-DD")}
          type="date"
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              endDate: e.target.value,
            })
          }
        />
      </div>
      <div className="col-md-4 mt-3">
        <label htmlFor="inputName4" className="form-label">
          {reporting_manager}
        </label>
        <span className="required">*</span>
        <div className="height-one-rem">
          {/* <Select
            className="dropdown-select"
            options={managerOptions}
            isSearchable={true}
            onChange={handleReportingManager}
          /> */}
          <input
            type="text"
            className="form-control"
            disabled={true}
            value={managerData}
          />
        </div>
      </div>
      <div className="col-md-4  mt-3">
        <label htmlFor="inputName4" className="form-label">
          {role_desc}
        </label>
        <span className="required">*</span>
        <input
          type="text"
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              roleDescription: e.target.value,
            })
          }
        />
        <p className="error">{errors.roleDescription}</p>
      </div>
      <div className="col-md-4  mt-3">
        <label htmlFor="inputName4" className="form-label">
          {utilization}
        </label>

        <input
          type="text"
          className="form-control"
          value={resourceData.utilization}
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              utilization: e.target.value.replace(/\D/g, ""),
            })
          }
        />
        <p className="error">{errors.utilization}</p>
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {purchaseOrderNoLabel}
        </label>

        <input
          type="text"
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              poNumber: e.target.value,
            })
          }
        />
        <p className="error">{errors.poNumber}</p>
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {poStartDateLabel}
        </label>

        <input
          type="date"
          max={moment(resourceData.poEndDate)
            .subtract(1, "day")
            .format("YYYY-MM-DD")}
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              poStartDate: e.target.value,
            })
          }
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {poEndDateLabel}
        </label>

        <input
          type="date"
          min={moment(resourceData.poStartDate)
            .add(1, "day")
            .format("YYYY-MM-DD")}
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              poEndDate: e.target.value,
            })
          }
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {currencyTypeLabel}
        </label>
        <Dropdown
          onSelect={(eventKey: any) =>
            setResourceData({
              ...resourceData,
              currencyType: eventKey,
            })
          }
        >
          <Dropdown.Toggle variant="light" className="form-control">
            {resourceData.currencyType}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {poValueInDropdown.map((value: any) => (
              <Dropdown.Item eventKey={value}>{value}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {billingStatusLabel}
        </label>

        <Dropdown
          onSelect={(eventKey: any) =>
            setResourceData({
              ...resourceData,
              billingRate: eventKey,
            })
          }
        >
          <Dropdown.Toggle variant="light" className="form-control">
            {resourceData.billingRate}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {billingRateDropdown.map((value: any) => (
              <Dropdown.Item eventKey={value}>{value}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="col-md-4">
        <label htmlFor="inputName4" className="form-label">
          {billingRateLabel}
        </label>
        <input
          type="text"
          className="form-control"
          value={resourceData.poAmount}
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              poAmount: e.target.value,
            })
          }
        />
        <p className="error">{errors.poAmount}</p>
      </div>
      <div className="col-md-4  mt-3">
        <label htmlFor="inputName4" className="form-label">
          {remarksLabel}
        </label>
        <input
          type="text"
          className="form-control"
          onChange={(e) =>
            setResourceData({
              ...resourceData,
              remarks: e.target.value,
            })
          }
        />
      </div>
      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideAddResource}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleResourceSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddResource;
