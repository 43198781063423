import React, { useEffect, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions/index";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import Button from "../../../component/Buttons/Button";

import SendEmail from "./SendEmail";
import Select from "react-select";
import {
  Strings,
  addEmployeeLabels,
  addEmployeeValidations,
} from "../../../constant/Strings";
import {
  employeeDesignationValidtion,
  employeeMobileNumberValidtion,
  employeeEmailIdValidation,
  employeeEmployeeIdValidtion,
  employeeLastNameValidtion,
  employeeCandidateNameValidtion,
} from "../../../constant/Validations";
import { timeDateFormat } from "../../../component/FormatDate";

const EditEmployee = (props: any) => {
  const userLogin: any = localStorage.getItem("login");
  const role = localStorage.getItem("role");
  const employeeHrManager = role?.includes("employee-db-hrmanager");
  const employeeDbChecker =  role?.includes("employee-db-checker");
  const loginId = JSON.parse(userLogin).id;
  const {
    id,
    title,
    candidateName,
    middleName,
    lastname,
    email,
    mobileNumber,
    designation,
    dateOfJoining,
    dateOfBirth,
    employeeId,
    recordStatus,
    hideEditModal,
    eId,
    reportingManagerName,
    statusData,
    lastWorkingDate,
    totalEntries,
    managerRef,
    reason,
    reasonForResignation,
    apiCallCompleted,
    resentDate,
  } = props;
  const [employeeData, setEmployeeData] = useState({
    id: id,
    title: title,
    candidateName: candidateName,
    middleName: middleName,
    lastname: lastname,
    emailId: email,
    mobileNumber: mobileNumber,
    designation: designation,
    dateOfJoining: dateOfJoining,
    dateOfBirth: dateOfBirth,
    employeeId: employeeId,
    eId: eId,
    updatedBy: loginId,
    managerRef: managerRef,
    reportingManagerName: reportingManagerName,
  });

  const [errors, setErrors] = useState({
    candidateName: "",
    middleName: "",
    lastname: "",
    emailId: "",
    mobileNumber: "",
    employeeId: "",
    designation: "",
  });
  const [btnDisable, setBtnDisable] = useState(false);
  const [nextScreen, setnextScreen] = useState(true);
  const {
    designationValid,
    mobileNumberValid,
    emailIdValid,
    employeeIdValid,
    lastnameValid,
    candidateNameValid,
  } = addEmployeeValidations;
  const {
    editSuccess,
    cancel,
    submit,
    skillmineMailid,
    first_name,
    middle_name,
    last_name,
    date_of_birth,
    e_code,
    date_of_joining,
    reporting_manager_name,
    official_email,
    mobile_number,
    employee_title,
    employee_designation,
    record_status,
    last_working_date,
    status,
    Reason,
    ReasonForResignation,
    active_label,
    inactive_label,
    new_label,
    absconded_label,
    resigned_label,
  } = Strings;
  const [employeeInfo, setEmployeeInfo] = useState([]);
  const [statusDetails, setStatusDetails] = useState({
    status: statusData,
    recordStatus: recordStatus,
    lastWorkingDate: lastWorkingDate,
    eId: eId,
    updatedBy: loginId,
    reason: reason,
    reasonForResignation: reasonForResignation,
    resentDate: resentDate,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      employeeData.candidateName &&
      employeeData.lastname &&
      employeeData.dateOfBirth &&
      employeeData.employeeId &&
      employeeData.emailId &&
      employeeData.mobileNumber &&
      employeeData.designation &&
      employeeData.dateOfJoining &&
      employeeData.title &&
      employeeData.managerRef &&
      employeeData.reportingManagerName &&
      ((statusDetails.status === "Active" &&
        statusDetails.status &&
        statusDetails.recordStatus) ||
        (statusDetails.status === "Inactive" &&
          statusDetails.recordStatus &&
          statusDetails.lastWorkingDate.length !== 0) ||
        (statusDetails.status === "New" && statusDetails.recordStatus))
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [employeeData, statusDetails]);
  let valid: boolean = true;
  const validate = () => {
    if (employeeDesignationValidtion(employeeData.designation) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        designation: designationValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, designation: "" }));
    }
    if (employeeMobileNumberValidtion(employeeData.mobileNumber) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        mobileNumber: mobileNumberValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, mobileNumber: "" }));
    }
    if (
      employeeEmailIdValidation(employeeData.emailId) &&
      employeeData.emailId.includes(skillmineMailid)
    ) {
      setErrors((errorObj) => ({ ...errorObj, emailId: "" }));
    } else {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        emailId: emailIdValid,
      }));
      setBtnDisable(false);
    }

    if (
      employeeData.employeeId.length < 5 ||
      employeeData.employeeId.length > 9 ||
      employeeEmployeeIdValidtion(employeeData.employeeId) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        employeeId: employeeIdValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, employeeId: "" }));
    }
    if (employeeLastNameValidtion(employeeData.lastname) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        lastname: lastnameValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, lastname: "" }));
    }
    if (employeeData.middleName) {
      if (employeeCandidateNameValidtion(employeeData.middleName) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          middleName: candidateNameValid,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({ ...errorObj, middleName: "" }));
      }
    }
    if (employeeCandidateNameValidtion(employeeData.candidateName) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        candidateName: candidateNameValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, candidateName: "" }));
    }
  };
  const handleDispatch = () => {
    dispatch(actions.updateSelectedIdAction(id));
    apiCallCompleted(false);
    dispatch(
      actions.editEmployeeDataAction(employeeData, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          apiCallCompleted(true);
        } else {
          if (res?.code === 200) {
            showSuccessToastMessage(editSuccess);
            apiCallCompleted(true);
          }
        }
      })
    );
    dispatch(actions.editEmployeeDataAction(statusDetails, (res: any) => {}));
    hideEditModal();
  };
  const handleEdit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  const employeeManagerData = useSelector(
    (state: any) => state.employeeReducer.getEmployeeData
  );
  useEffect(() => {
    if (employeeManagerData && employeeManagerData.data) {
      setEmployeeInfo(employeeManagerData.data);
    }
  }, [employeeManagerData, employeeInfo]);
  const managerOptions: any = employeeInfo.map((ele: any) => ({
    value: ele._id,
    label: ele.middleName
      ? ele.candidateName +
        " " +
        ele.middleName +
        " " +
        ele.lastname +
        "-" +
        ele.employeeId
      : ele.candidateName + " " + ele.lastname + "-" + ele.employeeId,
  }));
  const handleReportingManager = (selected: any) => {
    setEmployeeData({
      ...employeeData,
      managerRef: selected.value,
      reportingManagerName: selected.label,
    });
  };


  const [setDropdown, setShowDropdown] = useState(false);

  const disable = statusData == "Active" || statusData == "Inactive";

  return (
    <>
      {nextScreen ? (
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <label className="form-label">
                {employee_title}
                <span className="required">*</span>
              </label>
              <Dropdown
                onMouseLeave={() => setShowDropdown(false)}
                onSelect={(eventKey: any) =>
                  setEmployeeData({
                    ...employeeData,
                    title: eventKey,
                  })
                }
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {employeeData.title}
                </Dropdown.Toggle>
                <Dropdown.Menu show={setDropdown}>
                  <Dropdown.Item eventKey="Dr.">Dr.</Dropdown.Item>
                  <Dropdown.Item eventKey="Miss">Miss</Dropdown.Item>
                  <Dropdown.Item eventKey="Mr.">Mr.</Dropdown.Item>
                  <Dropdown.Item eventKey="Ms.">Ms.</Dropdown.Item>
                  <Dropdown.Item eventKey="Mrs.">Mrs.</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-4">
              <label className="form-label">{first_name}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                value={employeeData.candidateName}
                onChange={(e) =>
                  setEmployeeData({
                    ...employeeData,
                    candidateName: e.target.value.replace(/^\s+/, ""),
                  })
                }
                required
              />
              <p className="error">{errors.candidateName}</p>
            </div>
            <div className="col-md-4">
              <label className="form-label">{middle_name}</label>
              <input
                type="text"
                className="form-control"
                value={employeeData.middleName}
                onChange={(e) =>
                  setEmployeeData({
                    ...employeeData,
                    middleName: e.target.value.replace(/^\s+/, ""),
                  })
                }
              />
              <p className="error">{errors.middleName}</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <label className="form-label">{last_name}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                value={employeeData.lastname}
                onChange={(e) =>
                  setEmployeeData({
                    ...employeeData,
                    lastname: e.target.value.replace(/^\s+/, ""),
                  })
                }
              />
              <p className="error">{errors.lastname}</p>
            </div>
            <div className="col-md-4">
              <label className="form-label">{date_of_birth}</label>
              <span className="required">*</span>
              <input
                type="date"
                max={moment().subtract(21, "year").format("YYYY-MM-DD")}
                value={employeeData.dateOfBirth}
                className="form-control"
                onChange={(e) =>
                  setEmployeeData({
                    ...employeeData,
                    dateOfBirth: e.target.value.replace(/^\s+/, ""),
                  })
                }
              />
            </div>

            <div className="col-md-4">
              <label className="form-label">{e_code}</label>
              <span className="required">*</span>
              <input
                type="text"
                maxLength={9}
                className="form-control"
                value={employeeData.employeeId}
                onChange={(e) =>
                  setEmployeeData({
                    ...employeeData,
                    employeeId: e.target.value.replace(/^\s+/, ""),
                  })
                }
                disabled={disable}
              />
              <p className="error">{errors.employeeId}</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <label className="form-label">{official_email}</label>
              <span className="required">*</span>
              <input
                type="email"
                className="form-control"
                value={employeeData.emailId}
                onChange={(e) =>
                  setEmployeeData({ ...employeeData, emailId: e.target.value })
                }
                disabled={disable}
              />
              <p className="error">{errors.emailId}</p>
            </div>
            <div className="col-md-4">
              <label className="form-label">{mobile_number}</label>
              <span className="required">*</span>

              <input
                type="text"
                maxLength={15}
                className="form-control"
                value={employeeData.mobileNumber}
                onChange={(e) =>
                  setEmployeeData({
                    ...employeeData,
                    mobileNumber: e.target.value.replace(/^\s+/, ""),
                  })
                }
              />

              <p className="error">{errors.mobileNumber}</p>
            </div>

            <div className="col-md-4">
              <label className="form-label">{employee_designation}</label>
              <span className="required">*</span>
              <input
                type="text"
                className="form-control"
                value={employeeData.designation}
                onChange={(e) =>
                  setEmployeeData({
                    ...employeeData,
                    designation: e.target.value.replace(/^\s+/, ""),
                  })
                }
              />
              <p className="error">{errors.designation}</p>
            </div>

            <div className="col-md-4 mt-3">
              <label className="form-label">{date_of_joining}</label>
              <span className="required">*</span>
              <input
                type="date"
                max={moment().format("YYYY-MM-DD")}
                className="form-control"
                value={employeeData.dateOfJoining}
                onChange={(e) =>
                  setEmployeeData({
                    ...employeeData,
                    dateOfJoining: e.target.value.replace(/^\s+/, ""),
                  })
                }
              />
            </div>
            <div className="col-md-8 mt-3">
              <label className="form-label">{reporting_manager_name}</label>
              <span className="required">*</span>
              <Select
                className="dropdown-select"
                placeholder={employeeData.reportingManagerName}
                options={managerOptions}
                isSearchable={true}
                onChange={handleReportingManager}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label htmlFor="inputName4" className="form-label">
                {status}
              </label>

              <Dropdown
                onSelect={(eventKey: any) => {
                  if (eventKey == "Active") {
                    setStatusDetails((obj) => ({
                      ...obj,
                      status: eventKey,
                      recordStatus: "Approved",
                      lastWorkingDate: "",
                      reason: "",
                      reasonForResignation: "",
                    }));
                  } else if (eventKey == "New") {
                    setStatusDetails((obj) => ({
                      ...obj,
                      status: eventKey,
                      recordStatus: "Re-Sent",
                      lastWorkingDate: "",
                      reason: "",
                      reasonForResignation: "",
                    }));
                  } else {
                    setStatusDetails((obj) => ({
                      ...obj,
                      status: eventKey,
                      recordStatus: "",
                    }));
                  }
                }}
              >
                <Dropdown.Toggle variant="light" className="form-control">
                  {statusDetails.status}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={active_label}
                    hidden={!employeeHrManager}
                  >
                    {active_label}
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={inactive_label}>
                    {inactive_label}
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={new_label}>
                    {new_label}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {statusDetails.status == "Active" ||
            statusDetails.status == "New" ? (
              <>
                <div className="col-md-4 mt-3">
                  <label htmlFor="inputName4" className="form-label">
                    {record_status}
                  </label>
                  <span className="required">*</span>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      statusDetails.recordStatus == "Approved"
                        ? "Approved"
                        : statusDetails.recordStatus == "Re-Sent"
                        ? "Re-Send"
                        : statusDetails.recordStatus
                    }
                    disabled
                  />
                </div>
              </>
            ) : (
              statusDetails.status == "Inactive" && (
                <>
                  <div className="col-md-4 mt-3">
                    <label htmlFor="inputName4" className="form-label">
                      {record_status}
                    </label>
                    <span className="required">*</span>
                    <Dropdown
                      onSelect={(eventKey: any) =>
                        setStatusDetails({
                          ...statusDetails,
                          recordStatus: eventKey,
                        })
                      }
                    >
                      <Dropdown.Toggle variant="light" className="form-control">
                        {statusDetails.recordStatus}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey={absconded_label}>
                          {absconded_label}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={resigned_label}>
                          {resigned_label}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              )
            )}
            {statusDetails.status == "Inactive" && (
              <div className="col-md-4 mt-3">
                <label className="form-label">{last_working_date}</label>
                <span className="required">*</span>
                <input
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  className="form-control"
                  value={statusDetails.lastWorkingDate}
                  onChange={(e) =>
                    setStatusDetails({
                      ...statusDetails,
                      lastWorkingDate: e.target.value,
                    })
                  }
                />
              </div>
            )}
            {statusDetails.recordStatus == "Re-Sent" && (
              <div className="col-md-4 mt-3">
                <label className="form-label">{Reason}</label>
                <input
                  type="text"
                  className="form-control"
                  value={statusDetails.reason}
                  onChange={(e) =>
                    setStatusDetails({
                      ...statusDetails,
                      reason: e.target.value,
                    })
                  }
                />
              </div>
            )}
            {statusDetails.recordStatus == "Re-Sent" && (
              <div className="col-md-4 mt-3">
                <label className="form-label">Resent Date</label>
                <input
                  disabled={true}
                  className="form-control"
                  value={
                    statusDetails.resentDate
                      ? timeDateFormat(statusDetails.resentDate)
                      : "--/--/----"
                  }
                />
              </div>
            )}
            {statusDetails.recordStatus == "Resigned" && (
              <div className="col-md-4 mt-3">
                <label className="form-label">{ReasonForResignation}</label>
                <input
                  type="text"
                  className="form-control"
                  value={statusDetails.reasonForResignation}
                  onChange={(e) =>
                    setStatusDetails({
                      ...statusDetails,
                      reasonForResignation: e.target.value,
                    })
                  }
                />
              </div>
            )}
          </div>

          <div className="d-flex justify-content-between mt-5">
            <div className="modal-btn-wrapper">
              <div>
                <button
                  className="btn cancel-btn buttonsize"
                  onClick={hideEditModal}
                >
                  {cancel}
                </button>
              </div>
              <div>
                <button
                  className={
                    btnDisable
                      ? "btn add-submit-button buttonsize"
                      : "btn add-submit-button btn-disabled buttonsize"
                  }
                  onClick={handleEdit}
                >
                  {submit}
                </button>
              </div>
            </div>
            <div>
              {(recordStatus == "New" && employeeDbChecker) && (
                <div className="heading-button-wrapper">
                  <Button
                    title="Approve and Send Email"
                    onClick={() => setnextScreen(false)}
                    className="add-employee-btn"
                  ></Button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <SendEmail
          apiCallCompleted={apiCallCompleted}
          employeeData={employeeData}
          hideEditModal={hideEditModal}
          setnextScreen={setnextScreen}
        />
      )}
    </>
  );
};

export default EditEmployee;
