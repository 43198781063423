import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  Strings,
  eligibleReferralsStatusDropdown,
} from "../../../constant/Strings/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";

const StatusChangeModal = (props: any) => {
  const { hideChangeStatus, status, apiCallCompleted } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  const dispatch = useDispatch();
  const { cancel, submit, selectStatus, statusChangeSuccess, remarksLabel } =
    Strings;
  const [changeStatus, setChangeStatus] = useState({
    status: status.status,
    remarks: status.remarks,
  });
  useEffect(() => {
    if (changeStatus.status) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [changeStatus]);
  const handleRejectionSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      actions.putEligibleReferralPayoutsAction(
        status.id,
        changeStatus,
        (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res.error.error_description);
            apiCallCompleted(true);
          } else {
            showSuccessToastMessage(statusChangeSuccess);
            apiCallCompleted(true);
          }
        }
      )
    );
    hideChangeStatus();
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <label className="form-label">{selectStatus}</label>
            <span className="required">*</span>
            <Dropdown
              onSelect={(eventKey: any) =>
                setChangeStatus({
                  ...changeStatus,
                  status: eventKey,
                })
              }
            >
              <Dropdown.Toggle variant="light" className="form-control col">
                {changeStatus.status == 0
                  ? "Not Paid"
                  : changeStatus.status == 1
                  ? "Paid"
                  : ""}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {eligibleReferralsStatusDropdown.map((element: any) => (
                  <Dropdown.Item eventKey={element.value} value={element.value}>
                    {element.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-md-12 mt-4 mb-3">
            <label className="form-label">{remarksLabel}</label>
            <textarea
              id="exampleFormControlTextarea1"
              className="form-control"
              value={changeStatus.remarks}
              onChange={(e) =>
                setChangeStatus({
                  ...changeStatus,
                  remarks: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="modal-btn-wrapper mt-5 mx-auto">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={hideChangeStatus}
            >
              {cancel}
            </button>
          </div>
          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleRejectionSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusChangeModal;
