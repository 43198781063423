import React, { useEffect, useMemo, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import { FaSort } from "react-icons/fa";
import { icons } from "../../../assets/index";
import { Table } from "react-bootstrap";
import "../LearningDevelopment.scss";
import * as actions from "../../../Redux/Actions/index";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ReasonForRejection from "../LearningDevelopmentData/ReasonForRejection";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import ApprovalRemark from "../LearningDevelopmentData/ApprovalRemark";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import useLoadingIndicator from "../../../component/Loader/Loader";
import {
  learningDevFilters,
  learningStatus,
  Strings,
} from "../../../constant/Strings";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import TablePagination from "../../../component/TablePagination/TablePagination";

const LearningDevelopmentTable = (props: any) => {
  const { loadingComponent, apiCallCompleted } = useLoadingIndicator();
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const unAuthorisedUser =
    role && !role.includes("learninganddevelopment-manager");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const {
    arrow_drop_down,
    course_link_icon,
    orange_cross,
    green_tick_icon,
    disabled_cross_icon,
    disabled_tick_icon,
  } = icons;

  const {
    no_data_found,
    learning_development_manager_requests,
    select_by_status,
  } = Strings;

  const managerRequestList = useSelector(
    (state: any) => state.employeeReducer.managerRequestList
  );
  const dispatch = useDispatch();
  const [expansion, setExpansion] = useState(false);
  const [rowid, setRowid] = useState("");
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [pagePerSize, setPagePerSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [recordStatus, setRecordStatus] = useState<any>("");
  const [data, setdata] = useState<any>([]);

  function handleExpansion(id: any) {
    setRowid(id);
    setExpansion(!expansion);
  }

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isSorted, setisSorted] = useState(false);
  const [sortedData, setsortedData] = useState<any>([]);
  const [order, setorder] = useState("ASC");

  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;

  const sorting = (col: any) => {
    setisSorted(true);
    if (order === "ASC") {
      const sorted = [...data].sort((a: any, b: any) => {
        if (col == "candidateName") {
          if (a?.employees[0].candidateName < b?.employees[0].candidateName) {
            return 1;
          }
          if (a?.employees[0].candidateName > b?.employees[0].candidateName) {
            return -1;
          }
          return 0;
        } else if (col == "employeeId") {
          if (a?.employees[0].employeeId < b?.employees[0].employeeId) {
            return 1;
          }
          if (a?.employees[0].employeeId > b?.employees[0].employeeId) {
            return -1;
          }
          return 0;
        } else {
          if (a[col] < b[col]) {
            return 1;
          }
          if (a[col] > b[col]) {
            return -1;
          }
          return 0;
        }
      });
      setsortedData(sorted);
      setorder("DSC");
    } else {
      const sorted = [...data].sort((a: any, b: any) => {
        if (a[col] < b[col]) {
          return -1;
        }
        if (a[col] > b[col]) {
          return 1;
        }
        return 0;
      });
      setsortedData(sorted);
      setorder("ASC");
    }
  };

  const [requestId, setrequestId] = useState("");

  const [showAddRequirement, setShowAddRequirement] = useState(false);

  const handleRejection = (id: any) => {
    setShowAddRequirement(true);
    setrequestId(id);
  };
  const hideRejection = () => {
    setShowAddRequirement(false);
  };

  const [showApproval, setShowApproval] = useState(false);

  const handleApproval = (id: any) => {
    setShowApproval(true);
    setrequestId(id);
  };
  const hideApproval = () => {
    setShowApproval(false);
  };

  const updateStatusRequest = useSelector(
    (state: any) => state.employeeReducer.updateStatusRequest
  );
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    if (unAuthorisedUser) {
      navigate("/");
    } else if (condition) {
      dispatch(
        actions.managerRequestListAction(
          loginId,
          page,
          pagePerSize,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                setOnclickSearch(false);
              }
            }
          }
        )
      );
    }
  }, [
    updateStatusRequest,
    selectedStatus,
    page,
    pagePerSize,
    searchValue,
    filterValue,
    recordStatus,
    condition,
  ]);

  useEffect(() => {
    if (managerRequestList && managerRequestList.data) {
      setdata(managerRequestList.data);
      setTotalPages(managerRequestList.meta_data.totalPageCount);
      setTotalEntries(managerRequestList.meta_data.totalEntries);
    }
  }, [managerRequestList, data]);

  const COLUMNS: any = [
    {
      Header: "Employee Name",
      accessor: "candidateName",
    },
    {
      Header: "Training Type",
      accessor: "trainingType",
    },
    {
      Header: "Training Mode",
      accessor: "trainingMode",
    },
    {
      Header: "Experience",
      accessor: "experience",
    },
    {
      Header: "Current Designation",
      accessor: "currentDesignation",
    },
    {
      Header: "Course Name",
      accessor: "courseName",
    },
    {
      Header: "Purpose/Description",
      accessor: "purpose",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Course Link",
      accessor: "courseLink",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];
  const columns = useMemo(() => COLUMNS, []);

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };

  const [allRecordsValue, setAllRecordsValue] = useState("");
  const handleFilterByStatus = (value: any) => {
    setOnclickSearch(true);
    if (value == "All Records") {
      setAllRecordsValue(value);
      setFilterValue([]);
      setSearchValue("");
    } else {
      setFilterValue([{ title: "Status", value: "status" }]);
      setSearchValue(value);
      setAllRecordsValue("");
    }
    setPage(1);
  };

  return (
    <>
      {loadingComponent()}
      <div className="heading-button-wrapper">
        <div>
          <h2>{learning_development_manager_requests}</h2>
        </div>
      </div>
      <div className="mt-2">
        <div className="mt-3 buttonsize filter-wrapper margin-left">
          <div className="emp-searchbar-wrapper width-fifty-five">
            <SearchBar
              filter={searchValue}
              setFilter={handleSearch}
              onSearchClicked={onSearchClicked}
            />
          </div>

          <div className="emp-filter-container">
            <select
              id="select-filters"
              value={filterValue}
              onChange={(event) => {
                const selectedValue = event.target.value;
                const selectedOption = learningDevFilters.find(
                  (item: any) => item.value === selectedValue
                );
                if (selectedOption) {
                  filterChange(selectedOption);
                }
              }}
            >
              <option hidden>Filters</option>
              {learningDevFilters.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>

          <div className="emp-filter-container">
            <select
              value={allRecordsValue ? select_by_status : searchValue}
              onChange={(e: any) => handleFilterByStatus(e.target.value)}
            >
              <option hidden>{select_by_status}</option>
              {learningStatus.map((item: any, index: any) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </div>

        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
        />

        {data?.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="table-margin">
                <thead className="employeedb">
                  <tr className="custom-table-head-row">
                    {columns.map((ele: any) => (
                      <React.Fragment key={ele.Header}>
                        <td
                          onClick={() => sorting(ele.accessor)}
                          className="sorting-cursor"
                        >
                          {ele.Header}
                          {ele.Header !== "Action" &&
                          ele.Header !== "" &&
                          ele.Header != "Remark" ? (
                            <span>{<FaSort />}</span>
                          ) : (
                            " "
                          )}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>

                <tbody className="employeedata">
                  {data && data?.length === 0 ? (
                    <tr>
                      <td colSpan={12}> {no_data_found}</td>
                    </tr>
                  ) : (
                    (isSorted ? sortedData : data)?.map(
                      (ele: any, index: any) => (
                        <>
                          <tr key={ele?._id}>
                            <td
                              onClick={() => handleExpansion(index)}
                              className="text-start"
                            >
                              <img
                                src={arrow_drop_down}
                                className={
                                  index === rowid
                                    ? expansion
                                      ? "rotate-arrow-icon"
                                      : ""
                                    : ""
                                }
                                alt="icon"
                              />

                              <Link
                                to={`/learning-details/${ele._id}`}
                                className="learning-development-link"
                              >
                                <span className="mx-3">
                                  {ele?.EmployeeData[0]?.candidateName +
                                    " " +
                                    ele?.EmployeeData[0]?.middleName +
                                    " " +
                                    ele?.EmployeeData[0]?.lastname}
                                </span>
                              </Link>
                            </td>
                            <td>
                              {ele?.internalTraining === 1 ? (
                                <span className="internal-training-text">
                                  Udemy
                                </span>
                              ) : (
                                <span className="external-training-text">
                                  External
                                </span>
                              )}
                            </td>

                            <td>{ele?.trainingMode}</td>
                            <td>
                              {ele?.EmployeeData[0]?.employementDuration ===
                              "Above 6 Months"
                                ? "> 6M"
                                : "< 6M"}
                            </td>

                            <td className="text-start">
                              {ele?.EmployeeData[0]?.designation}
                            </td>
                            <td className="text-start">{ele?.courseName}</td>
                            <td className="text-start">
                              {ele?.purpose && ele?.purpose.length > 20
                                ? `${ele?.purpose?.slice(0, 20) || ""}...`
                                : ele?.purpose}
                            </td>
                            <td
                              className={
                                ele?.managerApprovalStatus === "New"
                                  ? "new-text-status"
                                  : ele?.managerApprovalStatus === "Approved"
                                  ? "accepted-text-status"
                                  : ele?.managerApprovalStatus === "Rejected"
                                  ? "declined-text-status"
                                  : ""
                              }
                            >
                              {ele?.managerApprovalStatus}
                            </td>
                            <td>
                              {ele?.courseLink && (
                                <a href={ele?.courseLink} target="_blank">
                                  <img src={course_link_icon} alt="icon" />
                                </a>
                              )}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                {ele?.managerApprovalStatus === "New" ? (
                                  <>
                                    <button
                                      type="button"
                                      className="green-tick-btn"
                                      onClick={() => handleApproval(ele._id)}
                                      title="Approve"
                                    >
                                      <img src={green_tick_icon} alt="icon" />
                                    </button>
                                    <button
                                      type="button"
                                      className="orange-cross-btn"
                                      onClick={() => handleRejection(ele._id)}
                                      title="Reject"
                                    >
                                      <img src={orange_cross} alt="icon" />
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      type="button"
                                      className="disabled-tick-btn"
                                    >
                                      <img
                                        src={disabled_tick_icon}
                                        alt="icon"
                                      />
                                    </button>
                                    <button
                                      type="button"
                                      className="disabled-tick-btn"
                                    >
                                      <img
                                        src={disabled_cross_icon}
                                        alt="icon"
                                      />
                                    </button>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                          {index === rowid && expansion && (
                            <tr>
                              <td colSpan={7}>
                                <div className="d-flex justify-content-between width-fifty">
                                  {ele?.managerApprovalStatus ===
                                    "Approved" && (
                                    <div>
                                      <div className="invoice_heading">
                                        Remarks
                                      </div>
                                      <div>
                                        {ele?.managerRemarks
                                          ? `${
                                              ele?.managerRemarks?.slice(
                                                0,
                                                20
                                              ) || ""
                                            }...`
                                          : "-"}
                                      </div>
                                    </div>
                                  )}

                                  {ele?.managerApprovalStatus ===
                                    "Rejected" && (
                                    <div>
                                      <div className="invoice_heading">
                                        Reason
                                      </div>
                                      <div>{ele?.reason}</div>
                                    </div>
                                  )}

                                  <div>
                                    <div className="invoice_heading">
                                      Skills
                                    </div>
                                    <div>
                                      {ele?.majorSkills
                                        ? ele?.majorSkills
                                        : "-"}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="invoice_heading">
                                      Client Name
                                    </div>
                                    <div>
                                      {ele?.EmployeeData[0]?.clientName}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      )
                    )
                  )}
                </tbody>
              </Table>
            </div>

            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pagePerSize}
                setPageSize={setPagePerSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />

              <TablePagination
                totalPages={totalPages}
                currentPage={page}
                onClick={handlePageChange}
              />
            </div>
          </>
        ) : (
          <p className="no-data">{no_data_found}</p>
        )}
      </div>

      <ModalComponents
        show={showAddRequirement}
        onHide={hideRejection}
        title={"Rejected"}
        modalBody={
          <ReasonForRejection
            apiCallCompleted={apiCallCompleted}
            hideRejection={hideRejection}
            requestId={requestId}
            role="Manager"
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showApproval}
        onHide={hideApproval}
        title={"Accepted"}
        modalBody={
          <ApprovalRemark
            apiCallCompleted={apiCallCompleted}
            hideApproval={hideApproval}
            requestId={requestId}
            role="Manager"
          />
        }
        className="modal-lg"
      />
    </>
  );
};

export default LearningDevelopmentTable;
