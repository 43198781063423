import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";

const DeleteEmployee = (props: any) => {
  const { id, hideDeleteModal } = props;
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(
      actions.deleteEmployeeDataAction(id, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
        } else {
          if (res.code === 200) {
            showSuccessToastMessage("Deleted successfully");
          }
        }
      })
    );
    hideDeleteModal();
  };
  const deleteEmployee = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeeData
  );

  return (
    <div className="container">
      <div className="row mx-auto">Are you sure you want to delete?</div>
      <div className="modal-btn-wrapper mt-3 mx-auto">
        <div>
          <button
            className="btn btn-outline-danger buttonsize"
            onClick={hideDeleteModal}
          >
            Cancel
          </button>
        </div>
        <div>
          <button className="btn btn-primary buttonsize" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteEmployee;
