import React, { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { Modal, ModalHeader } from "reactstrap";
import "./Loader.scss";

const useLoadingIndicator = () => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState("Loading");
  const showLoadingIndicator = (text = "loading") => {
    setVisible(true);
    setText(text);
  };
  const hideLoadingIndicator = (text = "loading") => {
    setVisible(false);
    setText(text);
  };
  const toggleLoadingIndicator = () => {
    setVisible((prv) => !prv);
  };
  const apiCallCompleted = (value: any) => {
    if (value == false) {
      showLoadingIndicator();
    } else {
      hideLoadingIndicator();
    }
  };
  const loadingComponent = () => (
    <Modal modalClassName="loading-container-modal" isOpen={visible}>
      <div className="modal-title">
        <ModalHeader>
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#28BCB9", "#28BCB9", "#28BCB9", "#28BCB9", "#28BCB9"]}
          />

          <div className="loadingText">
            {text}
            <span className="dots">...</span>
          </div>
        </ModalHeader>
      </div>
    </Modal>
  );
  return {
    showLoadingIndicator,
    hideLoadingIndicator,
    toggleLoadingIndicator,
    loadingComponent,
    apiCallCompleted,
  };
};

export default useLoadingIndicator;
