import React, { useEffect, useState } from "react";
import MainCard from "../../../../component/MainCard/MainCard";
import { useParams } from "react-router-dom";
import RemarksTab from "./RemarksTab";
import ProjectDetailsTab from "../../../../component/ProjectDetailsTab/ProjectDetailsTab";
import {
  Strings,
  invoiceDetailsColumn3,
  invoiceDetailsColumn4,
  invoiceColumn,
} from "../../../../constant/Strings/index";
import { useDispatch } from "react-redux";
import { showErrorToastMessage } from "../../../../component/Toasts/Toasts";
import * as actions from "../../../../Redux/Actions/index";
import { timeDateFormatYMD } from "../../../../component/FormatDate";

const IndividualInvoiceDetails = (props: any) => {
  const { invoice_id, project_id } = useParams();
  const [data, setData] = useState<any>([]);
  const dispatch = useDispatch();

  const { invoice_no } = Strings;
  const valueArray1: any = [
    data?.invoiceDate ? timeDateFormatYMD(data?.invoiceDate): "-",
    data?.revenueMonth ? data?.revenueMonth : "-",
    data?.baseAmount ? data?.baseAmount : "-",
    data?.cgst ? data?.cgst : "-",
    data?.sgst ? data?.sgst : "-",
    data?.igst ? data?.igst : "-",
  ];
  const valueArray2: any = [
    data?.vat ? data?.vat : "-",
    data?.tds ? data?.tds : "-",
    data?.totalAmount ? data?.totalAmount : "-",
    data?.paidAmount ? data?.paidAmount : "-",
    data?.differenceAmount ? data?.differenceAmount : "-",
    data?.status ? data?.status : "-",
  ];
  useEffect(() => {
    dispatch(
      actions.getInvoicesByInvoiceIdAction(invoice_id, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              setData(res.data);
            }
          }
        }
      })
    );
  }, []);
  const columnValues: any = [
    {
      name: `${data?.invoiceNumber} Invoice.pdf`,
      value: data.invoicePdf?.document_path_url,
    },
  ];
  return (
    <>
      <MainCard>
        <ProjectDetailsTab
          navigateTo={`/invoices/${project_id}`}
          tabHeading={invoice_no}
          headingValue={data?.invoiceNumber}
          firstColumnHeadings={invoiceDetailsColumn3}
          firstColumnValues={valueArray1}
          secondColumnHeadings={invoiceDetailsColumn4}
          secondColumnValues={valueArray2}
        />
        <div className="profile-container">
          <RemarksTab
            documentHeadingColumn={invoiceColumn}
            columnValues={columnValues}
            data={data}
          />
        </div>
      </MainCard>
    </>
  );
};

export default IndividualInvoiceDetails;
