import { useDispatch } from "react-redux";
import { showErrorToastMessage } from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";

const DeletePreferences = (props: any) => {
  const { id, hideDeletePreferences } = props;
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(actions.deleteEmployeePreferenceAction(id));
    hideDeletePreferences();
    showErrorToastMessage("Deleted Successfully!");
  };

  return (
    <div className="container">
      <div className="row mx-auto">Are you sure you want to delete?</div>
      <div className="row mt-3 mx-auto">
        <div className="col">
          <button
            className="btn btn-outline-danger"
            onClick={hideDeletePreferences}
          >
            Cancel
          </button>
        </div>
        <div className="col">
          <button className="btn btn-primary" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletePreferences;
