import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showSuccessToastMessage } from "../../../../../component/Toasts/Toasts";
import * as actions from "../../../../../Redux/Actions/index";
import {
  whiteSpaceValid,
  isYearValid,
  isMarks1Valid,
  isMarks2Valid,
  validationAlphaNumeric,
} from "../../../../../constant/Validations";
import { Strings } from "../../../../../constant/Strings";

const AddCertifications = (props: any) => {
  const employeeId: any = localStorage.getItem("selectedId");
  const { hideAddCertifictions } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  const [certifications, setCertifications] = useState({
    employeeRef: employeeId,
    nameOfCertification: "",
    institute: "",
    board: "",
    year: "",
    marks: "",
  });

  const {
    valid_board_name,
    valid_institute,
    valid_marks,
    valid_year,
    submit,
    cancel,
    valid_certificate,
    certification_name,
    percentage_of_marks,
    year_of_passing,
    board_affiliation_name,
    institute_body_name,
  } = Strings;

  const [
    shownameOfCertificationErrorToggle,
    setShownameOfCertificationErrorToggle,
  ] = useState(false);
  const [showinstituteErrorToggle, setShowinstituteErrorToggle] =
    useState(false);
  const [showboardErrorToggle, setShowboardErrorToggle] = useState(false);
  const [showyearErrorToggle, setShowyearErrorToggle] = useState(false);
  const [showmarksErrorToggle, setShowmarksErrorToggle] = useState(false);
  useEffect(() => {
    if (
      certifications.nameOfCertification &&
      certifications.institute &&
      certifications.board &&
      certifications.year &&
      certifications.marks
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [certifications]);

  const dispatch = useDispatch();
  const handleCertificationSubmit = () => {
    if (
      whiteSpaceValid(certifications.nameOfCertification) &&
      validationAlphaNumeric(certifications.nameOfCertification)
    ) {
      setShownameOfCertificationErrorToggle(false);
    } else {
      setShownameOfCertificationErrorToggle(true);
    }
    if (
      whiteSpaceValid(certifications.institute) &&
      validationAlphaNumeric(certifications.institute)
    ) {
      setShowinstituteErrorToggle(false);
    } else {
      setShowinstituteErrorToggle(true);
    }
    if (
      whiteSpaceValid(certifications.board) &&
      validationAlphaNumeric(certifications.board)
    ) {
      setShowboardErrorToggle(false);
    } else {
      setShowboardErrorToggle(true);
    }
    if (
      whiteSpaceValid(certifications.year) &&
      certifications.year.toString().length == 4 &&
      isYearValid(certifications.year)
    ) {
      setShowyearErrorToggle(false);
    } else {
      setShowyearErrorToggle(true);
    }
    if (
      (whiteSpaceValid(certifications.marks) &&
        isMarks1Valid(certifications.marks)) ||
      isMarks2Valid(certifications.marks)
    ) {
      setShowmarksErrorToggle(false);
    } else {
      setShowmarksErrorToggle(true);
    }

    if (
      whiteSpaceValid(certifications.nameOfCertification) &&
      validationAlphaNumeric(certifications.nameOfCertification) &&
      whiteSpaceValid(certifications.institute) &&
      validationAlphaNumeric(certifications.institute) &&
      whiteSpaceValid(certifications.board) &&
      validationAlphaNumeric(certifications.board) &&
      isYearValid(certifications.year) &&
      (isMarks2Valid(certifications.marks) ||
        isMarks1Valid(certifications.marks))
    ) {
      dispatch(actions.addEmployeeCertificationsAction(certifications));
      showSuccessToastMessage("Added Successfully!");
      hideAddCertifictions();
    }
  };

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-4">
          <label htmlFor="inputSkills" className="form-label">
            {certification_name}
          </label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setCertifications({
                ...certifications,
                nameOfCertification: e.target.value,
              })
            }
          />
          <p className="error">
            {certifications.nameOfCertification.length !== 0 &&
              (shownameOfCertificationErrorToggle ? valid_certificate : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{institute_body_name}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setCertifications({
                ...certifications,
                institute: e.target.value,
              })
            }
          />
          <p className="error">
            {certifications.institute.length !== 0 &&
              (showinstituteErrorToggle ? valid_institute : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{board_affiliation_name}</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setCertifications({
                ...certifications,
                board: e.target.value,
              })
            }
          />
          <p className="error">
            {certifications.board.length !== 0 &&
              (showboardErrorToggle ? valid_board_name : "")}
          </p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{year_of_passing}</label>
          <span className="required">*</span>
          <input
            type="text"
            maxLength={4}
            className="form-control"
            onChange={(e) =>
              setCertifications({
                ...certifications,
                year: e.target.value,
              })
            }
          />
          <p className="error">
            {certifications.year.length !== 0 &&
              (showyearErrorToggle ? valid_year : "")}
          </p>
        </div>

        <div className="col-md-4">
          <label className="form-label">{percentage_of_marks}</label>
          <span className="required">*</span>
          <input
            type="text"
            maxLength={5}
            className="form-control"
            onChange={(e) =>
              setCertifications({
                ...certifications,
                marks: e.target.value,
              })
            }
          />
          <p className="error">
            {certifications.marks.length !== 0 &&
              (showmarksErrorToggle ? valid_marks : "")}
          </p>
        </div>
      </div>
      <div className="modal-btn-wrapper mt-5">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideAddCertifictions}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleCertificationSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCertifications;
