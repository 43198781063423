import React, { useEffect, useState } from "react";
import "./SearchBar.scss";
import search_icon from "../../assets/search.svg";

const SearchBar = (props: any) => {
  const { setFilter, disabled, onSearchClicked,filter  } = props;
  const [searchValue, setSearchValue] = useState(filter ?? "");
  useEffect(() => {
    if (searchValue === "") {
      setFilter("");
    }
  }, [searchValue]);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && searchValue) {
      setFilter(searchValue);
      onSearchClicked(true);
    }
  };
  return (
    <div className={disabled ? "searchbar disabled-searchbar" : "searchbar"}>
      <button
        className="btn"
        onClick={() => {
          onSearchClicked(true);
          setFilter(searchValue);
        }}
      >
        <img src={search_icon} className="search-icon" alt="searchIcon" />
      </button>
      <input
        type="text"
        value={searchValue}
        placeholder="Search"
        onChange={(e: any) => {
          setSearchValue(e.target.value.trimStart());
        }}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
    </div>
  );
};

export default SearchBar;
