import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { Strings } from "../../../constant/Strings";

const SendEmail = (props: any) => {
  const { employeeData, hideEditModal, setnextScreen, apiCallCompleted } =
    props;

  const [emailDetails, setEmailDetails] = useState({
    name:
      employeeData.candidateName +
      " " +
      employeeData.middleName +
      " " +
      employeeData.lastname,
    email: employeeData.emailId,
  });
  const dispatch = useDispatch();
  const { yes, no, send_email_text, emailSuccess } = Strings;
  function handleEmailSubmit() {
    apiCallCompleted(false);
    dispatch(
      actions.postEmailNotificationAction(
        employeeData?.id,
        emailDetails,
        (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
            apiCallCompleted(true);
          } else {
            if (res.code === 200) {
              apiCallCompleted(true);
              showSuccessToastMessage(emailSuccess);
            }
          }
        }
      )
    );
    hideEditModal();
  }

  return (
    <div className="container">
      <div className="row mx-auto">
        {send_email_text +
          " " +
          employeeData.candidateName +
          " " +
          (employeeData.middleName ? employeeData.middleName : "") +
          " " +
          employeeData.lastname}
        ?
      </div>
      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={() => setnextScreen(true)}
          >
            {no}
          </button>
        </div>
        <div>
          <button
            className="btn add-submit-button buttonsize"
            onClick={handleEmailSubmit}
          >
            {yes}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendEmail;
