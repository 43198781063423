import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import { Dropdown } from "react-bootstrap";
import {
  Strings,
  homeWorkStatusDropdown,
} from "../../../constant/Strings/index";

const SessionStatusModal = (props: any) => {
  const { hideStatusModal, sessionId } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  const { remarks, cancel, submit, selectStatus, updateStatusSuccess } =
    Strings;
  const [statusData, setStatusData] = useState<any>({
    reasonForRework: "",
    homeWorkStatus: "",
  });
  const dispatch = useDispatch();

  const handleAcceptModalSubmit = () => {
    dispatch(
      actions.coachUpdateHwStatusAction(sessionId, statusData, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
        } else {
          if (res.code === 200) {
            showSuccessToastMessage(updateStatusSuccess);
          }
        }
      })
    );
    hideStatusModal();
  };

  const handleChange = (e: any) => {
    setStatusData({ ...statusData, reasonForRework: e.target.value });
  };

  useEffect(() => {
    if (statusData.reasonForRework && statusData.homeWorkStatus) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });

  return (
    <div className="container">
      <div className="row">
        <label className="form-label ">{selectStatus}</label>
        <div className="col-6">
          <Dropdown
            onSelect={(eventKey: any) =>
              setStatusData({
                ...statusData,
                homeWorkStatus:
                  eventKey === "Completed" ? 1 : eventKey === "Rework" ? 2 : "",
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control col">
              {statusData.homeWorkStatus === 1
                ? "Completed"
                : statusData.homeWorkStatus === 2
                ? "Rework"
                : ""}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {homeWorkStatusDropdown.map((element: any) => (
                <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-12 mt-2 mb-3">
          <label className="form-label">{remarks}</label>
          <textarea
            id="exampleFormControlTextarea1"
            className="form-control"
            style={{
              width: "24rem",
              height: "10rem",
            }}
            value={statusData.reasonForRework}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={hideStatusModal}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleAcceptModalSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionStatusModal;
