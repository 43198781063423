import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import uploadimg from "../../../assets/Uploadicon.png";
import "../EmployeeDb.scss";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
import "./style.scss";
import {
  Strings,
  addEmployeeValidations,
  addEmployeeLabels,
} from "../../../constant/Strings/index";
import {
  employeeCandidateNameValidtion,
  employeeDesignationValidtion,
  employeeEmailIdValidation,
  employeeEmployeeIdValidtion,
  employeeLastNameValidtion,
  employeeMobileNumberValidtion,
} from "../../../constant/Validations";

const AddEmployee = (props: any) => {
  const { hideAddModal, totalEntries, apiCallCompleted } = props;
  const [btnDisable, setBtnDisable] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState({
    candidateName: "",
    middleName: "",
    lastname: "",
    dateOfBirth: "",
    emailId: "",
    mobileNumber: "",
    company: "",
    designation: "",
    dateOfJoining: "",
    employeeId: "",
    title: "",
    managerRef: "",
  });

  const [errors, setErrors] = useState({
    candidateName: "",
    middleName: "",
    lastname: "",
    emailId: "",
    mobileNumber: "",
    employeeId: "",
    designation: "",
  });
  const { addSuccess, cancel, submit, skillmineMailid } = Strings;
  const {
    designationValid,
    mobileNumberValid,
    emailIdValid,
    employeeIdValid,
    lastnameValid,
    candidateNameValid,
  } = addEmployeeValidations;
  const {
    firstName,
    title,
    middleName,
    lastName,
    dateOfBirth,
    Ecode,
    officialEmail,
    mobileNumber,
    designation,
    dateOfJoining,
    reportingManagerName,
  } = addEmployeeLabels;

  const dispatch = useDispatch();

  const [employeeInfo, setEmployeeInfo] = useState([]);
  const employeeManagerData = useSelector(
    (state: any) => state.employeeReducer.getEmployeeData
  );
  useEffect(() => {
    if (employeeManagerData && employeeManagerData.data) {
      setEmployeeInfo(employeeManagerData.data);
    }
  }, [employeeManagerData, employeeInfo]);

  const managerOptions: any = employeeInfo.map((ele: any) => ({
    value: ele._id,
    label: ele.middleName
      ? ele.candidateName +
        " " +
        ele.middleName +
        " " +
        ele.lastname +
        "-" +
        ele.employeeId
      : ele.candidateName + " " + ele.lastname + "-" + ele.employeeId,
  }));
  const handleReportingManager = (selected: any) => {
    setEmployeeDetails({
      ...employeeDetails,
      managerRef: selected.value,
    });
  };

  useEffect(() => {
    if (
      employeeDetails.candidateName &&
      employeeDetails.lastname &&
      employeeDetails.dateOfBirth &&
      employeeDetails.employeeId &&
      employeeDetails.emailId &&
      employeeDetails.mobileNumber &&
      employeeDetails.designation &&
      employeeDetails.dateOfJoining &&
      employeeDetails.title &&
      employeeDetails.managerRef
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [employeeDetails]);

  let valid: boolean = true;
  const validate = () => {
    if (employeeDesignationValidtion(employeeDetails.designation) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        designation: designationValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, designation: "" }));
    }
    if (employeeMobileNumberValidtion(employeeDetails.mobileNumber) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        mobileNumber: mobileNumberValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, mobileNumber: "" }));
    }
    if (
      employeeEmailIdValidation(employeeDetails.emailId) &&
      employeeDetails.emailId.includes(skillmineMailid)
    ) {
      setErrors((errorObj) => ({ ...errorObj, emailId: "" }));
    } else {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        emailId: emailIdValid,
      }));
      setBtnDisable(false);
    }

    if (
      employeeDetails.employeeId.length < 5 ||
      employeeDetails.employeeId.length > 9 ||
      employeeEmployeeIdValidtion(employeeDetails.employeeId) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        employeeId: employeeIdValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, employeeId: "" }));
    }
    if (employeeLastNameValidtion(employeeDetails.lastname) == false) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        lastname: lastnameValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, lastname: "" }));
    }
    if (employeeDetails.middleName) {
      if (employeeCandidateNameValidtion(employeeDetails.middleName) == false) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          middleName: candidateNameValid,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({ ...errorObj, middleName: "" }));
      }
    }
    if (
      employeeCandidateNameValidtion(employeeDetails.candidateName) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        candidateName: candidateNameValid,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, candidateName: "" }));
    }
  };
  const handleEmployeeSubmit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };
  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.addEmployeeDataAction(employeeDetails, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          apiCallCompleted(true);
        } else {
          if (res?.code === 200) {
            showSuccessToastMessage(addSuccess);
            apiCallCompleted(true);
          }
        }
      })
    );
    hideAddModal();
  };

  const [setDropdown, setShowDropdown] = useState(false);
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <label className="form-label">
            {title}
            <span className="required">*</span>
          </label>
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
            onSelect={(eventKey: any) =>
              setEmployeeDetails({
                ...employeeDetails,
                title: eventKey,
              })
            }
          >
            <Dropdown.Toggle variant="light" className="form-control">
              {employeeDetails.title}
            </Dropdown.Toggle>
            <Dropdown.Menu show={setDropdown}>
              <Dropdown.Item eventKey="Dr.">Dr.</Dropdown.Item>
              <Dropdown.Item eventKey="Miss">Miss</Dropdown.Item>
              <Dropdown.Item eventKey="Mr.">Mr.</Dropdown.Item>
              <Dropdown.Item eventKey="Ms.">Ms.</Dropdown.Item>
              <Dropdown.Item eventKey="Mrs.">Mrs.</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-4">
          <label className="form-label">
            {firstName}
            <span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeDetails.candidateName}
            onChange={(e) =>
              setEmployeeDetails({
                ...employeeDetails,
                candidateName: e.target.value.replace(/^\s+/, ""),
              })
            }
          />
          <p className="error">{errors.candidateName}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{middleName}</label>
          <input
            type="text"
            className="form-control"
            value={employeeDetails.middleName}
            onChange={(e) =>
              setEmployeeDetails({
                ...employeeDetails,
                middleName: e.target.value.replace(/^\s+/, ""),
              })
            }
          />
          <p className="error">{errors.middleName}</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-4">
          <label className="form-label">
            {lastName}
            <span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeDetails.lastname}
            onChange={(e) =>
              setEmployeeDetails({
                ...employeeDetails,
                lastname: e.target.value.replace(/^\s+/, ""),
              })
            }
          />
          <p className="error">{errors.lastname}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{dateOfBirth}</label>
          <span className="required">*</span>
          <input
            type="date"
            max={moment().subtract(21, "year").format("YYYY-MM-DD")}
            value={employeeDetails.dateOfBirth}
            className="form-control"
            onChange={(e) =>
              setEmployeeDetails({
                ...employeeDetails,
                dateOfBirth: e.target.value.replace(/^\s+/, ""),
              })
            }
          />
        </div>

        <div className="col-md-4">
          <label className="form-label">{Ecode}</label>
          <span className="required">*</span>
          <input
            type="text"
            maxLength={9}
            value={employeeDetails.employeeId}
            className="form-control"
            onChange={(e) =>
              setEmployeeDetails({
                ...employeeDetails,
                employeeId: e.target.value.replace(/^\s+/, ""),
              })
            }
          />
          <p className="error">{errors.employeeId}</p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <label className="form-label">{officialEmail}</label>
          <span className="required">*</span>
          <input
            type="email"
            className="form-control"
            value={employeeDetails.emailId}
            onChange={(e) =>
              setEmployeeDetails({
                ...employeeDetails,
                emailId: e.target.value,
              })
            }
          />
          <p className="error">{errors.emailId}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{mobileNumber}</label>
          <span className="required">*</span>

          <input
            type="text"
            maxLength={15}
            value={employeeDetails.mobileNumber}
            className="form-control"
            onChange={(e) =>
              setEmployeeDetails({
                ...employeeDetails,
                mobileNumber: e.target.value.replace(/^\s+/, ""),
              })
            }
          />

          <p className="error">{errors.mobileNumber}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">{designation}</label>
          <span className="required">*</span>
          <input
            type="text"
            value={employeeDetails.designation}
            className="form-control"
            onChange={(e) =>
              setEmployeeDetails({
                ...employeeDetails,
                designation: e.target.value.replace(/^\s+/, ""),
              })
            }
          />
          <p className="error">{errors.designation}</p>
        </div>
        <div className="col-md-4 mt-3">
          <label className="form-label">{dateOfJoining}</label>
          <span className="required">*</span>
          <input
            type="date"
            max={moment().format("YYYY-MM-DD")}
            value={employeeDetails.dateOfJoining}
            className="form-control"
            onChange={(e) =>
              setEmployeeDetails({
                ...employeeDetails,
                dateOfJoining: e.target.value.replace(/^\s+/, ""),
              })
            }
          />
        </div>
        <div className="col-md-6 mt-3">
          <label className="form-label">{reportingManagerName}</label>
          <span className="required">*</span>
          <div style={{ height: "1rem" }}>
            <Select
              className="dropdown-select"
              options={managerOptions}
              isSearchable={true}
              onChange={handleReportingManager}
            />
          </div>
        </div>
      </div>
      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button className="btn cancel-btn buttonsize" onClick={hideAddModal}>
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleEmployeeSubmit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
