import { useEffect, useState } from "react";
import MainCard from "../../../component/MainCard/MainCard";
import { useDispatch, useSelector } from "react-redux";
import useLoadingIndicator from "../../../component/Loader/Loader";
import * as actions from "../../../Redux/Actions";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import ViewAllModulesComponent from "./ViewAllModulesComponent";
import BackButton from "../../../component/Buttons/BackButton";
import { Strings } from "../../../constant/Strings";

function ViewAllModule() {
  const [info, setInfo] = useState<any>([]);
  const dispatch = useDispatch();
  const { all, modules, module_master } = Strings;
  let { id } = useParams();
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const editModule = useSelector(
    (state: any) => state.employeeReducer.editInseedModuleDetails
  );
  const deleteModule = useSelector(
    (state: any) => state.employeeReducer.deleteInseedModuleDetails
  );
  useEffect(() => {
    showLoadingIndicator();
    dispatch(
      actions.getAllModulesByTrainingIdAction((res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              hideLoadingIndicator();
            }
          }
        }
      }, id)
    );
  }, [editModule, deleteModule]);
  const moduleData = useSelector(
    (state: any) => state.employeeReducer.getAllModulesByTrainingIdData
  );
  useEffect(() => {
    if (moduleData && moduleData.data) {
      setInfo(moduleData.data[0]?.ModuleData);
    }
  }, [moduleData, info]);

  return (
    <>
      <div>
        {loadingComponent()}

        <MainCard>
          <div className="heading-button-wrapper mb-3">
            <div>
              <h2>{module_master}</h2>
            </div>
          </div>
          <Card className="p-3">
            <BackButton navigateTo={"/training-schedule"} />
            <div className="project-name">{all + " " + modules}</div>
          </Card>

          {info.length > 0
            ? info.map((ele: any, index: any) => (
                <Card key={index} className="mt-4">
                  <ViewAllModulesComponent
                    moduleTitle={ele?.moduleName}
                    trainer_name_value={ele?.trainerName}
                    technology_stream_value={ele?.technologyStream}
                    duration_value={ele?.duration}
                    trainer_contact_value={ele?.trainerContactNumber}
                    trainer_institute_value={ele?.instituteName}
                    successCriteria={ele?.successCriteria}
                    syllabus={ele?.syllabus}
                  />
                </Card>
              ))
            : ""}
        </MainCard>
      </div>
    </>
  );
}

export default ViewAllModule;
