import { Card } from "react-bootstrap";
import { Strings } from "../../constant/Strings/index";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assets/index";
import "./ProjectDetailsTab.scss";

const ProjectDetailsTab = (props: any) => {
  const {
    navigateTo,
    tabHeading,
    headingValue,
    firstColumnHeadings,
    firstColumnValues,
    secondColumnHeadings,
    secondColumnValues,
  } = props;
  const { back } = Strings;
  const navigate = useNavigate();
  return (
    <>
      <Card className="invoice-card p-2">
        <div className="row">
          <div className="d-flex flex-row justify-content-between">
            <div className="col-md-2">
              <div
                className="d-flex flex-row jutify-content-between back-button"
                onClick={() => navigate(navigateTo)}
              >
                <img
                  src={icons.backIcon}
                  className="backIcon"
                  alt="back button"
                />
                <p className="back-text mx-2">{back}</p>
              </div>
              <div>
                <div className="project-heading">{tabHeading}:</div>
                <div className="project-name">{headingValue}</div>
              </div>
            </div>
            <div className="col-md-6 mx-6">
              <div className="row">
                <div className="col-md-4">
                  {firstColumnHeadings &&
                    firstColumnHeadings.map((element: any, index: any) => {
                      return (
                        <div key={index} className="invoice-details-label">
                          {element}
                        </div>
                      );
                    })}
                </div>
                <div className="col-md-7">
                  {firstColumnValues &&
                    firstColumnValues.map((element: any, index: any) => {
                      return (
                        <div key={index} className="invoice-wrap-details-values">
                          {element}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-md-3 mx-0">
              <div className="row">
                <div className="col-md-5">
                  {secondColumnHeadings &&
                    secondColumnHeadings.map((element: any, index: any) => {
                      return (
                        <div key={index} className="invoice-details-label">
                          {element}
                        </div>
                      );
                    })}
                </div>
                <div className="col-md-7">
                  {secondColumnValues &&
                    secondColumnValues.map((element: any, index: any) => {
                      return (
                        <div key={index} className="invoice-details-values">
                          {element}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ProjectDetailsTab;
