import { useEffect, useState } from "react";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions";
const ViewAssessmentDetails = (props: any) => {
  const { viewDetailsId, loginId } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (viewDetailsId) {
      const obj = {
        assessmentId: viewDetailsId,
        employeeId: loginId,
      };
      dispatch(
        actions.getAssessmentListByIdAction(obj, (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
          } else {
            if (res?.code === 200) {
              setData(res.data);
            }
          }
        })
      );
    }
  }, []);
  const adjustHeight = (element: any) => {
    element.style.height = "auto";
    const newHeight = element.scrollHeight;
    element.style.height = newHeight + "px";
  };
  return (
    <div>
      {data.map((item: any) => (
        <div key={item.id} className="my-assessment-container">
          <div className="questions-heading-assessment">
            {item.questionType} :
          </div>
          {item.questionData.map((questionData: any) => {
            return (
              <div key={questionData._id}>
                <div className="question-label">{questionData.question}</div>
                {/* <input
                            disabled
                            type="text"
                            className="form-control"
                            value={questionData?.answerData[0]?.assessment?.answer}
                        /> */}
                <textarea
                  disabled
                  className="form-control"
                  value={questionData?.answerData[0]?.assessment?.answer}
                  rows={1}
                  style={{ overflow: "hidden", resize: "none" }}
                  ref={(textarea) => {
                    if (textarea) {
                      adjustHeight(textarea);
                    }
                  }}
                />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
export default ViewAssessmentDetails;
