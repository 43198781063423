import React, { useState } from 'react'
import MainCard from '../../../component/MainCard/MainCard'
import BeaCoachTable from './BeACoachTable';
import { ToastContainer } from 'react-toastify';

const BeACoach = ()  => {
   
  return (
    <div>
       <MainCard>
      <BeaCoachTable/>
      </MainCard>
      <ToastContainer />
    </div>
  )
}

export default BeACoach