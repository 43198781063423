import React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import "./ProjectRepository.scss";
import {
  projectRepoFilters,
  Strings,
  projectRepoExportReport,
} from "../../../constant/Strings/index";
import * as XLSX from "xlsx";
import { icons } from "../../../assets/index";
import SearchBar from "../../../component/SearchBar/SearchBar";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import Button from "../../../component/Buttons/Button";
import * as actions from "../../../Redux/Actions/index";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import AddProject from "./ProjectRepositoryFunctions/AddProject";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import { TiArrowUnsorted } from "react-icons/ti";
import EditProject from "./ProjectRepositoryFunctions/EditProject";
import DeleteProject from "./ProjectRepositoryFunctions/DeleteProject";
import { Link } from "react-router-dom";
import TablePagination from "../../../component/TablePagination/TablePagination";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import MainCard from "../../../component/MainCard/MainCard";
import "../ResourceManagement.scss";
import "../ProjectRepository/ProjectRepository.scss";
import useLoadingIndicator from "../../../component/Loader/Loader";
import AddProjectUpload from "./ProjectRepositoryFunctions/AddProjectUpload";
import { timeDateFormat } from "../../../component/FormatDate";
import { Table } from "react-bootstrap";
import { reach } from "yup";

const ProjectrepositoryTable = (props: any) => {
  const {
    hideLoadingIndicator,
    showLoadingIndicator,
    loadingComponent,
    apiCallCompleted,
  } = useLoadingIndicator();
  const role = localStorage.getItem("role");
  const {
    hamburger_icon,
    export_report,
    template_icon,
    bulk_upload_icon,
    edit_blue_icon,
    delete_icon,
    arrow_drop_down,
  } = icons;
  const {
    totalPages,
    onPage,
    handlePageChange,
    searchValue,
    handleSearch,
    filterValue,
    filterChange,
    pagePerSize,
    totalEntries,
    setPagePerSize,
    onSearchClicked,
    editProjectDetails,
    filterKey,
  } = props;
  const {
    exportReport,
    template,
    bulk_upload,
    noData,
    errorAccess,
    project_repo,
    projectRepo,
  } = Strings;
  const [showExportReport, setShowExportReport] = useState(false);
  const [showAddProject, setShowAddProject] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showDeleteProject, setShowDeleteProject] = useState(false);
  const [showEditProject, setShowEditProject] = useState(false);
  const [expansion, setExpansion] = useState(false);
  const [projectDetails, setProjectDetails] = useState({
    id: "",
    geography: "",
    projectName: "",
    location: "",
    client: "",
    tdm: "",
    hrbp: "",
    status: "",
    projectType: "",
    techType: "",
    remarks: "",
    poValueIn: "",
    billingRate: "",
    poNumber: "",
    approvedHc: "",
    actualHc: "",
    poAmount: "",
    monthlyBilling: "",
    projectCode: "",
    subProjectCode: "",
    subProjectName: "",
    uploadPoNumber: "",
    projectStartDate: "",
    projectEndDate: "",
    poDate: "",
    poStartDate: "",
    poEndDate: "",
    msa: "",
    sow: "",
    recordStatus: "",
    projectDescription: "",
  });
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [rowid, setRowid] = useState("");
  const setnextScreen = (value: any) => {
    if (value == true) {
      setShowUploadModal(true);
    } else {
      setShowUploadModal(false);
    }
  };
  const checkerData = role?.includes("rmg-checker");
  const makerData = role?.includes("rmg-maker");
  const pmoRole = role?.includes("rmg-pmo");

  const hideAddProject = () => {
    setShowAddProject(false);
  };

  const hideEditProject = () => {
    setShowEditProject(false);
  };

  const handleDeleteProject = (id: any) => {
    setDeleteId(id);
    setShowDeleteProject(true);
  };
  const hideDeleteProject = () => {
    setShowDeleteProject(false);
  };

  const projectsDetails = useSelector(
    (state: any) => state.employeeReducer.getProjectDetails
  );

  const [data, setdata] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const dispatch = useDispatch();

  const COLUMNS: any = [
    {
      Header: "Region",
      accessor: "geography",
    },
    {
      Header: "Client",
      accessor: "clientName",
    },
    {
      Header: "Project Description",
      accessor: "projectDescription",
    },
    {
      Header: "Project Type",
      accessor: "projectType",
    },
    {
      Header: "Sub Project Code",
      accessor: "subProjectCode",
    },
    {
      Header: "BU Head",
      accessor: "tdm",
    },
    {
      Header: "HRBP",
      accessor: "hrbp",
    },
    {
      Header: "Actual HC",
      accessor: "actualHc",
    },
    {
      Header: "Approved HC",
      accessor: "approvedHc",
    },
    {
      Header: "PO Status",
      accessor: "status",
    },
    {
      Header: "Status",
      accessor: "recordStatus",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];
  const PMOCOLUMNS: any = [
    {
      Header: "Region",
      accessor: "geography",
    },
    {
      Header: "Client",
      accessor: "clientName",
    },
    {
      Header: "Project Description",
      accessor: "projectDescription",
    },
    {
      Header: "Project Type",
      accessor: "projectType",
    },
    {
      Header: "Sub Project Code",
      accessor: "subProjectCode",
    },
    {
      Header: "BU Head",
      accessor: "tdm",
    },
    {
      Header: "HRBP",
      accessor: "hrbp",
    },
    {
      Header: "Actual HC",
      accessor: "actualHc",
    },
    {
      Header: "Approved HC",
      accessor: "approvedHc",
    },
    {
      Header: "PO Status",
      accessor: "status",
    },
    {
      Header: "Status",
      accessor: "recordStatus",
    },
  ];
  const columns = useMemo(
    () => (checkerData || makerData ? COLUMNS : PMOCOLUMNS),
    [checkerData, makerData, pagePerSize]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pagePerSize,
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  useEffect(() => {
    if (projectsDetails && projectsDetails.data) {
      setdata(projectsDetails.data);
      setTotalRecords(projectsDetails.data.length);
    }
  }, [projectsDetails, editProjectDetails, data, pagePerSize, pageSize]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleBulkUpload = (e: any) => {
    if (e.target.files?.length > 0) {
      showLoadingIndicator();
      dispatch(
        actions.ProjectBulkUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                hideLoadingIndicator();
                const uploadedCount = res.meta_data.uploadedRecordsCount;
                const faultRecordsCount = res.meta_data.faultRecordsCount;
                if (faultRecordsCount > 0) {
                  var moduleMasterData: any = [
                    [
                      "Geography",
                      "Location",
                      "Client Name",
                      "Project Name",
                      "Project Type",
                      "Project Code",
                      "Sub Project Code",
                      "Service Line",
                      "TDM",
                      "HRBP",
                      "On-Boarding Date",
                      "Off-Boarding Date",
                      "PO Status",
                      "PO No.",
                      "PO Start Date",
                      "PO End Date",
                      "Approved HC",
                      "Currency Type",
                      "Billing Status",
                      "Billing Rate",
                      "Client SPOC Person Name",
                      "Client SPOC Contact Number",
                      "Client SPOC Email ID",
                      "Remarks",
                    ],
                  ];
                  res?.data?.forEach((item: any) => {
                    let emptyArray: any = [];
                    emptyArray?.push(
                      item.geography,
                      item.location,
                      item.clientName,
                      item.projectName,
                      item.projectType,
                      item.projectCode,
                      item.subProjectCode,
                      item.techType,
                      item.tdm,
                      item.hrbp,
                      item.projectStartDate,
                      item.projectEndDate,
                      item.status,
                      item.poNumber,
                      item.poStartDate,
                      item.poEndDate,
                      item.approvedHc,
                      item.poValueIn,
                      item.billingRate,
                      item.poAmount,
                      item.spocPersonName,
                      item.spocNumber,
                      item.spocEmail,
                      item.remarks
                    );
                    moduleMasterData?.push(emptyArray);
                  });
                  var projectRepository =
                    XLSX.utils.aoa_to_sheet(moduleMasterData);
                  const wb = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(
                    wb,
                    projectRepository,
                    projectRepo
                  );
                  XLSX.writeFile(wb, "Fault_Records_Project_Repository.xlsx");
                }
                if (fileInputRef.current) {
                  fileInputRef.current.value = "";
                }
                if (uploadedCount > 0 && faultRecordsCount === 0)
                  showSuccessToastMessage("Uploaded Successfully");
                if (faultRecordsCount > 0 && uploadedCount > 0)
                  showSuccessToastMessage(
                    `Uploaded ${uploadedCount} Records and Downloaded ${faultRecordsCount} Fault Records`
                  );
                if (uploadedCount === 0 && faultRecordsCount > 0)
                  showErrorToastMessage(
                    `Downloaded ${faultRecordsCount} Fault Records`
                  );
                if (uploadedCount === 0 && faultRecordsCount === 0)
                  showErrorToastMessage("Please Upload Valid Document");
              }
            }
          }
        )
      );
    }
  };

  function handleExpansion(id: any) {
    setRowid(id);
    setExpansion(!expansion);
  }

  const handleEditProjectDetails = (
    id: any,
    geography: any,
    projectName: any,
    location: any,
    client: any,
    tdm: any,
    hrbp: any,
    status: any,
    projectType: any,
    techType: any,
    remarks: any,
    poValueIn: any,
    billingRate: any,
    poNumber: any,
    approvedHc: any,
    poAmount: any,
    monthlyBilling: any,
    projectCode: any,
    subProjectCode: any,
    subProjectName: any,
    uploadPoNumber: any,
    projectStartDate: any,
    projectEndDate: any,
    poDate: any,
    poStartDate: any,
    poEndDate: any,
    msa: any,
    sow: any,
    recordStatus: any,
    spocPersonName: any,
    spocNumber: any,
    spocEmail: any,
    clientRef: any,
    actualHc: any,
    projectDescription: any
  ) => {
    setProjectDetails((obj: any) => ({
      ...obj,
      id: id,
      geography: geography,
      projectName: projectName,
      location: location,
      client: client,
      tdm: tdm,
      hrbp: hrbp,
      status: status,
      projectType: projectType,
      techType: techType,
      remarks: remarks,
      poValueIn: poValueIn,
      billingRate: billingRate,
      poNumber: poNumber,
      approvedHc: approvedHc,
      poAmount: poAmount,
      monthlyBilling: monthlyBilling,
      projectCode: projectCode,
      subProjectCode: subProjectCode,
      subProjectName: subProjectName,
      uploadPoNumber: uploadPoNumber,
      projectStartDate: projectStartDate,
      projectEndDate: projectEndDate,
      poDate: poDate,
      poStartDate: poStartDate,
      poEndDate: poEndDate,
      msa: msa,
      sow: sow,
      recordStatus: recordStatus,
      spocPersonName: spocPersonName,
      spocNumber: spocNumber,
      spocEmail: spocEmail,
      clientRef: clientRef,
      actualHc: actualHc,
      projectDescription: projectDescription,
    }));
    setShowEditProject(true);
  };
  const handleAddProject = () => {
    if (makerData) {
      setShowAddProject(true);
    } else {
      showErrorToastMessage(errorAccess);
    }
  };

  const generateXLFile = () => {
    showLoadingIndicator();
    dispatch(
      actions.getProjectRepositoryAction(
        totalEntries,
        1,
        filterKey,
        searchValue,
        (res: any) => {
          if (res.code === 200) {
            if (res?.data.length > 0) {
              var moduleMasterData = [projectRepoExportReport];
              res?.data?.forEach((item: any) => {
                const updatedInfo = item?.updatedByInfo
                  ? item?.updatedByInfo[0]
                  : "";
                const approvedInfo = item?.approvedByInfo
                  ? item?.approvedByInfo[0]
                  : "";
                const updatedByName = item?.updatedByInfo
                  ? (updatedInfo?.candidateName
                      ? updatedInfo?.candidateName
                      : "") +
                    " " +
                    (updatedInfo?.middleName ? updatedInfo?.middleName : "") +
                    " " +
                    (updatedInfo?.lastname ? updatedInfo?.lastname : "")
                  : "";
                const updatedById = item?.updatedByInfo
                  ? updatedInfo?.employeeId
                  : "";
                const approvedByName = item?.approvedByInfo
                  ? (approvedInfo?.candidateName
                      ? approvedInfo?.candidateName
                      : "") +
                    " " +
                    (approvedInfo?.middleName ? approvedInfo?.middleName : "") +
                    " " +
                    (approvedInfo?.lastname ? approvedInfo?.lastname : "")
                  : "";
                const approvedById = item.approvedByInfo
                  ? approvedInfo?.employeeId
                  : "";
                const tdm = `${item?.buHeadData?.[0]?.candidateName ?? ""} ${item?.buHeadData?.[0]?.middleName ?? ""
                } ${item?.buHeadData?.[0]?.lastname ?? ""}`;
                const hrbp = `${item?.hrbpData?.[0]?.candidateName ?? ""} ${item?.hrbpData?.[0]?.middleName ?? ""
                } ${item?.hrbpData?.[0]?.lastname ?? ""}`
                let emptyArray: string[] = [];
                emptyArray.push(
                  item.geography,
                  item.Client.map((ele: { clientName: any }) => ele.clientName),
                  item.projectName,
                  item?.projectDescription,
                  item.projectType,
                  item.projectCode,
                  item.subProjectCode,
                  item.techType,
                  tdm,
                  hrbp,
                  item.projectStartDate
                    ? timeDateFormat(item.projectStartDate)
                    : "",
                  item.projectEndDate
                    ? timeDateFormat(item.projectEndDate)
                    : "",
                  item.status,
                  item.poNumber,
                  item.poStartDate ? timeDateFormat(item.poStartDate) : "",
                  item.poEndDate ? timeDateFormat(item.poEndDate) : "",
                  item.approvedHc,
                  item.actualHc,
                  item.poValueIn,
                  item.billingRate,
                  item.poAmount,
                  item.spocPersonName,
                  item.spocNumber,
                  item.spocEmail,
                  item?.recordStatus,
                  updatedByName,
                  updatedById,
                  item.updatedDate ? timeDateFormat(item.updatedDate) : "",
                  approvedByName,
                  approvedById,
                  item?.approvedDate ? timeDateFormat(item?.approvedDate) : "",
                  item.remarks
                );
                moduleMasterData.push(emptyArray);
              });
              var projectRepository = XLSX.utils.aoa_to_sheet(moduleMasterData);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, projectRepository, projectRepo);
              hideLoadingIndicator();
              XLSX.writeFile(wb, "Project_Repository.xlsx");
            }
          }
        }
      )
    );
  };
  const exportReportRef = useRef<HTMLInputElement | null>(null);
  const imgIcon = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        exportReportRef.current &&
        !exportReportRef.current.contains(event.target as Node)
      ) {
        if (
          imgIcon.current &&
          !imgIcon.current.contains(event.target as Node)
        ) {
          setShowExportReport(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showExportReport]);
  return (
    <>
      {loadingComponent()}
      <MainCard>
        <div className="heading-button-wrapper">
          <div>
            <h2>{project_repo}</h2>
          </div>
        </div>
        <div className="mt-2 mr-2">
          <div className="mt-3 buttonsize filter-wrapper">
            <div className="searchbar-wrapper">
              <SearchBar
                filter={searchValue}
                setFilter={handleSearch}
                onSearchClicked={onSearchClicked}
              />
            </div>

            <div className="filter-container mx-2">
              <select
                id="select-filters"
                value={filterValue}
                onChange={(e: any) => {
                  const selectedValue = e.target.value;
                  const selectedOption = projectRepoFilters.find(
                    (item: any) => item.value === selectedValue
                  );
                  if (selectedOption) {
                    filterChange(selectedOption);
                  }
                }}
              >
                <option hidden>Filters</option>
                {projectRepoFilters.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
            {makerData && (
              <div className="btn-wrapper">
                <Button
                  title="+ Add New Project"
                  onClick={() => {
                    makerData
                      ? setShowAddProject(true)
                      : showErrorToastMessage(errorAccess);
                  }}
                  className="add-projectRepo-btn"
                />
              </div>
            )}
            <div className="export-report-wrapper mx-2">
              <img
                src={hamburger_icon}
                onClick={() => setShowExportReport(!showExportReport)}
                alt="hamburger_icon"
                height={30}
                width={30}
                ref={imgIcon}
              />
              {showExportReport && (
                <div className="report-btn-wrapper" ref={exportReportRef}>
                  <>
                    <div className="export-btn">
                      <button onClick={generateXLFile}>
                        <img src={export_report} alt="export_report" />{" "}
                        {exportReport}
                      </button>
                    </div>
                  </>
                  {makerData && (
                    <>
                      <div className="export-btn">
                        <a
                          href="https://management-spotlight.sgp1.digitaloceanspaces.com/spotlight/Project_Repository_Bulk_Upload_Template3.csv"
                          className="text-decoration-none"
                          target="_blank"
                        >
                          <button>
                            <img src={template_icon} alt="template_icon" />{" "}
                            {template}
                          </button>
                        </a>
                      </div>
                      <div className="export-btn">
                        <button>
                          <img src={bulk_upload_icon} alt="bulk_upload_icon" />{" "}
                          {bulk_upload}
                        </button>
                        <input
                          type="file"
                          accept={".csv"}
                          onChange={handleBulkUpload}
                          id="bulk_upload"
                          ref={fileInputRef}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
        />
        {data.length > 0 ? (
          <>
            <div className="table table-responsive">
              <Table className="table-margin">
                <thead>
                  <tr className="custom-table-head-row">
                    {columns.map((ele: any) => (
                      <React.Fragment key={ele.Header}>
                        <td className="sorting-cursor">{ele.Header}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>

                <tbody className="employeedata">
                  {data?.length === 0 ? (
                    <tr>
                      <td colSpan={12}>{noData}</td>
                    </tr>
                  ) : (
                    data.map((ele: any, index: any) => (
                      <>
                        <tr key={ele?._id}>
                          <td
                            onClick={() => handleExpansion(index)}
                            className="text-start"
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <img
                                src={arrow_drop_down}
                                className={
                                  index === rowid
                                    ? expansion
                                      ? "rotate-icon"
                                      : ""
                                    : ""
                                }
                                alt="icon"
                              />
                              <span>&nbsp;{ele?.geography}</span>
                            </div>
                          </td>
                          <td>
                            {ele?.Client[0].clientName && (
                              <Link to={`/invoices/${ele._id}`}>
                                <div className="project-repo-anchor-tag">
                                  {ele?.Client[0].clientName}
                                </div>
                              </Link>
                            )}
                          </td>
                          <td>{ele?.projectDescription}</td>
                          <td>{ele?.projectType}</td>
                          <td>{ele?.subProjectCode}</td>
                          <td>{ele?.buHeadData?.[0]?.candidateName ?? ""} {ele?.buHeadData?.[0]?.middleName ?? ""
            } {ele?.buHeadData?.[0]?.lastname ?? ""}</td>
                          <td>{ele?.hrbpData?.[0]?.candidateName ?? ""} {ele?.hrbpData?.[0]?.middleName ?? ""
            } {ele?.hrbpData?.[0]?.lastname ?? ""}</td>
                          <td>{ele?.actualHc}</td>
                          <td>{ele?.approvedHc}</td>
                          <td>
                            {ele?.status && (
                              <button
                                type="button"
                                className={
                                  ele?.status === "Ongoing"
                                    ? "btn projectRepo-status-btn projectRepo-ongoing-btn"
                                    : ele?.status === "New"
                                    ? "btn projectRepo-status-btn projectRepo-new-btn"
                                    : ele?.status === "Delivered"
                                    ? "btn projectRepo-status-btn projectRepo-delivered-btn"
                                    : ele?.status === "Closed"
                                    ? "btn projectRepo-status-btn projectRepo-closed-btn"
                                    : ele?.status === "On Hold"
                                    ? "btn projectRepo-status-btn projectRepo-onHold-btn"
                                    : ele?.status === "Extended"
                                    ? "btn projectRepo-status-btn projectRepo-updated-btn"
                                    : ele?.status === "No Extension"
                                    ? "btn projectRepo-status-btn projectRepo-rejected-btn"
                                    : ele?.status === "Conversion"
                                    ? "btn projectRepo-status-btn projectRepo-approved-btn"
                                    : ""
                                }
                              >
                                {ele?.status}
                              </button>
                            )}
                          </td>
                          <td>
                            <button
                              type="button"
                              className={
                                ele?.recordStatus === "New"
                                  ? "btn projectRepo-status-btn projectRepo-new-btn"
                                  : ele?.recordStatus === "Approved"
                                  ? "btn projectRepo-status-btn projectRepo-approved-btn"
                                  : ele?.recordStatus === "Rejected"
                                  ? "btn projectRepo-status-btn projectRepo-rejected-btn"
                                  : ele?.recordStatus === "Updated"
                                  ? "btn projectRepo-status-btn projectRepo-updated-btn"
                                  : ""
                              }
                            >
                              {ele?.recordStatus}
                            </button>
                          </td>
                          {(checkerData || makerData) && 
                          <td>
                                <div className="action-col">
                                  <div className="action-btn">
                                    <img
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="Edit"
                                      src={edit_blue_icon}
                                      alt="edit_icon"
                                      className="edit-icon"
                                      onClick={() =>{
                                        handleEditProjectDetails(
                                          ele?._id,
                                          ele?.geography,
                                          ele?.projectName,
                                          ele?.location,
                                          ele?.Client[0].clientName,
                                          ele?.buHeadData?.[0]?._id,
                                          ele?.hrbpData?.[0]?._id,
                                          ele?.status,
                                          ele?.projectType,
                                          ele?.techType,
                                          ele?.remarks,
                                          ele?.poValueIn,
                                          ele?.billingRate,
                                          ele?.poNumber,
                                          ele?.approvedHc,
                                          ele?.poAmount,
                                          ele?.monthlyBilling,
                                          ele?.projectCode,
                                          ele?.subProjectCode,
                                          ele?.subProjectName,
                                          ele?.uploadPoNumber,
                                          ele?.projectStartDate,
                                          ele?.projectEndDate,
                                          ele?.poDate,
                                          ele?.poStartDate,
                                          ele?.poEndDate,
                                          ele?.msa,
                                          ele?.sow,
                                          ele?.recordStatus,
                                          ele?.spocPersonName,
                                          ele?.spocNumber,
                                          ele?.spocEmail,
                                          ele?.clientRef,
                                          ele?.actualHc,
                                          ele?.projectDescription
                                        )
                                      }
                                      }
                                    />
                                    <img
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="Delete"
                                      src={delete_icon}
                                      alt="delete-icon"
                                      className="edit-icon"
                                      onClick={() =>
                                        handleDeleteProject(ele?._id)
                                      }
                                    />
                                  </div>
                                </div>
                          </td>
                           }
                        </tr>
                        {index === rowid && expansion && (
                          <tr>
                            <td colSpan={12}>
                              <div className="d-flex justify-content-start">
                                <div className="mx-4">
                                  <div className="invoice_heading">
                                    Project Code
                                  </div>
                                  <div>{ele?.projectCode}</div>
                                </div>
                                <div className="mx-4">
                                  <div className="invoice_heading">
                                    PO Number
                                  </div>
                                  <div>{ele?.poNumber}</div>
                                </div>
                                <div className="mx-4">
                                  <div className="invoice_heading">Remarks</div>
                                  <div>{ele?.remarks}</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))
                  )}
                </tbody>
              </Table>
              <div className="show-entries-wrapper">
                <ShowEntries
                  pageSize={pagePerSize}
                  setPageSize={setPagePerSize}
                  totalRecords={totalRecords}
                  totalEntries={totalEntries}
                />

                <TablePagination
                  totalPages={totalPages}
                  currentPage={onPage}
                  onClick={handlePageChange}
                />
              </div>
            </div>
          </>
        ) : (
          <p className="projectRepoData mt-4">{noData}</p>
        )}

        <ModalComponents
          show={showAddProject}
          onHide={hideAddProject}
          title="Add New Project"
          modalBody={
            <AddProject
              setnextScreen={setnextScreen}
              apiCallCompleted={apiCallCompleted}
              handleAddProject={handleAddProject}
              projectDetails={projectDetails}
              hideAddProject={hideAddProject}
            />
          }
          className="modal-lg"
        />

        <ModalComponents
          show={showEditProject}
          onHide={hideEditProject}
          title="Edit Project Details"
          modalBody={
            <EditProject
              apiCallCompleted={apiCallCompleted}
              projectDetails={projectDetails}
              hideEditProject={hideEditProject}
            />
          }
          className="modal-lg"
        />

        <ModalComponents
          show={showDeleteProject}
          onHide={hideDeleteProject}
          title="Delete Project Details"
          modalBody={
            <DeleteProject
              id={deleteId}
              hideDeleteProject={hideDeleteProject}
            />
          }
          className="modal-lg"
        />

        <ModalComponents
          show={showUploadModal}
          onHide={setnextScreen}
          title="Upload Project Documents"
          modalBody={
            <AddProjectUpload
              setnextScreen={setnextScreen}
              apiCallCompleted={apiCallCompleted}
            />
          }
          className="modal-lg"
        />
      </MainCard>
    </>
  );
};

export default ProjectrepositoryTable;
