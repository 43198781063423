import { useDispatch } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";

const FinanceFinalApprove = (props: any) => {
  const {
    hideApproval,
    amount,
    requestId,
    financeApprovalObj,
    apiCallCompleted,
  } = props;

  const dispatch = useDispatch();

  const handleSubmit = () => {
    apiCallCompleted(false);
    dispatch(
      actions.updateStatusRequestAction(
        requestId,
        financeApprovalObj,
        (res: any) => {
          if (res) {
            if (res.error) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            } else {
              showSuccessToastMessage("Approved Successfully!");
              apiCallCompleted(true);
            }
          }
        }
      )
    );
    hideApproval();
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 mt-2 mb-3">
          <label className="form-label">
            Prior to confirming, please verify that SkillMine will provide the
            employee with <b>₹{amount} rupees.</b>
            <br /> This confirmation message will be sent to the employee
            accordingly.
          </label>
        </div>
      </div>
      <div className="modal-btn-wrapper mt-5 mx-auto">
        <div>
          <button className="btn cancel-btn buttonsize" onClick={hideApproval}>
            Cancel
          </button>
        </div>
        <div>
          <button
            className="btn add-submit-button buttonsize"
            onClick={handleSubmit}
          >
            Send & Accept
          </button>
        </div>
      </div>
    </>
  );
};

export default FinanceFinalApprove;
