import React, { useEffect, useState } from "react";
import * as actions from "../../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../../component/Toasts/Toasts";
import { Strings, requiredFeild } from "../../../../constant/Strings";
import {
  validateAadhar,
  validatePAN,
  validatePassport,
  whiteSpaceValid,
} from "../../../../constant/Validations";

const EditDocumentInfo = (props: any) => {
  const {
    pan,
    aadharNumber,
    passportNumber,
    handleEditDocumentInfo,
    eId,
    aadharCardImage,
    panCardImage,
    status,
    apiCallCompleted,
  } = props;
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const { required } = requiredFeild;
  const {
    pan_number,
    aadhar_no,
    passport_no,
    editSuccess,
    cancel,
    submit,
    valid_pan_no,
    valid_aadhar_no,
    valid_passport_no,
    uploadFile,
    imageSize2mb,
  } = Strings;

  const [employeeData, setEmployeeData] = useState({
    pan: pan,
    aadharNumber: aadharNumber,
    passportNumber: passportNumber,
    eId: eId,
    updatedBy: eId,
    aadharCardImage: aadharCardImage,
    panCardImage: panCardImage,
  });

  const [errors, setErrors] = useState({
    pan: "",
    aadharNumber: "",
    passportNumber: "",
  });

  const [btnDisable, setBtnDisable] = useState(false);

  const [docFile, setDocFile] = useState<any>({
    aadharCardImage: null,
    panCardImage: null,
  });

  const handleSubmitaadharCardImage = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, aadharCardImage: e.target.files[0] });
      dispatch(
        actions.employeeDetailsDocUploadAction(
          { file: e.target.files[0], eId: employeeData.eId },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setEmployeeData({
                ...employeeData,
                aadharCardImage: res.data,
              });
            }
          }
        )
      );
    }
  };

  const handleSubmitPanCardImage = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileType = file.type;
    if (fileSize > 2 * 1024 * 1024) {
      showErrorToastMessage("File size too large. Maximum size is 2 MB.");
      return;
    } else if (
      ![
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ].includes(fileType)
    ) {
      showErrorToastMessage(
        "File type not allowed. Allowed types are JPEG, DOC, and PDF."
      );
      return;
    } else {
      setDocFile({ ...docFile, panCardImage: e.target.files[0] });
      dispatch(
        actions.employeeDetailsDocUploadAction(
          { file: e.target.files[0] },
          (res: any) => {
            if (res.code == 200 && res.data) {
              setEmployeeData({
                ...employeeData,
                panCardImage: res.data,
              });
            }
          }
        )
      );
    }
  };

  const dispatch = useDispatch();
  let valid: boolean = true;
  const validate = () => {
    if (
      validatePAN(employeeData.pan) == false ||
      whiteSpaceValid(employeeData.pan) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        pan: valid_pan_no,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, pan: "" }));
    }
    if (
      validateAadhar(employeeData.aadharNumber) == false ||
      whiteSpaceValid(employeeData.aadharNumber) == false
    ) {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        aadharNumber: valid_aadhar_no,
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({ ...errorObj, aadharNumber: "" }));
    }
    if (employeeData.passportNumber) {
      if (
        validatePassport(employeeData.passportNumber) == false ||
        whiteSpaceValid(employeeData.passportNumber) == false
      ) {
        valid = false;
        setErrors((errorObj) => ({
          ...errorObj,
          passportNumber: valid_passport_no,
        }));
        setBtnDisable(false);
      } else {
        setErrors((errorObj) => ({ ...errorObj, passportNumber: "" }));
      }
    }
  };

  const handleEdit = () => {
    validate();
    if (valid) {
      handleDispatch();
    }
  };

  const handleDispatch = () => {
    apiCallCompleted(false);
    dispatch(
      actions.editEmployeeDataAction(employeeData, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
              apiCallCompleted(true);
            }
          } else {
            if (res.code === 200) {
              showSuccessToastMessage(editSuccess);
              apiCallCompleted(true);
            }
          }
        }
      })
    );
    handleEditDocumentInfo();
  };

  useEffect(() => {
    if (
      employeeData.pan &&
      employeeData.aadharNumber &&
      employeeData.aadharCardImage &&
      employeeData.panCardImage
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  });
  const disableCondition: any =
    (!(role && role.includes("hr")) && status == "Active") ||
    status == "Inactive" ||
    (role && role.includes("hr") && status == "Active" && eId == loginId);
  return (
    <div>
      <div className="row g-4">
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {pan_number} <span className="required">{required}</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.pan.toUpperCase()}
            disabled={disableCondition}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                pan: e.target.value,
              });
            }}
          />
          <p className="error">{errors.pan}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {aadhar_no} <span className="required">{required}</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.aadharNumber}
            disabled={disableCondition}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                aadharNumber: e.target.value,
              });
            }}
          />

          <p className="error">{errors.aadharNumber}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="inputName4" className="form-label">
            {passport_no}
          </label>
          <input
            type="text"
            className="form-control"
            value={employeeData.passportNumber.toUpperCase()}
            onChange={(e) => {
              setEmployeeData({
                ...employeeData,
                passportNumber: e.target.value,
              });
            }}
          />
          <p className="error">{errors.passportNumber}</p>
        </div>

        <div className="col-md-4">
          <label className="form-label">Upload PAN Card</label>
          <span className="required">*</span>
          {!disableCondition && (
            <>
              <div>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleSubmitPanCardImage}
                  hidden
                  id="actual-btn-pan"
                  disabled={disableCondition}
                />
                <label
                  htmlFor="actual-btn-pan"
                  className={
                    disableCondition
                      ? "btn btn-secondary disable-upload buttonsize"
                      : "btn btn-outline-secondary buttonsize"
                  }
                >
                  {uploadFile}
                </label>
              </div>
              <p className="image-size-condition">
                {imageSize2mb}
                <span className="required">*</span>
              </p>
            </>
          )}
          <a
            href={employeeData.panCardImage?.document_path_url}
            target="_blank"
            className="buttonsize"
          >
            {employeeData.panCardImage?.document_path_url ? "View File" : ""}
          </a>
          <p className="image-size-condition">{docFile.panCardImage?.name}</p>
        </div>
        <div className="col-md-4">
          <label className="form-label">Upload Aadhar Card</label>
          <span className="required">*</span>
          {!disableCondition && (
            <>
              <div>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleSubmitaadharCardImage}
                  hidden
                  id="actual-btn-aadhar"
                />
                <label
                  htmlFor="actual-btn-aadhar"
                  className="btn btn-outline-secondary buttonsize"
                >
                  {uploadFile}
                </label>
              </div>
              <p className="image-size-condition">
                {imageSize2mb}
                <span className="required">*</span>
              </p>
            </>
          )}
          <a
            href={employeeData.aadharCardImage?.document_path_url}
            target="_blank"
            className="buttonsize"
          >
            {employeeData.aadharCardImage?.document_path_url ? "View File" : ""}
          </a>
          <p className="image-size-condition">
            {docFile.aadharCardImage?.name}
          </p>
        </div>
      </div>
      <div className="modal-btn-wrapper mt-4 mb-4">
        <div>
          <button
            className="btn cancel-btn buttonsize"
            onClick={handleEditDocumentInfo}
          >
            {cancel}
          </button>
        </div>
        <div>
          <button
            className={
              btnDisable
                ? "btn add-submit-button buttonsize"
                : "btn add-submit-button btn-disabled buttonsize"
            }
            onClick={handleEdit}
          >
            {submit}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditDocumentInfo;
