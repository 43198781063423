import { Card, CardBody } from "reactstrap";
import { Strings } from "../../../constant/Strings/index";
import "../CoachingProgress/Coaching.scss";
import { icons } from "../../../assets/index";

const AssignSessionCard = (props: any) => {
  const {
    sessionNumber,
    topicName,
    sessionDate,
    dueDate,
    homeWork,
    onClick,
    status,
    employeeUploadFile,
    coachUploadFile,
    feedback,
    rating,
  } = props;
  const {
    session,
    sessionDateAndTime,
    dueDateAndTime,
    homeWorkLabel,
    ratingLabel,
    feedbackLabel,
    assignment,
    assignmentSubmission,
    StatusLabel,
  } = Strings;
  const { draft } = icons;

  return (
    <>
      <div className="mt-4">
        <Card>
          <CardBody>
            <div className="d-flex flex-row justify-content-between mx-2">
              <div>
                <p className="card-text mx-2">
                  <span className="card-text-label">
                    {session} {sessionNumber} :&nbsp;
                  </span>
                  {topicName}
                </p>
              </div>
              <div className="d-flex flex-row justify-content-between mx-2">
                <p className="card-text mx-2">
                  <span className="card-text-label">
                    {sessionDateAndTime} :&nbsp;
                  </span>
                  {sessionDate}
                </p>
                <p className="card-text mx-2">
                  <span className="card-text-label">
                    {dueDateAndTime} :&nbsp;
                  </span>
                  {dueDate}
                </p>
              </div>
            </div>
            <div className="mx-2">
              <p className="card-text mx-2">
                <span className="card-text-label">{homeWorkLabel} :&nbsp;</span>
                {homeWork}
              </p>
            </div>

            {(rating || feedback) && (
              <>
                <div className="mt-2 mx-2">
                  <p className="card-text mx-2">
                    <span className="card-text-label">{ratingLabel}&nbsp;</span>
                    {rating}
                  </p>
                </div>
                <div className="mt-2 mx-2">
                  <p className="card-text mx-2">
                    <span className="card-text-label">
                      {feedbackLabel}&nbsp;
                    </span>
                    {feedback}
                  </p>
                </div>
              </>
            )}

            <div className="mx-2 mt-2 d-flex flex-row justify-content-start">
              <div>
                <p className="card-text mx-2">
                  <span className="card-text-label">{StatusLabel} :&nbsp;</span>
                  <button
                    type="button"
                    className={`btn ${
                      status === "WIP"
                        ? "coahing-inprogress-status-wip"
                        : status === "Completed"
                        ? "coahing-inprogress-status-completed"
                        : status === "ReWork"
                        ? "coahing-inprogress-status-reworks"
                        : ""
                    }`}
                    onClick={onClick}
                  >
                    {status}
                  </button>
                </p>
              </div>
              <div>
                <a
                  className="card-text mx-4"
                  href={coachUploadFile?.document_path_url}
                  target="_blank"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="View Assignment"
                >
                  <span className="card-text-label">
                    {assignment} &nbsp;
                    {coachUploadFile?.document_path_url ? (
                      <>
                        <img
                          src={draft}
                          alt="view assignment"
                          className="draft-icon"
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </a>
              </div>
              <div>
                <a
                  className="card-text mx-4"
                  href={employeeUploadFile?.document_path_url}
                  target="_blank"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="View Assignment"
                >
                  <span className="card-text-label">
                    {assignmentSubmission} &nbsp;
                    {employeeUploadFile?.document_path_url ? (
                      <>
                        <img
                          src={draft}
                          alt="view assignment"
                          className="draft-icon"
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </a>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default AssignSessionCard;
