import React, { useEffect, useMemo, useState } from "react";
import SearchBar from "../../../component/SearchBar/SearchBar";
import { FaSort } from "react-icons/fa";
import { Table } from "react-bootstrap";
import { Card } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import * as actions from "../../../Redux/Actions/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import { Strings, coachReportsFilter } from "../../../constant/Strings";
import FilterValues from "../../../component/FieldFilter/FilterValues";
import TablePagination from "../../../component/TablePagination/TablePagination";

const CoachingReportTable = (props: any) => {
  const dispatch = useDispatch();
  const { loadingComponent } = useLoadingIndicator();
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin).id;

  const [expansion, setExpansion] = useState(false);
  const [rowid, setRowid] = useState("");
  const [data, setdata] = useState<any>([]);
  const [order, setorder] = useState("ASC");
  const [page, setPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [pagePerSize, setPagePerSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();

  //  pagination
  const [totalRecords, setTotalRecords] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * pageSize;

  const [isSorted, setisSorted] = useState(false);
  const [sortedData, setsortedData] = useState<any>([]);

  const { no_data_found, view } = Strings;

  const sorting = (col: any) => {
    setisSorted(true);
    if (order === "ASC") {
      const sorted = [...data].sort((a: any, b: any) => {
        // const isReversed = 1;

        // return isReversed * a[col].localeCompare(b[col]);
        if (col == "coachName") {
          if (a?.Coach[0]?.candidateName < b?.Coach[0]?.candidateName) {
            return 1;
          }
          if (a?.Coach[0]?.candidateName > b?.Coach[0]?.candidateName) {
            return -1;
          }
          return 0;
        } else if (col == "completedSession") {
          if (
            a?.CompletedSession[0]?.CompletedSessionCount <
            b?.CompletedSession[0]?.CompletedSessionCount
          ) {
            return 1;
          }
          if (
            a?.CompletedSession[0]?.CompletedSessionCount >
            b?.CompletedSession[0]?.CompletedSessionCount
          ) {
            return -1;
          }
          return 0;
        } else if (col == "activeSession") {
          if (
            a?.ActiveSession[0]?.ActiveSessionCount <
            b?.ActiveSession[0]?.ActiveSessionCount
          ) {
            return 1;
          }
          if (
            a?.ActiveSession[0]?.ActiveSessionCount >
            b?.ActiveSession[0]?.ActiveSessionCount
          ) {
            return -1;
          }
          return 0;
        } else {
          if (a[col] < b[col]) {
            return 1;
          }
          if (a[col] > b[col]) {
            return -1;
          }
          return 0;
        }
      });
      setsortedData(sorted);
      setorder("DSC");
    } else {
      const sorted = [...data].sort((a: any, b: any) => {
        // const isReversed = -1;

        // return isReversed * a[col].localeCompare(b[col]);
        if (a[col] < b[col]) {
          return -1;
        }
        if (a[col] > b[col]) {
          return 1;
        }
        return 0;
      });
      setsortedData(sorted);
      setorder("ASC");
    }
  };

  const coachReport = useSelector(
    (state: any) => state.employeeReducer.addBeACoachReportDetails
  );
  const [onclickSearch, setOnclickSearch] = useState(false);
  const onSearchClicked = (value: any) => {
    setOnclickSearch(value);
  };
  const condition: boolean =
    (filterValue.length == 0 && searchValue == "") ||
    (filterValue.length > 0 && onclickSearch);
  const filterKey = filterValue.map((ele: any) => ele.value);
  useEffect(() => {
    if (condition) {
      dispatch(
        actions.getAllCoachReportPagAction(
          page,
          pagePerSize,
          filterKey,
          searchValue,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
            } else {
              if (res.code === 200) {
                setdata(res.data);
                setTotalPages(res.meta_data.totalPageCount);
                setTotalEntries(res.meta_data.totalEntries);
                setOnclickSearch(false);
              }
            }
          }
        )
      );
    }
  }, [
    coachReport,
    page,
    pagePerSize,
    searchKey,
    filterValue,
    searchValue,
    condition,
  ]);

  const getCoachReport = useSelector(
    (state: any) => state.employeeReducer.getAllCoachReportDetails
  );

  useEffect(() => {
    if (getCoachReport && getCoachReport.data) {
      setdata(getCoachReport.data);
      setTotalRecords(getCoachReport.data.length);
    }
  }, [getCoachReport, data]);

  const COLUMNS: any = [
    {
      Header: "Name Of The Coach",
      accessor: "coachName",
    },
    {
      Header: "Completed Sessions",
      accessor: "completedSession",
    },
    {
      Header: "Active Sessions",
      accessor: "activeSession",
    },

    {
      Header: "Report",
      accessor: "report",
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
    setOnclickSearch(true);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setPage(1);
  };

  const filterChange = (selectedOption: any) => {
    if (selectedOption) {
      if (filterValue.find((obj: any) => obj.value == selectedOption.value)) {
        setFilterValue((prevValue: any) =>
          prevValue.filter((obj: any) => obj.value != selectedOption.value)
        );
      } else {
        setFilterValue((prevValue: any) => [...prevValue, selectedOption]);
      }
    }
  };
  return (
    <>
      {loadingComponent()}

      <div className="be-a-coach-container">
        <div className="be-a-coach-searchbar-wrapper">
          <div className="searchbar-wrapper">
            <SearchBar
              filter={searchValue}
              setFilter={handleSearch}
              onSearchClicked={onSearchClicked}
            />
          </div>

          <div className="be-a-coach-filter-container">
            <select
              id="select-filters"
              value={filterValue}
              onChange={(e: any) => {
                const selectedValue = e.target.value;
                const selectedOption = coachReportsFilter.find(
                  (item: any) => item.value === selectedValue
                );
                if (selectedOption) {
                  filterChange(selectedOption);
                }
              }}
            >
              <option hidden>Filters</option>
              {coachReportsFilter.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </div>

        <FilterValues
          filterValueArray={filterValue}
          onFilterChange={filterChange}
        />

        {data?.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="table-margin">
                <thead className="employeedb">
                  <tr className="custom-table-head-row">
                    {columns?.map((ele: any) => (
                      <React.Fragment key={ele.Header}>
                        <td
                          onClick={() => sorting(ele.accessor)}
                          className="sorting-cursor"
                        >
                          {ele.Header}
                          {ele.Header !== "Action" &&
                          ele.Header !== "" &&
                          ele.Header != "Remark" ? (
                            <span>{<FaSort />}</span>
                          ) : (
                            " "
                          )}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>

                <tbody className="employeedata">
                  {data?.length === 0 ? (
                    <tr>
                      <td colSpan={12}> {no_data_found}</td>
                    </tr>
                  ) : (
                    (isSorted ? sortedData : data)
                      .filter((project: any) =>
                        globalFilter
                          ? project.Coach[0]?.candidateName
                              ?.toLowerCase()
                              .includes(globalFilter) ||
                            project.CompletedSession[0]?.CompletedSessionCount?.toString()
                              .toLowerCase()
                              .includes(globalFilter) ||
                            project.ActiveSession[0]?.ActiveSessionCount?.toString()
                              .toLowerCase()
                              .includes(globalFilter)
                          : project
                      )
                      ?.slice(pageVisited, pageVisited + pagePerSize)
                      .map((ele: any) => (
                        <>
                          <tr key={ele?._id}>
                            <td>
                              {ele?.Coach[0]?.candidateName +
                                " " +
                                ele?.Coach[0]?.middleName +
                                " " +
                                ele?.Coach[0]?.lastname}
                            </td>

                            <td>
                              {ele?.CompletedSession[0]?.CompletedSessionCount
                                ? ele?.CompletedSession[0]
                                    ?.CompletedSessionCount
                                : "-"}
                            </td>
                            <td>
                              {ele?.ActiveSession[0]?.ActiveSessionCount
                                ? ele?.ActiveSession[0]?.ActiveSessionCount
                                : "-"}
                            </td>

                            <td>
                              <Link to={`/report-pdf/${ele.employeeRef}`}>
                                {view}
                              </Link>
                            </td>
                          </tr>
                        </>
                      ))
                  )}
                </tbody>
              </Table>
            </div>

            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pagePerSize}
                setPageSize={setPagePerSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />

              <TablePagination
                totalPages={totalPages}
                currentPage={page}
                onClick={handlePageChange}
              />
            </div>
          </>
        ) : (
          <p className="employeedata">{no_data_found}</p>
        )}
      </div>

      <ToastContainer />
    </>
  );
};

export default CoachingReportTable;
