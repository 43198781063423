import React from "react";
import { Table } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import { useState, useEffect } from "react";
import { icons } from "../../../../../assets";
import next from "../../../../../assets/forward.png";
import back from "../../../../../assets/backward.png";
import Button from "../../../../../component/Buttons/Button";
import ModalComponents from "../../../../../component/ModalComponent/ModalComponent";
import EditWorkAchievements from "./EditWorkAchievements";
import AddWorkAchievements from "./AddWorkAchievements";
import DeleteWorkAchievements from "./DeleteWorkAchievements";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./../../../../../Redux/Actions";
import moment from "moment";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import useLoadingIndicator from "../../../../../component/Loader/Loader";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "./../../../../../component/Toasts/Toasts";

function Work() {
  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const selectedId = localStorage.getItem("selectedId");
  const status = localStorage.getItem("employeeStatus");
  const dispatch = useDispatch();
  const [employeeDetails, setEmployeeDetails] = useState<any>({
    status: "",
  });
  useEffect(() => {
    dispatch(
      actions.getemployeeDetailsAction(loginId, (res: any) => {
        if (res) {
          if (res.error) {
            if (res.error.error_description) {
              showErrorToastMessage(res.error.error_description);
            }
          } else {
            if (res.code === 200) {
              setProfile(res.data[0]);
            }
          }
        }
      })
    );
  }, [loginId]);
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();

  const [info, setInfo] = useState([]);
  const [workAchievements, setWorkAchievements] = useState({
    id: "",
    award: "",
    description: "",
    date: "",
    certificate: "",
  });
  const [deleteId, setDeleteId] = useState("");
  const isFresher: any = localStorage.getItem("fresherStatus");
  let navigate = useNavigate();

  const [showAddWorkAchievements, setShowAddWorkAchievements] = useState(false);

  const handleAddWorkAchievements = () => setShowAddWorkAchievements(true);
  const hideAddWorkAchievements = () => {
    setShowAddWorkAchievements(false);
  };

  const [showEditWorkAchievements, setShowEditWorkAchievements] =
    useState(false);
  const handleEditWorkAchievements = (
    id: string,
    award: any,
    description: any,
    date: any,
    certificate: any
  ) => {
    setWorkAchievements({
      id: id,
      award: award,
      description: description,
      date: date,
      certificate: certificate,
    });
    setShowEditWorkAchievements(true);
  };
  const hideEditWorkAchievements = () => {
    setShowEditWorkAchievements(false);
  };

  const [showDeleteWorkAchievements, setShowDeleteWorkAchievements] =
    useState(false);
  const handleDeleteWorkAchievements = (id: any) => {
    setDeleteId(id);
    setShowDeleteWorkAchievements(true);
  };
  const hideDeleteWorkAchievements = () => {
    setShowDeleteWorkAchievements(false);
  };

  const addEmployeeWorkAchievements = useSelector(
    (state: any) => state.employeeReducer.addEmployeeWorkAchievements
  );
  const deleteEmployeeWorkAchievements = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeeWorkAchievements
  );
  const editEmployeeWorkAchievements = useSelector(
    (state: any) => state.employeeReducer.editEmployeeWorkAchievements
  );

  const dispatchWorkAchievements = useDispatch();
  useEffect(() => {
    showLoadingIndicator();
    dispatchWorkAchievements(actions.getEmployeeWorkAchievementsAction());
    setTimeout(() => {
      hideLoadingIndicator();
    }, 1000);
  }, [
    addEmployeeWorkAchievements,
    editEmployeeWorkAchievements,
    deleteEmployeeWorkAchievements,
  ]);

  const employeeWorkAchievements = useSelector(
    (state: any) => state.employeeReducer.getEmployeeWorkAchievements
  );
  useEffect(() => {
    if (employeeWorkAchievements) {
      if (employeeWorkAchievements.data) {
        setInfo(employeeWorkAchievements.data);
      }
    }
  }, [employeeWorkAchievements, info]);

  const setProfile = (employeeData: any) => {
    setEmployeeDetails({
      status: employeeData && employeeData.status ? employeeData.status : "",
    });
  };
  return (
    <>
      {loadingComponent()}

      <div className="right__container">
        <div className="title">
          <h6>Work Achievements</h6>
          {(status !== "Active" && status !== "Inactive") ||
          (role === "hr" && status === "Active" && selectedId != loginId) ? (
            <>
              <div className="btn-wrapper">
                <Button
                  icon={icons.add_icon}
                  title="Add"
                  onClick={handleAddWorkAchievements}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="col">
          <Table hover striped>
            <thead className="employeedb">
              <tr className="sub-table-header table-head table-head-row">
                <td>Recognition Type</td>
                <td>Description</td>
                <td>Date</td>
                {(status !== "Active" && status !== "Inactive") ||
                (role === "hr" &&
                  status === "Active" &&
                  selectedId != loginId) ? (
                  <>
                    <td>Action</td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
            </thead>
            <tbody className="employeedata">
              {info.length > 0 &&
                info.map((ele: any) => (
                  <tr key={ele.id} className="buttonsize">
                    <td>{ele.award}</td>
                    <td>{ele.description}</td>
                    <td>{moment(ele.date).format("DD-MMM-YYYY")}</td>

                    {(status !== "Active" && status !== "Inactive") ||
                    (role === "hr" &&
                      status === "Active" &&
                      selectedId != loginId) ? (
                      <>
                        <td>
                          <div className="action-btn">
                            <img
                              src={icons.edit_icon}
                              alt="edit_icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              onClick={() => {
                                handleEditWorkAchievements(
                                  ele.id,
                                  ele.award,
                                  ele.description,
                                  ele.date,
                                  ele.certificate
                                );
                              }}
                              className="edit-icon"
                            />
                            <img
                              src={icons.delete_icon}
                              alt="delete-icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Delete"
                              onClick={() =>
                                handleDeleteWorkAchievements(ele.id)
                              }
                              className="edit-icon"
                            />
                          </div>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>

          <ModalComponents
            show={showAddWorkAchievements}
            onHide={hideAddWorkAchievements}
            title="Add WorkAchievement"
            modalBody={
              <AddWorkAchievements
                showAddSkill={handleAddWorkAchievements}
                hideAddWorkAchievements={hideAddWorkAchievements}
              />
            }
            className="modal-lg"
          />

          <ModalComponents
            show={showEditWorkAchievements}
            onHide={hideEditWorkAchievements}
            title="Edit WorkAchievement"
            modalBody={
              <EditWorkAchievements
                id={workAchievements.id}
                award={workAchievements.award}
                description={workAchievements.description}
                date={workAchievements.date}
                certificate={workAchievements?.certificate}
                hideEditWorkAchievements={hideEditWorkAchievements}
              />
            }
            className="modal-lg"
          />

          <ModalComponents
            show={showDeleteWorkAchievements}
            onHide={hideDeleteWorkAchievements}
            title="Delete WorkAchievement"
            modalBody={
              <DeleteWorkAchievements
                id={deleteId}
                hideDeleteWorkAchievements={hideDeleteWorkAchievements}
              />
            }
            className="modal-sm"
          />
        </div>
        <div className="row">
          <div className="col-2">
            <button
              className="btn btn-primary btn-next-pre"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Previous"
              onClick={
                isFresher == "false"
                  ? () => navigate("/dbsidebar/certifications")
                  : () => navigate("/dbsidebar/experience")
              }
            >
              <img src={back} alt="icon" />
              Back
            </button>
          </div>
          <div className="col-8">
            <button
              className="btn btn-primary btn-next-pre"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Save & Next"
              onClick={() => navigate("/dbsidebar/skills")}
            >
              Next <img src={next} alt="icon" />
            </button>
          </div>
          <div className="col">
            <button
              className="btn btn-outline-primary btn-skip"
              onClick={() => navigate("/dbsidebar/skills")}
            >
              Skip
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Work;
