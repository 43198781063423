import React, { useState } from 'react';
import "./YearAndMonth.scss"
const YearAndMonthDropdown = (props:any) => {
    const { onYearChange,onMonthChange} = props;

    const months = [
        { name: "January", value: 1 },
        { name: "February", value: 2 },
        { name: "March", value: 3 },
        { name: "April", value: 4 },
        { name: "May", value: 5 },
        { name: "June", value: 6 },
        { name: "July", value: 7 },
        { name: "August", value: 8 },
        { name: "September", value: 9 },
        { name: "October", value: 10 },
        { name: "November", value: 11 },
        { name: "December", value: 12 }
    ];

    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = 2010; i <= currentYear; i++) {
        years.push(i);
    }


    const handleMonthChange = (event:any) => {
        const month = Number(event.target.value);
        onMonthChange(month);
    };

    const handleYearChange = (event:any) => {
        const year = Number(event.target.value);
        onYearChange(year);
    };

   

    return (
        <div className="filter-wrap">
            <div className="skill-filter-container">
                <select id="select-month" onChange={handleMonthChange}>
                    <option value="" hidden> Month</option>
                    {months.map((month, index) => (
                        <option key={index} value={month.value}>{month.name}</option>
                    ))}
                </select>

                <select id="select-year" onChange={handleYearChange}>
                    <option value="" hidden> Year</option>
                    {years.map((year, index) => (
                        <option key={index} value={year}>{year}</option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default YearAndMonthDropdown;
