import React from "react";
import { Card } from "react-bootstrap";
import { Strings } from "../../constant/Strings/index";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assets/index";
import "../ProjectDetailsTab/ProjectDetailsTab.scss";

const HeaderDetailsCard = (props: any) => {
  const {
    navigateTo,
    tabHeading,
    headingValue,
    firstColumnData,
    secondColumnData,
  } = props;
  const { back } = Strings;
  const navigate = useNavigate();
  return (
    <>
      <Card className="invoice-card p-4 mt-3">
        <div className="row">
          <div className="d-flex flex-row justify-content-between">
            <div className="col-md-4">
              <div
                className="d-flex flex-row jutify-content-between back-button"
                onClick={() => navigate(navigateTo)}
              >
                <img
                  src={icons.backIcon}
                  className="backIcon"
                  alt="back button"
                />
                <p className="back-text">{back}</p>
              </div>
              <div>
                <div className="project-heading">{tabHeading}:</div>
                <div className="project-name">{headingValue}</div>
              </div>
            </div>
            <div className="col-md-4">

                {firstColumnData}

            </div>
            <div className="col-md-4">
              <div className="row">
              {secondColumnData}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default HeaderDetailsCard;
