import React from 'react'
import { Card } from 'react-bootstrap';
import "./PotentialCard.scss";

const PotentialCard = (props:any) => {
    return (
      <div className="potential-container">
        <Card className='potential-card-container'>
        <div className="col-9 software">{props.software}</div>
        <div className='col-3 score'>{props.score}</div>
        </Card>
      </div>
    );
  };
  

export default PotentialCard
