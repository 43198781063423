import { useEffect, useMemo, useState } from "react";
import { TiArrowUnsorted } from "react-icons/ti";
import Pagination from "../../../component/Pagination/Pagination";
import { useParams } from "react-router-dom";
import useLoadingIndicator from "../../../component/Loader/Loader";

import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as actions from "../../../Redux/Actions";
import { Card } from "react-bootstrap";
import { showErrorToastMessage } from "../../../component/Toasts/Toasts";
import MainCard from "../../../component/MainCard/MainCard";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { icons } from "../../../assets";
import ShowEntries from "../../../component/ShowEntries/ShowEntries";
import TablePagination from "../../../component/TablePagination/TablePagination";
import { Strings } from "../../../constant/Strings";

const IndividualCoacheeReport = () => {
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  let { coachee_id, coach_id } = useParams();
  const [info, setInfo] = useState([]);
  const [onPage, setOnPage] = useState<Number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState<any>([]);
  const [pagePerSize, setPagePerSize] = useState<any>(10);
  const [totalEntries, setTotalEntries] = useState<any>();
  const [totalRecords, setTotalRecords] = useState(0);

  const { back, no_data_found } = Strings;
  const { backIcon } = icons;

  const sessionDetails = useSelector(
    (state: any) => state.employeeReducer.getAllSessionByEmpIdDetails
  );

  useEffect(() => {
    if (sessionDetails && sessionDetails.data) {
      setInfo(sessionDetails.data);
    }
  }, [sessionDetails, info]);

  const dispatch = useDispatch();
  useEffect(() => {
    showLoadingIndicator();
    dispatch(
      actions.getAllSessionByEmpIdAction(
        onPage,
        pagePerSize,
        searchValue,
        coachee_id,
        coach_id,
        (res: any) => {
          if (res?.error?.error_description) {
            hideLoadingIndicator();
            showErrorToastMessage(res?.error?.error_description);
          } else {
            if (res.code === 200) {
              hideLoadingIndicator();
              setTotalPages(res.meta_data.totalPageCount);
              setTotalEntries(res.meta_data.totalEntries);
            }
          }
        }
      )
    );
  }, [onPage, pagePerSize, searchKey, searchValue]);

  const ResourceAddingColumns: any = [
    {
      Header: "Session Number",
      accessor: "sessionNumber",
    },
    {
      Header: "Session Name",
      accessor: "topicName",
    },

    {
      Header: "Session Date",
      accessor: "sessionDate",
      Cell: (Action: any) => {
        const sessionDate = Action.row.original.sessionDate;
        return moment(sessionDate).format("DD/MMM/YYYY");
      },
    },
    {
      Header: "Due Date",
      accessor: "dueDate",
      Cell: (Action: any) => {
        const dueDate = Action.row.original.dueDate;
        return moment(dueDate).format("DD/MMM/YYYY");
      },
    },
    {
      Header: "Employee Rating",
      accessor: "employeeRating",
    },
    {
      Header: "Employee Feedback",
      accessor: "employeeFeedBack",
    },
  ];

  const data = info;
  const columns = useMemo(() => ResourceAddingColumns, []);

  const {
    getTableProps,
    getTableBodyProps,
    page,
    rows,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    { columns, data, initialState: { pageSize: 10, pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handlePageChange = (newPage: any) => {
    setOnPage(newPage);
  };

  return (
    <>
      {loadingComponent()}
      <MainCard>
        <Link to={`/report-pdf/${coach_id}`} className="report-pdf-link">
          <img src={backIcon} className="backIcon" alt="icon" />
          <span>{back}</span>
        </Link>

        {data.length > 0 ? (
          <div>
            {/* Table  */}
            <div className="table-responsive">
              <div className="table">
                <table
                  className="table-main table table-sm mt-3"
                  {...getTableProps()}
                >
                  <thead className="table-head">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className="table-head-row"
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column: any) => (
                          <th
                            className="table-head-row"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            scope="col"
                          >
                            {column.render("Header")}

                            <span>
                              {column.id !== "action" &&
                              column.id !== "id" &&
                              column.id !== "remarks" ? (
                                column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TiArrowUnsorted />
                                  ) : (
                                    <TiArrowUnsorted />
                                  )
                                ) : (
                                  <TiArrowUnsorted />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="table-body" {...getTableBodyProps()}>
                    {rows.map((row: any) => {
                      prepareRow(row);
                      return (
                        <tr className="table-body-row" {...row.getRowProps()}>
                          {row.cells.map((cell: any) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="show-entries-wrapper">
              <ShowEntries
                pageSize={pagePerSize}
                setPageSize={setPagePerSize}
                totalRecords={totalRecords}
                totalEntries={totalEntries}
              />

              <TablePagination
                totalPages={totalPages}
                currentPage={onPage}
                onClick={handlePageChange}
              />
            </div>
          </div>
        ) : (
          <p className="no-data">{no_data_found}</p>
        )}
      </MainCard>
    </>
  );
};

export default IndividualCoacheeReport;
