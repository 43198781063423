import { useEffect, useState } from "react";
import * as actions from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import {
  Strings,
  competencyValidationStrings,
} from "../../../constant/Strings";
import useLoadingIndicator from "../../../component/Loader/Loader";
import Select from "react-select";

const AddorEditStreams = (props: any) => {
  const {
    editData,
    loginId,
    setShowAddOrEditStreams,
    setEditData,
    handleApiCall,
  } = props;
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const [btnDisable, setBtnDisable] = useState(false);

  const [StreamsForm, setStreamsForm] = useState<any>({
    streamName: editData.streamName ?? "",
    leader: editData
      ? {
          label:
            editData.leadersData[0].candidateName +
            editData.leadersData[0].middleName +
            editData.leadersData[0].lastname,
          value: editData.leaderRef,
        }
      : "",
  });

  const [errors, setErrors] = useState({
    streamName: "",
  });

  const { submitSuccess, cancel, submit, editSuccess } = Strings;
  const [leaderOptions, setLeaderOption] = useState([]);

  useEffect(() => {
    if (StreamsForm.streamName && StreamsForm.leader) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [StreamsForm]);

  const dispatch = useDispatch();
  const handleClearForm = () => {
    setStreamsForm({
      streamName: "",
      leader: "",
    });
  };

  let valid: boolean = true;
  const validate = () => {
    if (StreamsForm.streamName == "") {
      valid = false;
      setErrors((errorObj) => ({
        ...errorObj,
        certificateName: "streamName cannot be empty",
      }));
      setBtnDisable(false);
    } else {
      setErrors((errorObj) => ({
        ...errorObj,
        streamName: "",
      }));
    }
  };
  const handleStreamSubmit = (e: any) => {
    e.preventDefault();
    validate();
    if (valid) {
      handleDispatch();
      setBtnDisable(true);
    }
    setBtnDisable(false);
  };
  const handleDispatch = () => {
    showLoadingIndicator();
    const payloadObj: any = {
      leaderRef: StreamsForm.leader?.value,
      streamName: StreamsForm.streamName,
    };
    if (editData) {
      payloadObj.updatedBy = loginId;
    } else {
      payloadObj.createdBy = loginId;
    }
    if (editData) {
      dispatch(
        actions.UpdateStreanAction(
          "stream",
          editData._id,
          payloadObj,
          (res: any) => {
            if (res?.error?.error_description) {
              showErrorToastMessage(res?.error?.error_description);
              hideLoadingIndicator();
            } else {
              if (res?.code === 200) {
                showSuccessToastMessage(editSuccess);
                handleClearForm();
                hideLoadingIndicator();
                setShowAddOrEditStreams(false);
                setEditData("");
                handleApiCall();
              }
            }
          }
        )
      );
    } else {
      dispatch(
        actions.AddStreamAction("stream", payloadObj, (res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
            hideLoadingIndicator();
          } else {
            if (res?.code === 200) {
              showSuccessToastMessage(submitSuccess);
              handleClearForm();
              hideLoadingIndicator();
              setShowAddOrEditStreams(false);
              setEditData("");
              handleApiCall();
            }
          }
        })
      );
    }
  };

  useEffect(() => {
    if (loginId) {
      dispatch(
        actions.getLeaderListAction((res: any) => {
          if (res?.error?.error_description) {
            showErrorToastMessage(res?.error?.error_description);
          } else {
            if (res?.code === 200) {
              const options = res.data.map((item: any) => ({
                label: `${item.candidateName}  ${item.middleName}  ${item.lastname} `,
                value: item._id,
              }));
              setLeaderOption(options);
            }
          }
        })
      );
    }
  }, []);
  return (
    <>
      <div>
        <div>
          <label className="form-label">Stream</label>
          <span className="required">*</span>
          <input
            type="text"
            className="form-control"
            placeholder="Stream name"
            value={StreamsForm.streamName}
            onChange={(e) =>
              setStreamsForm({
                ...StreamsForm,
                streamName: e.target.value,
              })
            }
          />
          {errors.streamName != "" && (
            <p className="error">{errors.streamName}</p>
          )}
        </div>
        <div>
          <label className="form-label">Leader</label>
          <span className="required">*</span>
          <Select
            className="dropdown-select"
            options={leaderOptions}
            onChange={(option: any) => {
              setStreamsForm({
                ...StreamsForm,
                leader: option,
              });
            }}
            closeMenuOnSelect={true}
            placeholder="Select Leader"
            value={StreamsForm.leader}
          />
        </div>

        <div className="modal-btn-wrapper mt-4 mb-4">
          <div>
            <button
              className="btn cancel-btn buttonsize"
              onClick={() => {
                setEditData("");
                setShowAddOrEditStreams(false);
              }}
            >
              {cancel}
            </button>
          </div>

          <div>
            <button
              className={
                btnDisable
                  ? "btn add-submit-button buttonsize"
                  : "btn add-submit-button btn-disabled buttonsize"
              }
              onClick={handleStreamSubmit}
            >
              {submit}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddorEditStreams;
