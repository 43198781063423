import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../../component/Toasts/Toasts";
import * as actions from "../../../Redux/Actions/index";
import "./BeACoach.scss";
import Select from "react-select";
import {
  Strings,
  beACoachAddLabels,
  beACoachDropdown,
} from "../../../constant/Strings/index";

const EditBeACoachModal = (props: any) => {
  const { hideEditBeACoach, editData, apiCallCompleted } = props;
  const { cancel, submit } = Strings;
  const [btnDisable, setBtnDisable] = useState(false);
  const id = editData?._id;
  const { yearsOfExperienceLabel, expertiseLabel, LocationLabel } =
    beACoachAddLabels;
  const [beACoachData, setbeACoachData] = useState<any>({
    yearsOfExperience: editData?.yearsOfExperience,
    location: editData?.location,
    expertise: editData?.expertise,
    slotStatus: editData?.slotStatus,
    recordStatus: "Updated",
  });

  const [showDropdown, setShowDropdown] = useState(false);

  const dispatch = useDispatch();
  const handlebeACoachSubmit = () => {
    apiCallCompleted(true);
    dispatch(
      actions.editBeACoachAction(id, beACoachData, (res: any) => {
        if (res?.error?.error_description) {
          showErrorToastMessage(res?.error?.error_description);
          apiCallCompleted(false);
        } else {
          if (res.code === 200) {
            showSuccessToastMessage("Edited successfully");
            apiCallCompleted(false);
          }
        }
      })
    );
    hideEditBeACoach();
  };

  useEffect(() => {
    if (
      beACoachData.yearsOfExperience &&
      beACoachData.expertise &&
      beACoachData.location
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [beACoachData]);

  const NumberSelect = () => {
    const options = [];
    for (let i = 1; i <= 30; i++) {
      options.push({
        label: i.toString(),
        value: i,
      });
    }
    return options;
  };
  const NumberOptions = NumberSelect();
  const disableCodition = editData?.recordStatus != "Rejected";

  return (
    <>
      {
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label">{yearsOfExperienceLabel}</label>
            <span className="required">*</span>
            <Select
              isDisabled={disableCodition}
              className="dropdown-select"
              options={NumberOptions}
              onChange={(selected: any) => {
                setbeACoachData({
                  ...beACoachData,
                  yearsOfExperience: selected.label,
                });
              }}
              closeMenuOnSelect={true}
              placeholder={beACoachData.yearsOfExperience}
            />
          </div>

          <div className="col-md-4">
            <label className="form-label">{expertiseLabel}</label>
            <span className="required">*</span>
            <input
              disabled={disableCodition}
              type="text"
              className="form-control"
              placeholder="Expertise"
              value={beACoachData.expertise}
              onChange={(e) =>
                setbeACoachData({
                  ...beACoachData,
                  expertise: e.target.value,
                })
              }
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="inputName4" className="form-label">
              {LocationLabel}
            </label>
            <span className="required">*</span>
            <Dropdown
              className="qualification-dropdown"
              onSelect={(eventKey: any) =>
                setbeACoachData({
                  ...beACoachData,
                  location: eventKey,
                })
              }
            >
              <Dropdown.Toggle
                variant="light"
                className="form-control"
                disabled={disableCodition}
              >
                {beACoachData.location}
              </Dropdown.Toggle>
              <Dropdown.Menu show={showDropdown}>
                {beACoachDropdown.map((element: any) => (
                  <Dropdown.Item eventKey={element}>{element}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="modal-btn-wrapper mt-4 mb-4">
            <div>
              <button
                className="btn cancel-btn buttonsize"
                onClick={hideEditBeACoach}
              >
                {cancel}
              </button>
            </div>

            <div>
              <button
                className={
                  btnDisable
                    ? "btn add-submit-button buttonsize"
                    : "btn add-submit-button btn-disabled buttonsize"
                }
                onClick={handlebeACoachSubmit}
              >
                {submit}
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default EditBeACoachModal;
